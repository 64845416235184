<template>
  <div v-if="Object.keys(mapObjectType).length > 0">
    <Breadcrumb
      :id="mapObjectType.id"
      :name="nameLabel"
      :type="mapObjectType.name"
      :showBackButton="true"
      @backButtonPressed="clickBack"
    >
      <template v-slot:icon>
        <IconByTypeId
          :map_object_type_id="parseInt($route.params.type, 10)"
          label=""
          :size="39"
        />
      </template>
    </Breadcrumb>
    <CollapsePanel
      :title="$t('route_management.object_information')"
      :loading=loading
      :showCount=false
      :collapsed="true"
      :showCollapseAction="false"
    >
      <div class="create-object-content">
        <div v-if="$route.params.id" class="field">
          <label for="parent_id">{{ $t('model.parent') | capitalize }}:</label>
          <span id="parent_id">
            <IconByTypeId
              v-if="parent"
              :map_object_type_id="parent.map_object_type_id"
              :label="parent | mapObjectLabel"
              :size="30"
            />
            {{ parentLabel }}
          </span>
        </div>
        <div class="field">
          <label for="name">{{ $t('model.name') | capitalize }}:</label>
          <input
            id="name"
            name="name"
            type="text"
            v-model="mapObject.name"
            :class="{'error': errors.name !== null}"
            @change="resetError('name')"
            autofocus
          />
          <span class="input-error">
            {{ errors.name }}
          </span>
        </div>
        <div class="field">
          <label for="map_object_status_id" class="status-label">
            {{ $t('model.status') | capitalize }}:
          </label>
          <div class="field content status-select">
            <MultiSelect
              id="map_object_status_id"
              :options="Object.values(mapObjectStatuses)"
              v-model="mapObjectStatus"
              track-by="id"
              label="name"
              :multiple="false"
              :close-on-select="true"
              :allow-empty="false"
              :preselect-first="true"
              placeholder=""
              selectLabel=""
              selectedLabel=""
              deselectLabel=""
              @input="updateStatus"
            />
          </div>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel
      :title="$t('selections.attribute_info')"
      :loading="false"
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="create-object-content">
        <div v-for="(attribute, idx) in mapObjectType.attribute.data"
             :key="idx"
             class="attribute-field"
        >
          <span class="title">
              <span v-if="attribute.required" class="required">*</span>
              {{ attribute.name }}:
          </span>
          <div class="field content">
            <AttributeInput
              :disabled="false"
              :attribute="attribute"
              :error="errors[attribute.id]"
            />
          </div>
        </div>
      </div>
    </CollapsePanel>
    <div class="create-object-buttons">
      <a class="btn grey" @click="clickBack">
        {{ $t('button.cancel') | capitalize }}
      </a>
      <MultiButton
        @save_and_show="saveAndShow"
        @save_and_index="saveAndIndex"
        @save_and_edit="saveAndEdit"
        @save_and_create="saveAndCreate"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MultiSelect from 'vue-multiselect';

import MapObjectApi from '@/api/map_object';

import IconByTypeId from '@/components/IconByTypeId.vue';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import AttributeInput from '@/components/AttributeInput.vue';
import MultiButton from '@/components/Layout/MultiButton.vue';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';
import GeoType from '@/enums/GeoType';

export default {
  name: 'drawing.create_object',
  components: {
    MultiButton,
    MultiSelect,
    AttributeInput,
    CollapsePanel,
    Breadcrumb,
    IconByTypeId,
  },
  computed: {
    ...mapGetters({
      mapObjectStatuses: 'settings/getMapObjectStatuses',
      mapObjectTypes: 'settings/getMapObjectTypes',
      network: 'settings/getNetworkModel',
      user: 'user',
    }),
    nameLabel() {
      if (this.mapObjectType && this.mapObjectType.name) {
        return `${this.mapObjectType.name} toevoegen`;
      }
      return '';
    },
    parentLabel() {
      let type = '';
      let name = '';
      if (this.parent !== null) {
        type = Object.values(this.mapObjectTypes)
          .filter(item => item.id === this.parent.map_object_type_id)[0].name;
        name = this.$options.filters.mapObjectLabel(this.parent);
      }
      return `${type} ${name}`;
    },
  },
  data() {
    return {
      loading: false,
      mapObject: {
        name: '',
        map_object_status_id: 0,
        map_object_type_id: 0,
        parent_id: null,
      },
      mapObjectType: {},
      mapObjectStatus: null,
      errors: {
        name: null,
      },
      parent: null,
      coordinates: [],
      startPoint: null,
      endPoint: null,
      hasOwnGeo: false,
    };
  },
  methods: {
    clickBack() {
      EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
      this.$router.back();
    },
    async saveAndShow() {
      const mapObjectId = await this.save();
      if (mapObjectId !== false && Number.isInteger(mapObjectId)) {
        EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
        this.$router.push({ name: 'drawing.show', params: { id: mapObjectId } });
      }
    },
    async saveAndIndex() {
      const mapObjectId = await this.save();
      if (mapObjectId !== false && Number.isInteger(mapObjectId)) {
        EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
        this.$router.push({ name: 'drawing' });
      }
    },
    async saveAndEdit() {
      const mapObjectId = await this.save();
      if (mapObjectId !== false && Number.isInteger(mapObjectId)) {
        EventBus.$emit(
          'Map.changeMapMode',
          MapMode.objectUpdate,
          this.mapObjectType.geo_type,
          mapObjectId,
        );
        this.$router.push({ name: 'drawing.edit', params: { id: mapObjectId } });
      }
    },
    async saveAndCreate() {
      const mapObjectId = await this.save();
      if (mapObjectId !== false && Number.isInteger(mapObjectId)) {
        this.mapObjectType = this.getCurrentType();
        this.setHasOwnGeo();
        this.$set(this.mapObjectType, 'attribute', this.getCurrentType().attribute);
        const typeId = parseInt(this.$route.params.type, 10);
        // eslint-disable-next-line prefer-destructuring
        this.mapObjectStatus = Object.values(this.mapObjectStatus)[0];
        this.mapObject = {
          name: '',
          map_object_status_id: this.mapObjectStatus.id,
          map_object_type_id: typeId,
          parent_id: this.$route.params.id,
        };
        this.$forceUpdate();
        this.fetchNameForNewObject(typeId);
      }
    },
    save() {
      if (!this.validateFields()) {
        return false;
      }
      this.loading = true;

      // Add the created_by field to the selection with the current user ID
      this.mapObject.created_by = this.user.id;

      const data = {
        data: JSON.parse(JSON.stringify(this.mapObject)),
      };
      data.data.attribute = {
        data: JSON.parse(JSON.stringify(this.mapObjectType.attribute.data)),
      };
      data.data.attribute.data = data.data.attribute.data.filter(attribute => Object.hasOwn(attribute, 'value'));
      if (this.hasOwnGeo) {
        if (this.coordinates.length === 0) {
          this.$notify({
            type: 'warning',
            title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
            text: this.$t('validation.no_coordinates'),
          });
          this.loading = false;
          return false;
        }
        data.data.geoObject = {
          data: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: this.coordinates,
            },
            properties: {
              country_code: 528,
            },
          },
        };
        if (this.mapObjectType.geo_type === GeoType.linestring) {
          data.data.geoObject.data.geometry.type = 'LineString';
          data.data.geoObject.data.properties.start_point = this.startPoint;
          data.data.geoObject.data.properties.end_point = this.endPoint;
        } else if (this.mapObjectType.geo_type === GeoType.polygon) {
          data.data.geoObject.data.geometry.type = 'Polygon';
        }
      } else {
        data.data.geo_object_id = this.parent.geo_object_id;
      }

      return MapObjectApi.createMapObject(data)
        .then((response) => {
          this.loading = false;
          this.$notify({
            type: 'success',
            title: this.$t('dialog.created', {
              model: this.$options.filters.capitalize(this.$tc('models.mapobject', 1)),
              name: this.mapObject.name,
            }),
          });
          EventBus.$emit('Map.redrawMapObject', response.data);
          // EventBus.$emit('Map.redraw');
          return response.data.id;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
            data: error.response,
          });
          this.loading = false;
          return false;
        });
    },
    validateFields() {
      let valid = true;
      if (this.mapObject.name === '') {
        this.errors.name = this.$t('validation.name_empty');
        this.$notify({
          type: 'warning',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
          text: this.$t('validation.name_empty'),
          duration: 1000,
        });
        valid = false;
      }
      this.mapObjectType.attribute.data.forEach((attribute) => {
        if (attribute.required
          && (attribute.value === undefined || attribute.value === null || attribute.value === '')
        ) {
          this.errors[attribute.id] = true;
          this.$notify({
            type: 'warning',
            title: this.$t('error.create_failed', { model: this.$tc('models.attribute', 1) }),
            text: this.$t('validation.field_empty', { field: attribute.name }),
            duration: 1000,
          });
          valid = false;
        }
        return attribute;
      });
      return valid;
    },
    resetError(field) {
      this.errors[field] = null;
    },
    updateStatus(value) {
      if (!value) {
        this.mapObject.map_object_status_id = null;
      } else {
        this.mapObject.map_object_status_id = value.id;
      }
    },
    getCurrentType() {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return {};
      }
      if (Object.values(this.mapObjectTypes).length === 0) {
        return {};
      }
      return JSON.parse(JSON.stringify(this.mapObjectTypes[this.$route.params.type]));
    },
    getParent() {
      this.loading = true;
      MapObjectApi.getMapObjectbyId(this.mapObject.parent_id, 'mapObjectLabel').then((response) => {
        this.parent = response.data;
        this.loading = false;
      });
    },
    fetchNameForNewObject(typeId) {
      MapObjectApi.getNameForNewObject(typeId).then((response) => {
        if (response && response.data && response.data.name) {
          this.mapObject.name = response.data.name;
        }
      });
    },
    setHasOwnGeo() {
      if (this.mapObjectType
        && this.mapObjectType.mapObjectTypeSetting
        && this.mapObjectType.mapObjectTypeSetting.data
        && this.mapObjectType.mapObjectTypeSetting.data.length > 0
      ) {
        this.hasOwnGeo = this.mapObjectType.mapObjectTypeSetting.data.find(setting => setting.name === 'hasOwnGeo');
      }
    },
  },
  mounted() {
    if (this.$route.params.id !== null && this.$route.params.id !== undefined) {
      this.mapObject.parent_id = this.$route.params.id;
      this.getParent();
    }
    if (this.$route.params.type !== null && this.$route.params.type !== undefined) {
      const typeId = parseInt(this.$route.params.type, 10);
      this.mapObject.map_object_type_id = typeId;
      this.mapObjectType = this.getCurrentType();
      this.fetchNameForNewObject(typeId);
      this.setHasOwnGeo();
      if (this.hasOwnGeo) {
        EventBus.$emit(
          'Map.changeMapMode',
          MapMode.objectCreate,
          this.mapObjectType.geo_type,
          typeId,
        );
      }
    }
    EventBus.$on('Map.clickMap', (lat, lng) => {
      this.coordinates = [lng, lat];
    });

    EventBus.$on('Map.shareCoordinates', (coordinates) => {
      this.coordinates = coordinates;
    });

    EventBus.$on('Map.shareStartPoint', (startPoint) => {
      this.startPoint = startPoint;
    });

    EventBus.$on('Map.shareEndPoint', (endPoint) => {
      this.endPoint = endPoint;
    });
  },
};
</script>

<style scoped>
.field {
  overflow: visible;
  min-height: 40px;
}

.field label {
  width: auto;
}

.create-object-content {
  padding: 10px 20px 0 20px;
  overflow: inherit;
}

.status-label {
  display: inline-flex;
  float: initial;
}

.status-select {
  display: inline-flex;
  float: initial;
  overflow: inherit;
}

.attribute-field {
  overflow: inherit;
}

.create-object-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}

.create-object-buttons .save-button {
  float: right;
  margin-right: 7px;
}

.field span#parent_id {
  font-weight: 300;
  font-size: 1em;
  float: right;
  line-height: 36px;
}

.input-error {
  float: left;
  width: 100%;
  color: #ff7676;
}

input.error {
  border-color: #ff7676;
}
</style>
