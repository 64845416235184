<template>
  <div @click="closeModal" :class="['modal-container', {'show': visible}]" style="">
    <div @click="closeModal" class="modal-mask"></div>
    <div @click="closeModal" class="modal-wrap">
      <div class="modal" v-on:click.stop>
        <Loading :visible="loading || downloadLoading" />
        <div class="modal-content" v-on:click.stop>
          <div class="modal-header">
            <div class="close-button" @click="closeModal">
              <font-awesome-icon icon="times" />
            </div>
            <div class="save-button" @click="downloadImage">
              <font-awesome-icon icon="save" />
            </div>
          </div>
          <div v-if="images.length > 0 && images[index]" class="modal-body no-padding">
            <div :class="['image-left-arrow', {'disabled': index === 0}]"
                 @click="clickPrevious" v-on:click.stop
            >
              <font-awesome-icon icon="chevron-left" />
            </div>

            <div :class="['image-right-arrow', {'disabled': index === (images.length-1)}]"
                 @click="clickNext" v-on:click.stop
            >
              <font-awesome-icon icon="chevron-right" />
            </div>

            <img v-if="images && images[index]"
                 class="modal-image"
                 :src="images[index].link"
            />
            <div>
              {{$t('model.created_at')}}: {{images[index].created_at | dateTime}}
            </div>
            <div class="modal-image-number">
              {{ $t('actionlog.number_of_total', { number: index+1, total: images.length }) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import Loading from '../Loading.vue';

export default {
  name: 'ImagesModal',
  components: { Loading },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showModal: true,
      downloadLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    updateIndex(index) {
      this.$emit('updateIndex', index);
    },
    downloadImage() {
      const image = this.images[this.index];
      this.downloadLoading = true;
      let extension = '';
      if (image.id !== null) {
        extension = '.jpg';
      }

      axios({
        url: image.link,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', image.resource + extension);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.downloadLoading = false;
      });
    },
    clickPrevious() {
      if (this.index > 0) {
        this.updateIndex(this.index - 1);
      }
    },
    clickNext() {
      if (this.index < (this.images.length - 1)) {
        this.updateIndex(this.index + 1);
      }
    },
  },
};
</script>

<style scoped>
  .modal-container {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    z-index: 1000;
  }
  .modal-container.show {
    display: block;
    transition: opacity 0.3s ease-out;
    opacity: 1;
  }
  .modal-mask {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    z-index: 1000;
  }

  .modal-wrap {
    display: none;
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    outline: 0;
  }

  .modal-container.show .modal-mask,
  .modal-container.show .modal-wrap {
    display: block;
  }

  .modal {
    width: fit-content;
    border-radius: 10px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 0;
    list-style: none;
    position: relative;
    margin: 0 auto;
    max-width: 100vw;
    max-height: 100vh;
  }

  .modal-content {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    background-clip: padding-box;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .modal-header {
    height: 64px;
    padding: 16px 24px;
    border-radius: 10px 10px 0 0;
    background: #fff;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid #e8e8e8;
    font-size: 1.3em;
  }

  .modal-body {
    position: relative;
    padding: 24px;
    line-height: 1.5;
    word-wrap: break-word;
    border-left: 5px solid white;
    border-right: 5px solid white;
    border-bottom: 5px solid white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .modal-body.no-padding {
    padding: 0;
  }

  .modal-content .modal-image {
    max-width: calc(100vw - 160px);
    max-height: calc(100vh - 70px);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .close-button {
    float: right;
    font-size: 1.5em;
    line-height: 28px;
    display: block;
  }
  .close-button:hover {
    color: #ee335f;
    cursor: pointer;
  }

  .save-button {
    float: left;
    font-size: 1.5em;
    line-height: 28px;
    display: block;
  }
  .save-button:hover {
    color: silver;
    cursor: pointer;
  }

  .modal-body .image-left-arrow,
  .modal-body .image-right-arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    font-size: 1.5em;
    padding: 0;
    width: 40px;
    height: 100%;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
  }

  .modal-body .image-left-arrow {
    left: 0;
  }

  .modal-body .image-left-arrow svg {
    position: relative;
    left: -3px;
  }

  .modal-body .image-right-arrow {
    right: 0;
  }

  .modal-body .image-left-arrow.disabled,
  .modal-body .image-right-arrow.disabled {
    display: none;
  }
  .modal-body .image-left-arrow:hover,
  .modal-body .image-right-arrow:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    color: rgba(0, 0, 0, 0.5);
  }

  .modal-body .modal-image-number {
    position: relative;
    width: 100%;
    text-align: right;
    padding: 0 50px;
  }

</style>
