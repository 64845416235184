<template>
  <span  class="breadcrumb-parents">
    <ul>
      <li>
        <a href="javascript:void(0)" @click="clickParent('network', network.id)">
          <div class="parent-icon">
            <MapIcon
              v-bind="networkIcon"
              :size="20"
            />
          </div>
          <div v-if="network" class="parent-title">
            {{ network.name }}
          </div>
        </a>
      </li>
      <li v-for="(parent, index) in parents" :key="index">
        <a href="javascript:void(0)" @click="clickParent('map_object', parent.id)">
          <div class="parent-icon">
            <IconByTypeId
              :map_object_type_id="parent.map_object_type_id"
              :label="parent | mapObjectLabel"
              :size="20"
            />
          </div>
          <div class="parent-title">
            {{ parent | mapObjectLabel }}
          </div>
        </a>
      </li>
    </ul>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

import IconByTypeId from '@/components/IconByTypeId.vue';
import MapIcon from '@/components/Map/MapIcon.vue';

import NetworkIcon from '@/enums/NetworkIcon';

export default {
  name: 'BreadcrumbParents',
  components: { MapIcon, IconByTypeId },
  props: {
    parents: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
  },
  data() {
    return {
      networkIcon: NetworkIcon,
    };
  },
  methods: {
    clickParent(model, id) {
      this.$emit('clickParent', model, id);
    },
  },
};
</script>

<style scoped>
.breadcrumb-parents ul {
  margin: 0;
  padding: 0;
}

.breadcrumb-parents ul li {
  float: left;
  display: block;
  list-style: none;
  border-right: 1px solid #bec6d1;
  line-height: 1.8em;
}

.breadcrumb-parents ul li:first-child {
  border-left: 1px solid #bec6d1;
}

.breadcrumb-parents ul li:nth-last-child(1) {
  background: #f6f8ff;
}

.breadcrumb-parents ul li:nth-last-child(2) {
  background: #eef2f9;
}

.breadcrumb-parents ul li:nth-last-child(3) {
  background: #e2e9f3;
}

.breadcrumb-parents ul li:nth-last-child(4) {
  background: #d4dbe6;
}

.breadcrumb-parents ul li:nth-last-child(5) {
  background: #c6cedb;
}

.breadcrumb-parents ul li a {
  display: block;
  height: 34px;
  position: relative;
  text-decoration: none;
  color: #42464c;
  letter-spacing: 0;
  cursor: pointer;
  font-size: 1.0em;
  margin: 0;
  padding: 0;
  border-top: 1px solid #bec6d1;
  border-bottom: 1px solid #bec6d1;
}

.breadcrumb-parents ul li a .parent-icon {
  float: left;
  margin: 0 5px 0 5px;
  width: 30px;
  height: 30px;
}

.breadcrumb-parents ul li a .parent-title {
  display: block;
  float: left;
  line-height: 30px;
  font-size: .8em;
  margin-right: 10px;
  width: auto;
}
</style>
