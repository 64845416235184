<template>
  <li v-on:click="active ? '' :  $emit('click')"
      :class="{'tabpane-view': true, 'active': active}">
    <span>{{ title }}</span>
  </li>
</template>

<script>

export default {
  name: 'TabPane',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      showCollapse: false,
    };
  },
  methods: {
    toggleOpen() {
      this.showCollapse = !this.showCollapse;
    },
  },
};
</script>

<style scoped>
  .tabpane-view {
    display: table-cell;
    list-style: none;
    line-height: 1.8em;
  }

  .tabpane-view span {
    display: block;
    padding: 0 10px;
    text-decoration: none;
    color: #42464c;
    font-size: .85em;
    cursor: pointer;
    margin: 0;
    outline: 0;
  }

  .tabpane-view.active span {
    cursor: default;
    font-weight: 400;
    background: #f2f7fe;
    border-top: 1px solid #d9dadb;
    border-left: 1px solid #d9dadb;
    border-right: 1px solid #d9dadb;
  }
</style>
