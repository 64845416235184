/* eslint-disable no-shadow,no-param-reassign */
import authService from '@/services/auth';

const state = {
  auth: {
    loading: false,
    token: null,
  },
};

const actions = {
  login({ commit }) {
    commit('login');
    authService.login()
      .then(token => commit('tokenSet', token)).catch(() => {
      });
  },

  setToken({ commit }, object) {
    authService.setAccessToken(object.token);
    commit('tokenSet', object.token);
  },

  logout({ commit }) {
    commit('logout');
  },
};

const mutations = {
  login(state) {
    state.auth = { loading: true, token: null };
  },
  logout(state) {
    state.auth = { loading: true, token: null };
  },
  tokenSet(state, token) {
    state.auth = { loading: false, token };
  },
  tokenUnset(state) {
    state.auth = { loading: false, token: null };
  },
};

const getters = {
  loading: state => state.auth.loading,
  token: state => state.auth.token,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
