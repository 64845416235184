<template>
  <div>
    <Breadcrumb
      :name="user.name"
      :id="user.id"
    >
    <template v-slot:icon>
      <MapIcon color="red" :size="39"/>
    </template>
    </Breadcrumb>
    <DeleteUser v-if="this.deleteuser"
                :visible="this.deleteuser"
                :user="this.user"
                @closeModal="clickDeleteUser" />
    <CollapsePanel
      :title="$t('profile.user')"
      :loading=loading
      :showCount=false
      :collapsed="true"
      :showCollapseAction="true"
      icon="pen"
      @collapseAction="toggleShowCollapseMenu"
    >
      <template v-slot:customCollapseAction v-if="showCollapseMenu">
        <CollapseActionMenu>
          <CollapseActionMenuItem
            :title="$t('button.edit') | capitalize"
            icon="pen"
            @clickItem="toEditUser"
          />
          <CollapseActionMenuItem
            :title="$t('profile.passwordReset') | capitalize"
            icon="pen"
            @clickItem="toRequestNewPassword"
          />
          <CollapseActionMenuItem
            :title="$t('profile.removeAccount') | capitalize"
            icon="pen"
            @clickItem="clickDeleteUser"
          />
        </CollapseActionMenu>
      </template>
      <div class="content-padding">
          <ul class="location-details">
            <li><span>{{ $t('profile.name') | capitalize }}:</span>
              {{ user.name }}</li>
            <li><span>{{ $t('profile.email') | capitalize }}:</span>
              {{user.email}}</li>
            <li v-if="user.userAttribute.data.department">
            <span>{{ $t('profile.department') | capitalize }}:</span>
            {{ user.userAttribute.data.department }}</li>
            <li v-if="user.userAttribute.data.function">
              <span>{{ $t('profile.function') | capitalize }}:</span>
              {{ user.userAttribute.data.function }}</li>
            <li v-if="user.userAttribute.data.phone">
              <span>{{ $t('profile.phone') | capitalize }}:</span>
              {{ user.userAttribute.data.phone }}</li>
            <li v-if="user.userAttribute.data.phone_mobile">
              <span>{{ $t('profile.phone_mobile') | capitalize }}:</span>
              {{ user.userAttribute.data.phone_mobile }}</li>
          </ul>
      </div>
    </CollapsePanel>
    <CollapsePanel
      :title="$t('profile.network')"
      :loading=loading
      :showCount=true
      :collapsed="false"
      :showCollapseAction="false"
      icon="pen"
      :count="networks.length"
      @collapseAction="toggleShowCollapseMenu"
    >
      <div class="content-padding">
        <ul class="location-details">
          <li v-for="network in networks" v-bind:key="network.id">{{network.name}}</li>
        </ul>
      </div>
    </CollapsePanel>
    <CollapsePanel
      :title="$t('profile.teams')"
      :loading=loading
      :showCount=true
      :collapsed="false"
      :showCollapseAction="false"
      icon="pen"
      :count="teams.length"
      @collapseAction="toggleShowCollapseMenu"
    >
      <div class="content-padding">
        <ul class="location-details">
          <li v-for="team in teams" v-bind:key="team.id">{{team.name}}</li>
        </ul>
      </div>
    </CollapsePanel>
    <CollapsePanel
      :title="$t('profile.organisations')"
      :loading=loading
      :showCount=true
      :collapsed="false"
      :showCollapseAction="false"
      icon="pen"
      :count="tenants.length"
      @collapseAction="toggleShowCollapseMenu"
    >
      <div class="content-padding">
        <ul class="location-details">
          <li v-for="tenant in tenants" v-bind:key="tenant.id">{{tenant.name}}</li>
        </ul>
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import NetworkApi from '@/api/network';
import UserApi from '@/api/user';
import CollapseActionMenuItem from '@/components/CollapseActionMenuItem.vue';
import CollapseActionMenu from '@/components/CollapseActionMenu.vue';
import MapIcon from '@/components/Map/MapIcon.vue';
import DeleteUser from '@/modules/profile/DeleteUser.vue';

export default {
  name: 'profile',
  components: {
    Breadcrumb,
    CollapsePanel,
    CollapseActionMenuItem,
    CollapseActionMenu,
    MapIcon,
    DeleteUser,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
  },
  data() {
    return {
      loadingNetwork: false,
      loadingSettings: false,
      networks: [],
      user: [],
      showCollapseMenu: false,
      teams: [],
      tenants: [],
      loading: false,
      customBackAction: {
        type: Boolean,
        required: false,
        default: false,
      },
      deleteuser: false,
    };
  },
  methods: {
    clickBack() {
      if (this.customBackAction) {
        this.$emit('backButtonPressed');
      }
    },
    clickDeleteUser() {
      this.deleteuser = !this.deleteuser;
    },
    toEditUser() {
      this.$router.push({
        name: 'profile.edit',
      });
    },
    toRequestNewPassword() {
      this.$router.push({
        name: 'profile.passwordReset',
      });
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
  },
  async beforeMount() {
    NetworkApi.getNetwork().then((response) => {
      this.networks = response.data;
    });
    await UserApi.getMe().then((response) => {
      this.teams = response.data.team.data;
      this.user = response.data;
    });
    await UserApi.getTenants(this.user.id)
      .then((response) => {
        this.tenants = response.data;
      });
  },
};
</script>
<style>
.location-details li span{
  display: block;
  float: left;
  width: 40%;
}
.location-details {
  font-size:16px;
}
</style>
