import Vue from 'vue';
import moment from 'moment';
import Notifications from 'vue-notification';
import { abilitiesPlugin } from '@casl/vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faFilter, faSortAmountDown, faSortAmountUpAlt, faMapMarkerAlt, faPlus, faQuestionCircle, faCog,
  faMobile, faDesktop, faLink, faTimes, faSave, faPhone, faEnvelope,
  faChevronDown, faChevronUp, faChevronRight, faChevronLeft, faArrowLeft, faTrash, faSearch,
  faChevronCircleDown, faChevronCircleUp, faChevronCircleRight, faChevronCircleLeft,
  faExclamationCircle, faTimesCircle, faInfoCircle, faCheckCircle, faPen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import { router } from './router';
import store from './store';
// import gate from './policies';
import './registerServiceWorker';

// Plugins
import axios from './plugins/axios';
import i18n from './i18n';
import '@/mixins/mapIcon';
import mapFunctions from '@/mixins/mapFunctions';

Vue.mixin({
  methods: mapFunctions,
});

library.add(
  faFilter, faSortAmountDown, faSortAmountUpAlt, faMapMarkerAlt, faPlus, faQuestionCircle,
  faCog, faMobile, faDesktop, faLink, faTimes, faSave, faPhone, faEnvelope,
  faChevronDown, faChevronUp, faChevronRight, faChevronLeft, faArrowLeft, faTrash, faSearch,
  faChevronCircleDown, faChevronCircleUp, faChevronCircleRight, faChevronCircleLeft,
  faExclamationCircle, faTimesCircle, faInfoCircle, faCheckCircle, faPen,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// Todo: move to seperate file
// eslint-disable-next-line consistent-return
axios.interceptors.response.use(response => response, (error) => {
  if (error.response.status === 401) {
    if (error.response.data.error_description) {
      Vue.prototype.$notification.error({
        message: error.response.data.error,
        class: 'notification-info',
        placement: 'bottomRight',
        duration: 4,
        description: error.response.data.error_description,
      });
    }
    if (router.currentRoute && router.currentRoute.name !== 'logout') {
      router.push({ name: 'logout' });
    }
  } else if (error.response.status === 400) {
    Object.keys(error.response.data.data.messages)
      // eslint-disable-next-line array-callback-return
      .map((key) => {
        // eslint-disable-next-line array-callback-return
        error.response.data.data.messages[key].map((message) => {
          Vue.prototype.$notification.info({
            message: 'Validatie',
            description: message,
            placement: 'bottomRight',
            duration: 4,
          });
        });
      });
  } else if (error.response.status === 403) {
    if (error.response.data.data && error.response.data.data.detail) {
      Vue.prototype.$notification.error({
        message: 'Error',
        description: error.response.data.data.detail,
        placement: 'bottomRight',
        duration: 4,
      });
    }
  } else {
    return Promise.reject(error);
  }
});

Vue.config.productionTip = false;

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  return value.toString().charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('capitalizeEachWord', (value) => {
  if (!value) return '';
  return value.toString().split(' ').map(val => val.charAt(0).toUpperCase() + val.slice(1)).join(' ');
});

Vue.filter('dateTime', (value) => {
  if (!value) return '';
  return moment.unix(value).format('DD-MM-YYYY, HH:mm');
});

Vue.filter('dateFromInput', (value) => {
  if (!value) return '';
  return moment(value).format('DD-MM-YYYY');
});

Vue.filter('dateToInput', (value) => {
  if (!value) return '';
  return moment(value).format('YYYY-MM-DD');
});

Vue.filter('mapObjectLabel', (mapObject) => {
  if (!mapObject) {
    return '';
  }
  if (mapObject.mapObjectLabel
    && mapObject.mapObjectLabel.data
    && mapObject.mapObjectLabel.data.label
    && mapObject.mapObjectLabel.data.label.length > 0
  ) {
    return mapObject.mapObjectLabel.data.label;
  }
  if (mapObject.label) {
    return mapObject.label;
  }
  return mapObject.name;
});

Vue.use(abilitiesPlugin, store.getters.ability);
Vue.use(Notifications);


new Vue({
  router,
  store,
  axios,
  i18n,
  // gate,
  render: h => h(App),
}).$mount('#app');
