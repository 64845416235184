<template>
  <div>
    <div>
      <BreadcrumbPublication
        :showBackButton="showBackButton"
        :customBackAction="true"
        @backButtonPressed="goBack"
      />
      <ExportOverview v-if="!selectedExport" @chooseExportType="chooseExportType" />
      <ExportObjects v-else-if="selectedExport === 'objects'" />
      <ExportActions v-else-if="selectedExport === 'actions'" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import EventBus from '@/events/event-bus';

import BreadcrumbPublication from '@/modules/publication/components/BreadcrumbPublication.vue';
import ExportActions from '@/modules/publication/components/ExportActions.vue';
import ExportObjects from '@/modules/publication/components/ExportObjects.vue';
import ExportOverview from '@/modules/publication/components/ExportOverview.vue';

export default {
  name: 'publication',
  components: {
    ExportActions,
    ExportObjects,
    ExportOverview,
    BreadcrumbPublication,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
  },
  data() {
    return {
      loadingNetwork: false,
      loadingSettings: false,
      showBackButton: false,
      selectedExport: null,
    };
  },
  methods: {
    ...mapActions({
      resetMapObject: 'selected/resetMapObject',
    }),
    goBack() {
      this.selectedExport = null;
      this.showBackButton = false;
      this.$emit('showFullscreen', false);
    },
    chooseExportType(exportType) {
      this.selectedExport = exportType;
      this.showBackButton = true;
      this.$emit('showFullscreen', true);
    },
  },
  mounted() {
    this.resetMapObject();
    EventBus.$emit('Map.changeMapMode', null);
  },
};
</script>
