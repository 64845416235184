<template>
  <div id="module-help-modal" :class="{'popup-modal': true, 'open': show}">
    <div class="popup-overlay"></div>
    <div class="popup big">
      <div class="popup-head">
        <!-- Title of module -->
        <div class="heading text-capitalize">{{ content }}</div>

        <a class="close-button close destroyModal"  @click="closeModal">
          <font-awesome-icon icon="times" class="icon icon-close" />
        </a>
      </div>
      <div class="popup-content">
        <div class="container">
          <div class="row">
            <div class="help-module">
              <!-- Modules -->
              <div class="help-module-menu">
                <a id="algemeen" @click="content = 'algemeen'">Algemeen</a>
                <a id="routebeheer" @click="content = 'routebeheer'">Routebeheer</a>
                <a id="beheerplus" @click="content = 'beheerplus'">Beheerplus</a>
                <!--<a id="importeren">Importeren</a>-->
                <!--<a id="tekenen">Tekenen</a>-->
                <!--<a id="selecties">Selecties</a>-->
                <!--<a id="materialen">Materialen</a>-->
                <!--<a id="publicaties">Publicaties</a>-->
              </div>
              <!-- Module content -->
              <div class="help-modal-content">
                <Overview v-if="content === 'algemeen'" />
                <RouteManagement v-if="content === 'routebeheer'" />
                <ManagementPlus v-if="content === 'beheerplus'" />
                <Import v-if="content === 'importeren'" />
                <Drawing v-if="content === 'tekenen'" />
                <Materials v-if="content === 'materialen'" />
                <MSN v-if="content === 'msn'" />
                <Publications v-if="content === 'publicaties'" />
                <Selections v-if="content === 'selecties'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Overview from '@/components/Info/Overview.vue';
import RouteManagement from '@/components/Info/RouteManagement.vue';
import ManagementPlus from '@/components/Info/ManagementPlus.vue';
import Import from '@/components/Info/Import.vue';
import Drawing from '@/components/Info/Drawing.vue';
import Materials from '@/components/Info/Materials.vue';
import MSN from '@/components/Info/MSN.vue';
import Publications from '@/components/Info/Publications.vue';
import Selections from '@/components/Info/Selections.vue';
import EventBus from '@/events/event-bus';

export default {
  name: 'InfoModal',
  components: {
    RouteManagement,
    ManagementPlus,
    Overview,
    Import,
    Drawing,
    Materials,
    MSN,
    Publications,
    Selections,
  },
  data() {
    return {
      show: false,
      content: 'algemeen',
    };
  },
  methods: {
    closeModal() {
      this.show = false;
    },
  },
  mounted() {
    EventBus.$on('InfoModal.show', (contentType) => {
      console.log('InfoModal.show', contentType);
      this.content = contentType;
      this.show = true;
    });
  },
};
</script>

<style scoped>

.close-button {
  //float: right;
  font-size: 2em;
  line-height: 70px;
  margin-right: 10px;
  //display: block;
}
.close-button:hover {
  color: #ee335f;
  cursor: pointer;
}
</style>
