<template>
  <a href="javascript:void(0)" class="collapse-menu-item" @click="$emit('clickItem')"
     v-on:click.stop
  >
    <span class="collapse-menu-icon"><font-awesome-icon size="sm" :icon="icon" /></span>{{ title }}
  </a>
</template>

<script>
export default {
  name: 'CollapseActionMenuItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'pen',
    },
  },
};
</script>

<style scoped>

a.collapse-menu-item {
  display: block;
  width: 100%;
  text-decoration: none;
  line-height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 10px 0 40px;
  position: relative;
  color: #42464c;
  white-space: nowrap;
  touch-action: manipulation;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color .3s;
  border-radius: 10px;
}
a.collapse-menu-item:hover {
  color: #44a7dc;
  background: #f2f7fe;
}
a.collapse-menu-item span.collapse-menu-icon {
  position: absolute;
  left: 12px;
  text-align: center;
}
</style>
