<template>
  <div>

    <div class="info-block">
      <h4>C-TIP gebruiken</h4>
      <p>
        Om gebruik te maken van C-TIP, heeft u een eigen account nodig.
        Deze accountgegevens sturen wij per e-mail op zodra u het pakket bij ons
        afneemt. Een
        account kan niet door meerdere personen tegelijk worden gebruikt en is
        zodoende geschikt voor maar één gebruiker.
      </p>
    </div>
    <div class="info-block">
      <h4>Inloggen</h4>
      <p>
        Nadat u van ons uw inloggegevens voor C-TIP hebt ontvangen, kunt u zelf
        inloggen in het systeem. Dit kan door in de browser naar c-tip.com te gaan.
        Rechts
        bovenin deze website vindt u de knop ‘Inloggen’, wanneer u hierop klikt komt u
        terecht op de inlogpagina.
      </p>

      <img :src="ctipLogin" class="img-login-screen"
           alt="login-screen">

      <p>
        Op de inlogpagina dient u uw accountgegevens in te voeren; uw e-mailadres en
        bijbehorend wachtwoord. Klik vervolgens op ‘Inloggen’. Indien uw account
        toegang heeft tot één netwerk, opent het portaal zich direct. Heeft uw account
        meerdere netwerken? Dan komt u eerst op een pagina terecht waar u het
        netwerk selecteert dat u wilt beheren.
      </p>
      <p>
        Krijgt u bij het inloggen een melding dat de combinatie van het e-mailadres en
        wachtwoord niet klopt? Controleer dan de inloggegevens die u gebruikt en
        probeer het nogmaals. Blijft het fout gaan? Dan kunt u onder de inlogvelden
        kiezen voor ‘Wachtwoord vergeten’, om een
        nieuw wachtwoord in te stellen voor uw account. Indien u bij ‘Wachtwoord
        vergeten’ het e-mailadres van uw account heeft ingevoerd, ontvangt u binnen
        enkele
        minuten een instructiemail om uw wachtwoord opnieuw in te stellen. Ontvangt u
        deze e-mail niet? Dan is het opgegeven e-mailadres niet bekend in ons
        systeem.
      </p>
      <p>
        Neem in dit geval contact met ons op om de juiste accountgegevens te
        verkrijgen.
      </p>
    </div>
    <div class="info-block">
      <h4>Algemene interface</h4>
      <p>
        Wanneer u succesvol ingelogd bent en uw netwerk heeft geopend, komt u terecht
        in het portaal van C-TIP. Standaard opent de Routebeheer- of
        BeheerPlus-module zich, afhankelijk van het pakket dat u afneemt. Zie
        onderstaande afbeelding als voorbeeld.
      </p>
    </div>
    <div class="info-block">
      <h4>Module-balk</h4>
      <p>
        In de grijze balk aan de linkerkant van het scherm vindt u alle module-iconen
        van C-TIP, deze balk blijft altijd zichtbaar. Wanneer u met de muis op een
        icoon gaat staan, wordt de naam van de module getoond. Via deze iconen kunt u
        te allen tijde gemakkelijk wisselen tussen de voor u beschikbare modules.
      </p>
      <p>
        Een uitleg van de mogelijkheden van alle modules, is te vinden op onze
        website: c-tip.com. De modules die voor uw account niet beschikbaar zijn,
        worden
        donker weergegeven (zoals het tweede icoon in onderstaande afbeelding).
      </p>
      <p>
        Onderin de grijze balk is het instellingen-icoon te vinden. Wanneer u hierop
        klikt opent zich een klein menu. Hier kunt u - indien uw account tot
        meerdere netwerken toegang heeft - wisselen van netwerk. Tevens kan via hier
        het eigen profiel geopend (en aangepast) worden, van taal gewisseld worden,
        of het account uitloggen.
      </p>

      <img :src="ctipInterface" class="img-interface"
           alt="">
    </div>
  </div>
</template>

<script>
import ctipInterface from '../../assets/img/helpmodals/ctip-interface.png';
import ctipLogin from '../../assets/img/helpmodals/ctip-login.png';

export default {
  name: 'Overview',
  data() {
    return {
      ctipLogin,
      ctipInterface,
    };
  },
};
</script>
