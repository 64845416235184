import decode from 'jwt-decode';

const ACCESS_TOKEN_KEY = 'access_token';

export default {
  login() {
    return new Promise((resolve, reject) => {
      if (this.hasParameterByName(ACCESS_TOKEN_KEY)) {
        this.setAccessToken();
      }
      if (this.isLoggedIn()) {
        resolve(this.getAccessToken());
      }
      reject();
    });
  },

  logout() {
    this.clearAccessToken();
  },

  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },

  clearAccessToken() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  },

  hasParameterByName(name) {
    return this.getParameterByName(name) !== null;
  },

  getParameterByName(name) {
    const match = RegExp(`[#&]${name}=([^&]*)`)
      .exec(window.location.hash);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  },

  removeParameters() {
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, null, window.location.href.split('#')[0]);
  },

  // Get and store access_token in local storage
  setAccessToken(token = null) {
    const accessToken = token == null ? this.getParameterByName('access_token') : token;
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    this.removeParameters();
    return accessToken;
  },

  isLoggedIn() {
    const accessToken = this.getAccessToken();
    return !!accessToken && !this.isTokenExpired(accessToken);
  },

  // eslint-disable-next-line consistent-return
  getTokenExpirationDate(encodedToken) {
    try {
      const token = decode(encodedToken);

      if (!token.exp) {
        return null;
      }

      const date = new Date(0);
      date.setUTCSeconds(token.exp);

      return date;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Could not decode token');
    }
  },

  isTokenExpired() {
    return false; // Remove when CTIP usage valid JWT tokens
    //
    // const expirationDate = this.getTokenExpirationDate(token);
    // return expirationDate < new Date();
  },

};
