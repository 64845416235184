<template>
  <div :class="{'accordeon': true, 'open': showContent}">
    <div class="accordeon-item-header" @click="toggleOpen">
      <h2>

        <font-awesome-icon v-if="!showContent" icon="chevron-circle-right" class="arrow-icon" />
        <font-awesome-icon v-else icon="chevron-circle-down" class="arrow-icon" />
        {{ title }}
      </h2>
    </div>
    <div class="accordeon-item-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Accordeon',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showContent: false,
    };
  },
  methods: {
    toggleOpen() {
      this.showContent = !this.showContent;
    },
  },
};
</script>

<style scoped>
  .accordeon {
    border-left: 1px solid #d9dadb;
    border-right: 1px solid #d9dadb;
    border-bottom: 1px solid #d9dadb;
    background: #f2f7fe;
    min-height: 50px;
    padding: 0;
  }
  .accordeon:first-of-type {
    border-top: 1px solid #d9dadb;
  }

  .accordeon-item-content {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
  }

  .accordeon-item-header {
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    margin: 0;
    padding: 0 20px;
    position: relative;
    border-bottom: none;
  }

  .accordeon-item-header h2 {
    font-size: 1.2em;
    display: inline;
    color: #42464c;
  }

  .accordeon.open .accordeon-item-header {
    background: #f2f7fe;
    border-bottom: 1px solid #d9dadb;
  }

  .accordeon.open .accordeon-item-content {
    background: #ffffff;
    height: auto;
    padding: 15px;
    overflow: visible;
  }

  .arrow-icon {
    margin-right: 10px;
    font-size: 0.8em;
  }

</style>
