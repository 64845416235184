<template>
  <notifications position="bottom right"
                 width="500px"
                 :max="5"
                 :duration="4000"
  >
    <template slot="body" slot-scope="props">
      <div :class="['notification-wrapper', props.item.type]" @click="props.close">
        <div class="notification-icon">
          <font-awesome-icon v-if="props.item.type === 'success'"
                             icon="check-circle"
          />
          <font-awesome-icon v-else-if="props.item.type === 'warning'"
                             icon="exclamation-circle"
          />
          <font-awesome-icon v-else-if="props.item.type === 'error'"
                             icon="times-circle"
          />
          <font-awesome-icon v-else icon="info-circle" />
        </div>
        <div class="notification-content">
          <div class="notification-title">
            {{props.item.title}}
          </div>
          <div class="notification-text">
            <p v-if="props.item.text">
              {{ props.item.text }}
            </p>
            <div v-if="props.item.data">
              <p v-if="props.item.data.status">
                {{ props.item.data.status }}: {{ $t('error.'+props.item.data.status) }}
              </p>
              <p v-if="props.item.data.data">
                Details: {{props.item.data.data}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'NotificationWrapper',
};
</script>

<style scoped>
  .notification-wrapper {
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 3px;
    padding: 10px;
    color: #ffffff;
    background: #44A4FC;
    border-left: 5px solid #187FE7;
  }
  .notification-wrapper:hover {
    cursor: pointer;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  }
  .notification-wrapper.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }
  .notification-wrapper.success {
    background: #68CD86;
    border-left-color: #42A85F;
  }
  .notification-wrapper.warning {
    background: #ffb648;
    border-left-color: #f48a06;
  }
  .notification-wrapper .notification-icon {
    float: left;
    vertical-align: middle;
    position: relative;
    margin-right: 10px;
    line-height: 23px;
    height: 100%;
    font-size: 1.1em;
  }

  .notification-wrapper .notification-title {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 1.0em;
    margin-bottom: 10px;
  }

  .notification-wrapper .notification-text {
    font-size: 1.0em;
  }
</style>
