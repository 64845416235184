<template>
  <div>
    <BreadcrumbSelection
      :id="parseInt($route.params.type, 10)"
      :name="chosenSelectionType.name"
      :type="$t('selections.new_selection')"
      :showBackButton="true"
      :customBackAction="true"
      @backButtonPressed="goBack"
    />
    <CollapsePanel
      :title="$t('selections.selection_info')"
      :loading=loading
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="create-selection-content">
        <div class="field">
          <label for="selection_name">{{ $t('model.name') | capitalize }}:</label>
          <input
            id="selection_name"
            name="selection_name"
            type="text"
            v-model="selection.name"
            :class="{'error': errors.name !== null}"
            @change="resetError('name')"
          />
          <span class="input-error">
            {{ errors.name }}
          </span>
        </div>
        <div class="field">
          <label>{{ $t('model.description') | capitalize }}:</label>
          <textarea
            id="selection_description"
            v-model="selection.description"
          />
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel
      :title="$t('selections.attribute_info')"
      :loading="attributesLoading"
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="create-selection-content">
        <div v-for="attribute in attributes" :key="attribute.id" class="field attribute-field">
          <label :for="attribute.id">
            {{ attribute.name }}:
          </label>
          <div :id="attribute.id" class="input-select-field">
            <AttributeInput :disabled="false" :attribute="attribute" />
          </div>
        </div>
      </div>
    </CollapsePanel>
    <HandleSelectionObjects :is-network="isNetwork" :is-ordered="isOrdered"
                            :errors="errors.mapObjects" @resetErrors="errors.mapObjects = null"
    />
    <div class="create-selection-buttons">
      <a class="btn grey" @click="toIndexSelections">
        {{ $t('button.cancel') | capitalize }}
      </a>
      <a class="btn module-color-background save-button" @click="saveSelection">
        {{ $t('button.save') | capitalize }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MapObjectSelectionsApi from '@/api/map_object_selection';
import MapObjectSelectionTypesApi from '@/api/map_object_selection_type';

import AttributeInput from '@/components/AttributeInput.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import EventBus from '@/events/event-bus';
import BreadcrumbSelection from '@/modules/selections/components/BreadcrumbSelection.vue';
import MapMode from '@/enums/MapMode';
import HandleSelectionObjects from '@/modules/selections/components/HandleSelectionObjects.vue';

export default {
  name: 'CreateSelection',
  components: {
    HandleSelectionObjects,
    BreadcrumbSelection,
    AttributeInput,
    CollapsePanel,
  },
  computed: {
    ...mapGetters({
      selectionTypes: 'settings/getMapObjectSelectionTypes',
      user: 'user',
      selectedObjects: 'map/getSelected',
    }),
    chosenSelectionType() {
      if (this.$route.params.type !== null && this.$route.params.type !== undefined) {
        const selectedType = parseInt(this.$route.params.type, 10);
        return this.selectionTypes[selectedType];
      }
      return {};
    },
  },
  data() {
    return {
      loading: false,
      attributesLoading: false,
      showCollapseMenu: false,
      errors: {
        name: null,
        mapObjects: null,
      },
      attributes: [],
      selection: {
        name: '',
        description: '',
        map_object_selection_type_id: 0,
        created_by: 0,
      },
      mapObjects: [],
      isNetwork: false,
      isOrdered: false,
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'selection.list', params: { type: this.$route.params.type } });
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
    toIndexSelections() {
      this.$router.push({ name: 'selection.list', params: { type: this.$route.params.type } });
      this.$emit('toIndexSelections');
    },
    finishUp(selection) {
      this.loading = false;
      this.$router.push({ name: 'selection.show', params: { id: selection.id } });
    },
    resetError(field) {
      this.errors[field] = null;
    },
    validateFields() {
      let valid = true;
      if (this.selection.name === '') {
        this.errors.name = this.$t('validation.name_empty');
        this.$notify({
          type: 'warning',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobjectselection', 1) }),
          text: this.$t('validation.name_empty'),
          duration: 1000,
        });
        valid = false;
      }
      if (this.selectedObjects.length === 0) {
        this.errors.mapObjects = this.$t('validation.no_objects');
        this.$notify({
          type: 'warning',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobjectselection', 1) }),
          text: this.$t('validation.no_objects'),
          duration: 1000,
        });
        valid = false;
      }
      valid = true;
      return valid;
    },
    saveSelection() {
      if (!this.validateFields()) {
        return;
      }

      this.loading = true;

      // Add the created_by field to the selection with the current user ID
      this.selection.created_by = this.user.id;

      const data = {
        data: JSON.parse(JSON.stringify(this.selection)),
      };
      data.data.attribute = {
        data: JSON.parse(JSON.stringify(this.attributes)),
      };

      MapObjectSelectionsApi.createMapObjectSelection(data)
        .then((response) => {
          this.saveMapObjects(response.data);
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.create_failed', { model: this.$tc('models.mapobjectselection', 1) }),
            data: error.response,
          });
          this.loading = false;
        });
    },
    saveMapObjects(selection) {
      const mapObjects = JSON.parse(JSON.stringify(this.selectedObjects));
      const data = {
        data: {
          relation: mapObjects.map((mapObj, index) => {
            const obj = {};
            obj.id = mapObj.id;
            obj.label = mapObj.name;
            if (this.isOrdered) {
              obj.order = index;
            }
            return obj;
          }),
        },
      };

      MapObjectSelectionsApi.createMapObjectForMapObjectSelection(
        selection.id, data, true,
      )
        .then(() => {
          this.finishUp(selection);
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.update_failed', { model: this.$tc('models.mapobjectselection', 1) }),
            data: error.response,
          });
          this.loading = false;
        });
    },
    getSelectionTypeAttributes() {
      this.isOrdered = this.chosenSelectionType.is_ordered;
      this.isNetwork = this.chosenSelectionType.is_network;
      this.selection.map_object_selection_type_id = this.chosenSelectionType.id;
      this.attributesLoading = true;
      MapObjectSelectionTypesApi.getAttributesForSelectionType(this.chosenSelectionType.id, 'attributeLookupValue')
        .then((response) => {
          this.attributes = response.data;
          this.attributesLoading = false;
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.attribute', 0) }),
            data: error.response,
          });
          this.attributesLoading = false;
        });
    },
  },
  mounted() {
    this.getSelectionTypeAttributes();
    EventBus.$emit(
      'Map.changeMapMode',
      MapMode.selectionCreate,
      null,
      parseInt(this.$route.params.type, 10),
    );
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.type': function () {
      this.getSelectionTypeAttributes();
    },
  },
};
</script>

<style scoped>
.create-selection-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}

.create-selection-buttons .save-button {
  float: right;
  margin-right: 7px;
}

.input-error {
  float: left;
  width: 100%;
  color: #ff7676;
}

input.error {
  border-color: #ff7676;
}

.attribute-field {
  overflow: inherit;
}

.create-selection-content {
  padding: 10px 20px;
  overflow: inherit;
}

.create-selection-content .field label {
  width: 35%;
  display: inline-flex;
}

.create-selection-content .field .input-select-field {
  width: 65%;
  overflow: inherit;
  display: inline-flex;
  float: initial;
}
</style>
