<template>
  <div></div>
</template>

<script>
import '../../plugins/ant-design-vue';
import Auth from '@/services/auth';
import axios from '@/plugins/axios';

export default {
  name: 'logout',
  created() {
    Auth.clearAccessToken();
    localStorage.removeItem('tenant_id');
    localStorage.removeItem('network_id');
    axios.defaults.headers['x-tenant'] = null;
    axios.defaults.headers['x-network'] = null;
    this.$store.dispatch('auth/logout');
    this.$router.push({ name: 'login' });
  },
};
</script>
