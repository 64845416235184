<template>
    <div class="container-fluid bg-login">
      <div class="bg-banner">
        <a-row>
            <a-col :span="24">
                <div class="form-login">
                    <a-form class="login-form">
                        <a-card :bordered="false" title="Inloggen">
                            <a-form-item label="E-Mail" class="mb-0">
                                <a-input size="large"
                                         placeholder="E-Mail"
                                         v-model="email" id="email"
                                />
                            </a-form-item>
                            <a-form-item label="Wachtwoord">
                                <a-input v-on:keyup.enter="postLogin"
                                         size="large" placeholder="Wachtwoord"
                                         type="password" v-model="password"
                                         id="password"
                                />
                            </a-form-item>
                            <a-button type="primary" class="pull-right mb-20"
                                      :loading="loading" @click="postLogin"
                            >
                              Inloggen
                            </a-button>
                            <a-divider class="mt-20"/>
                            <div class="mb-20 mt-20 text-center">
                              <a @click="loginDemo()">Inloggen met demo-account</a>
                              <br><br>
                              <a @click="toPasswordForgot()">Wachtwoord vergeten?</a>
                            </div>
                        </a-card>
                    </a-form>
                </div>
            </a-col>
        </a-row>
      </div>
    </div>
</template>

<script>

import '../../plugins/ant-design-vue';
import Auth from '@/api/auth';
import axios from '@/plugins/axios';
import User from '@/api/user';
import config from '@/util/config';

export default {
  name: 'login',
  watch: {
    loggedIn() {
      User.getMe()
        .then((response) => {
          this.user = response.data;
        });
    },
    user() {
      User.getTenants(this.user.id)
        .then((response) => {
          this.tenants = response.data;
        });
    },
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      user: [],
      tenants: [],
      loggedIn: false,
      selected_tenant: null,
      selected_network: null,
      networks_disabled: true,
      networks_loading: false,
      networks: [],
    };
  },
  methods: {
    resetStoredData() {
      localStorage.removeItem('tenant_id');
      localStorage.removeItem('network_id');
      axios.defaults.headers['x-tenant'] = null;
      axios.defaults.headers['x-network'] = null;
    },
    loginDemo() {
      this.resetStoredData();
      this.loading = true;
      Auth.login(config.demoEmail, config.demoPassword)
        .then((response) => {
          axios.defaults.headers.Authorization = `Bearer ${response.access_token}`;
          this.$store.dispatch('auth/setToken', {
            token: response.access_token,
          });
          this.$router.push({ name: 'switcher' });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    postLogin() {
      this.resetStoredData();
      this.loading = true;
      Auth.login(this.email, this.password)
        .then((response) => {
          axios.defaults.headers.Authorization = `Bearer ${response.access_token}`;
          this.$store.dispatch('auth/setToken', {
            token: response.access_token,
          });
          this.$router.push({ name: 'switcher' });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    toPasswordForgot() {
      this.$router.push({ name: 'password.forgot' });
    },
  },
  beforeMount() {
    this.$emit('showFullscreen', true);
  },
};
</script>

<style>

    .form-login {
        max-width: 400px;
        height: 100vh;
        margin: 25vh auto auto;
    }

    .bg-login {
        background-image: url('/img/bg.png');
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
    }

    .bg-banner {
      background: url('/img/banner.svg') no-repeat;
    }

    .login-form .ant-card-head-title {
        color: #3bade8;
    }

    .login-form .ant-card-body {
        background: #f9f9f9 !important;
    }

    .login-form input:-webkit-autofill,
    .login-form input:-webkit-autofill:hover,
    .login-form input:-webkit-autofill:focus,
    .login-form input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .login-form input {
        font-size: 13px;
        border: 0;
    }

    .login-form .ant-select {
        font-size: 13px;
        border: 0;
    }

    .login-form .ant-select-lg .ant-select-selection--single {
        border: none;
    }

    .login-form button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .login-form .ant-card-body {
        padding: 15px 30px;
    }

</style>
