<template>
  <Breadcrumb
    :id="mapObject.id"
    :name="nameLabel"
    :type="typeLabel"
    :showBackButton="true"
    @backButtonPressed="clickBack"
  >
    <template v-if="showParents" v-slot:parents>
      <BreadcrumbParents :parents="parents" @clickParent="clickParent" />
    </template>
    <template v-slot:icon>
      <IconByTypeId
        v-if="mapObject.map_object_type_id"
        :map_object_type_id="mapObject.map_object_type_id"
        :label="mapObject | mapObjectLabel"
        :size="39"
      />
    </template>
  </Breadcrumb>
</template>

<script>
import { mapGetters } from 'vuex';

import IconByTypeId from '@/components/IconByTypeId.vue';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import BreadcrumbParents from '@/components/Layout/BreadcrumbParents.vue';

import NetworkIcon from '@/enums/NetworkIcon';

export default {
  name: 'BreadcrumbMapObject',
  components: {
    BreadcrumbParents,
    Breadcrumb,
    IconByTypeId,
  },
  props: {
    mapObject: {
      type: Object,
      required: true,
      default: null,
    },
    extraLabel: {
      type: String,
      required: false,
      default: '',
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showParents: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      networkIcon: NetworkIcon,
    };
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      keyValues: 'settings/getKeyValues',
    }),
    nameLabel() {
      return `${this.$options.filters.mapObjectLabel(this.mapObject)} ${this.extraLabel}`;
    },
    typeLabel() {
      return this.mapObject.mapObjectType.data.name;
    },
    parents() {
      const parents = JSON.parse(JSON.stringify(this.mapObject.parents.data));
      return parents.reverse();
    },
  },
  methods: {
    clickBack() {
      if (this.parents.length > 0) {
        this.$emit('clickBack', 'map_object', this.parents[this.parents.length - 1].id);
      } else {
        this.$emit('clickBack', 'network', this.network.id);
      }
    },
    clickParent(model, id) {
      this.$emit('clickParent', model, id);
    },
  },
};
</script>
