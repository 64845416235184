import L from 'leaflet';
import Vue from 'vue';
import managePlusIcons from '../assets/img/manage-plus-icons/icons.json';

Vue.mixin({
  methods: {
    // eslint-disable-next-line max-len
    getIconByMapObjectType(icon, count, label, isSelected = false, is_deleted = false, is_obstructed = false) {
      // By map object
      // console.log('getIconByMapObjectType', icon);
      if (icon != null) {
        const padding = 5;
        let size = 40;
        let iconX = 0;
        let iconY = 0;
        let iconUrl = '';
        // filter out non ASCII characters to ensure base64 encode in btoa()
        const filteredLabel = String(label || '').replace(/\uFFFD/g, '')
          .replace(/[\u{0080}-\u{10FFFF}]/gu, '');

        // By type
        if (icon.type === 'icon') {
          size = 30;
          iconX = (size + (padding * 2)) / 2;
          iconY = (size + (padding * 2)) / 2;
          iconUrl = `data:image/svg+xml;base64,${btoa(this.createIcon(icon, size, count, filteredLabel, isSelected, is_deleted, is_obstructed))}`;
        } else if (icon.type === 'circle') {
          size = 35;
          iconX = ((size / 2) + (padding / 2));
          iconY = ((size / 2) + (padding / 2));
          iconUrl = `data:image/svg+xml;base64,${btoa(this.toSVG(icon, count, filteredLabel, isSelected, size))}`;
        } else if (icon.type === 'small_circle') {
          size = 25;
          iconX = ((size / 2) + (padding / 2));
          iconY = ((size / 2) + (padding / 2));
          iconUrl = `data:image/svg+xml;base64,${btoa(this.toSVG(icon, count, filteredLabel, isSelected, size))}`;
        } else if (icon.type === 'label') {
          size = 40;
          iconX = (size + (padding * 2)) / 2;
          iconY = size;
          iconUrl = `data:image/svg+xml;base64,${btoa(this.toSVG(icon, count, filteredLabel, isSelected, size))}`;
        } else if (icon.type === 'cluster') {
          size = 50;
          iconX = ((size / 2) + (padding / 2));
          iconY = ((size / 2) + (padding / 2));
          iconUrl = `data:image/svg+xml;base64,${btoa(this.toSVG(icon, count, filteredLabel, isSelected, size))}`;
        } else if (icon.type === 'action') {
          size = 45;
          iconX = (size + padding) / 2;
          iconY = size - (padding / 2);
          iconUrl = `data:image/svg+xml;base64,${btoa(this.toSVG(icon, count, filteredLabel, isSelected, size))}`;
        }
        return L.icon({ iconUrl, iconAnchor: [iconX, iconY], className: 'fade-in' });
      }
      const padding = 5;
      const size = 35;
      const iconX = ((size / 2) + (padding / 2));
      const iconY = ((size / 2) + (padding / 2));
      const newIcon = {
        color: 'dimgray',
        text_color: 'white',
        type: 'circle',
      };
      const iconUrl = `data:image/svg+xml;base64,${btoa(this.toSVG(newIcon, count, label, isSelected, size))}`;
      return L.icon({ iconUrl, iconAnchor: [iconX, iconY], className: 'fade-in' });
    },
    createIcon(keyValue, size, count, label, isSelected) {
      const isDeleted = false;

      const icon = managePlusIcons[keyValue.icon];
      const scale = parseFloat((size / 40).toFixed(2));
      const padding = 5;
      const radius = 5;
      const circle = 8;

      let color = isSelected ? keyValue.color_selected : keyValue.color;
      const textColor = isSelected ? keyValue.text_color_selected : keyValue.text_color;

      if (isDeleted) {
        color = 'grey';
      }

      let svg = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="${size + padding * 2}" height="${size + padding * 2}" viewBox="-${padding} -${padding} ${size + padding * 2} ${size + padding * 2}">`;

      // FrameZ
      if (keyValue.frame === 'square_rounded') {
        svg += `<rect fill="${color}" stroke="#ffffff" rx="${radius}" ry="${radius}" width="${size}" height="${size}"/>`;
      } else if (keyValue.frame === 'square') {
        svg += `<rect fill="${color}" stroke="#ffffff" width="${size}" height="${size}"/>`;
      } else if (keyValue.frame === 'circle') {
        svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" stroke="#ffffff" fill="${color}"/>`;
      } else if (keyValue.frame === 'circle_outline') {
        svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" stroke="${color}" fill="#ffffff" stroke-width="3" />`;
      } else if (keyValue.frame === 'triangle') {
        // eslint-disable-next-line no-useless-concat
        svg += `<path fill="#FFFFFF" stroke="${color}" transform="scale(${scale}, ${scale})" stroke-width="3" stroke-miterlimit="10" d="M37.5,33.5L37.5,33.5V34c0,0.2,0,0.4,0,0.4` + 'c0,0.2,0,0.2-0.2,0.4c0,0.2-0.2,0.2-0.4,0.4h-0.2h-0.2c-0.2,0-0.4,0.2-0.6,0H3.9H3.5L3.1,35c-0.2,0-0.2-0.2-0.4-0.2' + 'c0-0.2-0.2-0.2-0.2-0.4c0,0,0-0.2,0-0.4c0-0.2,0.2-0.2,0.2-0.6L18.8,5.5V5.3L19,5.1l0.2-0.2l0.4-0.2c0.2,0,0.2,0,0.4,0' + 's0.2,0.2,0.4,0.2l0.2,0.2c0.2,0,0.2,0.2,0.4,0.4L37.5,33.5z"/>';
      }
      icon.split('/>').forEach((elem) => {
        if (elem.indexOf('fill') !== -1) {
          svg += `${elem} transform='scale(${scale}, ${scale})'/>`;
        } else if (elem !== '') {
          svg += `${elem} fill="${textColor}" transform="scale(${scale}, ${scale})"/>`;
        }
      });

      if (count !== 0) {
        svg += `<circle cx="${size - circle / 2}" cy="${circle / 2}" r="${circle}" fill="red"></circle>`;
        svg += `<text text-anchor="middle" x="${size - circle / 2}" y="${circle / 2 + 3}" font-family="Roboto, sans-serif" font-size="10px" fill="#fff">${count}</text>`;
      }

      // Return
      return `${svg}</svg>`;
    },
    toSVG(icon, count, label, isSelected, size) {
      const originalSize = 40;
      const circle = 8;
      const radius = 5;
      const padding = 5;
      let color = isSelected ? icon.color_selected : icon.color;
      const textColor = isSelected ? icon.text_color_selected : icon.text_color;

      let svg = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="${size + padding * 2}" height="${size + padding * 2}" viewBox="-${padding} -${padding} ${size + padding * 2} ${size + padding * 2}">`;
      if (icon.type === 'circle') {
        if (label !== undefined && label.length > 5) {
          svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="${color}"/>`
            + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 2}" fill="#fff"/>`
            + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 3}" fill="${color}"/>`
            + `<text text-anchor="middle" x="${size / 2}" y="${size / 2 + 3}" font-family="MS Sans Serif, Geneva, Sans-Serif" font-size="${size / 6 + 3}" fill="${textColor}">${label}</text>`;
        } else {
          svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="${color}"/>`
            + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 2}" fill="#fff"/>`
            + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 3}" fill="${color}"/>`
            + `<text text-anchor="middle" x="${size / 2}" y="${size / 2 + 3}" font-family="MS Sans Serif, Geneva, Sans-Serif" font-size="${size / 4 + 1}" fill="${textColor}">${label}</text>`;
        }
      } else if (icon.type === 'small_circle') {
        svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 4}" fill="${color}"/>`;
        svg += `<circle stroke-width="1" stroke="${textColor}" cx="${size / 2}" cy="${size / 2}" r="${size / 5.5}" fill="none"/>`;
      } else if (icon.type === 'action') {
        svg += `<rect stroke="${color}" transform="rotate(45 22.00000000000001,29.437499999999996) " id="svg_12" height="13.300008" width="13.501524" y="22.787495" x="15.249238" stroke-opacity="null" stroke-width="3" fill="${color}"/>`
          + `<ellipse ry="15" rx="15" id="svg_1" cy="17.75" cx="22" stroke-width="3" stroke="${color}" fill="#ffffff"/>`
          + `<text text-anchor="middle" x="${size / 2}" y="${size / 2 - 2}" font-family="MS Sans Serif, Geneva, Sans-Serif" font-size="${size / 5}" fill="black">${label}</text>`;
      } else if (icon.type === 'label') {
        svg += `<polygon fill="${color}" stroke="${color}" stroke-miterlimit="10" transform="scale(${size / originalSize}, ${size / originalSize})" points="20,35 17.3,29.8 14.7,24.7 20,24.7 25.3,24.7 22.6,29.8 "/>`
          + `<path fill="#ffffff" stroke="${color}" stroke-width="2" stroke-miterlimit="10" transform="scale(${size / originalSize}, ${size / originalSize})" d="M29.8,23.6H10.2c-4.3,0-7.7-3.5-7.7-7.7v-3.2 C2.5,8.5,6,5,10.2,5h19.5c4.3,0,7.7,3.5,7.7,7.7v3.2C37.5,20.1,34,23.6,29.8,23.6z"/>`
          + `<text text-anchor="middle" x="${size / 2}" y="${size / 2 - 2}" font-family="MS Sans Serif, Geneva, Sans-Serif" font-size="${size / 4}" fill="${textColor}">${label}</text>`;
      } else if (icon.type === 'cluster') {
        if (parseInt(label, 10) < 10) {
          color = '#B5E28C';
        } else if (parseInt(label, 10) < 99) {
          color = '#F1D357';
        } else {
          color = '#FD9C73';
        }
        svg += '<defs><radialGradient id="cluster" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">'
          + `<stop offset="50%" style="stop-color:${color};stop-opacity:0.9" />`
          + '<stop offset="100%" style="stop-color:rgb(255,255,255);stop-opacity:0" />'
          + '</radialGradient></defs>'
          + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="url(#cluster)"/>`
          + `<text text-anchor="middle" x="${size / 2}" y="${size / 2 + 3}" font-family="MS Sans Serif, Geneva, Sans-Serif" font-size="${size / 5 + 1}" fill="${textColor}">${label}</text>`;
      } else {
        svg = `<rect fill="#ffffff" stroke="${color}" rx="${radius}" ry="${radius}" width="${size}" height="${size}"/>`;
      }

      // Actie bol
      if (count !== 0) {
        svg += `<circle cx="${size - (circle / 2)}" cy="${circle / 2}" r="${circle}" fill="red"></circle>`;
        svg += `<text text-anchor="middle" x="${size - (circle / 2)}" y="${circle / 2 + 3}" font-family="MS Sans Serif, Geneva, Sans-Serif" font-size="10px" fill="#fff">${count}</text>`;
      }

      svg += '</svg>';
      return svg;
    },
  },
});
