<template>
  <div
    class="action-attribute-wrapper"
  >
    <h2>{{ title }}</h2>
    <div v-for="attribute in attributes" :key="attribute.id">
      <span v-if="attribute.required" class="title">
        <span class="required">*</span>
        {{ attribute.name }}:
      </span>
      <span v-else class="title">{{ attribute.name }}:</span>
      <div class="field content">
        <AttributeInput :disabled="disabled" :attribute="attribute" />
      </div>
    </div>
  </div>
</template>
<script>
import AttributeInput from '@/components/AttributeInput.vue';

export default {
  name: 'AttributesInputView',
  components: { AttributeInput },
  props: {
    title: {
      type: String,
      required: true,
    },
    attributes: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
  .action-attribute-wrapper .title {
    display: inline-flex;
    float: initial;
    width: 35%;
  }
  .action-attribute-wrapper .required {
    color: red;
  }

  .action-attribute-wrapper .field.content {
    display: inline-flex;
    float: initial;
    overflow: initial;
    width: 65%;
  }

  .action-attribute-wrapper {
    margin-top: 20px;
  }
</style>
