<template>
  <div>
    <BreadcrumbNetwork />
    <NetworkInformation />
    <ObjectObstructions v-if="$can('show', 'mapobject')" />
    <ActionLog v-if="$can('index', 'action')"
    />
    <Administration
      v-if="$can('module', 'beheerplus')"
    />
    <Statistics />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';
import switcher from '@/services/switcher';

import BreadcrumbNetwork from '@/components/Layout/BreadcrumbNetwork.vue';

import ActionLog from './components/ActionLog.vue';
import Administration from './components/Administration.vue';
import NetworkInformation from './components/NetworkInformation.vue';
import ObjectObstructions from './components/ObjectObstructions.vue';
import Statistics from './components/Statistics.vue';

export default {
  name: 'route_manager',
  components: {
    BreadcrumbNetwork,
    ActionLog,
    Administration,
    Statistics,
    NetworkInformation,
    ObjectObstructions,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObject: 'selected/getMapObject',
    }),
  },
  data() {
    return {
      selectedId: parseInt(switcher.getSelectedNetworkId(), 10),
    };
  },
  beforeMount() {
    if (this.mapObject !== null) {
      this.$router.push({ name: 'route_management.show', params: { id: this.mapObject.id } });
    }
  },
  mounted() {
    EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
  },
  watch: {
    mapObject(value) {
      console.log('route_management index watch mapobject', value);
      // if (value) {
      //   this.$router.push({ name: 'route_management.show', params: { id: value.id } });
      // }
    },
  },
};
</script>

<style scoped>
.content-section {
  height: 100vh;
}

.btn-open-icon {
  margin-left: -9px;
}

.btn-open {
  position: fixed;
  top: 40%;
  border-radius: 0px 5px 5px 0px;
  width: 20px;
  height: 59px;
  z-index: 20;
}

.pStyle {
  fontSize: 16px;
  color: rgba(0, 0, 0, 0.85);
  lineHeight: 24px;
  display: block;
  marginBottom: 16px;
}

.pStyle2 {
  marginBottom: 24px;
}


</style>
