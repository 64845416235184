<template>
  <div class="sidenav">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SideNav',
};
</script>

<style scoped>
  .sidenav {
    /*position: static;*/
  }
</style>
