<template>
  <div class="collapse-action-foldout">
    <div class="top"></div>
    <div class="collapse-action-menu-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapseActionMenu',
  components: {
  },
  props: {
  },
  data() {
    return {
      showCollapse: this.collapsed,
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.collapse-action-foldout {
  display: block;
  height: auto;
  width: auto;
  position: absolute;
  right: -10px;
  top: 55px;
  z-index: 2;
}
.collapse-action-foldout .top {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fff;
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  right: 20px;
  top: -10px;
}
.collapse-action-foldout .collapse-action-menu-content {
  overflow: visible;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
</style>
