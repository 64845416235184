import axios from '@/plugins/axios';

export default {
  getMapObjectSelectionTypes() {
    return axios.get('/v1/selection_type?perPage=9999')
      .then(response => response.data);
  },
  getMapObjectSelectionTypeById(id, include = '') {
    return axios.get(`/v1/selection_type/${id}?include=${include}`)
      .then(response => response.data);
  },
  updateMapObjectSelectionTypeById(id, data) {
    return axios.put(`/v1/selection_type/${id}`, data)
      .then(response => response.data);
  },
  deleteMapObjectSelectionTypeById(id) {
    return axios.delete(`/v1/selection_type/${id}`)
      .then(response => response.data);
  },
  createMapObjectSelectionType(data) {
    return axios.post('/v1/selection_type', data)
      .then(response => response.data);
  },
  getMapObjectSelectionsForSelectionType(id, include = '', perPage = 15, page = 1) {
    return axios.get(`/v1/selection_type/${id}/selection?include=${include}&perPage=${perPage}&page=${page}`)
      .then(response => response.data);
  },
  getAttributesForSelectionType(id, include = '') {
    return axios.get(`/v1/selection_type/${id}/attribute?include=${include}`)
      .then(response => response.data);
  },
};
