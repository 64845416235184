import { render, staticRenderFns } from "./ObjectComments.vue?vue&type=template&id=a173e652&scoped=true"
import script from "./ObjectComments.vue?vue&type=script&lang=js"
export * from "./ObjectComments.vue?vue&type=script&lang=js"
import style0 from "./ObjectComments.vue?vue&type=style&index=0&id=a173e652&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a173e652",
  null
  
)

export default component.exports