import axios from '@/plugins/axios';

export default {
  getRecurringAction() {
    return axios.get('/v1/recurring_action')
      .then(response => response.data);
  },
  getRecurringActionById(id, includes = '') {
    return axios.get(`/v1/recurring_action/${id}?include=${includes}`)
      .then(response => response.data);
  },
  createRecurringAction(data) {
    return axios.post('/v1/recurring_action', data)
      .then(response => response.data);
  },
  updateRecurringActionById(id, data) {
    return axios.put(`/v1/recurring_action/${id}`, data)
      .then(response => response.data);
  },
  deleteRecurringActionById(id) {
    return axios.delete(`/v1/recurring_action/${id}`)
      .then(response => response.data);
  },
};
