<template>
  <div>
    <BreadcrumbNetwork />
    <CollapsePanel
      :title="$t('selections.choose_selection_type')"
      :loading=false
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="map-object-selection-types-list">
        <CollapsePanelSmallLink
          v-for="(selectionType, index) in mapObjectSelectionTypes"
          :key="index" :title="selectionType.name"
          :loading="chosenSelectionType === selectionType.id"
          @clickItem="chooseSelectionType(selectionType.id)" />
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';

import BreadcrumbNetwork from '@/components/Layout/BreadcrumbNetwork.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import CollapsePanelSmallLink from '@/components/CollapsePanelSmallLink.vue';

export default {
  name: 'selections',
  components: {
    CollapsePanelSmallLink,
    CollapsePanel,
    BreadcrumbNetwork,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObjectSelectionTypes: 'settings/getMapObjectSelectionTypes',
    }),
  },
  data() {
    return {
      chosenSelectionType: null,
    };
  },
  methods: {
    ...mapActions({
      resetMapObject: 'selected/resetMapObject',
    }),
    chooseSelectionType(selectionTypeId) {
      if (!this.$can('index', 'mapobjectselection')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectselection', 0) }),
          text: this.$t('error.no_access_index', { model: this.$tc('models.mapobjectselection', 0) }),
        });
        this.chosenSelectionType = null;
        return;
      }
      this.chosenSelectionType = selectionTypeId;
      this.$router.push({ name: 'selection.list', params: { type: selectionTypeId } });
    },
  },
  mounted() {
    this.resetMapObject();
    EventBus.$emit('Map.changeMapMode', null);
  },
};
</script>

<style scoped>
.map-object-selection-types-list {
  margin: -10px 0;
}
</style>
