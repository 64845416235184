<template>
  <CollapsePanel
    :title="$t('route_management.object_children')"
    :loading=loading
    :showCount=true
    :count="pagination.total"
    :collapsed="true"
    :showCollapseAction="$can('create', 'mapobject') && mapObjectTypesToCreate.length > 0"
    @collapseAction="toggleShowCollapseMenu"
    :icon="showCollapseMenu ? 'times' : 'plus'"
  >
    <template v-slot:customCollapseAction v-if="showCollapseMenu">
      <CollapseActionMenu>
        <CollapseActionMenuItem
          v-for="mapObjectType in mapObjectTypesToCreate"
          :key="mapObjectType.id"
          :title="mapObjectType.name"
          icon="plus"
          @clickItem="toCreateMapObject(mapObjectType.id)"
        />
      </CollapseActionMenu>
    </template>
    <div class="map-objects-list">
      <ChildObject v-for="(child, key) in childrenToShow"
                   :key="key"
                   :child="child"
                   @goToObject="goToObject"
      />
    <Pagination
      @switchPage="switchPage"
      :total="pagination.total"
      :per_page="pagination.per_page"
      :current_page="pagination.current_page"
      :total_pages="pagination.total_pages"  />
    </div>
  </CollapsePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

// import MapObjectApi from '@/api/map_object';
// import NetworkApi from '@/api/network';

import EventBus from '@/events/event-bus';

import CollapsePanel from '@/components/CollapsePanel.vue';
import Pagination from '@/components/Pagination.vue';
import CollapseActionMenu from '@/components/CollapseActionMenu.vue';
import CollapseActionMenuItem from '@/components/CollapseActionMenuItem.vue';
import ChildObject from '@/components/Layout/ChildObject.vue';

export default {
  name: 'ObjectChildren',
  components: {
    ChildObject,
    CollapseActionMenuItem,
    CollapseActionMenu,
    CollapsePanel,
    Pagination,
  },
  props: {
    mapObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
    mapObjectTypesToCreate() {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return [];
      }
      if (Object.values(this.mapObjectTypes).length === 0) {
        return [];
      }
      if (!this.mapObject.map_object_type_id) {
        return [];
      }
      if (
        Object.values(this.mapObjectTypes)
          .filter(item => item.id === this.mapObject.map_object_type_id).length === 0
      ) {
        return [];
      }
      let constraintIds = Object.values(this.mapObjectTypes)
        .filter(item => item.id === this.mapObject.map_object_type_id)[0].constraintId.data;
      constraintIds = constraintIds.map(item => item.id);
      return Object.values(this.mapObjectTypes).filter(item => constraintIds.includes(item.id));
    },
  },
  data() {
    return {
      showCollapseMenu: false,
      loading: false,
      allChildren: [],
      childrenToShow: [],
      pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
        total_pages: 1,
      },
    };
  },
  methods: {
    ...mapActions({
      getChildrenForMapObject: 'settings/getChildrenForMapObject',
    }),
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
    switchPage(page) {
      this.pagination.current_page = page;
      this.childrenToShow = this.allChildren.slice(
        ((page - 1) * this.pagination.per_page),
        (page * this.pagination.per_page),
      );
    },
    toCreateMapObject(typeId) {
      this.$router.push({ name: 'drawing.create_child', params: { id: this.mapObject.id, type: typeId } });
    },
    goToObject(mapObjectId) {
      this.$router.push({ name: 'drawing.show', params: { id: mapObjectId } });
    },
    getChildren() {
      if (!this.mapObject) {
        return;
      }
      this.loading = true;
      this.getChildrenForMapObject({ id: this.mapObject.id }).then((children) => {
        this.allChildren = children;
        this.pagination.total = children.length;
        if (children.length > this.pagination.per_page) {
          this.childrenToShow = children.slice(0, this.pagination.per_page);
          this.pagination.total_pages = Math.ceil(children.length / this.pagination.per_page);
        } else {
          this.childrenToShow = children;
          this.pagination.total_pages = 1;
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    console.log('mapObject mounted', this.mapObject);
    this.getChildren();

    EventBus.$on('updateObjectChildren', (mapObjectId) => {
      if (mapObjectId === this.id) {
        this.getChildren();
      }
    });
  },
  watch: {
    mapObject() {
      console.log('mapObject watch', this.mapObject);
      this.getChildren();
    },
  },
};
</script>
<style scoped>
  div.map-objects-list {
    margin: -15px 0 0 0;
  }
</style>
