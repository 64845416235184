<template>
  <div id="pagination-panel" v-if="total > 0">
    <ul class="pagination">
      <li
        v-on:click="current_page > 1 ? switchPage(current_page - 1) : ''"
        :class="{'page-nav': true, 'disabled': current_page === 1}"
      >
        <a href="javascript:void(0)">&laquo;</a>
      </li>
      <li
        v-for="page in paginationRange"
        :key="page"
        :class="{'active': page === current_page}"
        v-on:click="page === current_page ? '' : switchPage(page)"
      >
        <span v-if="page === 'more'">...</span>
        <a v-else href="javascript:void(0)" class="white-text">
          {{ page }}
        </a>
      </li>
      <li
        v-on:click="current_page < total_pages ? switchPage(current_page + 1) : ''"
        :class="{'page-nav': true, 'disabled': current_page < total_pages}"
      >
        <a href="javascript:void(0)">&raquo;</a>
      </li>
    </ul>
    <span class="total_results">
      {{
        $tc(
          'actionlog.pagination',
          this.total,
          { number: startNumber+'-'+endNumber, total: this.total }
        )
      }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
      required: true,
    },
    per_page: {
      type: Number,
      required: true,
    },
    current_page: {
      type: Number,
      required: true,
    },
    total_pages: {
      type: Number,
      required: true,
    },
  },
  methods: {
    switchPage(page) {
      this.$emit('switchPage', page);
    },
  },
  computed: {
    paginationRange() {
      if (this.total_pages === 1) {
        return [1];
      }
      if (this.total_pages <= 5) {
        const range = Array.from(Array(this.total_pages + 1).keys());
        range.shift();
        return range;
      }
      const range = [];
      if (this.current_page < 5) {
        for (let i = 1; i <= 5; i += 1) {
          range.push(i);
        }
        range.push('more');
        range.push(this.total_pages);
      } else if (this.current_page >= (this.total_pages - 3)) {
        range.push(1);
        range.push('more');
        for (let i = (this.total_pages - 4); i <= this.total_pages; i += 1) {
          range.push(i);
        }
      } else {
        range.push(1);
        range.push('more');
        for (let i = (this.current_page - 2); i <= this.current_page + 2; i += 1) {
          range.push(i);
        }
        range.push('more');
        range.push(this.total_pages);
      }

      return range;
    },
    startNumber() {
      return ((this.current_page - 1) * this.per_page) + 1;
    },
    endNumber() {
      const end = this.startNumber + this.per_page - 1;
      if (end > this.total) {
        return this.total;
      }
      return end;
    },
  },
};
</script>

<style scoped>
  #pagination-panel {
    padding-bottom: 10px;
  }

  ul.pagination {
    margin: 20px 0;
    text-align: center; }
  ul.pagination li {
    display: inline-block;
    overflow: hidden;
    margin-left: 2px; }
  ul.pagination li:first-child {
    margin-left: 0; }
  ul.pagination li a {
    display: block;
    text-decoration: none;
    border-radius: 555px;
    width: 32px;
    height: 32px;
    line-height: 30px;
    font-size: .85em;
    color: #42464c;
    border: 1px solid rgba(66, 70, 76, 0.2);
    text-align: center; }
  ul.pagination li a:hover {
    background: rgba(66, 70, 76, 0.05); }
  ul.pagination li a span.icon {
    line-height: 32px;
    font-size: .65em; }
  ul.pagination li.active a {
    color: #fff;
    background: #42464c;
    border: none; }
  ul.pagination li.page-nav a {
    border: none; }

  .total_results {
    font-size: 10px;
    text-align: center;
    width: 100%;
    float: right;
  }

</style>
