<template>
  <div></div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';

export default {
  name: 'CreateMultiSelection',
  props: {
    map: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      keyValues: 'settings/getKeyValues',
      mapObjectTypes: 'settings/getMapObjectTypes',
      network: 'settings/getNetworkModel',
      selectedObjects: 'map/getSelected',
    }),
  },
  data() {
    return {
      interaction: true,
    };
  },
  methods: {
    ...mapActions({
      addSelectedOnMap: 'map/addSelected',
      removeSelectedOnMap: 'map/removeSelected',
      resetSelectedOnMap: 'map/resetSelected',
    }),
    mapClick(e) {
      console.log('Map CreateMultiSelection mapClick()', e);
    },
    toggleMapObject(mapObject) {
      console.log('CreateMultiSelection.toggleMapObject()', mapObject, this.interaction);
      if (this.interaction === false) {
        return;
      }
      if (this.selectedObjects.filter(item => item.id === mapObject.id).length > 0) {
        this.removeSelectedOnMap({ mapObjectId: mapObject.id });
      } else {
        console.log('mapObject', mapObject);
        this.addSelectedOnMap({ mapObject });
      }
    },
  },
  mounted() {
    console.log('Map CreateMultiSelection mounted()');
    this.map.off('click');
    this.map.on('click', (e) => {
      this.mapClick(e);
    });
    EventBus.$on('Map.clickMapObject', (data) => {
      this.toggleMapObject(data);
    });
    EventBus.$on('Map.disableInteraction', () => {
      this.interaction = false;
    });
    EventBus.$on('Map.enableInteraction', () => {
      this.interaction = true;
    });
  },
  beforeDestroy() {
    console.log('Map CreateMultiSelection beforeDestroy()');
    this.resetSelectedOnMap();
    EventBus.$off('Map.clickMapObject');
  },
};
</script>

<style scoped>

</style>
