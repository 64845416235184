<template>
  <div v-if="attribute.type === 'textarea'"
        style="font-style: italic; line-height: 1.2rem; margin-bottom: 10px;white-space: pre-wrap;"
  >
    <span>{{ value }}</span>

  </div>
  <div v-else-if="attribute.type === 'multilang' || attribute.type === 'multilangtextarea'"
        class="multilang-value">
    <div class="multilang-tabs">
      <ul>
        <li v-for="(countryCode, index) in countryCodes"
            :key="index"
            :class="{active: (activeMultiLangTab === countryCode)}"
            @click="changeMultiLangTab(countryCode)"
        >
          <a href="javascript:void(0)" class="multilang-tab-link">
            <country-flag v-if="countryCode === 'en_GB'" country="gb" size='small' />
            <country-flag v-else :country="countryCode" size='small' />
          </a>
        </li>
      </ul>
    </div>
    <div class="multilang-input">
      {{ attribute.value[activeMultiLangTab] }}
    </div>
  </div>
  <span v-else-if="attribute.type === 'multilang' || attribute.type === 'multilangtextarea'">
    <span v-for="(text,key, index) in value" :key="key">
      {{ key }}: {{ text }}
      <br v-if="index !== Object.keys(value).length - 1" />
    </span>
  </span>
  <span v-else>{{ value }}</span>
</template>

<script>
import CountryFlag from 'vue-country-flag';

export default {
  name: 'AttributeValue',
  components: {
    CountryFlag,
  },
  props: {
    attribute: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      countryCodes: ['nl', 'en_GB', 'de'],
      activeMultiLangTab: 'nl',
    };
  },
  computed: {
    value() {
      if (this.attribute.type === 'boolean') {
        if (this.attribute.value === 'true'
          || this.attribute.value === true
          || this.attribute.value === '1'
          || this.attribute.value === 1
          || this.attribute.value === 'yes'
          || this.attribute.value === 'ja') {
          return this.$options.filters.capitalize(this.$t('input.yes'));
        }
        return this.$options.filters.capitalize(this.$t('input.no'));
      }
      if (this.attribute.type === 'date') {
        return this.$options.filters.dateFromInput(this.attribute.value);
      }
      if (this.attribute.type === 'lookup') {
        if (this.attribute.attributeLookupValue && this.attribute.attributeLookupValue.data) {
          let val = '';
          this.attribute.attributeLookupValue.data.forEach((lookupValue) => {
            if (lookupValue.id === this.attribute.value) {
              val = lookupValue.name;
            }
          });
          if (val === '') {
            val = '-';
          }
          return val;
        }
        return 'attributeLookupValue not found';
      }
      if (this.attribute.type === 'multi_lookup') {
        if (this.attribute.attributeLookupValue && this.attribute.attributeLookupValue.data) {
          const val = [];
          if (this.attribute.value && this.attribute.value.length > 0) {
            this.attribute.value.forEach((value) => {
              this.attribute.attributeLookupValue.data.forEach((lookupValue) => {
                if (parseInt(lookupValue.id, 10) === parseInt(value, 10)) {
                  val.push(lookupValue.name);
                }
              });
            });
          } else {
            return '-';
          }
          return val.join(', ');
        }
        return 'attributeLookupValue not found';
      }
      if (this.attribute.type === 'multilang' && !this.attribute.value) {
        const value = {};
        this.countryCodes.forEach((country) => {
          value[country] = '';
        });
        return value;
      }
      if (this.attribute.type === 'multilangtextarea' && !this.attribute.value) {
        const value = {};
        this.countryCodes.forEach((country) => {
          value[country] = '';
        });
        return value;
      }
      if (!this.attribute.value) {
        return '-';
      }
      return this.attribute.value;
    },
  },
  methods: {
    changeMultiLangTab(country) {
      this.activeMultiLangTab = country;
    },
  },
};
</script>
<style scoped>
.multilang-value {
  padding-bottom: 10px;
}
.multilang-input {
  border-top: 0;
  border-left: 1px solid #d9dadb;
  border-right: 1px solid #d9dadb;
  border-bottom: 1px solid #d9dadb;
  position: relative;
  display: flex;
  top: -1px;
  padding: 13px 5px 11px;
}

.multilang-tabs {
  border-bottom: 1px solid #d9dadb;
  height: 26px;
}
.multilang-tabs ul {
  margin: 0;
  padding: 0 20px;
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
  top: 1px;
}
.multilang-tabs ul li {
  display: table-cell;
  list-style: none;
  line-height: 1.8em;
}
.multilang-tabs ul li a.multilang-tab-link {
  display: block;
  text-decoration: none;
  color: #42464c;
  font-size: .85em;
  touch-action: manipulation;
  position: relative;
  height: 25px;
}
.multilang-tabs ul li.active a.multilang-tab-link {
  cursor: default;
  font-weight: 400;
  background: #f2f7fe;
  border-top: 1px solid #d9dadb;
  border-left: 1px solid #d9dadb;
  border-right: 1px solid #d9dadb;
  display: block;
  text-decoration: none;
  color: #42464c;
  font-size: .85em;
}
.multilang-tabs ul li a.multilang-tab-link span {
  position: relative;
  overflow: hidden;
  top: -8px;
  left: -2px;
}
</style>
