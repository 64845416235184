<template>
  <div>
    <Loading :visible="loading" />
    <Breadcrumb
      :id="chosenMapObjectType.id"
      :name="chosenMapObjectType.name"
      :type="$t('drawing.multiselect')"
      :showBackButton="true"
      @backButtonPressed="goBack"
    >
      <template v-slot:icon>
        <MapIcon v-bind="networkIcon" icon="13_roadsign" :size="39"/>
      </template>
    </Breadcrumb>
    <CollapsePanel
      :title="$t('drawing.explanation')"
      :loading=false
      :showCount=false
      :collapsed="true"
      :showCollapseAction="false"
    >
      <div class="create-selection-content">
        <p>{{ $t('drawing.select_object_on_map')}}</p>
        <small><i>{{ $t('selections.select_multiple_ctrl')}}</i></small>
      </div>
    </CollapsePanel>
    <CollapsePanel
      :title="$t('selections.map_object_info')"
      :loading="false"
      :showCount=false
      :showCollapseAction="selectedObjects.length > 0"
      :collapsed="true"
      icon="pen"
      @collapseAction="toggleShowCollapseMenu"
    >
      <template v-slot:customCollapseAction v-if="showCollapseMenu">
        <CollapseActionMenu>
          <CollapseActionMenuItem
            :title="$t('button.empty_selection') | capitalize"
            icon="pen"
            @clickItem="emptySelectionObjects"
          />
        </CollapseActionMenu>
      </template>
      <div class="create-selection-content">
        <span v-if="selectedObjects.length === 0">
          <small><i>{{ $t('drawing.no_objects_selected') }}</i></small>
        </span>
        <span v-for="(mapObject, index) in selectedObjects"
              :key="mapObject.id+'-'+index"
              class="map-object-icon"
        >
          <span class="map-object-icon-image">
          <IconByTypeId :map_object_type_id="mapObject.map_object_type_id"
                        :label="mapObject | mapObjectLabel"
                        :size="35"
          />
          </span>
          <span class="map-object-label">
            {{ (mapObject.label || mapObject.name || '').slice(0,8) }}
          </span>
          <span class="delete-image"
                @click="deleteMapObject(mapObject)"
                v-on:click.stop
          >
            <font-awesome-icon icon="times"/>
          </span>
        </span>
      </div>
    </CollapsePanel>
    <div class="create-object-buttons">
      <a :class="selectedObjects.length > 0 ? 'btn cancel' : 'btn grey disabled'"
         @click="deleteObjects"
      >
        {{ $t('button.delete') | capitalize }}
      </a>
      <a :class="selectedObjects.length > 0
      ? 'btn module-color-background save-button' : 'btn grey disabled save-button'"
         @click="toEditObjects"
      >
        {{ $t(`button.edit`) | capitalize }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MapObjectApi from '@/api/map_object';

import EventBus from '@/events/event-bus';
import MapIcon from '@/components/Map/MapIcon.vue';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import NetworkIcon from '@/enums/NetworkIcon';
import CollapsePanel from '@/components/CollapsePanel.vue';
import MapMode from '@/enums/MapMode';
import CollapseActionMenuItem from '@/components/CollapseActionMenuItem.vue';
import CollapseActionMenu from '@/components/CollapseActionMenu.vue';
import IconByTypeId from '@/components/IconByTypeId.vue';
import Loading from '@/components/Loading.vue';

export default {
  name: 'drawing.multiselect.choose',
  components: {
    IconByTypeId,
    CollapseActionMenu,
    CollapseActionMenuItem,
    CollapsePanel,
    Breadcrumb,
    MapIcon,
    Loading,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObjectStatuses: 'settings/getMapObjectStatuses',
      mapObjectTypes: 'settings/getMapObjectTypes',
      selectedObjects: 'map/getSelected',
      getFilter: 'settings/getFilter',
    }),
    chosenMapObjectType() {
      if (this.$route.params.type !== null && this.$route.params.type !== undefined) {
        const selectedType = parseInt(this.$route.params.type, 10);
        return this.mapObjectTypes[selectedType];
      }
      return {};
    },
  },
  data() {
    return {
      loading: false,
      networkIcon: NetworkIcon,
      objectData: {
        name: '',
        map_object_status_id: 0,
        map_object_type_id: 0,
        parent_id: null,
      },
      mapObjectStatus: null,
      showCollapseMenu: false,
    };
  },
  methods: {
    ...mapActions({
      fetchSelection: 'selected/fetchSelection',
      removeSelectedId: 'map/removeSelected',
      resetSelected: 'map/resetSelected',
      resetMap: 'map/resetMap',
      setFilterForMap: 'settings/setFilter',
      resetFilterForMap: 'settings/resetFilter',
      removeMapObject: 'settings/removeMapObject',
    }),
    goBack() {
      this.$router.push({ name: 'drawing.multiselect' });
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
    deleteMapObject(mapObject) {
      this.removeSelectedId({ mapObjectId: mapObject.id });
    },
    emptySelectionObjects() {
      this.resetSelected();
    },
    deleteObjects() {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_map_objects_confirm'))) {
        this.loading = true;
        MapObjectApi.deleteMapObjectsInBulk(this.selectedObjects.map(a => a.id))
          .then(() => {
            this.$notify({
              type: 'success',
              title: this.$t('dialog.deleted', {
                model: this.$options.filters.capitalize(this.$tc('models.mapobject', 0)),
              }),
            });
            this.selectedObjects.map(a => a.id).forEach((mapObjectId) => {
              this.removeMapObject({ mapObjectId });
            });
            this.finishUp();
          }).catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.delete_failed', { model: this.$tc('models.map_object', 0) }),
              data: error.response,
            });
            this.loading = false;
          });
      }
    },
    finishUp() {
      this.resetMap();
      this.emptySelectionObjects();
      this.loading = false;
      this.resetFilterForMap();
      this.$router.push({ name: 'drawing.multiselect' });
    },
    toEditObjects() {
      this.$router.push({
        name: 'drawing.multiselect.edit',
        params: { type: this.$route.params.type },
      });
    },
  },
  mounted() {
    EventBus.$emit('Map.changeMapMode', MapMode.multiSelectionCreate);
    EventBus.$emit('Map.disableClustering');
    EventBus.$emit('Map.enableSelectArea');
    const filter = this.getFilter;
    if (filter !== null && filter !== undefined) {
      filter.mapObjectType = [this.$route.params.type];
      this.setFilterForMap({ filter });
      console.log('MS Mounted filter', filter);
    } else {
      this.setFilterForMap({ filter: { mapObjectType: [this.$route.params.type] } });
    }
    EventBus.$emit('Map.redraw');
  },
  beforeDestroy() {
    this.resetFilterForMap();
    EventBus.$emit('Map.enableClustering');
    EventBus.$emit('Map.redraw');
  },
};
</script>

<style scoped>
.create-object-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}

.create-object-buttons .save-button {
  float: right;
  margin-right: 7px;
}

.field span#parent_id {
  font-weight: 300;
  font-size: 1em;
  float: right;
  line-height: 36px;
}

.input-error {
  float: left;
  width: 100%;
  color: #ff7676;
}

input.error {
  border-color: #ff7676;
}

.delete-image {
  float: left;
  font-weight: bold;
  color: white;
  background-color: #ef325e;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  position: absolute;
  margin-left: 33px;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  line-height: 19px;
}

.delete-image:hover {
  background-color: #a82540;
}

.create-selection-content {
  padding: 10px 20px;
  overflow: inherit;
}

.create-selection-content .field label {
  width: 35%;
  display: inline-flex;
}
.map-object-icon {
  display: inline-flex;
  flex-direction: column;
  height: 65px;
  width: 50px;
}
.map-object-icon-image {
  display: inline-flex;
  align-content: center;
  align-self: center;
}
.map-object-label {
  font-size: 11px;
  display: inline-flex;
  text-align: center;
  align-content: center;
  align-self: center;
  margin-top: -6px;
}
</style>
