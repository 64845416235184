<template>
  <div :class="{'actionlog-top-foldout': true, 'active': show}">
    <div class="arrow sort"></div>
    <div class="content">
      {{ $t('actionlog.sort_on') | capitalize }}:
      <br />
      <div class="field">
        <span class="radio-option">
          <input type="radio"
                 id="sort-radio-created_at"
                 name="sort"
                 value="created_at"
                 v-model="sort"
          />
          <label class="sort-radio-label" for="sort-radio-created_at">
            {{ $t('model.created_at') | capitalize }}
          </label>
        </span>
        <span class="radio-option">
          <input type="radio"
                 id="sort-radio-updated_at"
                 name="sort"
                 value="updated_at"
                 v-model="sort"
          />
          <label class="sort-radio-label" for="sort-radio-updated_at">
            {{ $t('model.updated_at') | capitalize }}
          </label>
        </span>
      </div>
      <div class="field">
        <span class="radio-option">
          <input type="radio"
                 id="sort-radio-desc"
                 name="sortBy"
                 value="desc"
                 v-model="sortBy"
          />
          <label class="sort-radio-label" for="sort-radio-desc">
            {{ $t('actionlog.desc') | capitalize }}
          </label>
        </span>
        <span class="radio-option">
          <input type="radio"
                 id="sort-radio-asc"
                 name="sortBy"
                 value="asc"
                 v-model="sortBy"
          />
          <label class="sort-radio-label" for="sort-radio-asc">
            {{ $t('actionlog.asc') | capitalize }}
          </label>
        </span>
      </div>
      <div class="field">
        <a class="btn success" @click="performSort">{{ $t('actionlog.sort') | capitalize }}</a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ActionSort',
  components: {
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sort: 'updated_at',
      sortBy: 'desc',
    };
  },
  methods: {
    performSort() {
      this.$emit('performSort', this.sort, this.sortBy);
    },
  },
};
</script>

<style scoped>
  .radio-option {
    float: left;
    min-width: 100px;
    width: 50%;
  }

  .sort-radio-label {
    margin-left: 5px;
    width: auto;
    float: initial;
    line-height: normal;
  }
</style>
