import axios from 'axios';
import config from '@/util/config';

export default {
  searchGoogleGeocodingApi(query, bbox = '') {
    const data = {
      params: {
        address: query,
        key: config.googleMapsApiKey,
        bounds: bbox,
      },
    };
    return axios.get('https://maps.googleapis.com/maps/api/geocode/json', data)
      .then(response => response.data);
  },
};
