import axios from '@/plugins/axios';

export default {
  getActionPriority() {
    return axios.get('/v1/action_priority?perPage=9999')
      .then(response => response.data);
  },
  getActionPriorityById(id) {
    return axios.get(`/v1/action_priority/${id}`)
      .then(response => response.data);
  },
  updateActionPriorityById(id, data) {
    return axios.put(`/v1/action_priority/${id}`, data)
      .then(response => response.data);
  },
  deleteActionPriorityById(id) {
    return axios.delete(`/v1/action_priority/${id}`)
      .then(response => response.data);
  },
  createActionPriority(data) {
    return axios.post('/v1/action_priority', data)
      .then(response => response.data);
  },
};
