<template>
  <div class="action-details-wrapper">
    <div class="details-title">
      {{ $t('model.created_at') | capitalize }}:
    </div>
    <div class="details-content">
      {{ action.created_at | dateTime }}
      <span v-if="action.device && action.device !== 'web'" class="device-icon">
        <DeviceIcon :device="action.device" />
      </span>
    </div>
    <div class="details-title">
      {{ $t('model.updated_at') | capitalize }}:
    </div>
    <div class="details-content">
      {{ action.updated_at | dateTime }}
    </div>

    <div class="details-title">
      {{ $t('model.owner') | capitalize }}:
    </div>
    <div class="details-content">
      <MultiSelect
        v-if="hasLockingKV && !actionLocked"
        :options="usersWithTeams"
        v-model="selectedUser"
        track-by="id"
        label="name"
        :multiple="false"
        :close-on-select="true"
        :allow-empty="false"
        placeholder=""
        selectLabel=""
        selectedLabel=""
        deselectLabel=""
        @input="updateUser"
      />
      <span v-else>
        <span v-if="action.user && action.user.data">{{ action.user.data.name }}</span>
      </span>
    </div>
  </div>
</template>
<script>
import MultiSelect from 'vue-multiselect';

import { mapGetters } from 'vuex';
import userApi from '@/api/user';

import DeviceIcon from './DeviceIcon.vue';

export default {
  name: 'ActionDetails',
  components: { DeviceIcon, MultiSelect },
  props: {
    action: {
      type: Object,
      required: true,
    },
    hasLockingKV: Boolean,
    actionLocked: Boolean,
  },
  computed: {
    ...mapGetters({
      users: 'settings/getUsers',
    }),
    usersWithTeams() {
      return this.users.map((user) => {
        let team = '';
        const data = user;
        if (user.team && user.team.data && user.team.data.length > 0) {
          const teamData = user.team.data.map(item => item.name);
          team = teamData.join(', ');
          data.name = `${user.name} (${team})`;
        }
        return data;
      });
    },
  },
  data() {
    return {
      selectedUser: null,
    };
  },
  methods: {
    Compare(a, b) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      let comparison = 0;

      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }

      return comparison;
    },
    getUsersTeams() {
      userApi.getUsersTeams().then((response) => {
        this.users = response.data;
        this.users.sort(this.Compare);
        // eslint-disable-next-line prefer-destructuring
        this.selectedUser = this.usersWithTeams.filter(
          item => item.id === this.action.created_by,
        )[0];
      });
    },
    updateUser(value) {
      if (value) {
        this.action.created_by = value.id;
      }
    },
  },
  mounted() {
    if (!this.actionLocked) {
      // this.getUsersTeams();
    }
  },
  watch: {
    action() {
      if (this.users && this.users.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedUser = this.usersWithTeams.filter(
          item => item.id === this.action.created_by,
        )[0];
      }
    },
  },
};
</script>

<style scoped>
  .action-details-wrapper {
    width: 100%;
    font-style: italic;
  }

  .action-details-wrapper .details-title {
    display: inline-block;
    position: relative;
    text-align: left;
    width: 50%;
  }

  .action-details-wrapper .details-content {
    vertical-align: text-top;
    display: inline-block;
    position: relative;
    text-align: right;
    width: 50%;
  }

  .action-details-wrapper .details-content .device-icon {
    margin-left: 15px;
  }

</style>
