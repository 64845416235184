<template>
  <div class="routemanagement-breadcrumb">
    <Loading :visible="loading" />
    <div v-if="showBackButton" class="breadcrumb-back">
      <a class="breadcrumb-back-link" @click="clickBack()">
        <font-awesome-icon icon="arrow-left" />
      </a>
      <slot name="parents"></slot>
    </div>
    <div class="breadcrumb-icon-panel">
      <slot name="icon"></slot>
    </div>
    <div class="object-title" :data-id="id">
      <p class="object-type" :data-id="id">{{ type }}</p>
      <span class="object-label">{{ name }}</span>
    </div>
  </div>
</template>

<script>
import Loading from '../Loading.vue';

export default {
  name: 'Breadcrumb',
  components: { Loading },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    clickBack() {
      this.$emit('backButtonPressed');
    },
  },
};
</script>

<style scoped>
  .routemanagement-breadcrumb {
    border-bottom: 1px solid #d9dadb;
    min-height: 80px;
    height: 100%;
    position: relative;
  }

  .routemanagement-breadcrumb div.breadcrumb-back {
    cursor: pointer;
    display: block;
    float: left;
    width: 60px;
    height: 80px;
    line-height: 80px;
    color: #42464c;
    text-decoration: none;
    text-align: center;
    border-right: 1px solid #d9dadb;
    border-bottom: 1px solid #d9dadb;
    transition: all 0.3s ease;
    font-weight: 300;
  }

  .routemanagement-breadcrumb div.breadcrumb-back a.breadcrumb-back-link {
    color: #42464c;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
    font-size: 1.0em;
    margin: 0;
    padding: 0;
    outline: 0;
    transition: all 0.3s ease;
    background: transparent;
  }

  .routemanagement-breadcrumb div.breadcrumb-back .breadcrumb-parents {
    z-index: 10;
    transition: all 0.3s ease;
    overflow: hidden;
    position: absolute;
    bottom: -17px;
    left: 10px;
    background: #fff;
    height: 34px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
  }

  .routemanagement-breadcrumb .breadcrumb-icon-panel {
    float: left;
    transform: translateY(45%);
    margin: -7px 5px 0 5px;
  }

  .routemanagement-breadcrumb .object-title {
    line-height: 24px;
    display: block;
    font-size: 1.0em;
    width: 250px;
    overflow: hidden;
  }

  .routemanagement-breadcrumb .object-title .object-type {
    color: gray;
    font-size: 16px;
    line-height: 12px;
    margin: 25px 0 0;
    padding: 0;
  }

  .routemanagement-breadcrumb .object-title .object-label {
    line-height: 26px;
    font-size: 1.3em;
    display: block;
    width: 250px;
    overflow: hidden;
    box-sizing: border-box;
    outline: 0;

  }
</style>
