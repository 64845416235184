<template>
  <input
    v-if="attributeType === 'string'"
    type="text"
    maxlength="255"
    :name="attribute.id"
    :disabled="disabled"
    v-model="attribute.value"
    :required="attribute.required"
    :class="{'error': activeError}"
    @change="resetError"
  />
  <input
    v-else-if="attributeType === 'integer'"
    type="number"
    :name="attribute.id"
    :disabled="disabled"
    step="1"
    v-model="attribute.value"
    :required="attribute.required"
    :class="{'error': activeError}"
    @change="resetError"
  />
  <input
    v-else-if="attributeType === 'double'"
    type="number"
    :name="attribute.id"
    :disabled="disabled"
    step="any"
    v-model="attribute.value"
    :required="attribute.required"
    :class="{'error': activeError}"
    @change="resetError"
  />
  <input
    v-else-if="attributeType === 'date'"
    type="date"
    :name="attribute.id"
    :disabled="disabled"
    v-model="attribute.value"
    :class="{'error': activeError}"
    @change="resetError"
  />
  <MultiSelect
    v-else-if="attributeType === 'lookup' && hasLookupValues"
    :options="getLookupValues"
    v-model="lookupValueInput"
    track-by="id"
    label="name"
    :multiple="false"
    :close-on-select="true"
    :allow-empty="!attribute.required"
    :preselect-first="attribute.required"
    placeholder=""
    selectLabel=""
    selectedLabel=""
    deselectLabel=""
    @input="updateLookup"
  />
  <MultiSelect
    v-else-if="attributeType === 'multi_lookup' && hasLookupValues"
    :options="getLookupValues"
    v-model="multiLookupValueInput"
    track-by="id"
    label="name"
    :multiple="true"
    :close-on-select="false"
    :allow-empty="!attribute.required"
    :preselect-first="attribute.required"
    placeholder=""
    selectLabel=""
    selectedLabel=""
    deselectLabel=""
    @input="updateMultiLookup"
  />
  <textarea
    v-else-if="attributeType === 'textarea'"
    type="text"
    :name="attribute.id"
    :disabled="disabled"
    v-model="attribute.value"
  />
  <div v-else-if="attributeType === 'multilang'">
    <div class="multilang-tabs">
      <ul>
        <li v-for="(countryCode, index) in countryCodes"
            :key="index"
            :class="{active: (activeMultiLangTab === countryCode)}"
            @click="changeMultiLangTab(countryCode)"
        >
          <a href="javascript:void(0)" class="multilang-tab-link">
            <country-flag v-if="countryCode === 'en_GB'" country="gb" size='small' />
            <country-flag v-else :country="countryCode" size='small' />
          </a>
        </li>
      </ul>
    </div>
    <div class="multilang-input">
      <input
        type="text"
        maxlength="255"
        :name="attribute.id"
        :disabled="disabled"
        v-model="attribute.value[activeMultiLangTab]"
      />
    </div>
  </div>
  <div v-else-if="attributeType === 'multilangtextarea'">
    <div class="multilang-tabs">
      <ul>
        <li v-for="(countryCode, index) in countryCodes"
            :key="index"
            :class="{active: (activeMultiLangTab === countryCode)}"
            @click="changeMultiLangTab(countryCode)"
        >
          <a href="javascript:void(0)" class="multilang-tab-link">
            <country-flag v-if="countryCode === 'en_GB'" country="gb" size='small' />
            <country-flag v-else :country="countryCode" size='small' />
          </a>
        </li>
      </ul>
    </div>
    <div class="multilang-input">
      <textarea
        :name="attribute.id"
        :disabled="disabled"
        v-model="attribute.value[activeMultiLangTab]"
      />
    </div>
  </div>
  <div v-else-if="attributeType === 'boolean'" :class="{'boolean-error': activeError}">
    <div class="boolean-input">
      <input
        :disabled="disabled"
        type="radio"
        :id="`boolean-no-${attribute.id}`"
        :name="`boolean-${attribute.id}`"
        :value="false"
        v-model="attribute.value"
        @change="resetError"
      />
      <label class="radio-label" :for="`boolean-no-${attribute.id}`">
        {{ $t('input.no') | capitalize }}
      </label>
    </div>
    <div class="boolean-input">
      <input
        :disabled="disabled"
        type="radio"
        :id="`boolean-yes-${attribute.id}`"
        :name="`boolean-${attribute.id}`"
        :value="true"
        v-model="attribute.value"
        @change="resetError"
      />
      <label class="radio-label" :for="`boolean-yes-${attribute.id}`">
        {{ $t('input.yes') | capitalize }}
      </label>
    </div>
  </div>
  <input
    v-else
    type="text"
    maxlength="255"
    :name="attribute.id"
    v-model="attribute.value"
    :disabled="disabled"
  />
</template>

<script>
import MultiSelect from 'vue-multiselect';
import CountryFlag from 'vue-country-flag';


export default {
  name: 'AttributeInput',
  components: {
    MultiSelect,
    CountryFlag,
  },
  props: {
    attribute: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attributeTypes: ['string', 'integer', 'double', 'boolean', 'date', 'lookup', 'multi_lookup'],
      multiLookupValueInput: [],
      lookupValueInput: null,
      countryCodes: ['nl', 'en_GB', 'de'],
      activeMultiLangTab: 'nl',
      activeError: false,
    };
  },
  computed: {
    attributeType() {
      return this.attribute.type;
    },
    hasLookupValues() {
      if (!this.attribute.attributeLookupValue) {
        return false;
      }
      if (!this.attribute.attributeLookupValue.data) {
        return false;
      }
      return this.attribute.attributeLookupValue.data.length !== 0;
    },
    getLookupValues() {
      return this.attribute.attributeLookupValue.data;
    },
  },
  methods: {
    changeMultiLangTab(country) {
      this.activeMultiLangTab = country;
    },
    updateMultiLookup(values) {
      this.attribute.value = values.map(value => value.id);
    },
    updateLookup(value) {
      if (!value) {
        this.attribute.value = null;
      } else {
        this.attribute.value = value.id;
      }
    },
    resetError() {
      this.activeError = false;
    },
  },
  beforeMount() {
    if (this.attribute.type === 'multi_lookup') {
      if (!this.attribute.value) {
        this.attribute.value = [];
        this.multiLookupValueInput = [];
      } else {
        this.multiLookupValueInput = this.getLookupValues.filter(
          value => this.attribute.value.includes(value.id),
        );
      }
    }
    if (this.attribute.type === 'lookup') {
      if (this.attribute.value) {
        this.lookupValueInput = this.getLookupValues.filter(
          value => parseInt(this.attribute.value, 10) === parseInt(value.id, 10),
        );
      }
    }
    if (this.attribute.type === 'boolean' && !this.attribute.value) {
      this.attribute.value = null;
    }
    if (this.attribute.type === 'date' && this.attribute.value) {
      this.attribute.value = this.$options.filters.dateToInput(this.attribute.value);
    }
    if (this.attribute.type === 'multilang' && !this.attribute.value) {
      const value = {};
      this.countryCodes.forEach((country) => {
        value[country] = '';
      });
      this.attribute.value = value;
    }
    if (this.attribute.type === 'multilangtextarea' && !this.attribute.value) {
      const value = {};
      this.countryCodes.forEach((country) => {
        value[country] = '';
      });
      this.attribute.value = value;
    }
  },
  watch: {
    error() {
      this.activeError = this.error;
    },
  },
};
</script>
<style scoped>
  .boolean-input {
    width: 100%;
    position: relative;
    display: inline-block;
  }

  .radio-label {
    width: auto;
    float: initial;
    line-height: normal;
  }

  .multilang-input {
    border-top: 0;
    border-left: 1px solid #d9dadb;
    border-right: 1px solid #d9dadb;
    border-bottom: 1px solid #d9dadb;
    position: relative;
    display: flex;
    top: -1px;
    padding: 13px 5px 11px;
  }

  .multilang-tabs {
    border-bottom: 1px solid #d9dadb;
    height: 62px;
  }
  .multilang-tabs ul {
    margin: 0;
    padding: 0 20px;
    display: table;
    width: 100%;
    table-layout: fixed;
    position: relative;
    top: 1px;
  }
  .multilang-tabs ul li {
    display: table-cell;
    list-style: none;
    line-height: 1.8em;
  }
  .multilang-tabs ul li a.multilang-tab-link {
    display: block;
    padding: 0 10px;
    text-decoration: none;
    color: #42464c;
    font-size: .85em;
    touch-action: manipulation;
    position: relative;
    height: 25px;
  }
  .multilang-tabs ul li.active a.multilang-tab-link {
    cursor: default;
    font-weight: 400;
    background: #f2f7fe;
    border-top: 1px solid #d9dadb;
    border-left: 1px solid #d9dadb;
    border-right: 1px solid #d9dadb;
    display: block;
    padding: 0 10px;
    text-decoration: none;
    color: #42464c;
    font-size: .85em;
  }
  .multilang-tabs ul li a.multilang-tab-link span {
    position: relative;
    overflow: hidden;
    top: -8px;
    left: 8px;
  }
  input.error {
    border-color: #ff7676;
  }
  .boolean-error {
    color: #e36161;
  }
</style>
