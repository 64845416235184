<template>
  <a class="small-link-item" @click="clickItem">
    <Loading :visible=loading />
    <span class="item-name">{{ title }}</span>
    <span class="arrow-right">
      <font-awesome-icon icon="chevron-right" />
    </span>
  </a>
</template>

<script>
import Loading from './Loading.vue';

export default {
  name: 'CollapsePanelSmallLink',
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickItem() {
      this.$emit('clickItem');
    },
  },
};
</script>

<style scoped>
.small-link-item {
  position: relative;
  display: block;
  text-decoration: none;
  color: #42464c;
  overflow: hidden;
  border-bottom: 1px solid #cfd4da;
  padding: 10px 20px;
  touch-action: manipulation;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 1.0em;
  margin: 0;
}
.small-link-item:hover {
  background: #ebf3fd;
}

.small-link-item span.item-name {
  position: relative;
  color: #42464c;
  overflow: hidden;
  padding: 10px 20px;
}
.small-link-item span.arrow-right {
  transition: all 0.3s ease;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: .5em;
  opacity: .5;
}
</style>
