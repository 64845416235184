const NetworkIcon = {
  type: 'icon',
  map_object_type_id: 0,
  icon: '13_roadsign',
  frame: 'none',
  color: '#ffffff',
  color_selected: '#b6b6b6',
  text_color: '#000000',
  text_color_selected: '#000000',
};

export default NetworkIcon;
