<template>
  <div>
    <BreadcrumbSelection
      v-if="selection"
      :id="selection.id"
      :name="`${selection.name} ${$t('button.edit')}`"
      :type="selection.mapObjectSelectionType.data.name"
      :showBackButton="true"
      :customBackAction="true"
      @backButtonPressed="goBack"
    />
    <CollapsePanel
      :title="$t('selections.selection_info')"
      :loading="loading || !selection"
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div v-if="selection" class="create-selection-content">
        <div v-if="selection.status === 'broken'" class="warning-broken-selection">
          <p>
            <font-awesome-icon class="exclamation" icon="exclamation-circle"/>
            Let op: Deze selectie is kapot!
            <font-awesome-icon class="exclamation" icon="exclamation-circle"/>
          </p>
          <a class="btn red" @click="emptySelectionObjects">
            {{ $t('button.empty_object_selection') }}
          </a>
        </div>
        <div class="field">
          <label for="selection_name">{{ $t('model.name') | capitalize }}:</label>
          <input
            id="selection_name"
            name="selection_name"
            type="text"
            v-model="selection.name"
            :class="{'error': errors.name !== null}"
            @change="resetError('name')"
          />
          <span class="input-error">
            {{ errors.name }}
          </span>
        </div>
        <div class="field">
          <label for="selection_description">{{ $t('model.description') | capitalize }}:</label>
          <textarea
            id="selection_description"
            name="selection_description"
            v-model="selection.description"
          />
        </div>
        <div class="field" v-if="hasLockingKV">
          <label for="selection_name">{{ $t('model.owner') | capitalize }}:</label>
          <select
            id="created_by"
            name="created_by"
            type="text"
            v-model="selection.created_by"
            :class="{'error': errors.created_by !== null}"
            @change="resetError('created_by')"
          >
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
              <span v-if="user.team.data.length">(
                <span v-for="(team, index) in user.team.data" :key="team.id">
                  {{ team.name }}<span v-if="(index + 1) < user.team.data.length">, </span>
                </span>
              )</span>
            </option>
          </select>
          <span class="input-error">
            {{ errors.created_by }}
          </span>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel
      v-if="selection && selection.attribute &&
       selection.attribute.data &&
        selection.attribute.data.length > 0"
      :title="$t('selections.attribute_info')"
      :loading="false"
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="create-selection-content">
        <span class="objects-error">
          {{ errors.mapObjects }}
        </span>
        <div v-for="attribute in selection.attribute.data"
             :key="attribute.id"
             class="field attribute-field"
        >
          <label :for="attribute.id">
            {{ attribute.name }}:
          </label>
          <div :id="attribute.id" class="input-select-field">
            <AttributeInput :disabled="false" :attribute="attribute" />
          </div>
        </div>
      </div>
    </CollapsePanel>
    <HandleSelectionObjects
      v-if="selection && selection.mapObject &&
     selection.mapObject.data"
      :is-network="isNetwork" :is-ordered="isOrdered" :errors="errors.mapObjects"
      @resetErrors="errors.mapObjects = null"
      ref="handleSelectionObjects"
    />
    <div class="create-selection-buttons">
      <a class="btn grey" @click="goBack">
        {{ $t('button.cancel') | capitalize }}
      </a>
      <a class="btn save-button module-color-background" @click="saveSelection">
        {{ $t('button.save') | capitalize }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MapObjectSelectionsApi from '@/api/map_object_selection';
import KeyValueApi from '@/api/key_value';
import userApi from '@/api/user';

import CollapsePanel from '@/components/CollapsePanel.vue';
import AttributeInput from '@/components/AttributeInput.vue';
import EventBus from '@/events/event-bus';
import BreadcrumbSelection from '@/modules/selections/components/BreadcrumbSelection.vue';
import HandleSelectionObjects from '@/modules/selections/components/HandleSelectionObjects.vue';
import MapMode from '@/enums/MapMode';

export default {
  name: 'EditSelection',
  components: {
    BreadcrumbSelection,
    HandleSelectionObjects,
    AttributeInput,
    CollapsePanel,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      selection: 'selected/getSelection',
      selectedMapObjects: 'map/getSelected',
    }),
    isNetwork() {
      if (!this.selection) {
        return false;
      }
      if (!this.selection.mapObjectSelectionType && !this.selection.mapObjectSelectionType.data) {
        return false;
      }
      return this.selection.mapObjectSelectionType.data.is_network;
    },
    isOrdered() {
      if (!this.selection) {
        return false;
      }
      if (!this.selection.mapObjectSelectionType && !this.selection.mapObjectSelectionType.data) {
        return false;
      }
      return this.selection.mapObjectSelectionType.data.is_ordered;
    },
  },
  data() {
    return {
      loading: false,
      showCollapseMenu: false,
      errors: {
        name: null,
        mapObjects: null,
      },
      hasLockingKV: false,
      users: [],
      mapObjects: [],
    };
  },
  methods: {
    ...mapActions({
      fetchSelection: 'selected/fetchSelection',
      resetSelection: 'selected/resetSelection',
      resetSelected: 'map/resetSelected',
    }),
    goBack() {
      const selectionId = this.selection.id;
      this.resetSelection();
      this.$router.push({ name: 'selection.show', params: { id: selectionId } });
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
    emptySelectionObjects() {
      this.$refs.handleSelectionObjects.emptySelectionObjects();
      this.selection.mapObject.data = [];
      this.showCollapseMenu = false;
    },
    finishUp() {
      const selectionId = this.selection.id;
      this.resetSelection();
      this.$router.push({ name: 'selection.show', params: { id: selectionId } });
      this.loading = false;
    },
    resetError(field) {
      this.errors[field] = null;
    },
    validateFields() {
      let valid = true;
      if (this.selection.name === '') {
        this.errors.name = this.$t('validation.name_empty');
        this.$notify({
          type: 'warning',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobjectselection', 1) }),
          text: this.$t('validation.name_empty'),
          duration: 1000,
        });
        valid = false;
      }
      if (!this.selectedMapObjects || this.selectedMapObjects.length === 0) {
        this.errors.mapObjects = this.$t('validation.no_objects');
        this.$notify({
          type: 'warning',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobjectselection', 1) }),
          text: this.$t('validation.no_objects'),
          duration: 1000,
        });
        valid = false;
      }
      return valid;
    },
    saveSelection() {
      if (!this.validateFields()) {
        return;
      }
      this.loading = true;

      const data = {
        data: JSON.parse(JSON.stringify(this.selection)),
      };

      this.$delete(data.data, 'mapObject');
      this.$delete(data.data, 'mapObjectSelectionType');

      MapObjectSelectionsApi.updateMapObjectSelectionById(this.selection.id, data)
        .then(() => {
          this.updateMapObjects();
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.update_failed', { model: this.$tc('models.mapobjectselection', 1) }),
            data: error.response,
          });
          this.loading = false;
        });
    },
    updateMapObjects() {
      const mapObjects = JSON.parse(JSON.stringify(this.selectedMapObjects));
      const data = {
        data: {
          relation: mapObjects.map((mapObj, index) => {
            const obj = {};
            obj.id = mapObj.id;
            obj.label = mapObj.name;
            if (this.isOrdered) {
              obj.order = index;
            }
            return obj;
          }),
        },
      };
      console.log(this.mapObjects, data);

      MapObjectSelectionsApi.createMapObjectForMapObjectSelection(
        this.selection.id, data, true,
      ).then(() => {
        this.finishUp();
      }).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.update_failed', { model: this.$tc('models.mapobjectselection', 1) }),
          data: error.response,
        });
        this.loading = false;
      });
    },
    Compare(a, b) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      let comparison = 0;

      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }

      return comparison;
    },
    getUsersTeams() {
      userApi.getUsersTeams().then((response) => {
        this.users = response.data;
        this.users.sort(this.Compare);
      });
    },
  },
  mounted() {
    if (this.$route.params.id !== null && this.$route.params.id !== undefined) {
      this.selectedId = parseInt(this.$route.params.id, 10);
      if (!this.selection || this.selection.id !== this.selectedId) {
        this.fetchSelection({ id: this.selectedId }).then((response) => {
          EventBus.$emit(
            'Map.changeMapMode',
            MapMode.selectionUpdate,
            null,
            response.map_object_selection_type_id,
          );
        });
      } else {
        EventBus.$emit(
          'Map.changeMapMode',
          MapMode.selectionUpdate,
          null,
          this.selection.map_object_selection_type_id,
        );
      }
    }
    KeyValueApi.getKeyValueByKey(`lock_mapobjectselections_${this.network.id}`)
      .then((response) => {
        const resp = response.data;
        if (resp) {
          this.hasLockingKV = true;
          this.getUsersTeams();
        }
      });
  },
};
</script>

<style scoped>
.warning-broken-selection {
  padding: 0;
  color: rgba(238, 51, 95, 1);
  margin-bottom: 10px;
  text-align: center;
  border-bottom: 1px solid #d9dadb;
  height: 90px;
}

.warning-broken-selection .exclamation {
  color: rgba(238, 51, 95, 1);
}

.create-selection-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}

.create-selection-buttons .save-button {
  float: right;
  margin-right: 7px;
}

.input-error {
  float: left;
  width: 100%;
  color: #ff7676;
}

.objects-error {
  width: 100%;
  color: #ff7676;
}

input.error {
  border-color: #ff7676;
}

.attribute-field {
  overflow: inherit;
}

.create-selection-content {
  padding: 10px 20px;
  overflow: visible;
}

.create-selection-content .field label {
  width: 35%;
  display: inline-flex;
}

.create-selection-content .field .input-select-field {
  width: 65%;
  overflow: inherit;
  display: inline-flex;
  float: initial;
}
</style>
