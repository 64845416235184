import axios from '@/plugins/axios';

export default {
  getMapObjectSelections() {
    return axios.get('/v2/selection?perPage=9999')
      .then(response => response.data);
  },
  getMapObjectSelectionById(id, include = '') {
    return axios.get(`/v2/selection/${id}?include=${include}`)
      .then(response => response.data);
  },
  createMapObjectSelection(data) {
    return axios.post('/v2/selection', data)
      .then(response => response.data);
  },
  updateMapObjectSelectionById(id, data) {
    return axios.put(`/v2/selection/${id}`, data)
      .then(response => response.data);
  },
  deleteMapObjectSelectionById(id) {
    return axios.delete(`/v2/selection/${id}`)
      .then(response => response.data);
  },
  findMapObjectSelection(data, include = '') {
    return axios.post(`/v2/selection/find?include=${include}`, data)
      .then(response => response.data);
  },
  createImageForMapObjectSelection(selectionId, data) {
    return axios.post(`/v2/selection/${selectionId}/image`, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data);
  },
  deleteImageForMapObjectSelection(selectionId, imageId) {
    return axios.delete(`/v2/selection/${selectionId}/image/${imageId}`)
      .then(response => response.data);
  },
  createMapObjectForMapObjectSelection(selectionId, data, sync = false) {
    return axios.post(`/v2/selection/${selectionId}/object?sync=${sync}`, data)
      .then(response => response.data);
  },
};
