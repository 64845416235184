<template>
  <CollapsePanel
    :title="$t('route_management.administration')"
    :loading=loading
    :showCount=true
    :count="attachments.length"
    :showCollapseAction="true"
    :disabled="!$can('module', 'beheerplus')"
    @collapseAction="showCreateAttachment"
  >
    <div v-if="$can('module', 'beheerplus')" class="attachment-search">
      <div class="field">
        <input class="attachment-search-input"
               type="text"
               :placeholder="$t('route_management.search') | capitalize"
               v-model="input"
        >
        <a class="attachment-search-button">
          <font-awesome-icon icon="search" />
        </a>
      </div>
    </div>
    <div class="routemanagement-attachments">
      <div class="attachments-wrapper">
        <div v-for="(attachment, index) in filteredAttachments"
             :key="index" class="attachment-item"
        >
          <div class="attachment-image">
            <img :src="`/assets/img/mime/icon-${getExtension(attachment.filename)}.svg`"
                 :alt="getExtension(attachment.filename)">
          </div>
          <div class="attachment-content">
            <div class="attachment-buttons">
              <a :href="attachment.url" class="btn download-attachment module-color-background"
                 download>
                <font-awesome-icon icon="save" />
              </a>
              <a class="btn delete-attachment"
                 @click="deleteAttachment(attachment.token)">
                <font-awesome-icon icon="trash" />
              </a>
            </div>
            <p class="attachment-title">
              {{ attachment.title }}
            </p>
            <ul class="attachment-meta">
              <li>
                <span>{{ $t('model.created_at') | capitalize }}:</span>
                {{ attachment.created_at | dateTime }}
              </li>
              <li><span>{{ $t('model.created_by') | capitalize }}:</span>
                {{ attachment.user.data.name }}</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
    <input class="file-input" ref="fileInput" type="file" @change="createAttachment" />
  </CollapsePanel>
</template>

<script>
import { mapGetters } from 'vuex';
import MapObjectApi from '@/api/map_object';
import NetworkApi from '@/api/network';

import CollapsePanel from '@/components/CollapsePanel.vue';

export default {
  name: 'Administration',
  components: {
    CollapsePanel,
  },
  props: {
    mapObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      attachments: [],
      input: '',
    };
  },
  computed: {
    ...mapGetters({
      networkId: 'switcher/getNetworkId',
    }),
    filteredAttachments() {
      return this.attachments.filter(
        attachment => attachment.filename.toLowerCase().search(this.input.toLowerCase()) > -1,
      );
    },
  },
  methods: {
    getExtension(filename) {
      return filename.substr(filename.lastIndexOf('.') + 1);
    },
    showCreateAttachment() {
      this.$refs.fileInput.click();
    },
    createAttachment(event) {
      this.loading = true;
      const uploadedFile = event.target.files[0];
      const data = {
        data: {
          filename: uploadedFile.name,
          title: uploadedFile.name,
          size: uploadedFile.size,
        },
      };
      if (!this.mapObject) {
        NetworkApi.createAttachmentForNetwork(this.networkId, data)
          .then((response) => {
            if (response.data.upload_url) {
              const formData = new FormData();
              formData.append('file', uploadedFile);
              NetworkApi.uploadAttachmentForNetwork(response.data.upload_url, formData)
                .then(() => {
                  this.getAttachments();
                });
            }
          });
      } else {
        MapObjectApi.createAttachmentForMapObject(this.mapObject.id, data)
          .then((response) => {
            if (response.data.upload_url) {
              const formData = new FormData();
              formData.append('file', uploadedFile);
              MapObjectApi.uploadAttachmentForMapObject(response.data.upload_url, formData)
                .then(() => {
                  this.getAttachments();
                });
            }
          });
      }
      this.$refs.fileInput.value = '';
    },
    deleteAttachment(token) {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_attachment_confirm'))) {
        this.loading = true;
        if (!this.mapObject) {
          NetworkApi.deleteAttachmentForNetwork(this.networkId, token)
            .then(() => {
              this.getAttachments();
            });
        } else {
          MapObjectApi.deleteAttachmentForMapObject(this.mapObject.id, token)
            .then(() => {
              this.getAttachments();
            });
        }
      }
    },
    getAttachments() {
      if (!this.$can('module', 'beheerplus')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: 'Routebeheer module' }),
          text: this.$t('error.no_access_show', { model: 'Routebeheer module' }),
        });
        return;
      }
      this.loading = true;
      if (!this.mapObject) {
        NetworkApi.getAttachmentsForNetwork(this.networkId, 'user')
          .then((response) => {
            this.attachments = response.data;
            this.loading = false;
          });
      } else {
        MapObjectApi.getAttachmentsForMapObject(this.mapObject.id, 'user')
          .then((response) => {
            this.attachments = response.data;
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.getAttachments();
  },
  watch: {
    mapObject() {
      this.getAttachments();
    },
  },
};
</script>
<style scoped>
  .attachment-search {
    padding: 0 20px 15px;
    margin: 0;
    border-bottom: 1px solid #d9dadb;
  }

  .attachment-search .field {
    position: relative;
    margin: 0;
  }

  .attachment-search .field .attachment-search-input {
    border-radius: 555px;
    height: 30px;
    line-height: 30px;
    font-size: .85em;
    width: 100%;
    padding: 0 30px 0 12px;
    float: left;
    border: 1px solid #cfd4da;
  }

  .attachment-search .field .attachment-search-button {
    border: none;
    height: 30px;
    width: 30px;
    line-height: 30px;
    position: absolute;
    right: 0;
    background: none;
    color: #44a7dc;
    padding: 0;
    font-size: 0.8em;
    cursor: default;
  }


  .routemanagement-attachments {
    clear: both;
  }

  .file-input {
    display:none;
  }

  .routemanagement-attachments .attachments-wrapper {
    margin: 0 0 -11px 0;
  }

  .attachment-item {
    padding: 15px 20px;
    border-bottom: 1px solid #d9dadb;
    overflow: hidden;
  }

  .attachment-item .attachment-image {
    display: block;
    float: left;
    width: 60px;
    margin-right: 15px;
  }

  .attachment-item .attachment-image img {
    display: block;
    width: 100%;
    border: none;
  }

  .attachment-item .attachment-content {
    display: block;
    float: left;
    width: calc(100% - 75px);
  }

  .attachment-item .attachment-content .attachment-buttons {
    float: right;
  }

  .attachment-buttons .download-attachment {
    width: 30px;
    padding: 0;
    text-align: center;
    font-size: 0.9em;
  }

  .attachment-buttons .delete-attachment {
    background: #ee335f;
    width: 30px;
    padding: 0;
    text-align: center;
    font-size: 0.8em;
  }

  .attachment-title {
    text-transform: uppercase;
    word-break: break-all;
  }

  .attachment-item .attachment-content ul.attachment-meta {
    clear: both;
    margin: 0;
    overflow: auto;
    zoom: 1;
    font-size: .85em;
  }

  .attachment-item .attachment-content ul.attachment-meta li {
    line-height: 1.7em;
    position: relative;
    clear: both;
    list-style: none;
  }
</style>
