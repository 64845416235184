import axios from '@/plugins/axios';

export default {
  postActionExport(data) {
    return axios.post('/v1/export_actions', data)
      .then(response => response.data);
  },
  postObjectExport(data) {
    return axios.post('/v1/export_map_objects', data)
      .then(response => response.data);
  },
};
