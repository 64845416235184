<template>
  <keep-alive>
    <div v-if="$route.name === 'login'
      || $route.name === 'logout'
      || $route.name === 'password.forgot'
    ">
      <router-view />
      <NotificationWrapper />
    </div>
    <div v-else>
      <ModuleLayout v-if="mounted" :fullscreen="fullscreen">
        <router-view @showFullscreen="showFullscreen" />
        <NotificationWrapper />
      </ModuleLayout>
    </div>
  </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationWrapper from '@/components/Layout/NotificationWrapper.vue';
import ModuleLayout from '@/components/Layout/ModuleLayout.vue';
import EventBus from '@/events/event-bus';

export default {
  name: 'app',
  components: {
    ModuleLayout,
    NotificationWrapper,
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  data() {
    return {
      mounted: false,
      fullscreen: false,
    };
  },
  methods: {
    showFullscreen(fullscreen) {
      console.log('showFullscreen', fullscreen);
      this.fullscreen = fullscreen;
    },
  },
  async beforeMount() {
    await this.$store.dispatch('auth/login');
    await this.$store.dispatch('getUser');
    if (!this.$store.state.auth.auth.token) {
      this.mounted = true;
    }
  },
  watch: {
    user() {
      this.mounted = true;
    },
    $route(newRoute, oldRoute) {
      this.fullscreen = false;
      if ((!oldRoute || !oldRoute.name.includes('route_management')) && newRoute.name.includes('route_management')) {
        // EventBus.$emit('Map.showChildrenCluster');
        EventBus.$emit('Map.showActions');
      } else if ((!oldRoute || oldRoute.name.includes('route_management')) && !newRoute.name.includes('route_management')) {
        // EventBus.$emit('Map.hideChildrenCluster');
        EventBus.$emit('Map.hideActions');
      }
    },
  },
};
</script>
<style>
  @import "assets/css/portaal.css";

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  .settings-icon {
    bottom: -15px !important;
    position: absolute !important;
  }

  .admin-icon {
    position: absolute !important;
    bottom: 30px;
  }

  .ant-layout-sider-children {
    margin-top: 4px;
  }

  .min-height-100 {
    min-height: 100vh;
  }

  .ant-layout-sider {
    z-index: 100;
    width: 80px !important;
    max-width: 80px !important;
    overflow: hidden;
    min-width: 0 !important;
  }

  .ant-menu-vertical .ant-menu-item {
    margin-bottom: 0 !important;
  }

  .ant-menu-dark.ant-menu-vertical .ant-menu-item {
    margin-bottom: 0;
  }

  .ant-layout-sider {
    background: #42464c !important;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item:hover {
    background: #1890ff;
  }

  .ant-menu-item img {
    left: -8px !important;
    margin-left: -13px;
    margin-top: 13px;
  }

  .ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-vertical .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    height: 87px;
  }

  .ant-layout {
    background: #f0f2f5;
  }

  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark
  .ant-menu-item-selected {
    background-color: transparent;
  }

  .ant-menu-item span {
    color: white;
    padding-left: 20px;
  }

  .a-menu-item span {
    color: white;
    padding-left: 20px;
  }

  .ant-card-head-title {
    font-size: 17px;
  }

  .ant-menu-item {
    margin: 0 !important;
  }

  .ant-layout {
    min-height: 100vh !important;
  }

  .ant-menu.ant-menu-dark {
    background-color: #42464c;
  }

  .dropdown-sidebar {
    color: white;
    margin-right: 20px;
    bottom: 25px;
    position: absolute;
    left: 30px;
  }

  .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span {
    font-size: 15px !important;
  }

  @media only screen and (min-width: 1264px) {
    .container {
      max-width: 1264px;
    }
  }

  .ant-layout {
    max-height: 100vh;
  }

  .logo {
    width: 130px;
    float: left;
    padding-top: 11px;
    padding-left: 10px;
  }

  .w-100 {
    width: 100% !important;
  }

  .mb-10 {
    margin-bottom: 10px !important;
  }

  .ant-drawer-left.ant-drawer-open, .ant-drawer-right.ant-drawer-open {
    width: 100%;
    margin-left: 80px;
  }

  .ant-layout-sider-trigger {
    display: none;
  }

  .float-right {
    float: right;
  }

  .align-right {
    text-align: right;
  }

  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mt-10 {
    margin-top: 10px !important;
  }

  .mb-20 {
    margin-bottom: 20px !important;
  }

  .pull-right {
    float: right;
  }

  .form-item {
    margin-bottom: 10px;
  }

  .form-item input {
    margin-top: 5px;
  }

  td {
    font-size: 14px;
  }

  .btn-cancel {
    margin-right: 5px;
  }

  .width-100 {
    width: 100% !important;
  }

  .ant-notification-notice-icon svg {
    font-size: 20px !important;
  }

  .ant-notification-notice-icon {
    line-height: 55px !important;
    margin-left: 0px !important;
  }

  .ant-notification-notice-close {
    top: 29px !important;
  }

  .ant-card-head-title {
    font-weight: 600;
  }

  .height-100 {
    height: 100% !important;
  }

  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none !important;
  }

  .menu-title {
    border: none !important;
  }

  .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span {
    font-weight: 700;
    font-size: 14px !important;
  }

  .ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left,
  .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
    height: 100% !important;
    margin-bottom: 0 !important;
  }

  .text-center {
    text-align: center !important;
  }

  .ant-drawer-body {
    padding: 0;
  }

  #map-actions-float {
    position: absolute;
    z-index: -1;
    right: 20px;
    top: 15px;
  }
</style>
