<template>
  <CollapsePanel
    :title="$t('selections.map_object_info')"
    :loading="false"
    :showCount=false
    :showCollapseAction="selectedObjects.length > 0"
    :collapsed="true"
    icon="pen"
    @collapseAction="toggleShowCollapseMenu"
  >
    <template v-slot:customCollapseAction v-if="showCollapseMenu">
      <CollapseActionMenu>
        <CollapseActionMenuItem
          :title="$t('button.empty_selection') | capitalize"
          icon="pen"
          @clickItem="emptySelectionObjects"
        />
      </CollapseActionMenu>
    </template>
    <div class="create-selection-content">
      <div v-if="!isOrdered && !isNetwork" class="selection-tip">
        <small><i>{{ $t('selections.select_multiple_ctrl') }}</i></small>
      </div>
      <span class="objects-error">
          {{ errors }}
        </span>
      <span v-for="(mapObject, index) in selectionObjects"
            :key="mapObject.id+'-'+index">
          <IconByTypeId :map_object_type_id="mapObject.map_object_type_id"
                        :label="mapObject | mapObjectLabel"
                        :size="35"
          />
          <span v-if="!isOrdered || index === (selectionObjects.length - 1)"
                class="delete-image"
                @click="deleteMapObject(mapObject, index)"
                v-on:click.stop
          >
            <font-awesome-icon icon="times"/>
          </span>
        </span>
    </div>
  </CollapsePanel>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CollapsePanel from '@/components/CollapsePanel.vue';
import CollapseActionMenu from '@/components/CollapseActionMenu.vue';
import CollapseActionMenuItem from '@/components/CollapseActionMenuItem.vue';
import IconByTypeId from '@/components/IconByTypeId.vue';
import EventBus from '@/events/event-bus';

export default {
  name: 'HandleSelectionObjects',
  components: {
    CollapseActionMenuItem,
    CollapseActionMenu,
    IconByTypeId,
    CollapsePanel,
  },
  props: {
    isNetwork: {
      type: Boolean,
      required: true,
    },
    isOrdered: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      selectionTypes: 'settings/getMapObjectSelectionTypes',
      selection: 'selected/getSelection',
      selectedObjects: 'map/getSelected',
    }),
    selectionObjects() {
      const objects = this.selectedObjects;
      if (this.isNetwork || this.isOrdered) {
        return objects.filter(object => object.geoObject.data.geometry.type === 'LineString');
      }
      return objects;
    },
    chosenSelectionType() {
      if (this.$route.params.type !== null && this.$route.params.type !== undefined) {
        const selectedType = parseInt(this.$route.params.type, 10);
        return this.selectionTypes[selectedType];
      }
      return {};
    },
  },
  data() {
    return {
      showCollapseMenu: false,
      mapObjectErrors: null,
    };
  },
  methods: {
    ...mapActions({
      removeSelectedId: 'map/removeSelected',
      resetSelected: 'map/resetSelected',
      resetMap: 'map/resetMap',
    }),
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
    deleteMapObject(mapObject) {
      EventBus.$emit('DrawSelection.removeMapObject', mapObject);
    },
    emptySelectionObjects() {
      this.resetSelected();
    },
    removeMapObjectFromSelectionList(mapObject) {
      EventBus.$emit('DrawSelection.removeMapObject', mapObject);
    },
  },
};
</script>

<style scoped>

.delete-image {
  float: left;
  font-weight: bold;
  color: white;
  background-color: #ef325e;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  position: absolute;
  margin-left: -17px;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  line-height: 19px;
}

.delete-image:hover {
  background-color: #a82540;
}

.objects-error {
  width: 100%;
  color: #ff7676;
}

.create-selection-content {
  padding: 10px 20px;
  overflow: inherit;
}

.create-selection-content .field label {
  width: 35%;
  display: inline-flex;
}
</style>
