import axios from '@/plugins/axios';

export default {
  getAction() {
    return axios.get('/v2/action')
      .then(response => response.data);
  },
  getActionWithIncludesAndPagination(includes, pagination) {
    return axios.get(`/v2/action?include=${includes}&page=${pagination}`)
      .then(response => response.data);
  },
  getActionById(id, include = '') {
    return axios.get(`/v2/action/${id}?include=${include}`)
      .then(response => response.data);
  },
  getActionMapObjectType(id) {
    return axios.get(`/v1/action/${id}/map_object_type`)
      .then(response => response.data);
  },
  createAction(data, include = '') {
    return axios.post(`/v2/action?include=${include}`, data)
      .then(response => response.data);
  },
  updateActionById(id, data) {
    return axios.put(`/v2/action/${id}`, data)
      .then(response => response.data);
  },
  deleteActionById(id) {
    return axios.delete(`/v2/action/${id}`)
      .then(response => response.data);
  },
  getCommentsForAction(id) {
    return axios.get(`/v1/action/${id}/comment?include=user`)
      .then(response => response.data);
  },
  async createCommentForAction(id, data) {
    return axios.post(`/v1/action/${id}/comment`, data)
      .then(response => response.data);
  },
  deleteCommentForAction(id, commentId) {
    return axios.delete(`/v1/action/${id}/comment/${commentId}`)
      .then(response => response.data);
  },
  createImageForAction(id, data) {
    return axios.post(`/v2/action/${id}/image`, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data);
  },
  deleteImageForAction(actionId, imageId) {
    return axios.delete(`/v2/action/${actionId}/image/${imageId}`)
      .then(response => response.data);
  },
  promoteActionImagesToMapObject(actionId, mapObjectId, actionImageIds) {
    const data = {
      data: {
        action_image_id: actionImageIds,
      },
    };
    return axios.post(`/v2/action/${actionId}/image/promote/${mapObjectId}`, data)
      .then(response => response.data);
  },
};
