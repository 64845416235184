<template>
  <a
     class="child-object"
     @click="goToObject(child.id)" v-on:click.stop
  >
    <div class="chevron-icon">
      <font-awesome-icon icon="chevron-right" />
    </div>
    <div class="child-object-icon">
      <IconByTypeId
        :map_object_type_id="child.map_object_type_id"
        :label="child | mapObjectLabel"
        :size="40"
      />
    </div>
    <div class="child-object-title">
      {{ getType ? getType.name : '-' }} {{child | mapObjectLabel }}
    </div>
    <div v-for="(innerChild, innerKey) in child.children.data"
         :key="innerKey"
         class="child-object-child"
         @click="goToObject(innerChild.id)" v-on:click.stop
    >
      <div class="chevron-icon">
        <font-awesome-icon icon="chevron-right" />
      </div>
      <div class="child-object-icon">
        <IconByTypeId
          :map_object_type_id="innerChild.map_object_type_id"
          :label="innerChild | mapObjectLabel"
        />
      </div>
      <div class="child-object-title">
        {{ innerChild | mapObjectLabel }}
      </div>
    </div>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';
import IconByTypeId from '@/components/IconByTypeId.vue';

export default {
  name: 'ChildObject',
  components: { IconByTypeId },
  props: {
    child: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
    getType() {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return null;
      }
      if (!Object.hasOwn(this.mapObjectTypes, this.child.map_object_type_id)) {
        return null;
      }
      if (!this.mapObjectTypes[this.child.map_object_type_id]) {
        return null;
      }
      return this.mapObjectTypes[this.child.map_object_type_id];
    },
  },
  methods: {
    goToObject(id) {
      this.$emit('goToObject', id);
    },
  },
};
</script>

<style scoped>
a.child-object {
  position: relative;
  display: block;
  text-decoration: none;
  color: #42464c;
  overflow: hidden;
  border-bottom: 1px solid #cfd4da;
  padding: 10px 20px 0;
  cursor: pointer;
  font-size: 1.0em;
  margin: 0;
}
a.child-object:hover {
  box-shadow: inset 0 0 50px 50px rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

a.child-object .child-object-icon {
  float: left;
  margin: 0 5px;
}

a.child-object .child-object-title {
  float: left;
  margin: 0;
  padding: 0;
  border: none;
  width: 60%;
  height: 54px;
  line-height: 20px;
  vertical-align: middle;
}

a.child-object .chevron-icon {
  height: 100%;
  vertical-align: middle;
  float: right;
  display: inline-block;
  line-height: 48px;
}

a.child-object .child-object-child {
  width: calc(100% - 30px);
  height: 42px;
  display: inline-block;
  padding: 0 30px 0 20px;
  font-size: .9em;
  border-top: 1px solid #cfd4da;
  margin-left: 50px;
}
a.child-object .child-object-child:hover {
  box-shadow: inset 0 0 50px 50px rgba(255, 255, 255, 0.6);
  cursor: pointer;
}

a.child-object .child-object-child .child-object-title {
  float: initial;
  display: inline-block;
  height: 35px;
  line-height: 20px;
  vertical-align: middle;
}

a.child-object .child-object-child .chevron-icon {
  line-height: 42px;
}
</style>
