import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import config from '@/util/config';
// eslint-disable-next-line import/no-cycle
import auth from '@/services/auth';
// eslint-disable-next-line import/no-cycle
import switcher from '@/services/switcher';

if (window.tenant_id) {
  localStorage.setItem('tenant_id', window.tenant_id);
}
if (window.network_id) {
  localStorage.setItem('network_id', window.network_id);
}
if (window.access_token) {
  localStorage.setItem('access_token', window.access_token);
}

export default axios.create(
  {
    baseURL: config.apiUrl,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.getAccessToken()}`,
      'x-tenant': switcher.getSelectedTenantId(),
      'x-network': switcher.getSelectedNetworkId(),
    },
  },
);

Vue.use(VueAxios, axios);
