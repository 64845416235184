import Vue from 'vue';
import Vuex from 'vuex';
import { Ability } from '@casl/ability';
// eslint-disable-next-line import/no-cycle
import userApi from '@/api/user';
import abilityPlugin from './auth/ability';
import auth from './auth/auth.module';
// eslint-disable-next-line import/no-cycle
import switcher from './auth/switcher.module';
// eslint-disable-next-line import/no-cycle
import settings from './auth/settings.module';
// eslint-disable-next-line import/no-cycle
import selected from './auth/selected.module';
// eslint-disable-next-line import/no-cycle
import map from './auth/map.module';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    abilityPlugin,
  ],
  state: {
    rules: [],
    user: {},
    roles: [],
  },
  getters: {
    ability() {
      return new Ability([]);
    },
    user: state => state.user,
    getUser(state) {
      return state.user;
    },
    getRoles(state) {
      return state.roles;
    },
  },
  mutations: {
    setUserAndPermissions(state, user) {
      const permissions = user.permissions.data;
      const rules = permissions.map((permission) => {
        const splitted = permission.split(/-(.+)/);
        return { action: splitted[0], subject: splitted[1] };
      });
      if (user.super_admin) {
        rules.push({ action: 'all', subject: 'super_admin' });
      }
      state.rules = rules;
      state.user = user;
      return rules;
    },
    SET_ROLES(state, roles) {
      Vue.set(state, 'roles', roles);
    },
  },
  actions: {
    async getUser({ commit }) {
      const user = await userApi.getMe()
        .then((response) => {
          commit('setUserAndPermissions', response.data);
          return response.data;
        }).catch((e) => {
          // eslint-disable-next-line no-console
          console.log('getUser catch', e);
        });
      if (user && user.id) {
        userApi.getRoles(user.id)
          .then((response) => {
            commit('SET_ROLES', response.data);
          });
      }
    },
    getRoles({ commit }) {
      userApi.getRoles()
        .then((response) => {
          commit('SET_ROLES', response.data);
        });
    },
  },
  modules: {
    auth,
    switcher,
    settings,
    selected,
    map,
  },
});
