import axios from '@/plugins/axios';
import config from '@/util/config';

export default {
  login(email, password, type = 'password') {
    return axios.post('/v1/oauth/access_token', {
      username: email,
      client_id: config.clientId,
      client_secret: config.clientSecret,
      grant_type: type,
      password,
    })
      .then(response => response.data);
  },
  sendPasswordResetMail(email) {
    return axios.post('/v2/user/account/password', { data: { email } })
      .then(response => response.data);
  },
};
