<template>
  <div>
    <Breadcrumb
      :id="chosenMapObjectType.id"
      :name="chosenMapObjectType.name"
      :type="$t('drawing.multiselect')"
      :showBackButton="true"
      @backButtonPressed="goBack"
    >
      <template v-slot:icon>
        <MapIcon v-bind="networkIcon" icon="13_roadsign" :size="39"/>
      </template>
    </Breadcrumb>
    <CollapsePanel
      :title="$t('selections.map_object_info')"
      :loading="false"
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
      icon="pen"
    >
      <div class="create-selection-content">
        <span v-if="selectedObjects.length === 0">
          <small><i>{{ $t('drawing.no_objects_selected') }}</i></small>
        </span>
        <span v-for="(mapObject, index) in selectedObjects"
              :key="mapObject.id+'-'+index">
          <IconByTypeId :map_object_type_id="mapObject.map_object_type_id"
                        :label="mapObject | mapObjectLabel"
                        :size="35"
          />
        </span>
      </div>
    </CollapsePanel>

    <CollapsePanel
      :title="$t('route_management.object_information')"
      :loading=loading
      :showCount=false
      :collapsed="true"
      :showCollapseAction="false"
    >
      <div class="create-object-content">
        <div class="field">
          <label for="name">{{ $t('model.name') | capitalize }}:</label>
          <input
            id="name"
            name="name"
            type="text"
            v-model="objectData.name"
            :class="{'error': errors.name !== null}"
            @change="resetError('name')"
            autofocus
          />
          <span class="input-error">
            {{ errors.name }}
          </span>
        </div>
        <div class="field">
          <label for="map_object_status_id" class="status-label">
            {{ $t('model.status') | capitalize }}:
          </label>
          <div class="field content status-select">
            <MultiSelect
              id="map_object_status_id"
              :options="statusValues"
              v-model="mapObjectStatus"
              track-by="id"
              label="name"
              :multiple="false"
              :close-on-select="true"
              :allow-empty="false"
              :preselect-first="true"
              placeholder=""
              selectLabel=""
              selectedLabel=""
              deselectLabel=""
              @input="updateStatus"
            />
          </div>
        </div>
      </div>
    </CollapsePanel>
    <CollapsePanel
      :title="$t('selections.attribute_info')"
      :loading="false"
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="create-object-content">
        <div v-for="(attribute, idx) in chosenMapObjectType.attribute.data"
             :key="idx"
             class="attribute-field"
        >
          <span class="title">
              <span v-if="attribute.required" class="required">*</span>
              {{ attribute.name }}:
          </span>
          <div class="field content">
            <AttributeInput
              :disabled="false"
              :attribute="attribute"
              :error="errors[attribute.id]"
            />
          </div>
        </div>
      </div>
    </CollapsePanel>
    <div class="create-object-buttons">
      <a class="btn grey"
         @click="goBack"
      >
        {{ $t('button.back') | capitalize }}
      </a>
      <a class="btn module-color-background save-button"
         @click="saveObjects"
      >
        {{ $t(`button.edit`) | capitalize }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MultiSelect from 'vue-multiselect';

import EventBus from '@/events/event-bus';
import MapIcon from '@/components/Map/MapIcon.vue';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import NetworkIcon from '@/enums/NetworkIcon';
import AttributeInput from '@/components/AttributeInput.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import IconByTypeId from '@/components/IconByTypeId.vue';
import MapObjectApi from '@/api/map_object';

export default {
  name: 'drawing.multiselect.create',
  components: {
    IconByTypeId,
    CollapsePanel,
    AttributeInput,
    Breadcrumb,
    MapIcon,
    MultiSelect,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObjectStatuses: 'settings/getMapObjectStatuses',
      mapObjectTypes: 'settings/getMapObjectTypes',
      selectedObjects: 'map/getSelected',
      mapObjects: 'settings/getMapObjects',
    }),
    chosenMapObjectType() {
      if (this.$route.params.type !== null && this.$route.params.type !== undefined) {
        const selectedType = parseInt(this.$route.params.type, 10);
        return this.mapObjectTypes[selectedType];
      }
      return {};
    },
    statusValues() {
      const statuses = Object.values(this.mapObjectStatuses);
      statuses.unshift({
        id: 0,
        name: this.$t('drawing.unchanged'),
      });
      return statuses;
    },
  },
  data() {
    return {
      loading: false,
      networkIcon: NetworkIcon,
      objectData: {
        name: '',
        map_object_status_id: 0,
        map_object_type_id: 0,
      },
      errors: {
        name: null,
        mapObjects: null,
      },
      mapObjectStatus: null,
      showCollapseMenu: false,
    };
  },
  methods: {
    ...mapActions({
      fetchSelection: 'selected/fetchSelection',
      removeSelectedId: 'map/removeSelected',
      resetSelected: 'map/resetSelected',
      resetMap: 'map/resetMap',
      updateMapObject: 'settings/updateMapObject',
    }),
    goBack() {
      this.$router.push({ name: 'drawing.multiselect.choose', params: { type: this.$route.params.type } });
    },
    resetError(field) {
      this.errors[field] = null;
    },
    updateStatus(value) {
      if (!value) {
        this.objectData.map_object_status_id = null;
      } else {
        this.objectData.map_object_status_id = value.id;
      }
    },
    updateMapObjectList(mapObjects) {
      this.mapObjects = mapObjects;
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
    deleteMapObject(mapObject, emit = true) {
      console.log('deleteMapObject', mapObject, emit);
      this.removeSelectedId({ mapObjectId: mapObject.id });
    },
    emptySelectionObjects() {
      this.resetSelected();
    },
    removeMapObjectFromSelectionList(mapObject) {
      this.deleteMapObject(mapObject, false);
    },
    filterEmptyMultiLookupValues(data) {
      return data.map((attribute) => {
        if (attribute.type === 'multi_lookup' && attribute.value && attribute.value.length === 0) {
          const newAttribute = attribute;
          delete newAttribute.value;
          return newAttribute;
        }
        return attribute;
      });
    },
    saveObjects() {
      this.loading = true;
      const data = {
        update: this.objectData,
        ids: this.selectedObjects.map(a => a.id),
      };
      if (data.update.name === '') {
        delete data.update.name;
      }
      if (data.update.map_object_status_id === 0) {
        delete data.update.map_object_status_id;
      }
      data.update.map_object_type_id = this.chosenMapObjectType.id;
      data.update.attribute = {
        data: this.filterEmptyMultiLookupValues(
          JSON.parse(JSON.stringify(this.chosenMapObjectType.attribute.data)),
        ),
      };
      console.log('data', data);
      MapObjectApi.updateMapObjectsInBulk(data)
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('dialog.changes_saved'),
          });
          this.updateLocalObjects(data);
          this.finishUp();
        }).catch((error) => {
          console.log('error', error);
          this.$notify({
            type: 'error',
            title: this.$t('error.update_failed', { model: this.$tc('models.mapobject', 0) }),
            data: error.response,
          });
          this.loading = false;
        });
    },
    updateLocalObjects(data) {
      data.ids.forEach((id) => {
        const mapObject = this.mapObjects[id];
        if (!mapObject) return;
        if (data.update.name) {
          mapObject.name = data.update.name;
        }
        if (data.update.attribute && data.update.attribute.data) {
          const labelAttributes = data.update.attribute.data
            .filter(item => item.id === this.chosenMapObjectType.label_attribute_id);
          if (labelAttributes && labelAttributes.length > 0) {
            mapObject.label = labelAttributes[0].value ? labelAttributes[0].value : mapObject.label;
          }
        }
        if (data.update.map_object_status_id) {
          mapObject.map_object_status_id = data.update.map_object_status_id;
          delete mapObject.mapObjectStatus;
        }
        this.updateMapObject({ mapObject });
      });
    },
    finishUp() {
      this.emptySelectionObjects();
      this.resetMap();
      this.loading = false;
      this.$router.push({ name: 'drawing.multiselect' });
    },
  },
  mounted() {
    console.log('mounted selected', this.selectedObjects);
    if (this.selectedObjects.length === 0) {
      this.$router.push({ name: 'drawing.multiselect' });
    }
    EventBus.$emit('Map.disableInteraction');
  },
  beforeDestroy() {
    EventBus.$emit('Map.enableInteraction');
  },
};
</script>

<style scoped>
.field {
  overflow: visible;
  min-height: 40px;
}
.field label {
  width: auto;
}
.create-object-content {
  padding: 10px 20px 0 20px;
  overflow: inherit;
}
.status-label {
  display: inline-flex;
  float: initial;
}
.status-select {
  display: inline-flex;
  float: initial;
  overflow: inherit;
}
.attribute-field {
  overflow: inherit;
}

.create-object-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}

.create-object-buttons .save-button {
  float: right;
  margin-right: 7px;
}
.field span#parent_id {
  font-weight: 300;
  font-size: 1em;
  float: right;
  line-height: 36px;
}
.input-error {
  float: left;
  width: 100%;
  color: #ff7676;
}
input.error {
  border-color: #ff7676;
}


.delete-image {
  float: left;
  font-weight: bold;
  color: white;
  background-color: #ef325e;
  border-radius: 50%;
  height: 19px;
  width: 19px;
  position: absolute;
  margin-left: -17px;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  line-height: 19px;
}

.delete-image:hover {
  background-color: #a82540;
}

.objects-error {
  width: 100%;
  color: #ff7676;
}

.create-selection-content {
  padding: 10px 20px;
  overflow: inherit;
}

.create-selection-content .field label {
  width: 35%;
  display: inline-flex;
}
</style>
