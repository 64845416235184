<template>
  <div :class="['foldout-holder', {'closed': closed, 'full-screen-foldout': fullscreen}]">
    <div class="foldout-toggle">
      <a v-if="closed" class="toggle bigger module-color-background" @click="toggleBigger">
        <font-awesome-icon icon="chevron-right" />
      </a>
      <a v-else class="toggle smaller module-color-background" @click="toggleSmaller">
        <font-awesome-icon icon="chevron-left" />
      </a>
    </div>
    <div :class="['foldout', {'closed': closed}]">
      <div class="foldout-content">
        <h1 class="module-color module-title">
          {{ title }}
          <div class="help-icon-wrapper" @click="showInfoModal">
            <font-awesome-icon
              icon="question-circle"
              class="help-icon help-modal"
              :data-module-name="nav"
            />
          </div>
        </h1>

        <div :class="'panel panel-' + nav">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/events/event-bus';

export default {
  name: 'Drawer',
  props: {
    nav: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      closed: false,
    };
  },
  methods: {
    toggleSmaller() {
      this.closed = true;
    },
    toggleBigger() {
      this.closed = false;
    },
    showInfoModal() {
      EventBus.$emit('InfoModal.show', this.nav);
    },
  },
};
</script>

<style scoped>
  .foldout-holder.full-screen-foldout {
    width: auto;
    left: 80px;
    right: -20px;
    position: fixed;
  }

  .foldout-holder.full-screen-foldout .foldout {
    width: calc(100% - 20px);
  }

  .foldout-holder {
    position: absolute;
    top: 0;
    height: 100%;
    width: 420px;
    left: 80px;
    z-index: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s ease;
  }

  .foldout-holder.closed {
    width: 20px;
  }

  .foldout-holder .foldout-toggle {
    right: 0;
    left: auto;
  }

  .foldout-toggle {
    position: absolute;
    left: 480px;
    top: 50%;
    transform: translateY(-50%);
  }

  .foldout-toggle a.toggle {
    width: 20px;
    height: 60px;
    background: var(--module-color);
    color: #fff;
    font-size: .65em;
    text-align: center;
    line-height: 60px;
    margin-bottom: 4px;
    text-decoration: none;
    display: block;
    border-radius: 0 3px 3px 0;
  }

  .foldout-toggle a.toggle:last-child {
    margin-bottom: 0;
  }

  .foldout-toggle a.toggle span.icon {
    line-height: 60px;
  }

  .foldout-toggle a.toggle:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
  }

  .foldout {
    top: 0;
    height: 100%;
    width: 400px;
    left: 0;
    background: #fff;
    z-index: -1;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }

  .foldout.closed {
    width: 0;
  }

  .foldout .foldout-content h1 {
    border-bottom: 1px solid #d9dadb;
    color: var(--module-color);
    padding: 0 20px;
    height: 80px;
    line-height: 80px;
  }

  .help-icon-wrapper {
    position: relative;
    display: inline-block;
    float: right;
    text-align: right;
  }

  .help-icon-wrapper .help-icon {
    font-size: 0.8em;
    position: relative;
    text-align: right;
    line-height: 80px;
    float: initial;
  }

  .help-icon-wrapper .help-icon:hover {
    cursor: pointer;
  }

  h1.module-title {
    margin-bottom: 0;
  }
</style>
