<template>

  <ActionModal
    :title="$tc('models.recurringaction', 1)+' '+recurring_action_id | capitalize"
    :action_type_name="dynamicActionTypeName"
    :visible="visible"
    @closeModal="closeModal"
    :loading="loading"
  >
    <template v-slot:left-content>
      <ActionModalMapObjectInformation
        v-if="mapObject"
        :map_object="mapObject"
      />
    </template>
    <template v-slot:right-content>
      {{ $tc('models.status', 1) | capitalize }}:
      <div class="field">
        <ActionStatusSelect
          v-if="recurringAction.action_status_id"
          v-model="recurringAction.action_status_id"
          :action_statuses="actionStatuses"
        />
      </div>
      {{ $tc('models.priority', 1) | capitalize }}:
      <ActionPriorityButtons
        v-if="recurringAction.action_priority_id"
        v-model="recurringAction.action_priority_id"
      />
      <ActionDetails :action="recurringAction"/>

      <hr />

      {{ $t('model.interval') | capitalize }}:
      <div class="field">
        <select
          v-model="recurringAction.date_interval"
        >
          <option
            v-for="(title, key) in dateIntervals"
            :key="key"
            :value="key"
          >
            {{ $t('interval.'+title) | capitalize }}
          </option>
        </select>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-left-content">
        <a @click="deleteRecurringAction" class="btn cancel">
          {{ $t('actionlog.delete') | capitalize }}
        </a>
      </div>
      <div class="footer-content">
        <a @click="closeModal" class="btn grey">
          {{ $t('actionlog.cancel') | capitalize }}
        </a>
        <a
          @click="processRecurringAction"
          class="btn module-color-background"
        >
          {{ $t('actionlog.save_changes') | capitalize }}
        </a>
      </div>
    </template>
  </ActionModal>
</template>

<script>
import RecurringActionApi from '@/api/recurring_action';
import ActionStatusApi from '@/api/action_status';
import MapObjectApi from '@/api/map_object';

import DateInterval from '@/enums/DateInterval';

import ActionDetails from './ActionDetails.vue';
import ActionModal from './ActionModal.vue';
import ActionPriorityButtons from './ActionPriorityButtons.vue';
import ActionStatusSelect from './ActionStatusSelect.vue';
import ActionModalMapObjectInformation from './ActionModalMapObjectInformation.vue';

export default {
  name: 'RecurringActionModal',
  components: {
    ActionDetails,
    ActionModal,
    ActionPriorityButtons,
    ActionStatusSelect,
    ActionModalMapObjectInformation,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    recurring_action_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      recurringAction: {},
      mapObject: null,
      loading: false,
      actionStatuses: [],
      actionImageTypes: [],
      dynamicActionTypeName: '',
      actionImages: [],
      dateIntervals: DateInterval,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    deleteRecurringAction() {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_recurring_action_confirm'))) {
        this.loading = true;
        RecurringActionApi.deleteRecurringActionById(this.recurring_action_id).then(() => {
          this.finishUp();
        });
      }
    },
    async processRecurringAction() {
      this.loading = true;
      const data = {
        data: this.recurringAction,
      };
      RecurringActionApi.updateRecurringActionById(this.recurring_action_id, data)
        .then(() => {
          this.finishUp();
        });
    },
    finishUp() {
      this.loading = false;
      this.$emit('closeModal');
      this.$emit('updateActionLog');
    },
    getRecurringAction() {
      if (this.recurring_action_id === 0) {
        return;
      }
      this.loading = true;
      RecurringActionApi.getRecurringActionById(this.recurring_action_id, 'user,dynamicActionType,dynamicActionType.actionImageType').then((response) => {
        this.recurringAction = response.data;
        this.loading = false;
        if (this.recurringAction.map_object_id) {
          this.getMapObject(this.recurringAction.map_object_id);
        }
        if (this.recurringAction.dynamicActionType && this.recurringAction.dynamicActionType.data) {
          this.dynamicActionTypeName = this.recurringAction.dynamicActionType.data.name;
        }
      });
    },
    getMapObject(mapObjectId) {
      this.loading = true;
      MapObjectApi.getMapObjectbyId(mapObjectId, 'mapObjectType,mapObjectLabel,mapObjectStatus,attribute,attribute.attributeLookupValue')
        .then((response) => {
          this.mapObject = response.data;
          if (this.mapObject.mapObjectLabel && this.mapObject.mapObjectLabel.data) {
            this.label = this.mapObject.mapObjectLabel.data.label;
          } else {
            this.label = this.mapObject.name;
          }
          this.loading = false;
        });
    },
    getActionStatuses() {
      ActionStatusApi.getActionStatus().then((response) => {
        this.actionStatuses = response.data
          .filter(actionStatus => !actionStatus.is_final)
          .sort((a, b) => (a.weight > b.weight ? 1 : -1));
        this.recurringAction.action_status_id = this.actionStatuses[0].id;
      });
    },
  },
  beforeMount() {
    this.getActionStatuses();
  },
  watch: {
    recurring_action_id() {
      this.recurringAction = {};
      this.getRecurringAction();
    },
  },
};
</script>
<style scoped>
  div.action-type-header {
    font-family: "Abel", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.1em;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
  }

  .content-wrapper {
    position: relative;
    display: block;
    overflow: auto;
    background: #f2f7fe;
  }

  .left-content {
    float: left;
    display: inline-block;
    width: 65%;
    border-right: 1px solid #d9dadb;
    background: #f2f7fe;
    padding: 10px 24px 24px;
    height: inherit;
    min-height: 375px;
  }

  .right-content {
    float: left;
    display: inline-block;
    width: 35%;
    background: #f2f7fe;
    padding: 24px;
    height: auto;
  }

  .footer-content {
    float: right;
  }

  hr {
    border: 0;
    border-bottom: 1px solid #d9dadb;
  }
</style>
