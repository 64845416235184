<template>
  <div></div>
</template>

<script>
import L from 'leaflet';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';

export default {
  name: 'DrawLinestring',
  props: {
    map: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    network: {
      type: Object,
      required: true,
    },
    object: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
  },
  data() {
    return {
      coords: [],
      lines: [],
      tempPoints: [],
      fakePoints: [],
      startPoint: false,
      endPoint: false,
      nextKey: 0,
      tempCluster: new L.LayerGroup({}),
      findNewPointMode: false,
    };
  },
  methods: {
    ...mapActions({
      addSelectedOnMap: 'map/addSelected',
      removeSelectedOnMap: 'map/removeSelected',
    }),
    async mapClick(e) {
      if (this.startPoint === false) {
        this.$notify({
          type: 'warning',
          title: this.$t('drawing.warning'),
          text: this.$t('drawing.choose_startpoint_first'),
          duration: 1000,
        });
      } else if (!this.endPoint) {
        const { lat, lng } = e.latlng;
        this.addCoordinate(this.nextKey, lat, lng);
        this.nextKey += 1;
      }
    },
    addCoordinate(key, lat, lng) {
      this.coords.push({ key, lat, lng });
      const coord = this.addTempCoordinate(key, lat, lng);
      this.tempPoints.push(coord);
      this.redrawCluster();
    },
    addCoordinateWithoutRedraw(key, lat, lng) {
      this.coords.push({ key, lat, lng });
      const coord = this.addTempCoordinate(key, lat, lng);
      this.tempPoints.push(coord);
    },
    addTempCoordinate(key, lat, lng) {
      const icon = this.createTempCoordinateIcon();
      const coordinate = new L.Marker([lat, lng], {
        icon,
        key,
      });
      coordinate.on('click', () => {
        this.handleTempCoordinateClick(key);
      });
      this.tempCluster.addLayer(coordinate);
      this.setDraggableCoordinate(coordinate, key);
      return coordinate;
    },
    handleTempCoordinateClick(key) {
      const indexToSplice = this.coords.findIndex(x => x.key === key);
      this.coords.splice(indexToSplice, 1);
      const coord = this.tempPoints.splice(indexToSplice, 1);
      this.tempCluster.removeLayer(coord[0]);
      this.redrawCluster();
    },
    addFakeCoordinate(key, lat, lng) {
      const icon = this.createFakeCoordinateIcon();
      const coordinate = new L.Marker([lat, lng], {
        icon,
      });
      coordinate.on('click', () => {
        this.handleFakeCoordinateClick(key, lat, lng);
      });
      this.tempCluster.addLayer(coordinate);
      this.fakePoints.push(coordinate);
      return coordinate;
    },
    handleFakeCoordinateClick(key, lat, lng) {
      const indexToSplice = key + 1;
      this.coords.splice(indexToSplice, 0, { key: this.nextKey, lat, lng });
      const coord = this.addTempCoordinate(this.nextKey, lat, lng);
      this.tempPoints.splice(indexToSplice, 0, coord);
      this.nextKey += 1;
      this.redrawCluster();
    },
    addTempLine(prevLat, prevLng, lat, lng, color = 'red') {
      const line = new L.Polyline([
        [prevLat, prevLng],
        [lat, lng],
      ], { color });
      this.tempCluster.addLayer(line);
      this.lines.push(line);
      return line;
    },
    setDraggableCoordinate(coordinate, key) {
      coordinate.dragging.enable();
      coordinate.on('drag', (e) => {
        const { target } = e;
        const latLng = target.getLatLng();
        coordinate.setLatLng(latLng);
        this.coords[this.coords.findIndex(x => x.key === key)] = {
          key,
          lat: latLng.lat,
          lng: latLng.lng,
        };
        this.redrawCluster();
      });
    },
    createTempCoordinateIcon() {
      const svg = `<svg version="1.0" width="18" height="18" xmlns="http://www.w3.org/2000/svg">
               <g>
                <rect stroke-width="2" id="temp_coord" height="14" width="14"
                 y="2" x="2" stroke="#666" fill="#ffffff" rx="2" ry="2"
                />
               </g>
              </svg>`;
      const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
      return L.icon({ iconUrl, iconAnchor: [8, 8] });
    },
    createFakeCoordinateIcon() {
      const svg = `<svg version="1.0" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
               <g>
                <rect stroke-width="2" id="fake_coord" height="10" width="10"
                 y="2" x="2" stroke="#ACACAC" fill="#ffffff" rx="2" ry="2"
                />
               </g>
              </svg>`;
      const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
      return L.icon({ iconUrl, iconAnchor: [7, 7] });
    },
    removeTempMarkersFromMap() {
      this.fakePoints.forEach((point) => {
        this.tempCluster.removeLayer(point);
      });
      this.tempPoints.forEach((point) => {
        this.tempCluster.removeLayer(point);
      });
      this.fakePoints = [];
    },
    addTempMarkersBackToMap() {
      this.tempPoints.forEach((point) => {
        if (point.key !== 'start_point') {
          this.tempCluster.addLayer(point);
        }
      });
    },
    redrawCluster() {
      this.lines.forEach((line) => {
        this.tempCluster.removeLayer(line);
      });
      this.lines = [];
      this.fakePoints.forEach((point) => {
        this.tempCluster.removeLayer(point);
      });
      this.fakePoints = [];
      if (this.coords.length > 1) {
        let prevCoord = null;
        this.coords.forEach((coord) => {
          if (prevCoord !== null) {
            this.addTempLine(prevCoord.lat, prevCoord.lng, coord.lat, coord.lng);
          }
          prevCoord = coord;
        });
        this.lines.forEach((line, idx) => {
          const center = line.getBounds().getCenter();
          this.addFakeCoordinate(idx, center.lat, center.lng);
        });
      }
      this.shareCoordinates();
    },
    shareCoordinates() {
      const coords = [];
      this.coords.forEach((coord) => {
        coords.push([coord.lng, coord.lat]);
      });
      console.log('EventBus.$emit(Map.shareCoordinates, coords)');
      EventBus.$emit('Map.shareCoordinates', coords);
    },
    setStartPoint(marker) {
      console.log('setStartPoint', marker, marker.latLng);
      let lat;
      let lng;
      if (marker.latLng) {
        ({ lat, lng } = marker.latLng);
      } else if (marker.geoObject && marker.geoObject.data
        && marker.geoObject.data.geometry
        && marker.geoObject.data.geometry.coordinates
      ) {
        [lng, lat] = marker.geoObject.data.geometry.coordinates;
      } else {
        return;
      }
      this.coords.push({ key: 'start_point', lat, lng });
      this.tempPoints.push({ key: 'start_point', lat, lng });
      this.startPoint = marker;
      this.addSelectedOnMap({ mapObject: marker });
      EventBus.$emit('Map.shareStartPoint', marker.id);
    },
    handleStartPointClick() {
      if (this.coords.length === 1) {
        this.coords.pop();
        this.tempPoints.pop();
        this.removeSelectedOnMap({ mapObjectId: this.startPoint.id });
        this.startPoint = false;
      }
    },
    replaceStartPoint(mapObject) {
      this.removeSelectedOnMap({ mapObjectId: this.startPoint.id });
      this.addSelectedOnMap({ mapObject });
      this.startPoint = mapObject;
      const { lat, lng } = mapObject.latLng;
      this.coords[0] = { key: 'start_point', lat, lng };
      this.tempPoints[0] = ({ key: 'start_point', lat, lng });
      this.finishUpReplacePoint(mapObject);
    },
    setEndPoint(marker) {
      let lat;
      let lng;
      if (marker.latLng) {
        ({ lat, lng } = marker.latLng);
      } else if (marker.geoObject && marker.geoObject.data
        && marker.geoObject.data.geometry
        && marker.geoObject.data.geometry.coordinates
      ) {
        [lng, lat] = marker.geoObject.data.geometry.coordinates;
      } else {
        return;
      }
      this.coords.push({ key: 'end_point', lat, lng });
      this.endPoint = marker;
      this.redrawCluster();
      this.addSelectedOnMap({ mapObject: marker });
      EventBus.$emit('Map.shareEndPoint', marker.id);
    },
    handleEndPointClick() {
      this.coords.pop();
      this.removeSelectedOnMap({ mapObjectId: this.endPoint.id });
      this.endPoint = false;
      this.redrawCluster();
    },
    replaceEndPoint(mapObject) {
      this.removeSelectedOnMap({ mapObjectId: this.endPoint.id });
      this.addSelectedOnMap({ mapObject });
      this.endPoint = mapObject;
      const { lat, lng } = mapObject.latLng;
      this.coords.pop();
      this.coords.push({ key: 'end_point', lat, lng });
      this.finishUpReplacePoint(mapObject);
    },
    finishUpReplacePoint(mapObject) {
      this.addTempMarkersBackToMap();
      this.findNewPointMode = false;
      this.redrawCluster();
      EventBus.$emit('Map.newPoint', mapObject);
    },
    createPoint(mapObject) {
      const { coordinates } = mapObject.geoObject.data.geometry;
      return {
        id: mapObject.id,
        map_object_type_id: mapObject.map_object_type_id,
        label: this.$options.filters.mapObjectLabel(mapObject),
        actionCount: 0,
        latLng: new L.LatLng(coordinates[1], coordinates[0]),
      };
    },
    setCoordinatesByObject(mapObject) {
      console.log('setCoordinatesByObject');
      if (mapObject && mapObject.geoObject && mapObject.geoObject.data.geometry.coordinates) {
        this.nextKey = mapObject.geoObject.data.geometry.coordinates.length;
        this.setStartPoint(mapObject.startPoint.data);
        mapObject.geoObject.data.geometry.coordinates.forEach((coords, idx) => {
          if (idx === 0 || idx === (this.nextKey - 1)) {
            return;
          }
          const key = idx;
          const lat = coords[1];
          const lng = coords[0];
          this.addCoordinateWithoutRedraw(key, lat, lng);
        });
        this.setEndPoint(mapObject.endPoint.data);
      }
    },
    resetDraw() {
      this.coords = [];
      this.lines = [];
      this.tempPoints = [];
      this.fakePoints = [];
      this.startPoint = false;
      this.endPoint = false;
      this.nextKey = 0;
      this.tempCluster = new L.LayerGroup({});
    },
  },
  mounted() {
    this.map.off('click');
    this.map.on('click', (e) => {
      this.mapClick(e);
    });
    this.map.addLayer(this.tempCluster);
    if (this.object) {
      this.setCoordinatesByObject(this.object);
    }
    EventBus.$on('Map.findNewPoint', (mode) => {
      console.log('EventBus.$on(Map.findNewPoint)', mode);
      this.findNewPointMode = mode;
      this.removeTempMarkersFromMap();
    });
    EventBus.$on('Map.stopFindNewPoint', () => {
      console.log('EventBus.$on(Map.stopFindNewPoint)');
      this.findNewPointMode = false;
      this.addTempMarkersBackToMap();
      this.redrawCluster();
    });
    EventBus.$on('Map.clickMapObject', (mapObject) => {
      if (!this.startPoint) {
        this.setStartPoint(mapObject);
      } else if (mapObject.id === this.startPoint.id) {
        this.handleStartPointClick();
      } else if (!this.endPoint) {
        this.setEndPoint(mapObject);
      } else if (mapObject.id === this.endPoint.id) {
        this.handleEndPointClick();
      } else if (this.findNewPointMode === 'start') {
        this.replaceStartPoint(mapObject);
      } else if (this.findNewPointMode === 'end') {
        this.replaceEndPoint(mapObject);
      }
    });
  },
  beforeDestroy() {
    this.map.removeLayer(this.tempCluster);
    this.resetDraw();
  },
};
</script>

<style scoped>

</style>
