import axios from '@/plugins/axios';

export default {
  getObstruction() {
    return axios.get('/v2/obstruction')
      .then(response => response.data);
  },
  getObstructionWithIncludesAndPagination(id, includes, pagination) {
    return axios.get(`/v2/obstruction/${id}?include=${includes}&page=${pagination}`)
      .then(response => response.data);
  },
  getObstructionById(id, include = '') {
    return axios.get(`/v2/obstruction/${id}?include=${include}`)
      .then(response => response.data);
  },
  getObstructionByNetworkId(id, include = '', pagination) {
    return axios.get(`/v2/network/${id}/obstruction?include=${include}&page=${pagination}`)
      .then(response => response.data);
  },
  createObstruction(data, include = '') {
    return axios.post(`/v2/obstruction?include=${include}`, data)
      .then(response => response.data);
  },
  updateObstructionById(id, data) {
    return axios.put(`/v2/obstruction/${id}`, data)
      .then(response => response.data);
  },
  deleteObstructionById(id) {
    return axios.delete(`/v2/obstruction/${id}`)
      .then(response => response.data);
  },
};
