<template>
  <CollapsePanel
    :title="$t('route_management.statistics')"
    :loading="loading"
    :showCount="false"
    :showCollapseAction="false"
  >
    <div class="statistics-block">
      <h3 class="block-header">{{ $t('statistics.actionstatistics', ) | capitalize }}</h3>
      <ul class="block-details" v-for="(action, index) in action_statistics" :key="'action'+index">
        <li class="block-item">
          <span class="block-item-title">{{ action.name }}:</span>
          <span class="block-item-content">{{ action.aantal }}</span>
          <span class="block-item-content"> {{ $t('statistics.pcs', )}}</span>
        </li>
      </ul>

      <h3 class="block-header">{{ $t('statistics.map_objectstatistics', ) | capitalize }}</h3>
      <ul
        class="block-details"
        v-for="(mapobject, index) in map_object_statistics"
        :key="'mapobject'+index"
      >
        <li class="block-item">
          <span class="block-item-title">{{ mapobject.name }}:</span>
          <span class="block-item-content">{{ mapobject.aantal }}</span>
          <span class="block-item-content"> {{ $t('statistics.pcs', )}}</span>
          <span class="block-item-content" v-if="mapobject.lengte">
            ({{ mapobject.lengte }} km)
          </span>
        </li>
      </ul>
    </div>
  </CollapsePanel>
</template>

<script>
import { mapGetters } from 'vuex';

import Network from '@/api/network';

import CollapsePanel from '@/components/CollapsePanel.vue';

export default {
  name: 'Statistics',
  components: {
    CollapsePanel,
  },
  data() {
    return {
      map_object_statistics: [],
      action_statistics: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
  },
  mounted() {
    Network.getMapObjectStatistics(this.network.id).then((response) => {
      response.forEach((mapObject) => {
        if (mapObject.lengte) {
          const km = mapObject.lengte / 1000;
          // eslint-disable-next-line no-param-reassign
          mapObject.lengte = km.toFixed(1);
        }
        this.map_object_statistics.push(mapObject);
      });
    });
    Network.getActionStatistics(this.network.id).then((response) => {
      this.action_statistics = response;
      this.loading = false;
    });
  },
};
</script>
<style scoped>
.statistics-block {
  padding: 0 20px;
}

.statistics-block h3.block-header {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  margin: 30px 0 5px 0;
  padding: 0;
  color: #42464c;
}

.statistics-block ul.block-details {
  overflow: auto;
  zoom: 1;
  font-size: 0.85em;
  margin: 0 0 0px 0;
  padding: 0;
}

.statistics-block ul.block-details li.block-item {
  line-height: 1.7em;
  position: relative;
  clear: both;
  list-style: none;
}

.statistics-block .block-details .block-item .block-item-title {
  display: block;
  float: left;
  width: 60%;
}
</style>
