<template>
  <CollapsePanel
    v-if="mapObject"
    :title="$tc('models.comment', 0)"
    :loading=loading
    :showCount=true
    :count="pagination.total"
    :showCollapseAction="false"
  >
    <div v-if="$can('create', 'comment')" class="create-comment-wrapper">
      <div class="field create-comment">
        <textarea
          v-model="comment"
          name="create-comment-input"
          :placeholder="$t('actionlog.add_comment_placeholder')"
        />
      </div>
      <div class="create-comment-button">
        <a @click="createComment" class="btn module-color-background">
          {{ $t('button.save') | capitalize }}
        </a>
      </div>
    </div>
    <div class="comments-list">
      <div v-for="(comment, index) in comments" :key="index" class="mapobject-comment">
        <div v-if="$can('delete', 'comment') || comment.user_id == user.id"
             class="comment-delete"
             @click="deleteComment(comment.id)">
          <font-awesome-icon icon="trash" />
        </div>
        <p class="comment-comment">
          {{ comment.comment }}
        </p>
        <div v-if="comment.user && comment.user.data" class="comment-meta">
          <div class="comment-user">
            {{ comment.user.data.name }}
          </div>
          <a v-if="comment.user.data.email"
             :href="'mailto:'+comment.user.data.email"
             class="comment-mail"
          >
            <font-awesome-icon icon="envelope" />
          </a>
          <a v-if="comment.user.data.userAttribute
          && comment.user.data.userAttribute.data
          && comment.user.data.userAttribute.data.phone_mobile"
             :href="'tel:'+comment.user.data.userAttribute.data.phone_mobile"
             class="comment-phone"
          >
            <font-awesome-icon icon="phone" />
          </a>
          <div class="comment-date">
            {{ comment.created_at | dateTime }}
          </div>
        </div>
      </div>
    </div>
    <Pagination
      @switchPage="switchPage"
      :total="pagination.total"
      :count="pagination.count"
      :per_page="pagination.per_page"
      :current_page="pagination.current_page"
      :total_pages="pagination.total_pages"  />
  </CollapsePanel>
</template>

<script>
import MapObjectApi from '@/api/map_object';
import User from '@/api/user';
import CollapsePanel from '@/components/CollapsePanel.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: 'ObjectComments',
  components: {
    CollapsePanel,
    Pagination,
  },
  props: {
    mapObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      comments: [],
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1,
      },
      current_page: 1,
      comment: '',
      user: [],
    };
  },
  mounted() {
    this.getComments();
    User.getMe()
      .then((response) => {
        this.user = response.data;
      });
  },
  methods: {
    createComment() {
      this.loading = true;
      if (this.comment.length > 0) {
        const commentData = {
          data: {
            comment: this.comment,
          },
        };
        MapObjectApi.createCommentForMapObject(this.id, commentData)
          .then(() => {
            this.comment = '';
            this.getComments();
          });
      }
    },
    deleteComment(commentId) {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_comment_confirm'))) {
        this.loading = true;
        MapObjectApi.deleteCommentForMapObject(this.id, commentId)
          .then(() => {
            this.getComments();
          });
      }
    },
    switchPage(page) {
      this.current_page = page;
      this.getComments();
    },
    getComments() {
      if (!this.mapObject) {
        return;
      }
      this.loading = true;
      MapObjectApi.getCommentsForMapObject(this.mapObject.id, 'user,user.userAttribute', 15, this.current_page)
        .then((response) => {
          this.comments = response.data;
          this.pagination = response.meta.pagination;
          this.loading = false;
        });
    },
  },
  watch: {
    mapObject() {
      this.getComments();
    },
  },
};
</script>
<style scoped>
  .create-comment {
    padding: 10px 10px 0 10px;
    margin-bottom: 0;
  }

  .create-comment-button {
    margin: 0 10px 15px 10px;
    padding: 5px;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    border-radius: 0 0 4px 4px;
    background: #cfd4da;
    text-align: right;
  }

  .mapobject-comment {
    display: block;
    position: relative;
    border-bottom: 1px solid #d9dadb;
    padding: 10px 20px;
  }

  .mapobject-comment:first-child {
    border-top: 1px solid #d9dadb;
  }

  .mapobject-comment .comment-comment {
    color: #42464c;
  }

  .mapobject-comment .comment-delete {
    color: #EE335F;
    float: right;
    padding: 5px;
    font-size: .9em;
    text-decoration: none;
  }
  .mapobject-comment .comment-delete:hover {
    color: #9F160C;
    cursor: pointer;
  }

  .mapobject-comment .comment-meta {
    font-size: .85em;
    overflow: hidden;
    color: #6c7177;
  }

  .mapobject-comment .comment-meta .comment-user {
    font-weight: 400;
    float: left;
    letter-spacing: .05em;
  }

  .mapobject-comment .comment-meta .comment-mail,
  .mapobject-comment .comment-meta .comment-phone {
    float: left;
    text-decoration: none;
    color: #6c7177;
    opacity: .3;
    font-size: .9em;
    margin: 1px 0 0 10px;
  }
  .mapobject-comment .comment-meta .comment-mail:hover,
  .mapobject-comment .comment-meta .comment-phone:hover {
    opacity: .7;

  }

  .mapobject-comment .comment-meta .comment-date {
    margin-top: 3px;
    float: right;
    text-transform: uppercase;
    font-size: .8em;
    letter-spacing: .1em;
  }
</style>
