<template>
  <Breadcrumb
    :id="id"
    :name="nameLabel"
    :type="typeLabel"
    :showBackButton="showBackButton"
    @backButtonPressed="clickBack"
  >
    <template v-slot:icon>
      <MapIcon v-bind="networkIcon" icon="13_roadsign" :size="39"/>
    </template>
  </Breadcrumb>
</template>

<script>
import { mapGetters } from 'vuex';

import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import NetworkIcon from '@/enums/NetworkIcon';

import MapIcon from '@/components/Map/MapIcon.vue';

export default {
  name: 'BreadcrumbNetwork',
  components: { Breadcrumb, MapIcon },
  props: {
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      networkIcon: NetworkIcon,
    };
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
    id() {
      return this.network ? this.network.id : 0;
    },
    nameLabel() {
      return this.network ? this.network.name : this.name;
    },
    typeLabel() {
      return this.$options.filters.capitalize(this.$tc('models.network', 1));
    },
  },
  methods: {
    clickBack() {
      if (this.showBackButton) {
        this.$emit('backButtonPressed');
      }
    },
  },
};
</script>
