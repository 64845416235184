<template>
  <div>
    <div v-if="isActive" class="change-type-modal-cover" v-on:click="closeChangeType"></div>
    <div v-if="isActive && form === 'change-type'" class="change-type-modal">
      <Loading v-bind:visible="loading" />
      <div>
        <IconByTypeId
          v-if="object.map_object_type_id"
          :map_object_type_id="object.map_object_type_id"
          :label="object | mapObjectLabel"
          :size="39"
        />
        <span>{{this.object.mapObjectType.data.name}} {{this.object.name}}</span>
        <span> | {{ $t('drawing.change_objecttype') | capitalize }}</span>
      </div>
      <div class="changeBlock" v-if="possibleMapObjectTypes.length">
        <h3>{{ $t('drawing.warning') | capitalize }}</h3>

        <p style="font-size: 0.8em;">
          <span v-if="object.children.data.length">
            {{ $t('drawing.object_has_underlying_objects') | capitalize }}<br /><br />
          </span>
          {{ $t('drawing.warning_text') | capitalize }}.
        </p>
        <div id="input-field-status" class="field">
          <select v-model="selectedMapObjectType">
            <option :value="type" :key="type.id"
                    v-for="type in possibleMapObjectTypes">
              {{ type.name }}
            </option>
          </select>
        </div>
        <input type="checkbox" v-model="oldAttributes">
        <label> {{ $t('drawing.old_attributes') | capitalize }}</label>
      </div>
      <div class="changeBlock" v-else>
        <p>{{ $t('drawing.no_object_types') }}</p>
      </div>
      <div class="edit-object-buttons">
        <a class="btn grey" @click="closeChangeType">
          {{ $t('button.cancel') | capitalize }}
        </a>
        <a :class="{'btn': true, 'save-button': true, 'disabled': !selectedMapObjectType}"
           @click="toCreateForm">
          {{ $t('button.next') | capitalize }}
        </a>
      </div>
    </div>
    <div v-if="isActive && form === 'new-object'" class="change-type-modal">
      <Loading v-bind:visible="loading" />
      <div class="change-type-modal-inner">
        <div>
          <IconByTypeId
            v-if="object.map_object_type_id"
            :map_object_type_id="object.map_object_type_id"
            :label="object | mapObjectLabel"
            :size="39"
          />
          <span>{{this.object.mapObjectType.data.name}} {{this.object.name}}</span>
          <span> | {{ $t('drawing.change_objecttype') | capitalize }}</span>
        </div>
        <div v-if="selectedMapObjectType" class="changeBlock">
          <div class="field">
            <label for="name">{{ $t('model.name') | capitalize }}:</label>
            <input
              id="name"
              name="name"
              type="text"
              v-model="newMapObject.name"
              :class="{'error': errors.name !== null}"
              @change="resetError('name')"
              autofocus
            />
            <span class="input-error">
            {{ errors.name }}
          </span>
          </div>
          <div class="field">
            <label for="map_object_status_id" class="status-label">
              {{ $t('model.status') | capitalize }}:
            </label>
            <div class="field content status-select">
              <MultiSelect
                id="map_object_status_id"
                :options="Object.values(mapObjectStatuses)"
                v-model="mapObjectStatus"
                track-by="id"
                label="name"
                :multiple="false"
                :close-on-select="true"
                :allow-empty="false"
                :preselect-first="true"
                placeholder=""
                selectLabel=""
                selectedLabel=""
                deselectLabel=""
                @input="updateStatus"
              />
            </div>
          </div>
          <div v-for="(attribute, idx) in newMapObject.attribute.data"
               :key="idx"
               class="attribute-field"
          >
          <span class="title">
              <span v-if="attribute.required" class="required">*</span>
              {{ attribute.name }}:
          </span>
            <div class="field content">
              <AttributeInput
                :disabled="false"
                :attribute="attribute"
                :error="errors[attribute.id]"
              />
            </div>
          </div>
        </div>
        <div class="edit-object-buttons">
          <a class="btn grey" @click="closeChangeType">
            {{ $t('button.cancel') | capitalize }}
          </a>
          <a class="btn save-button" @click="saveChangeType">
            {{ $t('actionlog.save_changes') | capitalize }}
          </a>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from 'vue-multiselect';
import IconByTypeId from '@/components/IconByTypeId.vue';
import MapObjectApi from '@/api/map_object';
import Loading from '@/components/Loading.vue';
import AttributeInput from '@/components/AttributeInput.vue';
import EventBus from '@/events/event-bus';

export default {
  name: 'drawing.change_type',
  components: {
    AttributeInput,
    MultiSelect,
    Loading,
    IconByTypeId,
  },
  props: {
    isActive: Boolean,
    object: Object,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObjectTypes: 'settings/getMapObjectTypes',
      mapObjectStatuses: 'settings/getMapObjectStatuses',
      lines: 'map/getLines',
    }),
  },
  data() {
    return {
      form: 'change-type',
      loading: true,
      oldAttributes: true,
      selectedMapObjectType: null,
      newMapObject: {
        name: null,
        map_object_status_id: null,
        attribute: {
          data: {},
        },
      },
      mapObjectStatus: null,
      errors: {
        name: null,
      },
      emptyattributes: [],
      possibleMapObjectTypes: [],
      ParentObject: Object,
      ParentObjecttypes: Object,
    };
  },
  methods: {
    closeChangeType() {
      this.$emit('close');
    },
    toCreateForm() {
      if (!this.selectedMapObjectType) {
        return;
      }
      this.newMapObject = JSON.parse(JSON.stringify(this.object));
      this.newMapObjectType = JSON.parse(JSON.stringify(this.selectedMapObjectType));
      delete this.newMapObject.id;
      delete this.newMapObject.geo_object_id;
      delete this.newMapObject.geoObject.data.id;
      delete this.newMapObject.attribute;
      this.newMapObject.map_object_type_id = this.newMapObjectType.id;
      this.newMapObject.attribute = this.newMapObjectType.attribute;

      if (this.oldAttributes === true) {
        this.object.attribute.data.map((attribute) => {
          const attributeInNewObject = this.newMapObject.attribute.data
            .filter(item => item.id === attribute.id);
          if (attributeInNewObject && attributeInNewObject.length > 0) {
            attributeInNewObject[0].value = attribute.value;
          }
          return attribute;
        });
      }
      this.form = 'new-object';
    },
    updateStatus(value) {
      if (!value) {
        this.newMapObject.map_object_status_id = null;
      } else {
        this.newMapObject.map_object_status_id = value.id;
      }
    },
    resetError(field) {
      this.errors[field] = null;
    },
    saveChangeType() {
      this.loading = true;
      MapObjectApi.createMapObject(this.newMapObject)
        .then((response) => {
          this.moveObjectDetailsToNewObject(response.data);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$notify({
            type: 'error',
            title: this.$t('error.update_failed', { model: this.$tc('models.mapobject', 1) }),
            data: error.response,
          });
        });
    },
    async moveObjectDetailsToNewObject(newMapObject) {
      // move selections over
      if (this.object.mapObjectSelection && this.object.mapObjectSelection.data) {
        await Promise.all(this.object.mapObjectSelection.data.map((selection) => {
          const data = {
            data: {
              relation: {
                id: selection.id,
                order: (selection && selection.pivot
                  && selection.pivot.data && selection.pivot.data.order)
                  ? selection.pivot.data.order : 1,
                label: newMapObject.name,
              },
            },
          };
          return MapObjectApi.createSelectionForMapObject(newMapObject.id, data);
        }));
      }

      // copy comments
      await MapObjectApi.copyComments(this.object.id, newMapObject.id);

      // copy images
      await MapObjectApi.copyImages(this.object.id, newMapObject.id);

      // update children parent id
      if (this.object.children && this.object.children.data) {
        await Promise.all(this.object.children.data.map((child) => {
          const data = {
            data: {
              parent_id: newMapObject.id,
            },
          };
          return MapObjectApi.updateMapObjectById(child.id, data);
        }));
      }

      // update connected lines
      if (this.lines && this.lines.data) {
        const oldMapObjectId = this.object.id;
        const connectedLines = this.lines.data.filter(line => line.start_point === oldMapObjectId
          || line.end_point === oldMapObjectId);
        if (connectedLines && connectedLines.length > 0) {
          await this.updateConnectedLines(connectedLines, this.object.id, newMapObject.id);
        }
      }

      MapObjectApi.deleteMapObjectById(this.object.id, true)
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('dialog.created', {
              model: this.$options.filters.capitalize(this.$tc('models.mapobject', 1)),
              name: newMapObject.name,
            }),
          });
          EventBus.$emit('Map.redraw');
          this.$router.push({ name: 'drawing.show', params: { id: newMapObject.id } });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: 'error',
            title: this.$t('error.update_failed', { model: this.$tc('models.mapobject', 1) }),
            data: error.response,
          });
        });
    },
    async updateConnectedLines(connectedLines, oldMapObjectId, newMapObjectId) {
      await Promise.all(connectedLines.map(
        line => MapObjectApi.getMapObjectbyId(line.id).then((lineData) => {
          const geoData = lineData.data.geoObject;
          if (line.start_point === oldMapObjectId) {
            geoData.data.properties.start_point = newMapObjectId;
          } else if (line.end_point === oldMapObjectId) {
            geoData.data.properties.end_point = newMapObjectId;
          }
          const data = {
            data: {
              geoObject: geoData,
            },
          };
          return MapObjectApi.updateMapObjectById(line.id, data);
        }),
      ));
    },
    async getAllMapObjectTypes() {
      const result = Object.values(this.mapObjectTypes)
        .filter(item => item.id !== this.object.map_object_type_id
          && item.on_network === true
            && item.geo_type === this.object.geoObject.data.geometry.type.toLowerCase());
      this.possibleMapObjectTypes = result;
      this.loading = false;
    },
    async getMapobjectTypesByParent() {
      let result = this.mapObjectTypes[this.ParentObject.map_object_type_id].constraintId.data;
      result = result.map(item => item.id);
      const types = Object.values(this.mapObjectTypes).filter(item => result.includes(item.id)
        && item.id !== this.object.map_object_type_id);
      this.possibleMapObjectTypes = types;
      this.loading = false;
    },
    setMapObjectTypes() {
      if (this.object.parent_id === null) {
        this.getAllMapObjectTypes();
      } else {
        MapObjectApi.getMapObjectbyId(this.object.parent_id)
          .then((response) => {
            this.ParentObject = response.data;
            this.getMapobjectTypesByParent();
          });
      }
    },
  },
  created() {
    this.setMapObjectTypes();
  },
};
</script>

<style scoped>
.change-type-modal {
  min-width: 500px;
  max-height: 800px;
  position: fixed;
  background: white;
  border-radius: 10px;
  z-index:12;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}

.change-type-modal-inner {
  overflow-y: scroll;
  max-height: 800px;
  border-radius: 10px;
}
.change-type-modal-cover {
  background: rgba(66, 70, 76, 0.8);
  position:fixed;
  top:0;
  left:0;
  z-index:11;
  width:100%;
  height:100%;
}
.edit-object-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}
.changeBlock{
  background: lightgray;
  min-height: 200px;
  padding: 20px;
  padding-bottom: 50px;
  overflow: hidden;
  overflow-y: scroll;
}
.edit-object-buttons .save-button {
  float: right;
  margin-right: 7px;
}
.field.content {
  display: inline-flex;
  float: initial;
  overflow: initial;
  width: 100%;
}
</style>
