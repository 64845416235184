import Vue from 'vue';
import Router from 'vue-router';
import auth from '@/services/auth';
// Auth
import Login from '@/modules/auth/Login.vue';
import Logout from '@/modules/auth/Logout.vue';
import Switcher from '@/modules/auth/Switcher.vue';

import Profile from '@/modules/profile/index.vue';
import EditProfile from '@/modules/profile/edit.vue';
import PasswordReset from '@/modules/profile/PasswordReset.vue';

import RouteManagement from '@/modules/route_manager/index.vue';
import RouteManagementShow from '@/modules/route_manager/ShowObject.vue';
import Drawing from '@/modules/drawing/index.vue';
import DrawingShow from '@/modules/drawing/ShowObject.vue';
import DrawingCreate from '@/modules/drawing/CreateObject.vue';
import DrawingEdit from '@/modules/drawing/EditObject.vue';
import DrawingMove from '@/modules/drawing/MoveObject.vue';
import DrawingSplit from '@/modules/drawing/SplitObject.vue';
import DrawingChangeParent from '@/modules/drawing/ChangeParent.vue';
import DrawingChangeType from '@/modules/drawing/ChangeType.vue';
import Selections from '@/modules/selections/index.vue';
import SelectionList from '@/modules/selections/ListSelections.vue';
import SelectionShow from '@/modules/selections/ShowSelection.vue';
import SelectionCreate from '@/modules/selections/CreateSelection.vue';
import SelectionEdit from '@/modules/selections/EditSelection.vue';
import MultiSelection from '@/modules/drawing/multi_selection/Index.vue';
import MultiSelectionChoose from '@/modules/drawing/multi_selection/ChooseObjects.vue';
import MultiSelectionEdit from '@/modules/drawing/multi_selection/EditObjects.vue';
import Publication from '@/modules/publication/index.vue';
import PasswordForgot from '@/modules/auth/PasswordForgot.vue';


Vue.use(Router);

// eslint-disable-next-line import/prefer-default-export
export const router = new Router({
  mode: 'history',
  base: `${process.env.BASE_URL}`,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: '/route_management',
    },
    {
      path: '/route_management',
      name: 'route_management',
      component: RouteManagement,
    },
    {
      path: '/route_management/:id',
      name: 'route_management.show',
      component: RouteManagementShow,
    },
    {
      path: '/drawing',
      name: 'drawing',
      component: Drawing,
    },
    {
      path: '/drawing/create/:type',
      name: 'drawing.create',
      component: DrawingCreate,
    },
    {
      path: '/drawing/multiselect',
      name: 'drawing.multiselect',
      component: MultiSelection,
    },
    {
      path: '/drawing/multiselect/:type',
      name: 'drawing.multiselect.choose',
      component: MultiSelectionChoose,
    },
    {
      path: '/drawing/multiselect/:type/edit',
      name: 'drawing.multiselect.edit',
      component: MultiSelectionEdit,
    },
    {
      path: '/drawing/:id',
      name: 'drawing.show',
      component: DrawingShow,
    },
    {
      path: '/drawing/:id/create/:type',
      name: 'drawing.create_child',
      component: DrawingCreate,
    },
    {
      path: '/drawing/:id/edit',
      name: 'drawing.edit',
      component: DrawingEdit,
    },
    {
      path: '/drawing/:id/move',
      name: 'drawing.move',
      component: DrawingMove,
    },
    {
      path: '/drawing/:id/type',
      name: 'drawing.type',
      component: DrawingChangeType,
    },
    {
      path: '/drawing/:id/parent',
      name: 'drawing.parent',
      component: DrawingChangeParent,
    },
    {
      path: '/drawing/:id/split',
      name: 'drawing.split',
      component: DrawingSplit,
    },
    {
      path: '/selection',
      name: 'selection',
      component: Selections,
    },
    {
      path: '/selection/type/:type',
      name: 'selection.list',
      component: SelectionList,
    },
    {
      path: '/selection/:id',
      name: 'selection.show',
      component: SelectionShow,
    },
    {
      path: '/selection/create/:type',
      name: 'selection.create',
      component: SelectionCreate,
    },
    {
      path: '/selection/:id/edit',
      name: 'selection.edit',
      component: SelectionEdit,
    },
    {
      path: '/publication',
      name: 'publication',
      component: Publication,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/password-forgot',
      name: 'password.forgot',
      component: PasswordForgot,
    },
    {
      path: '/switcher',
      name: 'switcher',
      component: Switcher,
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
    },
    {
      path: '/profile/edit',
      name: 'profile.edit',
      component: EditProfile,
    },
    {
      path: '/profile/resetPassword',
      name: 'profile.passwordReset',
      component: PasswordReset,
    },
  ],
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/password-forgot'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = auth.isLoggedIn();

  if (authRequired && !loggedIn) {
    console.log('You are not logged in!');
    return next('/login');
  }

  next();
});
