<template>
  <div :class="{'drawer-collapse': true, 'open': showCollapse, 'disabled': disabled}">
    <Loading :visible=loading />
    <div class="drawer-collapse-header" @click="toggleOpen">
      <Loading :visible=loading />
      <span>{{ title }}</span>
      <span v-if="showCount && !disabled"
            :class="{'right-count': true, 'right-count': count <= 0,
            'count2': count > 0}">
        {{ count }}
      </span>
      <div class="action" v-if="showCollapseAction && !disabled">
        <div class="btn-icon module-color-background" @click="toggleAction" v-on:click.stop>
          <font-awesome-icon :icon="icon" />
        </div>
        <slot name="customCollapseAction"></slot>
      </div>
    </div>
    <div v-if="!disabled" class="drawer-collapse-content">
      <Loading :visible=loading />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Loading from './Loading.vue';

export default {
  name: 'CollapsePanel',
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
    showCollapseAction: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'plus',
    },
  },
  data() {
    return {
      showCollapse: this.collapsed,
    };
  },
  methods: {
    toggleOpen() {
      if (!this.disabled) {
        this.showCollapse = !this.showCollapse;
      }
    },
    toggleAction() {
      if (!this.disabled) {
        this.$emit('collapseAction');
      }
    },
  },
};
</script>

<style scoped>
  .drawer-collapse {
    border-bottom: 1px solid #d9dadb;
    min-height: 40px;
    padding: 0;
  }

  .drawer-collapse-content {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
  }

  .drawer-collapse-header {
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    margin: 0;
    padding: 0 20px;
    position: relative;
    border-bottom: none;
  }

  .drawer-collapse.disabled {
    background-color: #f2f7fe;
  }
  .drawer-collapse.disabled .drawer-collapse-header {
    cursor: default;
  }

  .drawer-collapse.open {
    background: #f2f7fe;
  }

  .drawer-collapse.open .drawer-collapse-header {
    background: #f2f7fe;
    border-bottom: 1px solid #d9dadb;
  }

  .drawer-collapse.open .drawer-collapse-content {
    height: auto;
    padding: 10px 0;
    overflow: visible;
  }

  .right-count {
    float: right;
    -webkit-border-radius: 555px;
    -moz-border-radius: 555px;
    -ms-border-radius: 555px;
    -o-border-radius: 555px;
    border-radius: 555px;
    background: #d9dfe6;
    color: #fff;
    padding: 0 6px;
    text-transform: uppercase;
    font-size: .7em;
    line-height: 20px;
    margin-top: 10px;
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    position: relative;
    margin-right: 10px;
  }

  .right-count.none {
    background: #d9dfe6;
  }

  div.action {
    position: absolute;
    right: 20px;
    top: 11px;
    line-height: 40px;
    cursor: pointer;
  }

  div.action .btn-icon {
    width: 38px;
    height: 38px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    background: var(--module-color);
    overflow: hidden;
    border-radius: 555px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-size: .8em;
    position: relative;
    line-height: 38px;
  }

  .drawer-collapse.open .drawer-collapse-header .action .btn-icon {
    visibility: visible;
    opacity: 1;
  }

  .drawer-collapse.open .drawer-collapse-header div.action .btn-icon:hover {
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
  }
  .count2{
    float: right;
    -webkit-border-radius: 555px;
    -moz-border-radius: 555px;
    -ms-border-radius: 555px;
    -o-border-radius: 555px;
    border-radius: 555px;
    background: #a4cd4b;
    color: #fff;
    padding: 0 6px;
    text-transform: uppercase;
    font-size: .7em;
    line-height: 20px;
    margin-top: 10px;
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    position: relative;
    margin-right: 10px;
  }
</style>
