<template>
  <select
    class="action-status-select"
    @input="$emit('input', $event.target.value)"
  >
    <option
      v-for="(actionStatus) in action_statuses"
      :key="actionStatus.id"
      :value="actionStatus.id"
      :selected="actionStatus.id === value"
    >
      {{ actionStatus.name }}
    </option>
  </select>
</template>
<script>
export default {
  name: 'ActionStatusSelect',
  components: {},
  props: {
    value: {},
    action_statuses: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
  .action-status-select {
    font-size: 1.1em;
    background: #44a7dc;
    color: white;
    text-align: center;
    border-radius: 10px;
  }

  .action-status-select option {
    font-size: 1.1em;
    padding:5px 0;
  }

</style>
