<template>
  <div v-if="visible === true" class="object-obstruction-block">
    <a-row>
      <a-col :span="24">
        <a-form>
          <a-form-item label="Periode" class="mb-0">
            <a-select
              placeholder="Selecteer een periode"
              style="width: 100%"
              size="large"
              v-model="obstruction.period_id"
              @change="changePeriod"
            >
              <a-select-option :value="1" :key="1">Eenmalig</a-select-option>
              <a-select-option :value="2" :key="2">Eenmalig, voor onbepaalde tijd</a-select-option>
              <a-select-option :value="3" :key="3">Jaarlijks</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item v-if="obstruction.period_id !==null" label="Begindatum" class="mb-0">
            <a-date-picker
              style="width: 50%"
              :format="setDateFormat"
              v-model="obstruction.from_date"
            />
          </a-form-item>
          <a-form-item
            v-if="obstruction.period_id == 1 || obstruction.period_id == 3"
            label="Einddatum" class="mb-0"
          >
            <a-date-picker
              style="width: 50%"
              :format="setDateFormat"
              v-model="obstruction.to_date"
            />
          </a-form-item>
          <a-form-item label="Reden" class="mb-0">
            <a-textarea
              placeholder="Geef een reden op voor de stremming"
              :auto-size="{ minRows: 2, maxRows: 6 }"
              v-model="obstruction.reason"
            />
          </a-form-item>
          <a-form-item label="Omleiding" class="mb-0">
            <a-textarea
              placeholder="Geef een te volgen omleiding op voor de stremming"
              :auto-size="{ minRows: 2, maxRows: 6 }"
              v-model="obstruction.detour"
            />
          </a-form-item>
          <a @click="closeForm" class="btn grey">
            {{ $t('button.cancel') | capitalize }}
          </a>
          <a
            @click="createObstruction"
            :class="[
            'btn',
            'module-color-background',
            {'disabled': (obstruction.period_id == 2) ? (obstruction.from_date === null) :
            (obstruction.from_date === null || obstruction.to_date === null)
            }]"
            :disabled="(obstruction.period_id == 2) ? (obstruction.from_date === null) :
            (obstruction.from_date === null || obstruction.to_date === null)"
          >
            {{ $t('button.save') | capitalize }}
          </a>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import '../../../plugins/ant-design-vue';
import moment from 'moment/moment';

export default {
  moment,
  name: 'ObjectObstructionCreate',
  components: {
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setDateFormat() {
      if (this.obstruction.period_id === 3) { return 'DD/MM'; }
      return 'DD/MM/YYYY';
    },
  },
  data() {
    return {
      obstruction: {
        map_object_id: this.id,
        period_id: null,
        from_date: null,
        to_date: null,
        reason: null,
        detour: null,
      },
    };
  },
  methods: {
    closeForm() {
      this.$emit('showObjectObstructionsForm', 'create', false);
    },
    changePeriod(value) {
      if (value === 2) { this.obstruction.to_date = moment('2099-12-31').format('YYYY-MM-DD'); }
    },
    async createObstruction() {
      this.obstruction.from_date = moment(this.obstruction.from_date).format('YYYY-MM-DD');
      this.obstruction.to_date = moment(this.obstruction.to_date).format('YYYY-MM-DD');
      this.$emit('showObjectObstructionsForm', 'create', false);
      await this.$emit('createObstruction', this.obstruction);
      this.obstructionCreated();
    },
    obstructionCreated() {
      this.obstruction.period_id = null;
      this.obstruction.from_date = null;
      this.obstruction.to_date = null;
      this.obstruction.reason = null;
      this.obstruction.detour = null;
    },
  },
  watch: {
    id() {
      this.obstruction.map_object_id = this.id;
    },
  },
};
</script>

<style scoped>
  .object-obstruction-block {
    padding: 0 20px;
  }
</style>
