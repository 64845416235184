<template>
  <div></div>
</template>

<script>

import L from 'leaflet';
import { mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';

export default {
  name: 'DrawPoint',
  props: {
    map: {
      type: Object,
      required: true,
    },
    map_object_type_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      keyValues: 'settings/getKeyValues',
    }),
  },
  data() {
    return {
      marker: null,
    };
  },
  methods: {
    mapClick(e) {
      if (this.marker !== null) {
        this.map.removeLayer(this.marker);
      }
      const { lat, lng } = e.latlng;

      EventBus.$emit('Map.shareCoordinates', [lng, lat]);
      const marker = new L.Marker([lat, lng]);
      marker.map_object_type_id = this.map_object_type_id;
      // eslint-disable-next-line max-len

      const iconValue = this.getIcon(this.map_object_type_id).value;
      const icon = this.getIconByMapObjectType(iconValue, 0, '', true);
      marker.setIcon(icon);
      this.marker = marker;
      marker.addTo(this.map);
    },
    getIcon(typeId) {
      let icon = this.keyValues.filter(
        u => u.key === `map_object_type_icon_${typeId}`,
      );
      if (icon.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        icon = icon[0];
      } else {
        icon = {};
      }
      return icon;
    },
  },
  mounted() {
    this.map.off('click');
    this.map.on('click', (e) => {
      this.mapClick(e);
    });
  },
  beforeDestroy() {
    console.log('DrawPoint beforeDestroy()');
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
  },
};
</script>

<style scoped>

</style>
