// eslint-disable-next-line import/no-cycle
import axios from '@/plugins/axios';

export default {
  getMe() {
    return axios.get('/v1/me?include=userAttribute,permissions,team,role')
      .then(response => response.data);
  },
  deleteUser(id) {
    return axios.delete(`/v1/user/${id}`)
      .then(response => response.data);
  },
  updateUser(data) {
    return axios.put(`/v1/user/${data.id}`, data)
      .then(response => response.data);
  },
  resetToken(data) {
    return axios.post('/v1/account/reset', data)
      .then(response => response.data);
  },
  createUser(data) {
    return axios.post('/v2/user', data)
      .then(response => response.data);
  },
  getUsers() {
    return axios.get('/v1/user?perPage=9999')
      .then(response => response.data);
  },
  getUser(id, include = 'userAttribute') {
    return axios.get(`/v1/user/${id}?include=${include}&perPage=9999`)
      .then(response => response.data);
  },
  getRoles(id) {
    return axios.get(`/v1/user/${id}/role`)
      .then(response => response.data);
  },
  getUsersTeams() {
    return axios.get('/v1/user?include=team&perPage=9999')
      .then(response => response.data);
  },
  getUserTeams(id) {
    return axios.get(`/v1/user/${id}/team`)
      .then(response => response.data);
  },
  getTenants(id) {
    return axios.get(`/v1/user/${id}/tenant?perPage=9999`,
      {
        transformRequest: (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers['x-tenant'];
          // eslint-disable-next-line no-param-reassign
          delete headers['x-network'];
          return data;
        },
      })
      .then(response => response.data);
  },
  getNetwork(id) {
    return axios.get(`/v1/user/${id}/network?perPage=9999`)
      .then(response => response.data);
  },
  addUserByNetwork(id, data) {
    return axios.post(`/v1/user/${id}/network`, data)
      .then(response => response.data);
  },
  sendPasswordRequestMail(data) {
    return axios.post('/v2/user/account/password', data)
      .then(response => response.data);
  },
};
