<template>
  <div>
    <div class="info-block">
      <h4>Tekenen</h4>
      <p>Informatie over deze tool is nog niet beschikbaar.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Drawing',
};
</script>
