<template>
  <Breadcrumb
    :id="id"
    :name="name"
    :type="type"
    :showBackButton="showBackButton"
    @backButtonPressed="clickBack"
  >
    <template v-slot:icon>
      <MapIcon label="" color="red" :size="39"/>
    </template>
  </Breadcrumb>
</template>

<script>
import { mapGetters } from 'vuex';

import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import NetworkIcon from '@/enums/NetworkIcon';

import MapIcon from '../../../components/Map/MapIcon.vue';

export default {
  name: 'BreadcrumbSelection',
  components: { Breadcrumb, MapIcon },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    customBackAction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      networkIcon: NetworkIcon,
    };
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
  },
  methods: {
    clickBack() {
      if (this.customBackAction) {
        this.$emit('backButtonPressed');
      }
    },
  },
};
</script>
