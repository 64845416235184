<template>
  <div :class="{'actionlog-top-foldout': true, 'active': show}">
    <div class="arrow filter"></div>
    <div class="content">
      <div class="field">
        <label for="action_id">{{ $t('actionlog.action_id') | capitalize }}:</label>
        <input
          id="action_id"
          name="id"
          type="number"
          min="0"
          step="1"
          style="width: 65%"
          v-model="actionId"
          :class="{'active': filtersActive && actionId}"
        />
      </div>
      <div class="field">
        <label for="input-dynamic-action-type-id">
          {{ $tc('models.dynamicactiontype', 1) | capitalize }}:
        </label>
        <div id="input-dynamic-action-type-id" class="input-select-field">
          <MultiSelect
            :options="Object.values(dynamicActionTypes)"
            v-model="dynamicActionTypeInput"
            track-by="id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            placeholder=""
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            @input="updateDynamicActionType"
          />
        </div>
      </div>
      <div class="field">
        <label for="input-action-status-id">
          {{ $tc('models.actionstatus', 1) | capitalize }}:
        </label>
        <div id="input-action-status-id" class="input-select-field">
          <MultiSelect
            :options="Object.values(actionStatuses)"
            v-model="actionStatusInput"
            track-by="id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            placeholder=""
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            @input="updateActionStatus"
          />
        </div>
      </div>
      <div class="field">
        <label for="input-action-priority-id">
          {{ $tc('models.actionpriority', 1) | capitalize }}:
        </label>
        <div id="input-action-priority-id" class="input-select-field">
          <MultiSelect
            :options="Object.values(actionPriorities)"
            v-model="actionPriorityInput"
            track-by="id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            placeholder=""
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            @input="updateActionPriority"
          />
        </div>
      </div>
      <div class="field">
        <label for="input-created-by">
          {{ $t('model.created_by') | capitalize }}:
        </label>
        <div id="input-created-by" class="input-select-field">
          <MultiSelect
            :options="usersArray"
            v-model="createdByInput"
            track-by="id"
            label="name"
            :multiple="true"
            :close-on-select="false"
            placeholder=""
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            @input="updateCreatedBy"
          />
        </div>
      </div>
      <div class="field">
        <label for="input-city-district">
          {{ $t('model.city_district') | capitalize }}:
        </label>
        <div id="input-city-district" class="input-select-field">
          <MultiSelect
            :options="cityDistricts"
            v-model="cityDistrict"
            :multiple="false"
            :close-on-select="true"
            placeholder=""
            selectLabel=""
            selectedLabel=""
          />
        </div>
      </div>
      <div class="field">
        <label style="float: initial;">
          {{ $t('actionlog.period') | capitalize }}:
        </label>

        <div class="input-select-field">
          <span class="radio-option" style="width: 100%">
            <input type="radio"
                   id="filter-radio-created_at"
                   name="date"
                   value="created_at"
                   v-model="date"
            />
            <label class="filter-radio-label" for="filter-radio-created_at">
              {{ $t('model.created_at') | capitalize }}
            </label>
          </span>
          <br />
          <span class="radio-option" style="width: 100%">
            <input type="radio"
                   id="filter-radio-updated_at"
                   name="date"
                   value="updated_at"
                   v-model="date"
            />
            <label class="filter-radio-label" for="filter-radio-updated_at">
              {{ $t('model.updated_at') | capitalize }}
            </label>
          </span>
        </div>

        <label class="date-filter-label">
            {{ $t('actionlog.from') | capitalize }}:
        </label>
        <label class="date-filter-label">
            {{ $t('actionlog.to') | capitalize }}:
        </label>
        <input
          type="date"
          style="width: 50%"
          :class="{'active': filtersActive && fromDate}"
          v-model="fromDate"
        />
        <input
          type="date"
          style="width: 50%"
          :class="{'active': filtersActive && toDate}"
          v-model="toDate"
        />
      </div>
      <div class="field">
        <a @click="performFilter" class="filter success btn">
          {{ $t('actionlog.filter') }}
        </a>
        <a
          @click="performFilterReset"
          class="reset cancel btn"
        >
          {{ $t('actionlog.filter_reset') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import MultiSelect from 'vue-multiselect';

import networkApi from '@/api/network';

export default {
  name: 'ActionFilter',
  components: {
    MultiSelect,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    network_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      cityDistricts: [],
      actionId: null,
      dynamicActionType: [],
      cityDistrict: '',
      actionPriority: [],
      actionStatus: [],
      createdBy: [],
      dynamicActionTypeInput: [],
      actionPriorityInput: [],
      actionStatusInput: [],
      createdByInput: [],
      cityDistrictInput: null,
      date: 'created_at',
      fromDate: null,
      toDate: null,
      filtersActive: false,
    };
  },
  computed: {
    ...mapGetters({
      actionPriorities: 'settings/getActionPriorities',
      actionStatuses: 'settings/getActionStatuses',
      dynamicActionTypes: 'settings/getDynamicActionTypes',
      users: 'settings/getUsers',
    }),
    usersArray() {
      console.log(this.users, Object.values(this.users));
      return Object.values(this.users);
    },
  },
  methods: {
    performFilter() {
      let filter = '';
      if (this.dynamicActionType.length > 0) {
        filter += `&dynamic_action_type_id=(${this.dynamicActionType.join(',')})`;
      }
      if (this.actionPriority.length > 0) {
        filter += `&action_priority_id=(${this.actionPriority.join(',')})`;
      }
      if (this.actionStatus.length > 0) {
        filter += `&action_status_id=(${this.actionStatus.join(',')})`;
      }
      if (this.cityDistrict !== null && this.cityDistrict.length !== 0) {
        filter += `&geoObject-city_district=${this.cityDistrict}`;
      }
      if (this.createdBy.length > 0) {
        filter += `&created_by=(${this.createdBy.join(',')})`;
      }
      if (this.actionId !== null && this.actionId.length !== 0) {
        filter += `&id=${this.actionId}`;
      }
      if (this.fromDate) {
        const startDate = (Date.parse(this.fromDate) / 1000);
        if (!this.toDate) {
          filter += `&range=${startDate}`;
        } else {
          const endDate = (Date.parse(this.toDate) / 1000);
          filter += `&range=(${startDate},${endDate},${this.date})`;
        }
      }
      this.filtersActive = (filter.split('&').length - 1) > 0;
      this.$emit('performFilter', filter);
    },
    performFilterReset() {
      this.actionId = null;
      this.dynamicActionType = [];
      this.cityDistrict = [];
      this.actionPriority = [];
      this.actionStatus = [];
      this.createdBy = [];
      this.dynamicActionTypeInput = [];
      this.actionPriorityInput = [];
      this.actionStatusInput = [];
      this.createdByInput = [];
      this.cityDistrictInput = null;
      this.date = 'created_at';
      this.fromDate = null;
      this.toDate = null;
      this.performFilter();
    },
    updateDynamicActionType(actionTypes) {
      this.dynamicActionType = actionTypes.map(actionType => actionType.id);
    },
    updateActionStatus(actionStatuses) {
      this.actionStatus = actionStatuses.map(actionStatus => actionStatus.id);
    },
    updateActionPriority(actionPriorities) {
      this.actionPriority = actionPriorities.map(actionPriority => actionPriority.id);
    },
    updateCreatedBy(users) {
      this.createdBy = users.map(user => user.id);
    },
    getCityDistricts() {
      networkApi.getActionCityDistricts(this.network_id).then((response) => {
        this.cityDistricts = response.data;
      });
    },
  },
  mounted() {
    this.getCityDistricts();
  },
};
</script>
<style scoped>
  .field {
    overflow: inherit;
  }

  .field label {
    width: 35%;
    display: inline-flex;
  }

  .field label.date-filter-label {
    width: 50%;
    text-align: center;
  }

  .field .input-select-field {
    width: 65%;
    overflow: inherit;
    display: inline-flex;
    float: initial;
  }

  .field input[type=number],.field input[type=date] {
    width: 65%;
    display: inline-flex;
    float: initial;
  }
  .field .btn.filter {
    float: initial;
  }

  .field .btn.reset {
    float: right;
  }

  .filter-radio-label {
    margin-left: 5px;
    width: auto;
    float: initial;
    line-height: normal;
  }
</style>
