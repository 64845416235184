<template>
  <CollapsePanel
    :title="$t('route_management.object_children')"
    :loading=loading
    :showCount=true
    :count="pagination.total"
    :collapsed="true"
    :showCollapseAction="$can('create', 'mapobject')"
    @collapseAction="toggleShowCollapseMenu"
    :icon="showCollapseMenu ? 'times' : 'plus'"
  >
    <template v-slot:customCollapseAction v-if="showCollapseMenu">
      <CollapseActionMenu>
        <CollapseActionMenuItem
          v-for="mapObjectType in mapObjectTypesOnNetwork"
          :key="mapObjectType.id"
          :title="mapObjectType.name"
          icon="plus"
          @clickItem="toCreateMapObject(mapObjectType.id)"
        />
      </CollapseActionMenu>
    </template>
    <div class="map-objects-list">
      <ChildObject v-for="(child, key) in children"
                   :key="key"
                   :child="child"
                   @goToObject="goToObject"
      />
    <Pagination
      @switchPage="switchPage"
      :total="pagination.total"
      :count="pagination.count"
      :per_page="pagination.per_page"
      :current_page="pagination.current_page"
      :total_pages="pagination.total_pages"  />
    </div>
  </CollapsePanel>
</template>

<script>
import { mapGetters } from 'vuex';

import NetworkApi from '@/api/network';

import switcher from '@/services/switcher';

import CollapsePanel from '@/components/CollapsePanel.vue';
import Pagination from '@/components/Pagination.vue';
import ChildObject from '@/components/Layout/ChildObject.vue';
import CollapseActionMenu from '@/components/CollapseActionMenu.vue';
import CollapseActionMenuItem from '@/components/CollapseActionMenuItem.vue';

export default {
  name: 'NetworkChildren',
  components: {
    CollapseActionMenuItem,
    CollapseActionMenu,
    ChildObject,
    CollapsePanel,
    Pagination,
  },
  computed: {
    ...mapGetters({
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
    mapObjectTypesOnNetwork() {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return [];
      }
      if (Object.values(this.mapObjectTypes).length === 0) {
        return [];
      }
      return Object.values(this.mapObjectTypes).filter(item => item.on_network);
    },
  },
  data() {
    return {
      showCollapseMenu: false,
      networkId: parseInt(switcher.getSelectedNetworkId(), 10),
      loading: false,
      children: [],
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1,
      },
      current_page: 1,
    };
  },
  methods: {
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
    switchPage(page) {
      this.current_page = page;
      this.getChildren();
    },
    toCreateMapObject(typeId) {
      this.$router.push({ name: 'drawing.create', params: { type: typeId } });
    },
    goToObject(mapObjectId) {
      this.$router.push({ name: 'drawing.show', params: { id: mapObjectId } });
    },
    getChildren() {
      const include = 'mapObjectLabel,mapObjectType,children,children.mapObjectLabel,children.mapObjectType';
      this.loading = true;
      NetworkApi.getChildrenForNetwork(this.networkId, include, false, 15, this.current_page)
        .then((response) => {
          this.children = response.data;
          this.pagination = response.meta.pagination;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getChildren();
  },
  watch: {
    networkId() {
      this.getChildren();
    },
  },
};
</script>
<style scoped>
  div.map-objects-list {
    margin: -15px 0 0 0;
  }
</style>
