<template>
  <div>
    <BreadcrumbNetwork />
    <div class="edit-object-buttons">
      <a
        title="Naar Multiselect"
        @click="toMultiSelect"
        :class="['btn', 'module-color-background']"
      >
        {{ $t('drawing.to_multiselect') | capitalize }}
      </a>
    </div>

    <NetworkInformation />
    <NetworkChildren v-if="$can('show', 'mapobject')"
                    :id="selectedId"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';
import switcher from '@/services/switcher';

import BreadcrumbNetwork from '@/components/Layout/BreadcrumbNetwork.vue';
import NetworkInformation from '@/modules/drawing/components/NetworkInformation.vue';
import NetworkChildren from '@/modules/drawing/components/NetworkChildren.vue';

export default {
  name: 'drawing',
  components: {
    NetworkInformation,
    BreadcrumbNetwork,
    NetworkChildren,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObject: 'selected/getMapObject',
    }),
  },
  data() {
    return {
      selectedId: parseInt(switcher.getSelectedNetworkId(), 10),
    };
  },
  beforeMount() {
    if (this.mapObject !== null) {
      this.$router.push({ name: 'drawing.show', params: { id: this.mapObject.id } });
    }
  },
  mounted() {
    EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
  },
  methods: {
    toMultiSelect() {
      this.$router.push({ name: 'drawing.multiselect' });
    },
  },
  watch: {
    // mapObject(value) {
    //   console.log('drawing index watch mapobject', value);
    //   if (value) {
    //     this.$router.push({ name: 'drawing.show', params: { id: value.id } });
    //   }
    // },
  },
};
</script>
<style scoped>
.edit-object-buttons {
  padding: 10px 35px 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}
.edit-object-buttons a {
  width: 100%;
  text-align: center;
}
</style>
