<template>
  <div>
    <div class="info-block">
      <h4>BeheerPlus</h4>
      <p>
        Routebeheer is een uniek systeem voor beheer, onderhoud en digitalisering van routes en
        routenetwerken. Zo zijn wijzigingen, stremmingen,
        schades en onderhoudsmeldingen eenvoudig in te voeren en af te handelen. Routebeheer biedt
        te allen tijde een actueel overzicht van de
        status van het netwerk; alle data wordt realtime verwerkt.
      </p>
      <p>
        In Routebeheer zijn relaties tussen objecten mogelijk. Dit houdt bijvoorbeeld in dat een
        routeknooppunt meerdere dragers kan bevatten en
        onder deze dragers hangen vervolgens verschillende knooppuntborden. Dit zijn allemaal losse
        objecten, maar hangen trapsgewijs onder elkaar.
        Dankzij deze structuur zijn netwerken veel gedetailleerder te beheren.
      </p>
      <p>
        Wanneer u Routebeheer opent, krijgt u naast de grijze menubalk een wit paneel te zien. Via
        dit paneel kunt u alle
        informatie
        van uw netwerk inzien. Bovenin ziet u altijd de naam van het geselecteerde object, met het
        bijbehorende icoon. Via de kleinere labels onder
        de
        naam, ziet u onder welke bovenliggende objecten het object valt. Deze zijn aanklikbaar om
        snel tussen de verschillende niveaus te kunnen
        navigeren.
      </p>
      <p>
        Verder bestaat het paneel uit meerdere tabbladen, waarvan ‘Objectinformatie’ standaard
        geopend is.
      </p>
    </div>
    <div class="info-block min-height-570">
      <h4>Objectinformatie</h4>
      <p>
        <img :src="objectInformation" class="img-object-info" alt="object-informatie">
        Dit tabblad toont alle beschikbare details van het object dat u geselecteerd heeft op de
        kaart. Deze gegevens zijn opgesplitst in
        locatiegegevens en objectgegevens. De locatiegegevens worden automatisch gegenereerd op
        basis van de locatie van het object. Objectgegevens bevat alle
        (zelf ingestelde) attributen en andere kenmerken van het gekozen object. Via de blauwe
        plus-knop kunt u eenvoudig een nieuwe actie aanmaken voor het
        object. Meer informatie over acties vindt u verderop in deze
        handleiding.
      </p>
    </div>
    <div class="info-block">
      <h4>Onderliggende objecten</h4>
      <p>
        Zoals eerder uitgelegd, staat Routebeheer relaties tussen objecten toe. Bijvoorbeeld: een
        routenetwerk bevat knooppunten en deze knooppunten
        bevatten een informatiepaneel. In het tabblad ‘Onderliggende objecten’ kunnen deze relaties
        duidelijk ingezien worden. Hier worden alle
        objecten getoond die onder het geselecteerde object aanwezig zijn. Door op zo’n onderliggend
        object in de lijst te klikken, worden de details van dat
        object geopend.
      </p>
      <p>
        Het paneel en de kaart werken met elkaar samen bij het navigeren tussen objecten. Dus ook
        als u op de kaart een ander object aanklikt,
        verandert de informatie die in het paneel getoond wordt.
      </p>
    </div>
    <div class="info-block">
      <h4>Actielogboek</h4>
      <p>
        In Routebeheer wordt tegenwoordig gebruik gemaakt van de term ‘acties’ in plaats van
        meldingen. De reden hiervan is dat er op deze manier
        duidelijker onderscheid gemaakt kan worden tussen de aard van de taken. Zo kunnen namelijk
        tal van verschillende acties onderscheiden
        worden. Voorbeelden van actietypen zijn: inventariseren, verplaatsen en schoonmaken.
      </p>
      <p>
        Behalve dergelijke onderhoudsacties die afgehandeld moeten worden, kan er ook gewerkt worden
        met zogenaamde wijzigingsverzoeken. Dit houdt
        in dat gebruikers met een bepaalde (beperkte) rol, gegevens niet direct eigenhandig kunnen
        wijzigen. Zij maken gebruik van verzoeken om
        wijzigingen door te voeren. Deze verzoeken dienen eerst door bijvoorbeeld een beheerder
        gevalideerd te worden, voordat de wijzigingen in het systeem
        plaatsvinden. Deze werkwijze kunnen wij afstemmen op uw wensen.
      </p>
      <p>
        In het tabblad ‘Actielogboek’ in het paneel, vindt u alle acties die op het geselecteerde
        object hebben plaatsgevonden. Deze acties zijn
        gecategoriseerd in ‘Niet-afgehandeld’ (openstaand), ‘Afgehandeld’ en ‘Terugkerend’
        (ingepland).
      </p>
      <p>
        Van de acties die in deze lijsten staan, worden de belangrijkste details direct
        overzichtelijk weergegeven. Indien gewenst kan deze lijst
        gefilterd worden op bepaalde kenmerken.
      </p>
      <p>
        Bij het klikken op het linker (blauwe) locatie-icoon verplaatst de kaart zich naar de exacte
        locatie van de actie. De rode knop verwijdert
        de actie.
      </p>
    </div>
    <div class="info-block min-height-500">
      <h4>Actiedetails</h4>
      <p>
        <img :src="actionLog" class="img-logboek">
        De bredere ‘Verwerken’-knop opent alle details van de actie in een pop-up, zoals te zien op
        de onderstaande afbeelding. Deze knop kan overigens
        ook een andere tekst bevatten, dit is afhankelijk van het type actie. In het pop-up venster
        worden de vaststaande actiegegevens altijd links
        bovenin getoond, met de (te wijzigen) status en prioriteit rechts ernaast.
      </p>
      <p>
        Daaronder worden de actie-attributen getoond, welke attributen er getoond worden is
        afhankelijk van het soort actie. Het kan dus ook
        voorkomen dat hier geen attributen getoond worden. De attributen die hier ingevoerd worden
        hebben invloed op de details van het object waar de actie
        op wordt uitgevoerd. Wanneer een actie afgehandeld wordt, zullen deze gegevens de bestaande
        details van het object overschrijven.
      </p>
      <p>
        Naast de attributen is een opmerkingenveld te vinden. Hier kan commentaar aan de actie
        worden toegevoegd, zonder dat dit invloed heeft op de
        details van de actie zelf. Dit kan gebruikt worden om met collega’s te overleggen over de
        actie.
      </p>
      <p>
        Helemaal onderin het venster is de mogelijkheid om foto’s toe te voegen aan de actie. Dit
        kan zowel bij het aanmaken als bewerken van een
        actie. Indien gewenst kunnen deze afbeelding gecategoriseerd worden in bijvoorbeeld ‘Voor’
        en ‘Na’ foto’s.
      </p>
      <p>
        Wanneer een actie afgehandeld of gevalideerd is, wordt deze onder het tabblad ‘Afgehandeld’
        ondergebracht in het actielogboek.
      </p>


    </div>
    <div class="info-block">
      <h4>Opmerkingen</h4>
      <p>
        In dit tabblad kan algemene communicatie over het geselecteerde objecten plaatsvinden, dus
        zonder dat dit samenhangt met een specifieke
        actie. Op deze manier kunt u informatie over bepaalde objecten gemakkelijk terugvinden.
      </p>
      <div align="center">
        <img :src="actionDetails" class="img-3" width="100%" alt="actie-details">
      </div>
    </div>
    <div class="info-block">
      <h4>Afbeeldingen</h4>
      <p>
        Onder het tabblad ‘Afbeeldingen’ kunnen foto’s toegevoegd worden aan het object. Met het
        rode kruisje is het mogelijk om een foto te
        verwijderen.
      </p>
    </div>
  </div>
</template>

<script>
import objectInformation from '../../assets/img/helpmodals/objectinformation.jpg';
import actionLog from '../../assets/img/helpmodals/actielogboek.jpg';
import actionDetails from '../../assets/img/helpmodals/actie-details.jpg';

export default {
  name: 'ManagementPlus',
  data() {
    return {
      objectInformation,
      actionLog,
      actionDetails,
    };
  },
};
</script>
