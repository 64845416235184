<template>
  <div></div>
</template>

<script>
import L from 'leaflet';
import { mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';

export default {
  name: 'DrawPolygon',
  props: {
    map: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    network: {
      type: Object,
      required: true,
    },
    object: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
  },
  data() {
    return {
      coords: [],
      lines: [],
      tempPoints: [],
      fakePoints: [],
      tempPolygon: null,
      nextKey: 0,
      tempCluster: new L.LayerGroup({}),
    };
  },
  methods: {
    async mapClick(e) {
      const { lat, lng } = e.latlng;
      this.addCoordinate(this.nextKey, lat, lng);
      this.nextKey += 1;
    },
    addCoordinate(key, lat, lng) {
      this.coords.push({ key, lat, lng });
      const coord = this.addTempCoordinate(key, lat, lng);
      this.tempPoints.push(coord);
      this.redrawCluster();
    },
    addTempCoordinate(key, lat, lng) {
      const icon = this.createTempCoordinateIcon();
      const coordinate = new L.Marker([lat, lng], {
        icon,
        key,
      });
      coordinate.on('click', () => {
        this.handleTempCoordinateClick(key);
      });
      this.tempCluster.addLayer(coordinate);
      this.setDraggableCoordinate(coordinate, key);
      return coordinate;
    },
    handleTempCoordinateClick(key) {
      const indexToSplice = this.coords.findIndex(x => x.key === key);
      this.coords.splice(indexToSplice, 1);
      const coord = this.tempPoints.splice(indexToSplice, 1);
      this.tempCluster.removeLayer(coord[0]);
      this.redrawCluster();
    },
    addFakeCoordinate(key, lat, lng) {
      const icon = this.createFakeCoordinateIcon();
      const coordinate = new L.Marker([lat, lng], {
        icon,
      });
      coordinate.on('click', () => {
        this.handleFakeCoordinateClick(key, lat, lng);
      });
      this.tempCluster.addLayer(coordinate);
      this.fakePoints.push(coordinate);
      return coordinate;
    },
    handleFakeCoordinateClick(key, lat, lng) {
      const indexToSplice = key + 1;
      this.coords.splice(indexToSplice, 0, { key: this.nextKey, lat, lng });
      const coord = this.addTempCoordinate(this.nextKey, lat, lng);
      this.tempPoints.splice(indexToSplice, 0, coord);
      this.nextKey += 1;
      this.redrawCluster();
    },
    addTempLine(prevLat, prevLng, lat, lng, color = 'red') {
      const line = new L.Polyline([
        [prevLat, prevLng],
        [lat, lng],
      ], { color });
      this.tempCluster.addLayer(line);
      this.lines.push(line);
      return line;
    },
    setDraggableCoordinate(coordinate, key) {
      coordinate.dragging.enable();
      coordinate.on('drag', (e) => {
        const { target } = e;
        const latLng = target.getLatLng();
        coordinate.setLatLng(latLng);
        this.coords[this.coords.findIndex(x => x.key === key)] = {
          key,
          lat: latLng.lat,
          lng: latLng.lng,
        };
        this.redrawCluster();
      });
    },
    createTempCoordinateIcon() {
      const svg = `<svg version="1.0" width="18" height="18" xmlns="http://www.w3.org/2000/svg">
               <g>
                <rect stroke-width="2" id="temp_coord" height="14" width="14"
                 y="2" x="2" stroke="#666" fill="#ffffff" rx="2" ry="2"
                />
               </g>
              </svg>`;
      const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
      return L.icon({ iconUrl, iconAnchor: [8, 8] });
    },
    createFakeCoordinateIcon() {
      const svg = `<svg version="1.0" width="14" height="14" xmlns="http://www.w3.org/2000/svg">
               <g>
                <rect stroke-width="2" id="fake_coord" height="10" width="10"
                 y="2" x="2" stroke="#ACACAC" fill="#ffffff" rx="2" ry="2"
                />
               </g>
              </svg>`;
      const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
      return L.icon({ iconUrl, iconAnchor: [7, 7] });
    },
    redrawCluster() {
      if (this.tempPolygon) {
        this.tempCluster.removeLayer(this.tempPolygon);
      }
      this.lines.forEach((line) => {
        this.tempCluster.removeLayer(line);
      });
      this.lines = [];
      this.fakePoints.forEach((point) => {
        this.tempCluster.removeLayer(point);
      });
      this.fakePoints = [];
      if (this.coords.length > 1) {
        let prevCoord = null;
        this.coords.forEach((coord) => {
          if (prevCoord !== null) {
            this.addTempLine(prevCoord.lat, prevCoord.lng, coord.lat, coord.lng);
          }
          prevCoord = coord;
        });
        this.addTempLine(this.coords[0].lat, this.coords[0].lng, prevCoord.lat, prevCoord.lng);
        this.lines.forEach((line, idx) => {
          const center = line.getBounds().getCenter();
          this.addFakeCoordinate(idx, center.lat, center.lng);
        });
      }
      this.tempPolygon = new L.Polygon(this.coords, {
        color: 'darkgrey',
      });
      this.tempCluster.addLayer(this.tempPolygon);
      this.shareCoordinates();
    },
    shareCoordinates() {
      const coords = [];
      this.coords.forEach((coord) => {
        coords.push([coord.lng, coord.lat]);
      });
      coords.push([this.coords[0].lng, this.coords[0].lat]);
      EventBus.$emit('Map.shareCoordinates', [coords]);
    },
    setCoordinatesByObject(mapObject) {
      if (mapObject && mapObject.geoObject && mapObject.geoObject.data.geometry.coordinates) {
        let { coordinates } = mapObject.geoObject.data.geometry;
        if (coordinates.length > 1) {
          // eslint-disable-next-line prefer-spread
          coordinates = [].concat.apply([], coordinates);
        }
        this.nextKey = coordinates[0].length;
        coordinates[0].forEach((coords, idx) => {
          if (idx === (this.nextKey - 1)) {
            return;
          }
          const key = idx;
          const lat = coords[1];
          const lng = coords[0];
          this.addCoordinate(key, lat, lng);
        });
      }
    },
    resetDraw() {
      this.coords = [];
      this.lines = [];
      this.tempPoints = [];
      this.fakePoints = [];
      this.startPoint = false;
      this.endPoint = false;
      this.nextKey = 0;
      this.tempCluster = new L.LayerGroup({});
    },
  },
  mounted() {
    this.map.off('click');
    this.map.on('click', (e) => {
      this.mapClick(e);
    });
    this.map.addLayer(this.tempCluster);
    if (this.object) {
      this.setCoordinatesByObject(this.object);
    }
  },
  beforeDestroy() {
    this.map.removeLayer(this.tempCluster);
    this.resetDraw();
  },
};
</script>

<style scoped>

</style>
