<template>
  <div>
    <div class='dots'>
      <div class='dot'></div>
      <div class='dot'></div>
      <div class='dot'></div>
      <div class='dot'></div>
      <div class='dot'></div>
    </div>
    <svg xmlns='http://www.w3.org/2000/svg' version='1.1'>
      <defs>
        <filter id='goo'>
          <feGaussianBlur in='SourceGraphic' stdDeviation='12' result='blur'></feGaussianBlur>
          <feColorMatrix in='blur' mode='matrix'
                         values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7' result='goo'>
          </feColorMatrix>
          <feBlend in='SourceGraphic' in2='goo'>
          </feBlend>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoadingIcon',
};
</script>

<style scoped>
  .dots {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    filter: url(#goo); }

  @-moz-document url-prefix() {
    .dots {
      filter: none !important; } }

  .dot {
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0; }
  .dot:before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50px;
    background: #c7d853;
    position: absolute;
    left: 50%;
    transform: translateY(0) rotate(0deg);
    margin-left: -7.5px;
    margin-top: -7.5px; }

  @keyframes dot-move {
    0% {
      transform: translateY(0); }
    18%, 22% {
      transform: translateY(-30px); }
    40%, 100% {
      transform: translateY(0); } }

  @keyframes dot-colors {
    0% {
      background-color: #c7d853; }
    25% {
      background-color: #69c4a4; }
    50% {
      background-color: #44a7dc; }
    75% {
      background-color: #8ec74f; }
    100% {
      background-color: #c7d853; } }
  .dot:nth-child(5):before {
    z-index: 100;
    width: 19.5px;
    height: 19.5px;
    margin-left: -9.75px;
    margin-top: -9.75px;
    animation: dot-colors 3s ease infinite; }

  @keyframes dot-rotate-1 {
    0% {
      transform: rotate(-105deg); }
    100% {
      transform: rotate(270deg); } }
  .dot:nth-child(1) {
    animation: dot-rotate-1 3s 0s linear infinite; }
  .dot:nth-child(1):before {
    background-color: #69c4a4;
    animation: dot-move 3s 0s ease infinite; }

  @keyframes dot-rotate-2 {
    0% {
      transform: rotate(165deg); }
    100% {
      transform: rotate(540deg); } }
  .dot:nth-child(2) {
    animation: dot-rotate-2 3s 0.75s linear infinite; }
  .dot:nth-child(2):before {
    background-color: #44a7dc;
    animation: dot-move 3s 0.75s ease infinite; }

  @keyframes dot-rotate-3 {
    0% {
      transform: rotate(435deg); }
    100% {
      transform: rotate(810deg); } }
  .dot:nth-child(3) {
    animation: dot-rotate-3 3s 1.5s linear infinite; }
  .dot:nth-child(3):before {
    background-color: #8ec74f;
    animation: dot-move 3s 1.5s ease infinite; }

  @keyframes dot-rotate-4 {
    0% {
      transform: rotate(705deg); }
    100% {
      transform: rotate(1080deg); } }
  .dot:nth-child(4) {
    animation: dot-rotate-4 3s 2.25s linear infinite; }
  .dot:nth-child(4):before {
    background-color: #c7d853;
    animation: dot-move 3s 2.25s ease infinite; }
</style>
