<template>
  <CollapsePanel
    :title="$t('route_management.object_information')"
    :loading=loading
    :showCount=false
    :collapsed="true"
    :showCollapseAction="false"
    icon="pen"
    @collapseAction="toggleShowCollapseMenu"
  >
    <div v-if="network && network.geoObject && network.geoObject.data"
         class="object-information-block"
    >
      <h3 class="block-header">{{ $t('route_management.location_data') }}</h3>
      <ul class="block-details">
        <li v-if="network.geoObject.data.properties.area" class="block-item">
          <span class="block-item-title">{{ $t('model.area') | capitalize }}:</span>
          <span class="block-item-content">
            {{ area }}
          </span>
        </li>
      </ul>
    </div>
  </CollapsePanel>
</template>

<script>
import { mapGetters } from 'vuex';

import CollapsePanel from '@/components/CollapsePanel.vue';

export default {
  name: 'NetworkInformation',
  components: {
    CollapsePanel,
  },
  data() {
    return {
      loading: false,
      showCollapseMenu: false,
    };
  },
  methods: {
    toEditObject() {
      this.$router.push({
        name: 'drawing.edit',
        params: { id: this.id },
      });
    },
    toMoveObject() {
      this.$router.push({
        name: 'drawing.move',
        params: { id: this.id },
      });
    },
    deleteObject() {
      return false;
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
    area() {
      if (!this.network.geoObject || !this.network.geoObject.data.properties.area) {
        return null;
      }
      const area = parseFloat(this.network.geoObject.data.properties.area);

      if (area > 1100000) {
        return `${(area / 1000000).toFixed(0)} km2`;
      }
      return `${area.toFixed(0)} m2`;
    },
  },
};
</script>
<style scoped>
  .object-information-block {
    padding: 0 20px;
  }
  .object-information-block h3.block-header {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    margin: 0 0 5px;
    padding: 0;
    color: #42464c;
  }
  .object-information-block ul.block-details {
    overflow: auto;
    zoom: 1;
    font-size: .85em;
    margin: 0 0 20px 0;
    padding: 0;
  }
  .object-information-block ul.block-details li.block-item {
    line-height: 1.7em;
    position: relative;
    clear: both;
    list-style: none;
  }
  .object-information-block .block-details .block-item .block-item-title {
    display: block;
    float: left;
    width: 40%;
  }
  .object-information-block .block-details .parent-selections {
    margin-left: 30px;
    font-size: 1.1em;
  }
</style>
