<template>
  <div v-if="selectedId !== null">
    <Loading :visible="loading || !mapObject" />
    <BreadcrumbMapObject v-if="mapObject"
                         :mapObject="mapObject"
                         @clickBack="goToView"
                         @clickParent="goToView"
    />
    <ObjectInformation v-if="$can('show', 'mapobject') && mapObject"
                       :mapObject="mapObject"
                       :mapObjectLocked="mapObjectLocked"
                       :owner_name="owner_name"
    />
    <ObjectChildren v-if="$can('show', 'mapobject')
                           && mapObject
                            && Object.keys(mapObject).length > 0"
                    :mapObject="mapObject"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MapObjectApi from '@/api/map_object';
import TeamApi from '@/api/team';
import UserApi from '@/api/user';

import Loading from '@/components/Loading.vue';
import BreadcrumbMapObject from '@/components/Layout/BreadcrumbMapObject.vue';
import ObjectInformation from '@/modules/drawing/components/ObjectInformation.vue';
import ObjectChildren from '@/modules/drawing/components/ObjectChildren.vue';
// import EventBus from '@/events/event-bus';

export default {
  name: 'drawing.show_object',
  components: {
    Loading,
    BreadcrumbMapObject,
    ObjectChildren,
    ObjectInformation,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObject: 'selected/getMapObject',
      user: 'user',
      keyValues: 'settings/getKeyValues',
      networkId: 'switcher/getNetworkId',
      tenantId: 'switcher/getTenantId',
    }),
    mapObjectLock() {
      const lockItems = Object.values(this.keyValues)
        .filter(item => item.key.includes(`lock_mapobject_${this.networkId}`));
      if (lockItems.length > 0) {
        return lockItems[0];
      }
      return null;
    },
  },
  data() {
    return {
      loading: false,
      owner_name: '-',
      mapObjectLocked: false,
      showBackButton: false,
      selectedId: null,
    };
  },
  methods: {
    ...mapActions({
      fetchMapObject: 'selected/fetchMapObject',
      resetMapObject: 'selected/resetMapObject',
      resetSelected: 'map/resetSelected',
      addSelected: 'map/addSelected',
    }),
    goToView(model, id) {
      if (model === 'network') {
        this.resetMapObject();
        this.resetSelected();
        if (this.$route.path.includes('route_management')) {
          this.$router.push({ name: 'route_management' });
        } else if (this.$route.path.includes('drawing')) {
          this.$router.push({ name: 'drawing' });
        }
        return;
      }
      if (this.$route.path.includes('route_management')) {
        this.$router.push({ name: 'route_management.show', params: { id } });
      } else if (this.$route.path.includes('drawing')) {
        this.$router.push({ name: 'drawing.show', params: { id } });
      }
    },
    getMapObject() {
      this.loading = true;
      MapObjectApi.getMapObjectbyId(this.selectedId, 'mapObjectType,mapObjectStatus,attribute,attribute.attributeLookupValue,mapObjectSelection,parents,parents.mapObjectSelection')
        .then((response) => {
          this.mapObject = response.data;
          this.checkMapObjectLocked();
          this.loading = false;
        });
    },
    checkMapObjectLocked() {
      this.loading = true;
      if (this.mapObject.created_by !== null
        && this.mapObject.created_by !== undefined
        && this.mapObjectLock !== null) {
        if (this.user.id === this.mapObject.created_by) {
          this.owner_name = this.user.name;
          if (this.mapObjectLock.value === 'team') {
            this.owner_name += ` (${this.user.team.data.map(item => item.name).join(', ')})`;
          }
        } else if (this.mapObjectLock.value === '' || this.mapObjectLock.value === 'user') {
          if (!this.$can('overrule', 'locking-mapobject')) {
            this.mapObjectLocked = true;
          }
          UserApi.getUser(this.mapObject.created_by)
            .then((r2) => {
              const user = r2.data;
              this.owner_name = user.name;
            });
        } else if (this.mapObjectLock.value === 'team') {
          TeamApi.checkSameTeam(this.tenantId, this.user.id, this.mapObject.created_by)
            .then((sameTeam) => {
              if (!sameTeam && !this.$can('overrule', 'locking-mapobject')) {
                this.mapObjectLocked = true;
              }
              UserApi.getUser(this.mapObject.created_by, 'team')
                .then((r2) => {
                  const user = r2.data;
                  this.owner_name = user.name;
                  if (this.mapObjectLock.value === 'team') {
                    this.owner_name += ` (${user.team.data.map(item => item.name).join(', ')})`;
                  }
                });
            });
        }
      }
      this.loading = false;
    },
  },
  mounted() {
    if (this.$route.params.id !== null && this.$route.params.id !== undefined) {
      this.selectedId = parseInt(this.$route.params.id, 10);
      if (!this.mapObject || this.mapObject.id !== this.selectedId) {
        this.fetchMapObject({ id: this.selectedId });
      }
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.id': function (id) {
      if (id !== null && id !== undefined) {
        this.loading = true;
        this.selectedId = parseInt(id, 10);
        if (!this.mapObject || this.mapObject.id !== this.selectedId) {
          this.fetchMapObject({ id: this.selectedId }).then(() => {
            this.loading = false;
          });
        }
      }
    },
    mapObject(value) {
      if (value) {
        this.resetSelected();
        if (value.geoObject.data.geometry.type === 'LineString') {
          if (value.startPoint && value.startPoint.data) {
            this.addSelected({ mapObject: value.startPoint.data });
          }
          if (value.endPoint && value.endPoint.data) {
            this.addSelected({ mapObject: value.endPoint.data });
          }
        }
        this.addSelected({ mapObject: value });
      }
    },
  },
};
</script>

<style scoped>
</style>
