<template>
  <ObjectModal
    title=""
    :visible="visible"
    @closeModal="closeModal"
    :loading="false"
  >
    <template v-slot:header>
      {{ object.mapObjectType.data.name }} {{ object.name }} |
      <span style="color:#44a7dc">{{ $t('drawing.change_parent_object') }}</span>
    </template>
    <template v-slot:body>
      <div class="greyBlock">
        <div class="block">
          <h3>{{ $t('drawing.warning') | capitalize }}</h3>
          <p v-if="object.children.data.length">
            {{ $t('drawing.has_children') }}
          </p>
          <p v-if="parent">
            <strong>{{ $t('drawing.currentparent') }}</strong>
            {{ parent.mapObjectType.data.name }} {{ parent.name }}
          </p>
          <p v-if="possibleParentTypes && possibleParentTypes.length > 0">
            <strong>{{ $t('drawing.possible_parent_types') }} </strong>
            <span v-for="(parentType, idx) in possibleParentTypes" :key="parentType.id">
              <span v-if="idx > 0">, </span>{{ parentType.name }}
            </span>
          </p>
          <h4 class="small-header">{{ $t('drawing.search_new_parent') }}</h4>
          <div id="search-change-parent" class="field">
            <input id="search-input" class="module-color-border"
                   v-model="searchInput" type="text" @input="typing">
            <button id="submit-search-parent" type="submit" @click="searchObjects">
              <font-awesome-icon icon="search"/>
            </button>
          </div>
          <div id="search-parent-result">
            <h4 class="search-parent-result-header small-header">Resultaten</h4>
            <div v-for="object in objectResults" :key="object.id">
              <input type="radio" v-model="selectedObject" :value="object.id">
              {{ object.mapObjectType.data.name }} {{ object.name }}
              => {{ object | mapObjectLabel }}
            </div>
            <br>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-content">
        <a class="btn grey" @click="closeModal">
          {{ $t('actionlog.cancel') | capitalize }}
        </a>
        <a
          @click="saveChange()"
          :class="['btn',
            'module-color-background',
              selectedObject ? '' :
            'disabled',
          ]"
        >
          {{ $t('actionlog.save_changes') | capitalize }}
        </a>
      </div>
    </template>
  </ObjectModal>
</template>

<script>
import { mapGetters } from 'vuex';
import ObjectModal from '@/modules/drawing/components/ObjectModal.vue';
import MapObjectApi from '@/api/map_object';

export default {
  name: 'drawing.change_parent',
  components: {
    ObjectModal,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObject: 'selected/getMapObject',
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
    possibleParentTypes() {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return [];
      }
      if (Object.values(this.mapObjectTypes).length === 0) {
        return [];
      }
      return Object.values(this.mapObjectTypes)
        .filter((item) => {
          if (!item.constraintId
            || !item.constraintId.data
            || item.constraintId.data.length === 0
          ) {
            return false;
          }
          return item.constraintId.data.map(i => i.id).includes(this.object.map_object_type_id);
        });
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    object: Object,
    parent: Object,
  },
  data() {
    return {
      showSearchResults: false,
      searchInput: '',
      searchType: 'all',
      objectResults: [],
      timer: null,
      interval: 300,
      selectedObject: '',
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    saveChange() {
      this.object.parent_id = this.selectedObject;
      const data = { data: this.object };
      const dialog = this.$t('dialog.changes_saved');
      MapObjectApi.updateMapObjectById(this.object.id, data)
        .then(() => {
          this.$notify({
            type: 'success',
            title: dialog,
          });
        });

      this.fetchMapObject({ id: this.mapObject.id, force: true });
      this.$router.push({ name: 'drawing.show', params: { id: this.object.id } });
    },
    selectSearchType() {
      this.$nextTick(() => this.$refs.search.focus());
      this.typing();
    },
    typing() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.searchObjects, this.interval);
    },
    searchObjects() {
      this.showSearchResults = this.searchInput.length > 0;
      if (!this.showSearchResults) {
        return;
      }
      let input = this.searchInput;
      if (!this.exactResults) {
        input = input.replace(/[^A-Za-z0-9]/gi, '').split('').join('%');
        input = `%${input}%`;
      }

      const parentIds = this.possibleParentTypes.map(item => item.id);
      let queries = {};
      queries = [
        {
          query: {
            map_object_type_id: parentIds,
          },
          operator: '=',
        }, {
          query: {
            label: input,
          },
          operator: this.operator,
        },
      ];
      this.findObjects(queries);
    },
    findObjects(queries) {
      this.objectsLoading = true;
      const promise = MapObjectApi.findMapObject(
        { data: { queries, limit: 10000 } }, ['mapObjectLabel', 'mapObjectType'],
      );
      this.lastPromise = promise;
      return new Promise(() => {
        promise.then((response) => {
          if (promise === this.lastPromise) {
            this.lastPromise = null;
            this.objectResults = response.data.sort(
              (a, b) => (a.map_object_type_id > b.map_object_type_id ? 1 : -1),
            );
            this.objectResults = this.objectResults
              .filter(object => (object.id !== this.parent.id));
            this.objectsLoading = false;
          }
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.mapobject', 0) }),
            data: error.response,
          });
          this.objectsLoading = false;
        });
      });
    },
  },
  mounted() {
    console.log('mapObjectTypes', this.possibleParentTypes);
  },
};
</script>

<style scoped>
.greyBlock {
  background: lightgray;
  min-height: 200px;
  padding: 20px;
  padding-bottom: 50px;
  overflow: hidden;
  overflow-y: scroll;
}
</style>
