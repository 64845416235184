// eslint-disable-next-line import/no-cycle
import axios from '@/plugins/axios';

export default {
  getNetwork() {
    return axios.get('/v2/network?perPage=9999')
      .then(response => response.data);
  },
  getNetworkById(id) {
    return axios.get(`/v2/network/${id}?perPage=9999`)
      .then(response => response.data);
  },
  getNetworkByUser(id) {
    return axios.get(`/v1/user/${id}/network?perPage=9999`)
      .then(response => response.data);
  },
  getAttachmentsForNetwork(id, include = '') {
    return axios.get(`/v1/network/${id}/attachment?include=${include}`)
      .then(response => response.data);
  },
  createAttachmentForNetwork(id, data) {
    return axios.post(`/v1/network/${id}/attachment`, data)
      .then(response => response.data);
  },
  uploadAttachmentForNetwork(url, data) {
    return axios.post(`/v1/${url}/javascript`, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data);
  },
  deleteAttachmentForNetwork(id, token) {
    return axios.delete(`/v1/network/${id}/attachment/${token}`)
      .then(response => response.data);
  },
  addNetworkByUser(id, data) {
    return axios.post(`/v1/network/${id}/user`, data)
      .then(response => response.data);
  },
  updateNetworkByUser(id, data) {
    return axios.put(`/v1/network/${id}/user`, data)
      .then(response => response.data);
  },
  removeNetworkByUser(id, data) {
    return axios.delete(`/v1/network/${id}/user`, data)
      .then(response => response.data);
  },
  getNetworkData(id) {
    return axios.get(`/v2/network/${id}/all`)
      .then(response => response.data);
  },
  getNetworkSettings(id) {
    return axios.get(`/v2/network/${id}/config`)
      .then(response => response.data);
  },
  getNetworkActions(id) {
    return axios.get(`/v2/network/${id}/actions`)
      .then(response => response.data);
  },
  getNetworkMapObjects(id) {
    return axios.get(`/v2/network/${id}/objects`)
      .then(response => response.data);
  },
  getNetworkSelections(id) {
    return axios.get(`/v2/network/${id}/selections`)
      .then(response => response.data);
  },
  getActions(networkId, includes, filter, pagination = 1, sort, sortBy, onlyNotFinal = false) {
    const filters = `&page=${pagination}&sort=${sort}&sortBy=${sortBy}&onlyNotFinal=${onlyNotFinal}${filter}`;
    return axios.get(`/v2/network/${networkId}/action?include=${includes}${filters}`)
      .then(response => response.data);
  },
  getRecurringActions(networkId, includes, filter, pagination = 1,
    sort, sortBy, onlyNotFinal = false) {
    const filters = `&page=${pagination}&sort=${sort}&sortBy=${sortBy}&onlyNotFinal=${onlyNotFinal}${filter}`;
    return axios.get(`/v2/network/${networkId}/recurring_action?include=${includes}${filters}`)
      .then(response => response.data);
  },
  getActionCityDistricts(networkId) {
    return axios.get(`/v2/network/${networkId}/action/city_district`)
      .then(response => response.data);
  },
  getMapObjectStatistics(networkId) {
    return axios.get(`/v1/network/${networkId}/map_object/statistics`)
      .then(response => response.data);
  },
  getActionStatistics(networkId) {
    return axios.get(`/v1/network/${networkId}/action/statistics`)
      .then(response => response.data);
  },
  getChildrenForNetwork(id, include = '', depth = true, perPage = 15, page = 1) {
    return axios.get(`/v1/network/${id}/children?include=${include}&depth=${depth}&perPage=${perPage}&page=${page}`)
      .then(response => response.data);
  },
  findNearestObject(id, lat, lon, include = '', geoType = null) {
    let url = `/v1/network/${id}/nearest?include=${include}&lat=${lat}&lon=${lon}`;
    if (geoType !== null) {
      url += `&geo_type=${geoType}`;
    }
    return axios.get(url)
      .then(response => response.data);
  },
  getObjectsInBoundingBox(id, lat1, lon1, lat2, lon2, zoom, filter = {}) {
    const url = `/v1/network/${id}/bbox?lat1=${lat1}&lon1=${lon1}&lat2=${lat2}&lon2=${lon2}&zoom=${zoom}&${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  getPointsInBoundingBox(id, bbox, zoom, clustering, filter = {}) {
    const url = `/v1/network/${id}/bbox/points?lat1=${bbox[0]}&lon1=${bbox[1]}&lat2=${bbox[2]}&lon2=${bbox[3]}&zoom=${zoom}&clustering=${clustering}&${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  getLinesInBoundingBox(id, lat1, lon1, lat2, lon2, zoom, filter = {}) {
    const url = `/v1/network/${id}/bbox/lines?lat1=${lat1}&lon1=${lon1}&lat2=${lat2}&lon2=${lon2}&zoom=${zoom}&${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  getPolygonsInBoundingBox(id, lat1, lon1, lat2, lon2, zoom, filter = {}) {
    const url = `/v1/network/${id}/bbox/polygons?lat1=${lat1}&lon1=${lon1}&lat2=${lat2}&lon2=${lon2}&zoom=${zoom}&${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  getObjectsOnMap(id, zoom, filter = {}) {
    const url = `/v1/network/${id}/map?zoom=${zoom}&${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  getPointsOnMap(id, zoom, clustering = true, filter = {}) {
    const url = `/v1/network/${id}/map/points?zoom=${zoom}&clustering=${clustering}&${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  getLinesOnMap(id, zoom, filter = {}) {
    const url = `/v1/network/${id}/map/lines?zoom=${zoom}&${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  getPolygonsOnMap(id, zoom, filter = {}) {
    const url = `/v1/network/${id}/map/polygons?zoom=${zoom}&${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  getActionsOnMap(id, filter = {}) {
    const url = `/v1/network/${id}/map/actions?${this.setFilterParams(filter)}`;
    return axios.get(url)
      .then(response => response.data);
  },
  setFilterParams(filter) {
    const params = new URLSearchParams();
    Object.keys(filter).forEach((key) => {
      params.append(`filter[${key}]`, filter[key]);
    });
    return params.toString();
  },
};
