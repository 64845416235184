<template>
  <CollapsePanel
    v-if="mapObject"
    :title="$t('route_management.object_children')"
    :loading=loading
    :showCount=true
    :count="pagination.total"
    :showCollapseAction="false"
  >
    <div class="map-objects-list">
      <ChildObject v-for="(child, key) in childrenToShow"
                   :key="key"
                   :child="child"
                   @goToObject="goToObject"
      />
    <Pagination
      v-if="!loading"
      @switchPage="switchPage"
      :total="pagination.total"
      :per_page="pagination.per_page"
      :current_page="pagination.current_page"
      :total_pages="pagination.total_pages"  />
    </div>
  </CollapsePanel>
</template>

<script>
import { mapActions } from 'vuex';
// import MapObjectApi from '@/api/map_object';

import CollapsePanel from '@/components/CollapsePanel.vue';
import Pagination from '@/components/Pagination.vue';
import ChildObject from '@/components/Layout/ChildObject.vue';

export default {
  name: 'ObjectChildren',
  components: {
    ChildObject,
    CollapsePanel,
    Pagination,
  },
  props: {
    mapObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      allChildren: [],
      childrenToShow: [],
      pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
        total_pages: 1,
      },
    };
  },
  methods: {
    ...mapActions({
      getChildrenForMapObject: 'settings/getChildrenForMapObject',
    }),
    switchPage(page) {
      this.pagination.current_page = page;
      this.childrenToShow = this.allChildren.slice(
        ((page - 1) * this.pagination.per_page),
        (page * this.pagination.per_page),
      );
    },
    goToObject(mapObjectId) {
      this.$router.push({ name: 'route_management.show', params: { id: mapObjectId } });
    },
    getChildren() {
      if (!this.mapObject) {
        return;
      }
      this.loading = true;
      this.getChildrenForMapObject({ id: this.mapObject.id }).then((children) => {
        this.allChildren = children;
        this.pagination.total = children.length;
        if (children.length > this.pagination.per_page) {
          this.childrenToShow = children.slice(0, this.pagination.per_page);
          this.pagination.total_pages = Math.ceil(children.length / this.pagination.per_page);
        } else {
          this.childrenToShow = children;
          this.pagination.total_pages = 1;
        }
        this.loading = false;
      });
    },
  },
  mounted() {
    if (!this.mapObject) {
      return;
    }
    this.getChildren();
  },
  watch: {
    mapObject() {
      this.getChildren();
    },
  },
};
</script>
<style scoped>
  div.map-objects-list {
    margin: -15px 0 0 0;
  }
</style>
