<template>
  <div class="nav nav-settings">
    <div class="nav-icon non-active">
      <font-awesome-icon icon="cog" />
      <div class="settings-foldout">
        <div class="arrow"></div>
        <div class="menu-content">
          <router-link :to="{ name: 'switcher' }">
            <a href="#" class="menu-item">
              Wissel netwerk
            </a>
          </router-link>
          <router-link :to="{ name: 'profile' }">
            <a href="#" class="menu-item">
              Profiel bekijken
            </a>
          </router-link>
          <router-link :to="{ name: 'logout' }">
            <a href="#" class="menu-item">
              Uitloggen
            </a>
          </router-link>
        </div>
        <div class="padding"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Settings',
};
</script>

<style scoped>

</style>
