<template>
  <div>
    <span class="map-object-header" v-if="map_object">
      <IconByTypeId
        :map_object_type_id="map_object.map_object_type_id"
        :label="map_object | mapObjectLabel"
      />
      <span v-if="map_object.mapObjectType && map_object.mapObjectType.data">
        {{ map_object.mapObjectType.data.name }}
      </span>
      <span> </span>
      <span>
        {{ map_object | mapObjectLabel }}
      </span>
      <a
        class="show-object-attributes"
        @click="showObjectAttributeBox = !showObjectAttributeBox"
      >
        {{ $t('actionlog.object_details') }}
        <span v-if="showObjectAttributeBox">
          {{ $t('actionlog.hide') }}
          <font-awesome-icon icon="chevron-up" class="small" />
        </span>
        <span v-else>
          {{ $t('actionlog.show') }}
          <font-awesome-icon icon="chevron-down" class="small" />
        </span>
      </a>
    </span>
    <div v-if="map_object"
         :class="{'object-attribute-box': true,'open': showObjectAttributeBox}"
    >
      <div>
        <span class="title">{{ $t('model.name') | capitalize }}:</span>
        <span class="content">{{ map_object.name }}</span>
      </div>
      <div v-if="map_object.mapObjectStatus && map_object.mapObjectStatus.data">
        <span class="title">{{ $tc('models.status', 1) | capitalize }}:</span>
        <span class="content">{{ map_object.mapObjectStatus.data.name }}</span>
      </div>
      <div v-if="map_object.attribute && map_object.attribute.data">
        <div v-for="(attribute,idx) in map_object.attribute.data" :key="idx">
          <span class="title">{{attribute.name }}:</span>
          <span class="content"><AttributeValue :attribute="attribute"/></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AttributeValue from '@/components/AttributeValue.vue';
import IconByTypeId from '@/components/IconByTypeId.vue';

export default {
  name: 'ActionModalMapObjectInformation',
  components: { IconByTypeId, AttributeValue },
  props: {
    map_object: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showObjectAttributeBox: false,
    };
  },
};
</script>

<style scoped>
  .map-object-header {
    position: relative;
    top: -5px;
  }

  .show-object-attributes {
    margin-left: 20px;
  }

  .object-attribute-box {
    display: none;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
  }
  .object-attribute-box.open {
    display: block;
  }
  .object-attribute-box .title {
    display: inline-flex;
    float: initial;
    width: 35%;
  }
  .object-attribute-box .content {
    width: 65%;
    display: inline-flex;
    float: initial;
  }
</style>
