<template>
  <div>
    <Loading :visible="!mapObject || !data" />
    <BreadcrumbMapObject v-if="mapObject"
                         :mapObject="mapObject"
                         :extraLabel="$t('button.edit')"
                         @clickBack="clickBack"
                         :show-parents="false"
    />
    <CollapsePanel
      :collapsed="true"
      :loading=false
      :showCount=false
      :title="$t('route_management.object_information')"
    >
      <div v-if="mapObject && data" class="object-information-block">
        <span>{{ $t('model.type') | capitalize }}</span>
          <p>
            <span class="thick">{{ mapObject.mapObjectType.data.name }}</span>
            <a class="pull-to-right" v-on:click="clickChangeType">
              <font-awesome-icon icon="pen" /> {{ $t('actionlog.change') | capitalize }} <br>
            </a>
          </p>
          <ChangeType v-if="this.changetype"
                      :is-active="this.changetype"
                      :object="this.data"
                      @close="clickChangeType" />
          <span> {{ $t('route_management.object_parents') | capitalize }}</span>
          <p>
            <span v-if="this.parent && this.parent.mapObjectType" class="thick">
              {{this.parent.mapObjectType.data.name}} {{this.parent.name}}
            </span>
            <span v-else class="thick">
              {{ $tc('models.network',1) | capitalize }} {{this.network.name}}
            </span>
            <a v-if="this.parent && this.parent.mapObjectType"
               class="pull-to-right"
               @click="ChangeParent"
            >
              <font-awesome-icon icon="pen"/> {{ $t('actionlog.change') | capitalize }} <br>
            </a>
          </p>
          <label for="input-field-name"> {{ $t('model.name') | capitalize }} </label>
          <div id="input-field-name" class="field">
            <input
              id="name"
              name="name"
              type="text"
              v-model="data.name"
              :class="{'error': errors.name !== null}"
              @change="resetError('name')"
            />
          </div>
          <span class="input-error">
            {{ errors.name }}
          </span>
          <label for="input-field-name">
            {{ $t('model.status') | capitalize }}
          </label>
          <div id="input-field-status" class="field">
            <select v-model="data.map_object_status_id">
              <option :value="mapobjectstatus.id" :key="mapobjectstatus.id"
                      v-for="mapobjectstatus in mapObjectStatuses">
                {{ mapobjectstatus.name }}
              </option>
            </select>
          </div>
        <div id="attributes" v-if="data.attribute">
          <div v-for="attribute in data.attribute.data" :key="attribute.id">
            <span v-if="attribute.required" class="title">
                <span class="required">*</span>
                {{ attribute.name }}:
            </span>
            <span v-else class="title">{{ attribute.name }}:</span>
            <div class="field content">
              <AttributeInput
                :disabled="false"
                :attribute="attribute"
                :error="errors[attribute.id]"
              />
            </div>
          </div>
        </div>
      </div>
    </CollapsePanel>
    <ButtonRow @back="clickBack" @save="saveObject" />
    <ChangeParent
      v-if="mapObject && parent"
      :visible="showChangeParent"
      :object="data"
      :parent="parent"
      @closeModal="ChangeParent"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CollapsePanel from '@/components/CollapsePanel.vue';
import MapObjectApi from '@/api/map_object';
import AttributeInput from '@/components/AttributeInput.vue';
import MapobjectTypeApi from '@/api/map_object_type';
import Loading from '@/components/Loading.vue';
import BreadcrumbMapObject from '@/components/Layout/BreadcrumbMapObject.vue';
import ButtonRow from '@/components/ButtonRow.vue';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';
import ChangeParent from '@/modules/drawing/ChangeParent.vue';
import ChangeType from '@/modules/drawing/ChangeType.vue';

export default {
  name: 'drawing.edit_object',
  components: {
    ButtonRow,
    BreadcrumbMapObject,
    Loading,
    CollapsePanel,
    AttributeInput,
    ChangeParent,
    ChangeType,

  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObject: 'selected/getMapObject',
      mapObjects: 'settings/getMapObjects',
    }),
  },
  data() {
    return {
      mapObjectStatuses: [],
      data: null,
      parent: null,
      loading: true,
      changetype: false,
      errors: {
        name: null,
      },
      showChangeParent: false,
    };
  },
  methods: {
    ...mapActions({
      fetchMapObject: 'selected/fetchMapObject',
      updateMapObject: 'settings/updateMapObject',
    }),
    saveObject() {
      if (!this.validateFields()) {
        return;
      }
      MapObjectApi.updateMapObjectById(this.mapObject.id, this.data)
        .then((response) => {
          this.$notify({
            type: 'success',
            title: this.$t('dialog.changes_saved'),
          });
          this.updateLocalObjects(response.data);
          this.fetchMapObject({ id: this.mapObject.id, force: true });
          EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
          this.$router.push({ name: 'drawing.show', params: { id: this.mapObject.id } });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.update_failed', { model: this.$tc('models.mapobject', 1) }),
            data: error.response,
          });
        });
    },
    updateLocalObjects(data) {
      console.log('updateLocalObjects', data, this.mapObject);
      const mapObject = data;
      let updatedLabel = false;
      if (!mapObject) return;
      if (this.data.attribute && this.data.attribute.data) {
        const labelAttributes = this.data.attribute.data
          .filter(item => item.id === this.mapObject.mapObjectType.data.label_attribute_id);
        if (labelAttributes && labelAttributes.length > 0) {
          mapObject.label = labelAttributes[0].value ? labelAttributes[0].value : mapObject.label;
          updatedLabel = true;
        }
      }
      if (!updatedLabel) {
        mapObject.label = this.mapObject.label;
      }
      const originalObject = this.mapObjects[data.id];
      mapObject.actions = originalObject.actions;
      mapObject.children = originalObject.children;
      this.updateMapObject({ mapObject });
    },
    validateFields() {
      let valid = true;
      if (this.data.name === '') {
        this.errors.name = this.$t('validation.name_empty');
        this.$notify({
          type: 'warning',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
          text: this.$t('validation.name_empty'),
          duration: 1000,
        });
        valid = false;
      }
      this.data.attribute.data.forEach((attribute) => {
        if (attribute.required
          && (attribute.value === undefined || attribute.value === null || attribute.value === '')
        ) {
          this.errors[attribute.id] = true;
          this.$notify({
            type: 'warning',
            title: this.$t('error.create_failed', { model: this.$tc('models.attribute', 1) }),
            text: this.$t('validation.field_empty', { field: attribute.name }),
            duration: 1000,
          });
          valid = false;
        }
        return attribute;
      });
      return valid;
    },
    resetError(field) {
      this.errors[field] = null;
    },
    clickBack() {
      this.$router.back();
    },
    clickParent() {
      EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
      this.$router.push({ name: 'drawing' });
    },
    clickChangeType() {
      this.changetype = !this.changetype;
    },
    getMapObjectStatuses() {
      MapobjectTypeApi.getMapObjectTypeStatusById(this.mapObject.map_object_type_id)
        .then((response) => {
          this.mapObjectStatuses = response.data;
        });
      this.loading = false;
    },
    getParent() {
      if (!this.mapObject || this.mapObject.parent_id === null) {
        return;
      }
      this.loading = true;
      MapObjectApi.getParentForMapObject(this.mapObject.id, 'mapObjectType')
        .then((response) => {
          this.parent = response.data;
          this.loading = false;
        });
    },
    ChangeParent() {
      this.showChangeParent = !this.showChangeParent;
    },
  },
  mounted() {
    if (this.$route.params.id !== null && this.$route.params.id !== undefined) {
      const id = parseInt(this.$route.params.id, 10);
      if (!this.mapObject || this.mapObject.id !== id) {
        this.fetchMapObject({ id }).then(() => {
          this.getMapObjectStatuses();
          this.getParent();
        });
      } else {
        this.getMapObjectStatuses();
        this.getParent();
        this.data = JSON.parse(JSON.stringify(this.mapObject));
      }
      EventBus.$emit(
        'Map.changeMapMode',
        null,
      );
    }
  },
  watch: {
    mapObject(newValue) {
      this.data = JSON.parse(JSON.stringify(newValue));
    },
  },
};
</script>

<style scoped>
.object-information-block {
  padding: 0 20px;
}

.object-information-block h3.block-header {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  margin: 0 0 5px;
  padding: 0;
  color: #42464c;
}

.object-information-block ul.block-details {
  overflow: auto;
  zoom: 1;
  font-size: .85em;
  margin: 0 0 20px 0;
  padding: 0;
}

.object-information-block ul.block-details li.block-item {
  line-height: 1.7em;
  position: relative;
  clear: both;
  list-style: none;
}

.object-information-block .block-details .block-item .block-item-title {
  display: block;
  float: left;
  width: 40%;
}

.object-information-block .block-details .parent-selections {
  margin-left: 30px;
  font-size: 1.1em;
}
.edit-object-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}

.edit-object-buttons .save-button {
  float: right;
  margin-right: 7px;
}
.field.content {
  display: inline-flex;
  float: initial;
  overflow: initial;
  width: 100%;
}

.required {
  color: red;
}
</style>
