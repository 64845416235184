<template>
  <div>
  </div>
</template>

<script>

import L from 'leaflet';
import EventBus from '@/events/event-bus';

export default {
  name: 'ContextMenu',
  props: {
    map: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tempMapActionLayer: null,
      showSelectActionTypeToCreateMapActionModal: false,
    };
  },
  methods: {
    init() {
      this.tempMapActionLayer = new L.LayerGroup();
      this.map.addLayer(this.tempMapActionLayer);

      this.map.on('contextmenu', (e) => {
        this.onContextMenuClick(e.latlng);
      });
    },
    onContextMenuClick(latlng) {
      if (this.$route.path.includes('route_management')) {
        this.createMapActionIcon(latlng);
        this.showSelectActionTypeToCreateMapActionModal = true;
        EventBus.$emit('RouteManagement.createActionOnMap', latlng);
      }
    },
    createMapActionIcon(coords) {
      this.removeMapActionIcon();
      const marker = new L.Marker(coords);
      marker.label = '';
      const icon = this.getIconByMapObjectType({
        type: 'action',
        color: 'orange',
        color_selected: 'red',
      }, 0, '', false);
      marker.setIcon(icon);
      marker.addTo(this.tempMapActionLayer);
    },
    closeModal() {
      this.showSelectActionTypeToCreateMapActionModal = false;
      this.removeMapActionIcon();
    },
    removeMapActionIcon() {
      this.tempMapActionLayer.clearLayers();
    },
  },
  mounted() {
    this.init();
    EventBus.$on('RouteManagement.closeCreateActionOnMap', () => {
      this.closeModal();
    });
  },
  beforeDestroy() {
    this.map.off('contextmenu');
    this.map.removeLayer(this.tempMapActionLayer);
  },
};
</script>

<style scoped>

</style>
