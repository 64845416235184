<template>
  <div id="filter-component" style="padding-left:10px" align="right">
    <div id="filter-pop-up-field"
         :class="['module-color-border', {'active': active}]"
    >
      <button type="submit" id="filterbtn" class="module-color-background"
              ref="filterButton"
              @click="active = !active">
        <font-awesome-icon v-if="!active" icon="filter" />
        <font-awesome-icon v-else icon="times" />
      </button>
    </div>
    <div v-if="active"
         id="results"
         style="background: white"
    >
      <Loading :visible=loading />
      <div class="foldout" align="left">
        <div class="filter-head">

          <div v-if="countObjects() === 0" class="head-info">
            <span class="module-color filter-message">Kaart Filteren</span>
          </div>
          <div v-else class="head-info">
            <span class="module-color filter-message">{{ countObjects() }}</span>
            <span class="module-color filter-message"> filter(s) geselecteerd</span>
          </div>
        </div>
        <div class="filter-body">
          <div class="filterselectbox" >
            <label class="control control-checkbox">
              <input name="show_deleted" type="checkbox"
                     v-model="filters.deleted">
              <span class="control-indicator"></span>
              <span class="filter-name" style="font-size: smaller;">
                Toon verwijderde objecten op de kaart</span>
            </label>
          </div>
          <div class="filterselectbox">
            <label class="control control-checkbox">
              <input name="show_obstructed" type="checkbox"
                     v-model="filters.obstructed">
              <span class="control-indicator"></span>
              <span class="filter-name" style="font-size: smaller;">
                Toon alleen gestremde objecten op de kaart</span>
            </label>
          </div>
        </div>
        <SmallCollapsePanel
          :title="$tc('models.actionpriority',1) | capitalize"
          :loading=false
          :showCount=true
          :count="countObjects('actionPriority')"
          :showCollapseAction="false"
        >
          <div v-for="actionPriority in actionPriorities"
               :key="actionPriority.id" class="filterselectbox">
            <label class="control control-checkbox">
              <input type="checkbox"
                     :value="actionPriority.id"
                     v-model="filters.actionPriority">
              <span class="control-indicator"></span>
              <span class="filter-name" style="font-size: smaller;">
                {{actionPriority.name}}
              </span>
            </label>
          </div>
        </SmallCollapsePanel>
        <SmallCollapsePanel
          :title="$tc('models.actionstatus',2) | capitalize"
          :loading=false
          :showCount=true
          :count="countObjects('actionStatus')"
          :showCollapseAction="false"
        >
          <div v-for="actionStatus in actionStatuses"
               :key="actionStatus.id" class="filterselectbox">
            <label class="control control-checkbox">
              <input type="checkbox"
                     :value="actionStatus.id"
                     v-model="filters.actionStatus">
              <span class="control-indicator"></span>
              <span class="filter-name" style="font-size: smaller;">
                {{actionStatus.name}}
              </span>
            </label>
          </div>
        </SmallCollapsePanel>
        <SmallCollapsePanel
          :title="$tc('models.dynamicactiontype',2) | capitalize"
          :loading=false
          :showCount=true
          :count="countObjects('dynamicActionType')"
          :showCollapseAction="false"
        >
          <div v-for="dynamicActionType in dynamicActionTypes"
               :key="dynamicActionType.id" class="filterselectbox">
            <label class="control control-checkbox">
              <input type="checkbox"
                     :value="dynamicActionType.id"
                     v-model="filters.dynamicActionType">
              <span class="control-indicator"></span>
              <span class="filter-name" style="font-size: smaller;">
                {{dynamicActionType.name}}
              </span>
            </label>
          </div>
        </SmallCollapsePanel>
        <SmallCollapsePanel
          :title="$tc('models.mapobjectstatus',2) | capitalize"
          :loading=false
          :showCount=true
          :count="countObjects('mapObjectStatus')"
          :showCollapseAction="false"
        >
          <div v-for="mapObjectStatus in mapObjectStatuses"
               :key="mapObjectStatus.id" class="filterselectbox">
            <label class="control control-checkbox">
              <input type="checkbox"
                     :value="mapObjectStatus.id"
                     v-model="filters.mapObjectStatus">
              <span class="control-indicator"></span>
              <span class="filter-name" style="font-size: smaller;">
                {{mapObjectStatus.name}}
              </span>
            </label>
          </div>
        </SmallCollapsePanel>
        <SmallCollapsePanel
          :title="$tc('models.mapobjecttype',2) | capitalize"
          :loading=false
          :showCount=true
          :count="countObjects('mapObjectType')"
          :showCollapseAction="false"
        >
          <div v-for="mapObjectType in mapObjectTypes"
               :key="mapObjectType.id" class="filterselectbox">
            <label class="control control-checkbox">
              <input type="checkbox"
                     :value="mapObjectType.id"
                     v-model="filters.mapObjectType">
              <span class="control-indicator"></span>
              <span class="filter-name" style="font-size: smaller;">
                {{mapObjectType.name}}
              </span>
            </label>
          </div>
        </SmallCollapsePanel>
        <SmallCollapsePanel
          :title="$tc('models.selection',2) | capitalize"
          :loading=false
          :showCount=true
          :count="countObjects('mapObjectSelection')"
          :showCollapseAction="false"
        >
          <div v-for="mapObjectSelection in mapObjectSelections"
               :key="mapObjectSelection.id" class="filterselectbox">
            <label class="control control-checkbox">
              <input type="checkbox"
                     :value="mapObjectSelection.id"
                     v-model="filters.mapObjectSelection">
              <span class="control-indicator"></span>
              <span class="filter-name" style="font-size: smaller;">
                {{mapObjectSelection.name}}
              </span>
            </label>
          </div>
        </SmallCollapsePanel>
        <div id="button-block">
          <a id="reset-button" class="btn grey" v-on:click="resetFilters">
            Reset
          </a>
          <a id="save-button" class="btn module-color-background" v-on:click="useFilter">
            Filter
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '../Loading.vue';
import SmallCollapsePanel from '@/components/SmallCollapsePanel.vue';
import EventBus from '@/events/event-bus';

export default {
  name: 'MapFilter',
  components: {
    SmallCollapsePanel,
    Loading,
  },
  data() {
    return {
      active: false,
      reset: false,
      filters: {
        actionPriority: [],
        actionStatus: [],
        dynamicActionType: [],
        mapObjectStatus: [],
        mapObjectType: [],
        mapObjectSelection: [],
        obstructed: false,
        deleted: false,
      },
      loading: false,
      types: [
        'priority',
        'actionstatus',
        'dynamicaction',
        'mapobjectstatus',
        'mapobjectselections',
        'mapobjecttypes',
      ],
    };
  },
  computed: {
    ...mapGetters({
      networkId: 'switcher/getNetworkId',
      mapObjectTypes: 'settings/getMapObjectTypes',
      actionStatuses: 'settings/getActionStatuses',
      actionPriorities: 'settings/getActionPriorities',
      dynamicActionTypes: 'settings/getDynamicActionTypes',
      mapObjectStatuses: 'settings/getMapObjectStatuses',
      mapObjectSelections: 'settings/getMapObjectSelections',
    }),
  },
  methods: {
    ...mapActions({
      setFilterForMap: 'settings/setFilter',
      resetFilterForMap: 'settings/resetFilter',
    }),
    countObjects(filterType) {
      if (filterType) {
        return this.filters[filterType].length;
      }
      let count = 0;
      Object.keys(this.filters).forEach((key) => {
        if (Array.isArray(this.filters[key])) {
          count += this.filters[key].length;
        } else {
          count += (this.filters[key] ? 1 : 0);
        }
      });
      return count;
    },
    resetFilters() {
      this.loading = true;
      Object.keys(this.filters).forEach((key) => {
        if (Array.isArray(this.filters[key])) {
          this.filters[key] = [];
        } else {
          this.filters[key] = false;
        }
      });
      this.resetFilterForMap();
      EventBus.$emit('Map.redraw');
      this.loading = false;
    },
    useFilter() {
      console.log('setFilter', { filter: this.filters });
      this.setFilterForMap({ filter: this.filters });
      EventBus.$emit('Map.redraw');
    },
  },
  created() {
  },
};
</script>

<style scoped>
#filter-component {
  float: left;
}

#filter-component #filter-pop-up-field {
  position: relative;
  width: 0;
  margin-bottom: 15px;
  overflow: hidden;
  height: 50px;
  padding: 0 46px 0 2px;
  border: 1px solid var(--module-color);
  border-radius: 555px;
  font-weight: 300;
  line-height: 36px;
  background: #fff;
}
#filter-component #filter-pop-up-field #filterbtn {
  position: absolute;
  right: 0;
  top: 25px;
  transform: translateY(-50%);
  font-size: 1em;
  color: var(--module-color);
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: none;
  background: none;
  padding: 0;
  border-radius: 555px;
  display: inline-block;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: .1em;
  -webkit-appearance: button;
  cursor: pointer;
}
#filter-component #results {
  max-height: 80vh;
  overflow-y: auto;
  position: absolute;
  width: 360px;
  right: -5px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.filterselectbox{
  line-height: 32px;
  min-height: 30px;
  background: #f2f7fe;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.head-info{
  display: inline-block;
  padding:10px;
  width: 50%;
  color: var(--module-color);
}
#button-block {
  padding: 10px;
  height: 50px;
}
#button-block #reset-button {
  display: block;
  float: left;
  padding: 0 15px;
}
#button-block #save-button {
  display: block;
  float: right;
  margin-right: 7px;
  padding: 0 15px;
}
</style>
