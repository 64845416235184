<template>
  <div class="priority-options">
    <div
      v-for="actionPriority in actionPriorities"
      :key="actionPriority.id"
      :class="[
                'priority-option',
                `option-${actionPriority.id}`,
                {
                  'selected': value === actionPriority.id,
                  'disabled': disabled
                },
              ]"
      @click="clickButton(actionPriority.id)"
    >
      {{ $t('priority.' + actionPriority.name.toLowerCase()) | capitalize }}
    </div>
  </div>
</template>
<script>
import ActionPriorityApi from '@/api/action_priority';

export default {
  name: 'ActionPriorityButtons',
  components: {},
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actionPriorities: [],
    };
  },
  methods: {
    clickButton(actionPriorityId) {
      if (!this.disabled) {
        this.$emit('input', actionPriorityId);
      }
    },
    getActionPriorities() {
      ActionPriorityApi.getActionPriority()
        .then((response) => {
          this.actionPriorities = response.data;
        });
    },
  },
  beforeMount() {
    this.getActionPriorities();
  },
};
</script>

<style scoped>
  .priority-options {
    width: 100%;
    margin-bottom: 15px;
    overflow: hidden;
    line-height: normal;
  }

  .priority-options .priority-option {
    color: black;
    float: left;
    width: 33%;
    padding: 8px 10px;
    position: relative;
    text-align: center;
    background: #cfcfcf;
    transition: background-color 0.2s ease;
    cursor: default;
  }
  .priority-options .priority-option:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .priority-options .priority-option:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .priority-options .priority-option:not(.selected):not(.disabled):hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  .priority-options .priority-option.option-1.selected {
    background: #44a7dc;
  }

  .priority-options .priority-option.option-2.selected {
    background: #ff9900;
  }

  .priority-options .priority-option.option-3.selected {
    background: #ee335f;
  }
</style>
