<template>
  <Modal
    :visible="visible"
    @closeModal="closeModal"
    :header="true"
    :footer="true"
    :no_padding="true"
    width="30%"
    :loading="loading"
  >
    <template v-slot:header>
      {{ title }}
    </template>

    <template v-slot:body>
     <slot name="body" />
    </template>

    <template v-slot:footer>
      <slot name="footer" />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Layout/Modal.vue';

export default {
  name: 'ObjectModal',
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
<style scoped>

</style>
