import axios from '@/plugins/axios';

export default {
  getKeyValues() {
    return axios.get('/v1/kv?perPage=9999')
      .then(response => response.data);
  },
  getKeyValueByKey(key) {
    return axios.get(`/v1/kv/${key}?perPage=9999`)
      .then(response => response.data);
  },
  createKeyValue(key, data) {
    return axios.post(`/v1/kv/${key}`, data)
      .then(response => response.data);
  },
  getIcon(mapObjectTypeId) {
    return axios.get('/v1/kv?perPage=9999')
      .then((response) => {
        const icon = response.data.data.filter(
          u => u.key === `map_object_type_icon_${mapObjectTypeId}`,
        );
        if (icon.length > 0) {
          return icon[0];
        }
        return {};
      });
  },
  updateIconById(mapObjectTypeId, data) {
    const id = `map_object_type_icon_${mapObjectTypeId}`;
    return axios.put(`/v1/kv/${id}`, data)
      .then(response => response.data);
  },
  updateKeyValue(id, data) {
    return axios.put(`/v1/kv/${id}`, data)
      .then(response => response.data);
  },
  deleteKeyValue(id) {
    return axios.delete(`/v1/kv/${id}`)
      .then(response => response.data);
  },
};
