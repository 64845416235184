<template>
  <div class="tabs-view">
    <ul>
      <slot></slot>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'Tabs',
};
</script>

<style scoped>
  .tabs-view {
    border-bottom: 1px solid #d9dadb;
    margin: 0 0 15px;
  }

  .tabs-view ul {
    margin: 0;
    padding: 0 10px;
    text-align: center;
    display: table;
    width: 100%;
    table-layout: fixed;
    position: relative;
    top: 1px;
  }

</style>
