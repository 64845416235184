<template>
  <div class="action-comment">
    <div class="comment">{{ comment.comment }}</div>
    <div v-if="$can('delete', 'comment') || comment.user_id == user.id"
      class="comment-delete"
      @click="deleteComment(comment.id)">
      <font-awesome-icon icon="trash" />
    </div>
    <div class="metadata">
      <span v-if="comment.user && comment.user.data" class="user">
        {{ comment.user.data.name }}
      </span>
      <span v-else class="no-user">-</span>
      <span class="date">{{ comment.created_at | dateTime }}</span>
    </div>
  </div>
</template>
<script>
import User from '@/api/user';

export default {
  name: 'ActionComment',
  components: {},
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    User.getMe()
      .then((response) => {
        this.user = response.data;
      });
  },
  data() {
    return {
      user: [],
    };
  },
  methods: {
    deleteComment(commentId) {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_comment_confirm'))) {
        this.$emit('deleteComment', commentId);
      }
    },
  },
};
</script>
<style scoped>
  .action-comment {
    margin-bottom: 15px;
  }
  .action-comment .metadata {
    font-style: italic;
  }
  .action-comment .metadata .user {
    font-weight: bold;
  }
  .action-comment .comment-delete {
    color: #EE335F;
    float: right;
    padding: 5px;
    font-size: .9em;
    text-decoration: none;
  }
</style>
