<template>
  <CollapsePanel
    :title="$t('route_management.object_obstructions')"
    :loading=loading
    :showCount=true
    :count="pagination.total"
    :showCollapseAction="$can('create', 'action') && mapObject !== null"
    @collapseAction="showObjectObstructionsForm('create', true)"
  >
    <ObjectObstructionsCreate
      v-if="mapObject"
      :id="mapObject.id"
      :visible="showObjectObstructionsCreate"
      @showObjectObstructionsForm="showObjectObstructionsForm"
      @createObstruction="createObstruction"
    />
    <ObjectObstructionsEdit
      v-if="mapObject"
      :id="mapObject.id"
      :obstruction="obstruction"
      :visible="showObjectObstructionsEdit"
      @showObjectObstructionsForm="showObjectObstructionsForm"
      @updateObstruction="updateObstruction"
    />
    <ObjectObstructionsItem
      v-for="(obstruction, index) in obstructions"
      v-bind:key="index"
      :id="mapObject ? mapObject.id : parseInt(networkId, 10)"
      :index="index"
      :obstruction="obstruction"
      :visible="showObjectObstructionsItem"
      @showObjectObstructionsForm="showObjectObstructionsForm"
      @deleteObstructionById="deleteObstructionById"
    />
    <div class="no-results" v-if="obstructions.length === 0">
      <p>{{ $t('model.no_blockages') | capitalize }}</p>
    </div>
    <Pagination
      @switchPage="switchPage"
      :total="pagination.total"
      :count="pagination.count"
      :per_page="pagination.per_page"
      :current_page="pagination.current_page"
      :total_pages="pagination.total_pages"
    />
  </CollapsePanel>
</template>

<script>
import moment from 'moment/moment';
import { mapGetters } from 'vuex';
import MapObject from '@/api/map_object';
import ObstructionApi from '@/api/map_object_obstruction';
import CollapsePanel from '@/components/CollapsePanel.vue';
import Pagination from '@/components/Pagination.vue';
import ObjectObstructionsItem from './ObjectObstructionsItem.vue';
import ObjectObstructionsCreate from './ObjectObstructionsCreate.vue';
import ObjectObstructionsEdit from './ObjectObstructionsEdit.vue';

export default {
  name: 'ObjectObstructions',
  components: {
    CollapsePanel,
    Pagination,
    ObjectObstructionsItem,
    ObjectObstructionsCreate,
    ObjectObstructionsEdit,
  },
  props: {
    mapObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  mounted() {
    this.getObjectObstructions();
  },
  data() {
    return {
      loading: false,
      obstructions: [],
      obstruction: {},
      showObjectObstructionsItem: true,
      showObjectObstructionsCreate: false,
      showObjectObstructionsEdit: false,
      obstructionPage: 1,
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      networkId: 'switcher/getNetworkId',
      actionStatuses: 'settings/getActionStatuses',
    }),
  },
  methods: {
    switchPage(page) {
      this.pagination.current_page = page;
      this.getObjectObstructions();
    },
    getObjectObstructions() {
      this.loading = true;

      if (this.mapObject) {
        ObstructionApi.getObstructionWithIncludesAndPagination(this.mapObject.id, '', this.pagination.current_page)
          .then((response) => {
            this.handleObjectObstructionResponse(response);
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectobstruction', 0) }),
              data: error.response,
            });
            this.loading = false;
          });
      } else {
        ObstructionApi.getObstructionByNetworkId(this.networkId, '', this.pagination.current_page)
          .then((response) => {
            this.handleObjectObstructionResponse(response);
          })
          .catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectobstruction', 0) }),
              data: error.response,
            });
            this.loading = false;
          });
      }

      this.loading = false;
    },
    handleObjectObstructionResponse(response) {
      this.obstructions = response.data;
      this.pagination = response.meta.pagination;
      this.loading = false;
    },
    showObjectObstructionsForm(type, isVisible, index = 0) {
      if (type === 'create' && isVisible) {
        this.showObjectObstructionsItem = false;
        this.showObjectObstructionsCreate = true;
      } else if (type === 'create' && !isVisible) {
        this.showObjectObstructionsItem = true;
        this.showObjectObstructionsCreate = false;
      }

      if (type === 'edit' && isVisible) {
        this.obstruction = this.obstructions[index];

        // Set the from_date and to_date otherwise you get an error from Ant DatePicker
        this.obstruction.from_date = moment(this.obstruction.from_date, 'YYYY-MM-DD');
        this.obstruction.to_date = moment(this.obstruction.to_date, 'YYYY-MM-DD');

        this.showObjectObstructionsItem = false;
        this.showObjectObstructionsEdit = true;
      } else if (type === 'edit' && !isVisible) {
        this.showObjectObstructionsItem = true;
        this.showObjectObstructionsEdit = false;
      }
    },
    async createObstruction(obstruction) {
      // eslint-disable-next-line no-param-reassign
      obstruction.network_id = this.networkId;
      await ObstructionApi.createObstruction(obstruction);
      const isobstructed = { data: { is_obstructed: true } };
      await MapObject.updateMapObjectById(this.mapObject.id, isobstructed);
      this.getObjectObstructions();
    },
    async updateObstruction(obstruction) {
      await ObstructionApi.updateObstructionById(obstruction.id, obstruction);
      this.getObjectObstructions();
    },
    async deleteObstructionById(id) {
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_obstruction_confirm'))) {
        await ObstructionApi.deleteObstructionById(id);
        this.getObjectObstructions();
      }
    },
  },
  watch: {
    mapObject() {
      this.getObjectObstructions();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  body {
    font-size: 17px;
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #42464c;
    background: #d8e0e7;
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  a.btn {
    background: var(--module-color);
    border-radius: 555px;
    padding: 0 12px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    text-decoration: none;
    float: left;
    margin-left: 7px;
    font-weight: 300;
    letter-spacing: .1em;
    font-size: .7em;
    text-transform: uppercase;
    display: inline-block;
    outline: 0;
  }

  a.btn:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }

  .btn.grey {
    background: #cfcfcf;
    color: #000;
  }

  a.btn.btn-icon {
    width: 30px;
    padding: 0;
    text-align: center;
  }

  a.btn.btn-icon span.icon {
    line-height: 30px;
    font-size: .85em;
  }

  input[type="text"].multiselect__input {
    border: 0;
  }
</style>
<style scoped>
  * {
    font-size: 17px;
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #42464c;
  }

  @media screen and (max-width: 1300px) {
    * {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1200px) {
    * {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 1100px) {
    * {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 1023px) {
    * {
      font-size: 13px;
    }
  }

  .buttons {
    border-bottom: 1px solid #d9dadb;
    position: relative;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .buttons .button {
    float: left;
    margin: 0 5px;
    text-decoration: none;
    display: block;
    letter-spacing: .01em;
    font-size: 1.1em;
    font-family: "Abel", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #44a7dc;
    color: #fff;
    border-radius: 5px;
    line-height: 40px;
    width: 45%;
  }

  .buttons .button:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }

  .buttons .button.btn-grey {
    background: #cfcfcf;
    color: #000;
  }

  span.right-count.filter {
    display: inline;
  }

  .icon.active {
    color: #a4cd4b;
  }

  .no-results {
    padding: 20px;
  }
</style>
