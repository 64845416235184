<template>
  <router-link :to="{ name: routerLink }">
    <div :class="'nav nav-'+nav" :module="nav">
      <div class="nav-icon">{{ title }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'Module',
  props: {
    nav: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    routerLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  a.router-link-active div.nav {
    background: #fff;
  }
</style>
