import L from 'leaflet';

export default {
  reduceFunc(acc, it) {
    // eslint-disable-next-line no-return-assign
    return (acc[it.id] = it, acc);
  },
  getCoordinates(object, type = null) {
    if (!object) {
      return null;
    }
    if (object.value_point && (!type || type === 'point')) {
      return this.wktPointToArray(object.value_point);
    }
    if (object.value_linestring && (!type || type === 'linestring')) {
      return this.wktMultilineToArray(object.value_linestring);
    }
    if (object.value_polygon && (!type || type === 'polygon')) {
      return this.wktPolygonToArray(object.value_polygon);
    }
    if (!object.geoObject || !object.geoObject.data) {
      return null;
    }
    const geoObject = object.geoObject.data;
    if (geoObject.geometry.type === 'Point' && (!type || type === 'point')) {
      const coords = geoObject.geometry.coordinates;
      return coords.reverse();
    }
    if (geoObject.geometry.type === 'LineString' && (!type || type === 'linestring')) {
      const { coordinates } = JSON.parse(JSON.stringify(geoObject.geometry));
      const coordinatesReversed = [];
      coordinates.forEach((coordinate) => {
        coordinatesReversed.push(coordinate.reverse());
      });
      return coordinatesReversed;
    }
    if (geoObject.geometry.type === 'Polygon' && (!type || type === 'polygon')) {
      const { coordinates } = JSON.parse(JSON.stringify(geoObject.geometry));
      const coordinatesReversed = [];
      coordinates.forEach((coordinate) => {
        const innerCoords = [];
        coordinate.forEach((innerCoord) => {
          innerCoords.push(innerCoord.reverse());
        });
        coordinatesReversed.push(innerCoords);
      });
      return coordinatesReversed;
    }
    return null;
  },
  createPointForLeaflet(mapObject, latlng) {
    const { coordinates } = mapObject.geoObject.data.geometry;
    const coords = latlng || [coordinates[1], coordinates[0]];
    const marker = new L.Marker(coords);
    marker.map_object_id = mapObject.id;
    marker.map_object_type_id = mapObject.map_object_type_id;
    marker.parent_id = mapObject.parent_id;
    marker.is_obstructed = mapObject.is_obstructed;
    marker.label = this.$options.filters.mapObjectLabel(mapObject);
    const iconData = this.mapObjectTypes[mapObject.map_object_type_id].icon.data;
    const icon = this.getIconByMapObjectType(iconData, 0, marker.label, true);
    marker.setIcon(icon);
    return marker;
  },
  createLinestringForLeaflet(mapObject) {
    const { coordinates } = JSON.parse(JSON.stringify(mapObject.geoObject.data.geometry));
    const coordinatesReversed = [];
    coordinates.forEach((coordinate) => {
      coordinatesReversed.push(coordinate.reverse());
    });
    // eslint-disable-next-line new-cap
    const line = new L.polyline(coordinatesReversed, { color: 'red' });
    line.map_object_id = mapObject.id;
    line.map_object_type_id = mapObject.map_object_type_id;
    return line;
  },
  createPolygonForLeaflet(mapObject) {
    const coordinates = this.parsePolygonCoordinates(mapObject);
    const iconData = this.mapObjectTypes[mapObject.map_object_type_id].icon.data;
    const polygon = new L.Polygon(coordinates, {
      color: iconData.color_selected,
    });
    polygon.map_object_id = mapObject.id;
    polygon.map_object_type_id = mapObject.map_object_type_id;
    polygon.parent_id = mapObject.parent_id;
    polygon.is_obstructed = mapObject.is_obstructed;
    polygon.label = this.$options.filters.mapObjectLabel(mapObject);
    return polygon;
  },
  parsePolygonCoordinates(mapObject) {
    const { coordinates } = JSON.parse(JSON.stringify(mapObject.geoObject.data.geometry));
    const coordinatesReversed = [];
    coordinates.forEach((coordinate) => {
      const innerCoords = [];
      coordinate.forEach((innerCoord) => {
        innerCoords.push(innerCoord.reverse());
      });
      coordinatesReversed.push(innerCoords);
    });
    return coordinatesReversed;
  },
  getCentroid(arr) {
    let twoTimesSignedArea = 0;
    let cxTimes6SignedArea = 0;
    let cyTimes6SignedArea = 0;

    const { length } = arr[0];

    // eslint-disable-next-line func-names
    const x = function (i) {
      return arr[0][i % length][0];
    };
    // eslint-disable-next-line func-names
    const y = function (i) {
      return arr[0][i % length][1];
    };

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr[0].length; i++) {
      const twoSA = x(i) * y(i + 1) - x(i + 1) * y(i);
      twoTimesSignedArea += twoSA;
      cxTimes6SignedArea += (x(i) + x(i + 1)) * twoSA;
      cyTimes6SignedArea += (y(i) + y(i + 1)) * twoSA;
    }
    const sixSignedArea = 3 * twoTimesSignedArea;
    return [cxTimes6SignedArea / sixSignedArea, cyTimes6SignedArea / sixSignedArea];
  },
  geoTypeOfMapObject(mapObject) {
    if (!mapObject) {
      return null;
    }
    if (mapObject.geoObject
      && mapObject.geoObject.data
      && mapObject.geoObject.data.geometry
      && mapObject.geoObject.data.geometry.type
    ) {
      return mapObject.geoObject.data.geometry.type;
    }
    return null;
  },
  geoTypeOfGeoObject(geoObject) {
    if (!geoObject) {
      return null;
    }
    if (geoObject
      && geoObject.geometry
      && geoObject.geometry.type
    ) {
      return geoObject.geometry.type;
    }
    return null;
  },
  wktPointToArray(value) {
    if (!value) {
      return null;
    }
    const coords = this.splitCoordinates(value);
    const splits = coords.split(' ');
    return [parseFloat(splits[0]), parseFloat(splits[1])];
  },
  wktLineToArray(value) {
    const coords = this.splitCoordinates(value);
    const splits = coords.split(' ');
    return [parseFloat(splits[0]), parseFloat(splits[1])];
  },
  wktPolygonToArray(value) {
    const matches = value.match('(?:\\((\\d*\\.*\\,*\\s*)*\\))');
    const coordinates = [];
    matches.forEach((matchVal) => {
      const coordsUnformatted = matchVal.match('((\\d+\\.+\\d+\\,*\\s*)+)');
      if (coordsUnformatted == null) {
        return;
      }
      const innerCoordinates = [];
      coordsUnformatted[0].split(',').forEach((innerCoords) => {
        const splits = innerCoords.split(' ');
        innerCoordinates.push([parseFloat(splits[0]), parseFloat(splits[1])]);
      });
      coordinates.push(innerCoordinates);
    });
    return coordinates;
  },
  wktMultilineToArray(value) {
    const matches = value.match(/(?:\((\d*\.*,*\s*)*\))/gm);
    const coordinates = [];
    matches.forEach((matchVal) => {
      const coordsUnformatted = matchVal.match('((\\d+\\.+\\d+\\,*\\s*)+)');
      if (coordsUnformatted == null) {
        return;
      }
      const innerCoordinates = [];
      coordsUnformatted[0].split(',').forEach((innerCoords) => {
        if (!innerCoords) {
          return;
        }
        const splits = innerCoords.split(' ');
        if (splits.length === 2) {
          innerCoordinates.push([parseFloat(splits[0]), parseFloat(splits[1])]);
        }
      });
      coordinates.push(innerCoordinates);
    });
    return coordinates;
  },
  splitCoordinates(value) {
    return value.match('\\(([^)]+)\\)')[1];
  },
};
