<template>
  <div>
    <BreadcrumbSelectionType :selection-type="chosenSelectionType"
                             :showBackButton="true" @backButtonPressed="goBack"
    />
    <CollapsePanel
      :title="$tc('models.selection', 0)"
      :loading=loading
      :showCount=true
      :count="pagination.total"
      :showCollapseAction="$can('create', 'mapobjectselection')"
      :collapsed="true"
      @collapseAction="toCreateSelection"
    >
      <div class="field search-field">
        <label for="search-selection">{{ $t('selections.search') | capitalize }}:</label>
        <input
          id="search-selection"
          type="text"
          name="id"
          style="width: 65%"
          ref="search"
          :class="['module-color-border']"
          v-model="searchInput"
          @input="typing"
        />
        <span class="search-icon" @click="searchSelectionByName">
        <font-awesome-icon icon="search" />
      </span>
      </div>
      <div class="map-object-selection-list">
        <Loading :visible="loadSelections" />
        <CollapsePanelBigLink
          v-for="(selection, index) in selections"
          :key="index" :title="selection.name" :type="chosenSelectionType.name"
          :warning="selection.status === 'broken'"
          :loading="chosenSelection === selection.id"
          @clickItem="chooseSelection(selection.id)" />

        <Pagination
          v-if="!showSearchResults"
          @switchPage="switchPage"
          :total="pagination.total"
          :count="pagination.count"
          :per_page="pagination.per_page"
          :current_page="pagination.current_page"
          :total_pages="pagination.total_pages"  />
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MapObjectSelectionApi from '@/api/map_object_selection';
import MapObjectSelectionTypeApi from '@/api/map_object_selection_type';

import BreadcrumbSelectionType from '@/modules/selections/components/BreadcrumbSelectionType.vue';

import CollapsePanel from '@/components/CollapsePanel.vue';
import CollapsePanelBigLink from '@/components/CollapsePanelBigLink.vue';
import Pagination from '@/components/Pagination.vue';
import Loading from '@/components/Loading.vue';
import EventBus from '@/events/event-bus';

export default {
  name: 'selections.list',
  components: {
    BreadcrumbSelectionType,
    Loading,
    CollapsePanelBigLink,
    CollapsePanel,
    Pagination,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      selectionTypes: 'settings/getMapObjectSelectionTypes',
    }),
    chosenSelectionType() {
      if (this.$route.params.type !== null && this.$route.params.type !== undefined) {
        const selectedType = parseInt(this.$route.params.type, 10);
        return this.selectionTypes[selectedType];
      }
      return {};
    },
  },
  data() {
    return {
      loading: false,
      loadSelections: false,
      selections: [],
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1,
      },
      chosenSelection: null,
      showSearchResults: false,
      active: false,
      searchInput: '',
      searchType: 'name',
      operator: 'ILIKE',
      timer: null,
      interval: 300,
    };
  },
  methods: {
    ...mapActions({
      fetchSelection: 'selected/fetchSelection',
    }),
    goBack() {
      this.$router.push({ name: 'selection' });
    },
    chooseSelection(selectionId) {
      if (!this.$can('show', 'mapobjectselection')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectselection', 1) }),
          text: this.$t('error.no_access_show', { model: this.$tc('models.mapobjectselection', 1) }),
        });
        this.chosenSelectionType = null;
        return;
      }
      this.chosenSelection = selectionId;
      this.$router.push({ name: 'selection.show', params: { id: selectionId } });
    },
    toCreateSelection() {
      if (!this.$can('create', 'mapobjectselection')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectselection', 0) }),
          text: this.$t('error.no_access_create', { model: this.$tc('models.mapobjectselection', 0) }),
        });
        this.chosenSelectionType = null;
        return;
      }
      this.$router.push({ name: 'selection.create', params: { type: this.$route.params.type } });
    },
    getSelections() {
      if (!this.$route.params.type) {
        return;
      }
      this.loading = true;
      MapObjectSelectionTypeApi.getMapObjectSelectionsForSelectionType(this.$route.params.type, '', 15, this.current_page)
        .then((response) => {
          this.selections = response.data.sort(
            (a, b) => (a.name > b.name ? 1 : -1),
          );
          this.pagination = response.meta.pagination;
          this.loading = false;
        });
    },
    switchPage(page) {
      this.current_page = page;
      this.getSelections();
    },
    selectSearchType() {
      this.$nextTick(() => this.$refs.search.focus());
      this.typing();
    },
    typing() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.searchSelectionByName, this.interval);
    },
    searchSelectionByName() {
      this.showSearchResults = this.searchInput.length > 0;
      if (!this.showSearchResults) {
        this.getSelections();
        return;
      }
      let input = this.searchInput;
      input = input.replace(/[^A-Za-z0-9]/gi, '').split('').join('%');
      input = `%${input}%`;

      let query = {};
      query = {
        name: input,
        map_object_selection_type_id: this.$route.params.type,
      };
      this.findSelection(query);
    },
    findSelection(query) {
      this.loading = true;
      const promise = MapObjectSelectionApi.findMapObjectSelection(
        { data: { query, operator: this.operator, limit: 10000 } },
      );
      this.lastPromise = promise;
      return new Promise(() => {
        promise.then((response) => {
          if (promise === this.lastPromise) {
            this.lastPromise = null;
            this.selections = response.data.sort(
              (a, b) => (a.name > b.name ? 1 : -1),
            );
            this.pagination.total = response.data.length;
            this.loading = false;
          }
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectselection', 0) }),
            data: error.response,
          });
          this.loading = false;
        });
      });
    },
  },
  mounted() {
    this.getSelections();
    EventBus.$emit('Map.changeMapMode', null);
  },
  watch: {
    active() {
      if (this.active) {
        this.$nextTick(() => this.$refs.search.focus());
      }
    },
  },
};
</script>

<style scoped>
.map-object-selection-list {
  margin: -10px 0 0 0;
}
.search-field {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #cfd4da;
}
.search-field label {
  width: 25%;
  padding-left: 20px;
}
.search-field .search-icon {
  width: 40px;
  height: 34px;
  transition: all 0.3s ease;
  position: absolute;
  padding: 7px 10px;
  top: 42%;
  transform: translateY(-50%);
  opacity: .8;
}
.search-field .search-icon:hover {
  box-shadow: 0 0 100px 100px rgba(255, 255, 255, 0.1);
  opacity: 1;
  cursor: pointer;
}
</style>
