<template>
  <ProfileModal
    title="Delete User"
    :visible="visible"
    @closeModal="closeModal"
    :loading="false"
  >
    <template v-slot:body>
        <div class="block">
          <h3>{{ $t('drawing.warning') | capitalize }}</h3>
          <p>
            {{ $t('profile.warning_text') | capitalize }}
          </p>
        </div>
    </template>

    <template v-slot:footer>
      <div class="footer-content">
        <a class="btn grey"  @click="closeModal">
          {{ $t('actionlog.cancel') | capitalize }}
        </a>
        <a
          @click="saveChange()"
          :class="['btn',
            'module-color-background',
          ]"
        >
          {{ $t('actionlog.delete') | capitalize }}
        </a>
      </div>
    </template>
  </ProfileModal>
</template>

<script>

import ProfileModal from '@/modules/profile/components/ProfileModal.vue';
import UserApi from '@/api/user';

export default {
  name: 'drawing.deleteUser',
  components: {
    ProfileModal,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    user: Object,
  },
  data() {
    return {
      data: String,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    saveChange() {
      UserApi.deleteUser(this.user.id)
        .then(() => {
          this.$notify({
            type: 'success',
            title: this.$t('profile.userdeleted'),
          });
        });
      this.$router.push({ name: 'logout' });
    },
  },
};
</script>

<style scoped>
.block{
  padding: 20px;
  padding-bottom: 50px;
}
</style>
