import axios from '@/plugins/axios';

export default {
  getDynamicActionTypes() {
    return axios.get('/v1/dynamic_action_type?perPage=9999')
      .then(response => response.data);
  },
  getDynamicActionTypeById(id, include = '') {
    return axios.get(`/v1/dynamic_action_type/${id}?include=${include}`)
      .then(response => response.data);
  },
  updateDynamicActionTypeById(id, data) {
    return axios.put(`/v1/dynamic_action_type/${id}`, data)
      .then(response => response.data);
  },
  deleteDynamicActionTypeById(id) {
    return axios.delete(`/v1/dynamic_action_type/${id}`)
      .then(response => response.data);
  },
  createDynamicActionType(data) {
    return axios.post('/v1/dynamic_action_type/', data)
      .then(response => response.data);
  },
  getDynamicActionTypeAttributes(id) {
    return axios.get(`/v1/dynamic_action_type/${id}/attribute?perPage=9999`)
      .then(response => response.data);
  },
  addDynamicActionTypeAttributes(id, data) {
    return axios.post(`/v1/dynamic_action_type/${id}/attribute`, data)
      .then(response => response.data);
  },
  deleteDynamicActionTypeAttributes(id, data) {
    return axios.delete(`/v1/dynamic_action_type/${id}/attribute`, data)
      .then(response => response.data);
  },
  getDynamicActionTypeMapObjectTypes(id) {
    return axios.get(`/v1/dynamic_action_type/${id}/map_object_type?perPage=9999`)
      .then(response => response.data);
  },
  addDynamicActionTypeMapObjectTypes(id, data) {
    return axios.post(`/v1/dynamic_action_type/${id}/map_object_type`, data)
      .then(response => response.data);
  },
  deleteDynamicActionTypeMapObjectTypes(id, data) {
    return axios.delete(`/v1/dynamic_action_type/${id}/map_object_type`, data)
      .then(response => response.data);
  },
};
