<template>
  <div></div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShowSelection',
  props: {
    map: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      selection: 'selected/getSelection',
      selectionId: 'selected/getSelectionId',
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
  },
  methods: {
    ...mapActions({
      setSelected: 'map/setSelected',
      resetSelected: 'map/resetSelected',
    }),
    addSelectionToMap() {
      console.log('ShowSelection.addSelectionToMap()', this.selection);
      if (!this.selection) {
        return;
      }
      this.setSelected({ mapObjects: this.selection.mapObject.data });
    },
    resetDraw() {
      this.resetSelected();
    },
  },
  mounted() {
    this.map.off('click');
    this.addSelectionToMap();
  },
  beforeDestroy() {
    this.resetDraw();
  },
  watch: {
    selectionId() {
      this.addSelectionToMap();
    },
  },
};
</script>

<style scoped>

</style>
