<template>
  <div class="edit-object-buttons">
    <a class="btn grey" @click="clickBack">
      {{ $t('button.cancel') | capitalize }}
    </a>
    <a :class="{'btn': true, 'save-button': true, 'disabled': disabled}" @click="saveChanges">
      {{ (saveText ? saveText : this.$t('button.save_changes')) | capitalize }}
    </a>
  </div>
</template>

<script>

export default {
  name: 'ButtonRow',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    saveText: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    clickBack() {
      this.$emit('back');
    },
    saveChanges() {
      if (!this.disabled) {
        this.$emit('save');
      }
    },
  },
};
</script>

<style scoped>
.edit-object-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}

.edit-object-buttons .save-button {
  float: right;
  margin-right: 7px;
}
</style>
