<template>
  <CollapsePanel
    :title="$t('actionlog.action_log')"
    :loading=loading
    :count="pagination.total"
    :showCount=true
    :showCollapseAction="$can('create', 'action') && mapObject !== null"
    @collapseAction="showActionTypeToCreateModal"
  >
    <Tabs>
      <TabPane @click="goToActionsTab"
               :title="$t('actionlog.unresolved') | capitalize"
               :active="current_tab === 'action'"
      />
      <TabPane @click="goToResolvedActionsTab"
               :title="$t('actionlog.resolved') | capitalize"
               :active="current_tab === 'resolved_action'"
      />
      <TabPane @click="goToRecurringActionsTab"
               :title="$t('actionlog.recurring') | capitalize"
               :active="current_tab === 'recurring_action'"
      />
    </Tabs>
    <div class="actionlog-top buttons">
      <a class="button btn-grey" @click="toggleSort">

        <font-awesome-icon v-if="sortBy === 'desc'" icon="sort-amount-down" />
        <font-awesome-icon v-else-if="sortBy === 'asc'" icon="sort-amount-up-alt" />
        {{ $t('actionlog.sort') | capitalize }}
      </a>
      <ActionSort
        :show="showSort"
        @performSort="handleSort"
      />
      <a class="button btn-grey" @click="toggleFilter">
        <font-awesome-icon icon="filter" />
        {{ $t('actionlog.filter') | capitalize }}
        <span :class="{'right-count': true, 'filter': true, 'none': filtersActive <= 0}">
          {{ filtersActive }}
        </span>
      </a>
      <ActionFilter :network_id="parseInt(networkId,10)"
                    :show="showFilter"
                    @performFilter="handleFilter"
      />
    </div>
    <div class="no-results" v-if="actions.length === 0">
      <p>{{ $t('model.no_results') | capitalize }}.</p>
    </div>
    <ActionLogItem v-for="(action, index) in actions" v-bind:key="index"
      @showAction="processAction"
      :action="action"
    />
    <Pagination
      @switchPage="switchPage"
      :total="pagination.total"
      :count="pagination.count"
      :per_page="pagination.per_page"
      :current_page="pagination.current_page"
      :total_pages="pagination.total_pages"  />

    <SelectActionTypeToCreateModal
      :visible="showSelectActionTypeToCreateModal"
      :map_object_id="mapObject ? mapObject.id : null"
      :mapObject="mapObject"
      :ownGeo="ownGeo"
      @closeModal="closeSelectActionTypeToCreateModal"
      @createActionForActionType="createActionForActionType"
    />
    <CreateActionModal
      v-if="dynamicActionTypeId"
      :visible="showCreateActionModal"
      :latlng="latlng"
      :dynamic_action_type_id="dynamicActionTypeId"
      :map-object="mapObject"
      @closeModal="closeCreateActionModal"
      @updateActionLog="retrieveActions"
      @goToProcessAction="goToProcessAction"
    />
    <ProcessActionModal
      :visible="showProcessActionModal"
      :action_id="selected_action"
      @closeModal="closeProcessActionModal"
      @updateActionLog="retrieveActions"
    />
    <RecurringActionModal
      :visible="showRecurringActionModal"
      :recurring_action_id="selected_recurring_action"
      @closeModal="closeRecurringActionModal"
      @updateActionLog="retrieveActions"
    />
  </CollapsePanel>
</template>

<script>
import { mapGetters } from 'vuex';

import mapObjectApi from '@/api/map_object';
import networkApi from '@/api/network';
import CollapsePanel from '@/components/CollapsePanel.vue';
import Pagination from '@/components/Pagination.vue';
import TabPane from '@/components/TabPane.vue';
import Tabs from '@/components/Tabs.vue';
import ActionFilter from './ActionFilter.vue';
import ActionLogItem from './ActionLogItem.vue';
import ActionSort from './ActionSort.vue';
import CreateActionModal from './CreateActionModal.vue';
import ProcessActionModal from './ProcessActionModal.vue';
import RecurringActionModal from './RecurringActionModal.vue';
import SelectActionTypeToCreateModal from './SelectActionTypeToCreateModal.vue';
import EventBus from '@/events/event-bus';

export default {
  name: 'ActionLog',
  components: {
    CollapsePanel,
    Pagination,
    TabPane,
    Tabs,
    ActionFilter,
    ActionLogItem,
    ActionSort,
    CreateActionModal,
    ProcessActionModal,
    RecurringActionModal,
    SelectActionTypeToCreateModal,
  },
  props: {
    mapObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      actions: [],
      action_page: 1,
      pagination: {
        total: 0,
        count: 0,
        per_page: 1,
        current_page: 1,
        total_pages: 1,
      },
      selected_action: 0,
      selected_recurring_action: 0,
      current_tab: 'action',
      action_includes: 'user,mapObject,mapObject.mapObjectType,mapObject.mapObjectLabel,geoObject,dynamicActionType,dynamicActionType.dynamicActionTypeCategory,actionPriority,actionStatus',
      showSort: false,
      showFilter: false,
      sort: 'created_at',
      sortBy: 'desc',
      filter: '',
      filtersActive: 0,
      onlyNotFinal: true,
      showSelectActionTypeToCreateModal: false,
      showCreateActionModal: false,
      dynamicActionTypeId: null,
      showProcessActionModal: false,
      showRecurringActionModal: false,
      latlng: null,
      ownGeo: false,
    };
  },
  computed: {
    ...mapGetters({
      networkId: 'switcher/getNetworkId',
      actionStatuses: 'settings/getActionStatuses',
    }),
  },
  methods: {
    goToProcessAction(actionId) {
      setTimeout(() => this.processAction(actionId), 1000);
    },
    closeSelectActionTypeToCreateModal() {
      this.showSelectActionTypeToCreateModal = false;
      this.dynamicActionTypeId = null;
      EventBus.$emit('RouteManagement.closeCreateActionOnMap');
    },
    closeCreateActionModal() {
      this.showCreateActionModal = false;
      this.dynamicActionTypeId = null;
      EventBus.$emit('RouteManagement.closeCreateActionOnMap');
    },
    closeProcessActionModal() {
      this.selected_action = 0;
      this.showProcessActionModal = false;
    },
    closeRecurringActionModal() {
      this.selected_recurring_action = 0;
      this.showRecurringActionModal = false;
    },
    showActionTypeToCreateModal() {
      this.ownGeo = false;
      this.showSelectActionTypeToCreateModal = true;
    },
    createActionForActionType(dynamicActionTypeId, latlng = null) {
      this.showCreateActionModal = true;
      this.dynamicActionTypeId = dynamicActionTypeId;
      if (latlng) {
        this.latlng = latlng;
      }
    },
    processAction(actionId) {
      if (this.current_tab === 'recurring_action') {
        this.selected_recurring_action = actionId;
        this.showRecurringActionModal = true;
      } else {
        this.selected_action = actionId;
        this.showProcessActionModal = true;
      }
    },
    handleSort(sort, sortBy) {
      this.sort = sort;
      this.sortBy = sortBy;
      this.toggleSort();
      this.retrieveActions();
    },
    handleFilter(filter) {
      this.filter = filter;
      this.filtersActive = filter.split('&').length - 1;
      this.onlyNotFinal = !(this.filtersActive > 0 && filter.includes('action_status_id'));
      this.toggleFilter();
      this.retrieveActions();
    },
    toggleSort() {
      this.showSort = !this.showSort;
      this.showFilter = false;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
      this.showSort = false;
    },
    switchPage(page) {
      this.action_page = page;
      this.retrieveActions();
    },
    goToActionsTab() {
      this.current_tab = 'action';
      this.action_page = 1;
      this.retrieveActions();
    },
    goToResolvedActionsTab() {
      this.current_tab = 'resolved_action';
      this.action_page = 1;
      this.retrieveActions();
    },
    goToRecurringActionsTab() {
      this.current_tab = 'recurring_action';
      this.action_page = 1;
      this.retrieveActions();
    },
    retrieveActions() {
      if (this.current_tab === 'action') {
        this.getActions();
      }
      if (this.current_tab === 'resolved_action') {
        this.getResolvedActions();
      }
      if (this.current_tab === 'recurring_action') {
        this.getRecurringActions();
      }
    },
    getActions() {
      this.loading = true;
      if (!this.mapObject) {
        networkApi.getActions(
          this.networkId, this.action_includes, this.filter, this.action_page,
          this.sort, this.sortBy, this.onlyNotFinal,
        ).then((response) => {
          this.handleActionResponse(response);
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.action', 0) }),
            data: error.response,
          });
          this.loading = false;
        });
      } else {
        mapObjectApi.getActions(
          this.mapObject.id, this.action_includes, this.filter, this.action_page,
          this.sort, this.sortBy, this.onlyNotFinal, true,
        ).then((response) => {
          this.handleActionResponse(response);
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.action', 0) }),
            data: error.response,
          });
          this.loading = false;
        });
      }
    },
    getResolvedActions() {
      this.loading = true;
      const actionStatusIds = Object.values(this.actionStatuses)
        .filter(actionStatus => actionStatus.is_final)
        .map(actionStatus => actionStatus.id);

      const finalizedActions = `${this.filter}&action_status_id=(${actionStatusIds.join(',')})`;

      if (!this.mapObject) {
        networkApi.getActions(
          this.networkId, this.action_includes, finalizedActions, this.action_page,
          this.sort, this.sortBy, false,
        ).then((response) => {
          this.handleActionResponse(response);
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.action', 0) }),
            data: error.response,
          });
          this.loading = false;
        });
      } else {
        mapObjectApi.getActions(
          this.mapObject.id, this.action_includes, finalizedActions, this.action_page,
          this.sort, this.sortBy, false,
        ).then((response) => {
          this.handleActionResponse(response);
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.action', 0) }),
            data: error.response,
          });
          this.loading = false;
        });
      }
    },
    getRecurringActions() {
      this.loading = true;
      if (!this.mapObject) {
        networkApi.getRecurringActions(
          this.networkId, this.action_includes, this.filter, this.action_page,
          this.sort, this.sortBy, this.onlyNotFinal,
        ).then((response) => {
          this.handleActionResponse(response);
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.recurring_action', 0) }),
            data: error.response,
          });
          this.loading = false;
        });
      } else {
        mapObjectApi.getRecurringActions(
          this.mapObject.id, this.action_includes, this.filter, this.action_page,
          this.sort, this.sortBy, this.onlyNotFinal, true,
        ).then((response) => {
          this.handleActionResponse(response);
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.recurring_action', 0) }),
            data: error.response,
          });
          this.loading = false;
        });
      }
    },
    handleActionResponse(response) {
      this.actions = response.data;
      this.pagination = response.meta.pagination;
      this.loading = false;
    },
    handleEvent(event) {
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data.origin !== 'portaal' || (
        event.data.type !== 'create_action' && event.data.type !== 'show_action'
      )) {
        return;
      }
      // eslint-disable-next-line no-console
      console.log('Vue handleEvent', event);
      if (event.data.type === 'create_action') {
        if (!event.data
          || !event.data.model
          || !event.data.id
          || !event.data.origin
          || !event.data.type
          || !event.data.dynamic_action_type_id
          || !event.data.lat
          || !event.data.lng) {
          return;
        }
        this.createActionForActionType(
          event.data.dynamic_action_type_id,
          {
            lat: event.data.lat,
            lng: event.data.lng,
          },
        );
      } else if (event.data.type === 'show_action') {
        if (!event.data
          || !event.data.model
          || !event.data.id
          || !event.data.origin
          || !event.data.type) {
          return;
        }
        this.selected_action = event.data.id;
        this.current_tab = 'action';
        this.showProcessActionModal = true;
      }
    },
  },
  mounted() {
    this.goToActionsTab();
    EventBus.$on('Map.clickAction', (id) => {
      this.showProcessActionModal = false;
      this.selected_action = id;
      this.showProcessActionModal = true;
    });
    EventBus.$on('RouteManagement.createActionOnMap', (latlng) => {
      console.log('ActionLog RouteManagement.createActionOnMap()', latlng);
      this.showProcessActionModal = false;
      this.ownGeo = true;
      this.showSelectActionTypeToCreateModal = true;
      this.latlng = latlng;
    });
  },
  watch: {
    mapObject() {
      this.goToActionsTab();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  body {
    font-size: 17px;
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #42464c;
    background: #d8e0e7;
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  a.btn {
    background: var(--module-color);
    border-radius: 555px;
    padding: 0 12px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    text-decoration: none;
    float: left;
    margin-left: 7px;
    font-weight: 300;
    letter-spacing: .1em;
    font-size: .7em;
    text-transform: uppercase;
    display: inline-block;
    outline: 0;
  }

  a.btn:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }

  .btn.grey {
    background: #cfcfcf;
    color: #000;
  }

  a.btn.btn-icon {
    width: 30px;
    padding: 0;
    text-align: center;
  }

  a.btn.btn-icon span.icon {
     line-height: 30px;
     font-size: .85em;
  }

  div.actionlog-top-foldout {
    position: absolute;
    left: 10px;
    z-index: 10;
    top: 42px;
    display: none;
    width: 365px;
    background-color: #ffffff;
  }

  div.actionlog-top-foldout.active {
    display: block;
  }
  div.actionlog-top-foldout .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    top: -8px;
  }
  div.actionlog-top-foldout .arrow.sort {
    left: 80px;
  }
  div.actionlog-top-foldout .arrow.filter {
    right: 80px;
  }
  div.actionlog-top-foldout .content {
    box-shadow: 0 8px 20px 8px rgba(0,0,0,0.6);
    border-radius: 10px;
    text-align: left;
    padding: 10px;
  }
  input[type="text"].multiselect__input {
    border: 0;
  }
</style>
<style scoped>
  * {
    font-size: 17px;
    font-family: "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    color: #42464c;
  }
  @media screen and (max-width: 1300px) {
    * {
      font-size: 16px; } }

  @media screen and (max-width: 1200px) {
    * {
      font-size: 15px; } }

  @media screen and (max-width: 1100px) {
    * {
      font-size: 14px; } }

  @media screen and (max-width: 1023px) {
    * {
      font-size: 13px; } }

  .buttons {
    border-bottom: 1px solid #d9dadb;
    position: relative;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }
  .buttons .button {
    float: left;
    margin: 0 5px ;
    text-decoration: none;
    display: block;
    letter-spacing: .01em;
    font-size: 1.1em;
    font-family: "Abel", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #44a7dc;
    color: #fff;
    border-radius: 5px;
    line-height: 40px;
    width: 45%;
  }

  .buttons .button:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }

  .buttons .button.btn-grey {
    background: #cfcfcf;
    color: #000;
  }
  span.right-count.filter {
    display: inline;
  }
  .icon.active {
    color: #a4cd4b;
  }
  .no-results {
    padding: 20px;
  }
</style>
