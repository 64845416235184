<template>
  <ActionModal
    :title="$tc('models.action', 1)+' '+action_id | capitalize"
    :action_type_name="dynamicActionType.name"
    :visible="visible"
    @closeModal="closeModal"
    :loading="actionLoading || mapObjectLoading || mapObjectTypeAttributesLoading
     || actionStatusLoading || mapObjectStatusLoading || loading"
  >
    <template v-slot:left-content>
      <ActionModalMapObjectInformation
        v-if="mapObject"
        :map_object="mapObject"
      />

      <AttributesInputView
        v-if="!isCreatesObject
        && filteredAttributes.length > 0"
        :title="$t('actionlog.action_details')"
        :attributes="filteredAttributes"
        :disabled="showOnly"
      />

      <div
        v-else-if="isCreatesObject"
        class="object-attribute-wrapper"
      >
        <h2>{{ $t('actionlog.suggested_object_details') }}</h2>
        <div v-if="isCreatesObject">
          <span class="title">{{ $tc('models.type', 1) | capitalize }}:</span>
          <div class="field content">
            {{ mapObjectTypes[action.map_object_type_id].name }}
          </div>
        </div>
        <div v-if="mapObject && mapObject.name">
          <span class="title">{{ $t('model.name') | capitalize }}:</span>
          <div class="field content">
            <input type="text" v-model="selectedMapObjectName" />
          </div>
        </div>
        <div v-if="Object.values(mapObjectStatuses).length > 0">
          <span class="title">{{ $tc('models.status', 1) | capitalize }}:</span>
          <div class="field content">
            <MultiSelect
              :options="Object.values(mapObjectStatuses)"
              v-model="selectedMapObjectStatus"
              track-by="id"
              label="name"
              :multiple="false"
              :close-on-select="true"
              :allow-empty="false"
              :preselect-first="true"
              placeholder=""
              selectLabel=""
              selectedLabel=""
              deselectLabel=""
            />
          </div>
        </div>
        <div v-for="attribute in filteredAttributes" :key="attribute.id">
          <span class="title">{{ attribute.name }}:</span>
          <div class="field content">
            <AttributeInput :disabled="false" :attribute="attribute" />
          </div>
        </div>
      </div>

      <div class="action-image-types" v-if="actionImageTypes">
        <div
          v-for="actionImageType in actionImageTypes"
          :key="actionImageType.id"
          class="images-field"
        >
          <h2 class="action-type-header">{{ actionImageType.name }}</h2>
          <div class="field">
            <ImagePanel
              :action_id="action_id"
              :action_image_type_id="actionImageType.id"
              :action_images="actionImages"
              :selectable="copyImages"
              :show_only="showOnly"
              :external_loading="actionImageType.loading"
              @addImage="onImageAdd"
              @deleteImage="onImageDelete"
            />
          </div>
        </div>
      </div>

      <div v-if="!showOnly">
        <h2>{{ $t('actionlog.extra_options') }}</h2>
        <div v-if="action.map_object_id">
          <input @change="changeCopyImages" type="checkbox" :checked="copyImages"/>
          {{ $t('actionlog.copy_images_checkbox') }}
          <div v-if="copyImages" class="field">
                <span class="copy-images-text">
                  {{ $t('actionlog.copy_images_description') }}
                </span>
          </div>
        </div>
        <div>
          <input @change="changeRecurring" type="checkbox" :checked="createRecurring"/>
          {{ $t('actionlog.recurring_actions_checkbox') }}
          <div v-if="createRecurring" class="field">
            <label for="date_interval">{{ $t('model.interval') |capitalize }}</label>
            <select id="date_interval" name="date_interval" v-model="dateInterval">
              <option
                v-for="(title, key) in dateIntervals"
                :key="key"
                :value="key"
              >
                {{ $t('interval.'+title) | capitalize }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:right-content>
      {{ $tc('models.status', 1) | capitalize }}:
      <div class="field">
        <ActionStatusSelect
          v-if="action.action_status_id"
          v-model="action.action_status_id"
          :action_statuses="Object.values(actionStatuses)"
        />
      </div>
      {{ $tc('models.priority', 1) | capitalize }}:
      <ActionPriorityButtons
        v-if="action.action_priority_id"
        v-model="action.action_priority_id"
        :disabled="showOnly"
      />
      <ActionDetails
        :action="action"
        :hasLockingKV="hasLockingKV"
        :actionLocked="actionLocked"
      />
      <hr/>
      <div v-if="action.comment && action.comment.data">
        <h2>
          {{ $tc('models.comment', 0) | capitalize }}
        </h2>
        <ActionComment
          v-for="comment in action.comment.data"
          :comment="comment"
          :key="comment.id"
          @deleteComment="deleteComment"
        />
      </div>
      <div v-else-if="!showOnly">
        <h2>
          {{ $t('actionlog.add_comment') | capitalize }}
        </h2>
      </div>
      <div v-if="!showOnly" class="field">
            <textarea
              v-model="comment"
              name="comment"
              :placeholder="$t('actionlog.add_comment_placeholder')"
            >
            </textarea>
      </div>
    </template>

    <template v-slot:footer>
      <div class="footer-left-content">
        <a
          @click="$can('delete', 'action') && !actionLocked && deleteAction()"
          :title="!$can('delete', 'action') ? $t('error.no_access_button') : actionLocked ?
          $t('error.only_owner') : ''"
          :class="['btn', 'cancel', {
            'disabled': !$can('delete', 'action') || actionLocked
          }]"
        >
          {{ $t('actionlog.delete') | capitalize }}
        </a>
      </div>
      <div class="footer-content">
        <a v-if="reopen()" @click="reopenAction" class="btn module-color-background">
          {{ $t('actionlog.reopen') | capitalize }}
        </a>
        <a v-if="showOnly" @click="closeModal" class="btn grey">
          {{ $t('actionlog.close') | capitalize }}
        </a>
        <a v-else @click="closeModal" class="btn grey">
          {{ $t('actionlog.cancel') | capitalize }}
        </a>
        <a
          v-if="!showOnly"
          @click="$can('update', 'action') && !actionLocked && processAction()"
          :title="!$can('update', 'action') ? $t('error.no_access_button') : actionLocked ?
           $t('error.only_owner') : ''"
          :class="['btn',
            {'module-color-background': $can('update', 'action') && !actionLocked},
            {'disabled': !$can('update', 'action') || actionLocked}
          ]"
        >
          {{ $t('actionlog.save_changes') | capitalize }}
        </a>
      </div>
    </template>
  </ActionModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MultiSelect from 'vue-multiselect';

import EventBus from '@/events/event-bus';

import ActionApi from '@/api/action';
import MapObjectApi from '@/api/map_object';
import MapObjectTypeApi from '@/api/map_object_type';
import RecurringActionApi from '@/api/recurring_action';
import KeyValueApi from '@/api/key_value';
import TeamApi from '@/api/team';
import userApi from '@/api/user';

import DateInterval from '@/enums/DateInterval';

import AttributeInput from '@/components/AttributeInput.vue';
import ImagePanel from '@/components/ImagePanel.vue';
import ActionComment from './ActionComment.vue';
import ActionDetails from './ActionDetails.vue';
import ActionModal from './ActionModal.vue';
import ActionPriorityButtons from './ActionPriorityButtons.vue';
import ActionStatusSelect from './ActionStatusSelect.vue';
import AttributesInputView from './AttributesInputView.vue';
import ActionModalMapObjectInformation from './ActionModalMapObjectInformation.vue';

export default {
  name: 'ProcessActionModal',
  components: {
    MultiSelect,
    AttributeInput,
    ImagePanel,
    ActionComment,
    ActionDetails,
    ActionModal,
    ActionPriorityButtons,
    ActionStatusSelect,
    AttributesInputView,
    ActionModalMapObjectInformation,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    action_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      action: {},
      comments: [],
      comment: '',
      mapObject: null,
      loading: false,
      actionLoading: false,
      mapObjectLoading: false,
      mapObjectTypeAttributesLoading: false,
      actionStatusLoading: false,
      mapObjectStatusLoading: false,
      imageLoading: false,
      selectedMapObjectStatus: null,
      selectedMapObjectName: '',
      mapObjectTypeAttributes: [],
      actionImageTypes: [],
      dynamicActionType: {},
      actionImages: [],
      showOnly: false,
      showObjectAttributeBox: false,
      createRecurring: false,
      copyImages: false,
      goToProcess: false,
      selfLoading: false,
      dateInterval: 'P1D',
      dateIntervals: DateInterval,
      isChangeRequest: false,
      isCreatesObject: false,
      isDeletesObject: false,
      requiredcheck: true,
      hasLockingKV: false,
      actionLocked: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      networkId: 'switcher/getNetworkId',
      tenantId: 'switcher/getTenantId',
      actionStatuses: 'settings/getActionStatuses',
      mapObjectStatuses: 'settings/getMapObjectStatuses',
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
    images() {
      if (!this.action.actionImage || !this.action.actionImage.data) {
        return [];
      }
      return this.action.actionImage.data;
    },
    selectedStatus() {
      if (Object.values(this.actionStatuses).length === 0) {
        return {};
      }
      return this.actionStatuses[this.action.action_status_id];
    },
    filteredAttributes() {
      let attributes = {};
      if (this.action && this.action.attribute && this.action.attribute.data) {
        attributes = this.action.attribute.data;

        if (this.isChangeRequest && !this.isCreatesObject && !this.isDeletesObject
          && this.mapObject && this.mapObject.attribute && this.mapObject.attribute.data) {
          const mapObjectAttributes = this.mapObject.attribute.data.map(attribute => attribute.id);
          attributes = attributes.filter(
            attribute => (mapObjectAttributes.indexOf(attribute.id) > -1),
          );
        }
      }

      return attributes;
    },
  },
  methods: {
    ...mapActions({
      updateAction: 'settings/updateAction',
      removeAction: 'settings/removeAction',
    }),
    closeModal() {
      this.$emit('closeModal');
    },
    updateActionLog() {
      this.$emit('updateActionLog');
    },
    setImageLoading(typeId, loading) {
      this.actionImageTypes.forEach((imageType, index) => {
        if (parseInt(imageType.id, 10) === parseInt(typeId, 10)) {
          this.$set(this.actionImageTypes[index], 'loading', loading);
        }
      });
    },
    onImageAdd(actionImageTypeId, file) {
      this.setImageLoading(actionImageTypeId, true);
      this.imageLoading = true;
      const formData = new FormData();
      formData.append('image', file.file);
      formData.append('action_image_type_id', actionImageTypeId);
      // eslint-disable-next-line no-await-in-loop
      ActionApi.createImageForAction(this.action_id, formData)
        .then((response) => {
          this.actionImages[actionImageTypeId].push(response.data);
          this.imageLoading = false;
          this.setImageLoading(actionImageTypeId, false);
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.create_failed', { model: this.$tc('models.actionimage', 1) }),
            data: error.response,
          });
          this.loading = false;
        });
    },
    onImageDelete(actionImageTypeId, index) {
      this.setImageLoading(actionImageTypeId, true);
      this.imageLoading = true;
      const image = this.actionImages[actionImageTypeId].splice(index, 1);
      ActionApi.deleteImageForAction(this.action_id, image[0].id).then(() => {
        this.$set(this.actionImages, actionImageTypeId, this.actionImages[actionImageTypeId]);
        this.setImageLoading(actionImageTypeId, false);
        this.imageLoading = false;
      }).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.delete_failed', { model: this.$tc('models.actionimage', 1) }),
          data: error.response,
        });
        this.loading = false;
      });
    },
    changeRecurring() {
      this.createRecurring = !this.createRecurring;
    },
    changeCopyImages() {
      this.copyImages = !this.copyImages;
    },
    reopen() {
      if (!this.showOnly) {
        return false;
      }
      if (this.isCreatesObject) {
        return false;
      }
      return true;
    },
    deleteAction() {
      if (!this.$can('delete', 'action')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.action', 1) }),
          text: this.$t('error.no_access_delete', { model: this.$tc('models.action', 1) }),
        });
        return;
      }
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_action_confirm'))) {
        this.loading = true;
        ActionApi.deleteActionById(this.action_id)
          .then(() => {
            this.removeAction({ actionId: this.action_id });
            this.finishUp();
          }).catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.delete_failed', { model: this.$tc('models.action', 1) }),
              data: error.response,
            });
            this.loading = false;
          });
      }
    },
    reopenAction() {
      if (!this.selectedStatus.is_final) {
        this.processAction();
      } else {
        this.$notify({
          type: 'error',
          title: this.$t('error.update_failed', { model: this.$tc('models.action', 1) }),
          text: this.$t('actionlog.reopenactionmsg'),
        });
      }
    },
    async processAction() {
      if (!this.$can('update', 'action')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.action', 1) }),
          text: this.$t('error.no_access_update', { model: this.$tc('models.action', 1) }),
        });
        return;
      }
      this.loading = true;

      if (this.copyImages) {
        const allImages = this.actionImages.flat();
        await this.copyImagesToMapObject(allImages);
      }

      if (this.comment.length > 0) {
        this.createComment(this.action_id);
      }
      if (this.createRecurring) {
        this.createRecurringAction(this.action_id);
      }
      const data = {
        data: Object.assign({}, this.action),
      };
      this.$delete(data.data, 'device');

      const attributesdata = data.data.attribute.data;
      attributesdata.forEach((item) => {
        if (item.required && !item.value) {
          this.requiredcheck = false;
        }
      });
      if (this.requiredcheck) {
        ActionApi.updateActionById(this.action_id, data)
          .then((response) => {
            this.updateAction({ action: response.data });
            if (this.selectedStatus.is_final === true) {
              if (!this.mapObject) {
                EventBus.$emit('Map.removeActionFromMap', this.action_id);
              }
              if (this.isCreatesObject) {
                this.handleCreatesObject();
              } else if (this.isDeletesObject) {
                this.handleDeletesObject();
              } else if (this.isChangeRequest) {
                this.handleChangeRequest();
              } else {
                this.finishUp();
              }
            } else {
              this.finishUp();
            }
          }).catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.update_failed', { model: this.$tc('models.action', 1) }),
              data: error.response,
            });
            this.loading = false;
          });
      } else {
        this.$notify({
          type: 'error',
          title: this.$t('error.update_failed', { model: this.$tc('models.action', 1) }),
          text: this.$t('error.required_fields_not_completed'),
        });
        this.loading = false;
        this.requiredcheck = true;
      }
    },
    finishUp() {
      this.loading = false;
      this.$emit('closeModal');
      this.$emit('updateActionLog');
    },
    copyImagesToMapObject(images) {
      if (this.selectedStatus.is_final) {
        const mapObjectId = this.action.map_object_id;
        const selectedImages = images.filter(image => image.selected)
          .map(image => image.id);
        ActionApi.promoteActionImagesToMapObject(
          this.action.id,
          mapObjectId,
          selectedImages,
        ).then(() => {
          EventBus.$emit('updateObjectImages', mapObjectId);
        });
      }
    },
    createComment(actionId) {
      if (this.comment.length > 0) {
        const commentData = {
          data: {
            comment: this.comment,
          },
        };
        return ActionApi.createCommentForAction(actionId, commentData)
          .then(() => {
            this.comment = '';
            return true;
          }).catch((error) => {
            this.$notify({
              type: 'error',
              title: this.$t('error.create_failed', { model: this.$tc('models.comment', 1) }),
              data: error.response,
            });
            this.loading = false;
          });
      }
      return true;
    },
    deleteComment(commentId) {
      this.loading = true;
      ActionApi.deleteCommentForAction(this.action.id, commentId)
        .then(() => {
          ActionApi.getCommentsForAction(this.action.id)
            .then((response) => {
              this.action.comment.data = response.data;
            });
          this.action.comment.data = {};
          this.loading = false;
        });
    },
    createRecurringAction() {
      const data = {
        data: {
          map_object_type_id: this.action.map_object_type_id,
          action_status_id: this.action.action_status_id,
          map_object_id: this.action.map_object_id,
          geo_object_id: this.action.geo_object_id,
          dynamic_action_type_id: this.action.dynamic_action_type_id,
          action_priority_id: this.action.action_priority_id,
          date_interval: this.dateInterval,
          is_relative: false,
        },
      };
      return RecurringActionApi.createRecurringAction(data).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.create_failed', { model: this.$tc('models.recurringaction', 1) }),
          data: error.response,
        });
        this.loading = false;
      });
    },
    handleChangeRequest() {
      const mapObjectId = this.action.map_object_id;
      const { action, mapObject } = this;
      const data = {
        data: {
          name: this.selectedMapObjectName,
          map_object_status_id: this.selectedMapObjectStatus.id,
          attribute: { data: this.mapObjectTypeAttributes },
        },
      };
      MapObjectApi.updateMapObjectById(this.action.map_object_id, data).then(() => {
        this.finishUp();
        if (mapObjectId) {
          EventBus.$emit('updateObjectInformation', mapObjectId);
          EventBus.$emit('updateActionOnMap', action, mapObject);
        }
      }).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.update_failed', { model: this.$tc('models.mapobject', 1) }),
          data: error.response,
        });
        this.loading = false;
      });
    },
    handleCreatesObject() {
      const mapObjectId = this.action.map_object_id;
      const { action } = this;
      const data = {
        data: {
          name: this.selectedMapObjectName,
          parent_id: this.action.map_object_id,
          map_object_status_id: this.selectedMapObjectStatus.id,
          map_object_type_id: this.action.map_object_type_id,
          geo_object_id: this.action.geo_object_id,
          attribute: { data: this.mapObjectTypeAttributes },
        },
      };
      MapObjectApi.createMapObject(data).then((response) => {
        this.finishUp();
        if (mapObjectId) {
          EventBus.$emit('updateObjectChildren', mapObjectId);
          EventBus.$emit('updateActionOnMap', action, response.data);
        }
      }).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
          data: error.response,
        });
        this.loading = false;
      });
    },
    handleDeletesObject() {
      const parentId = this.mapObject.parent_id;
      const { action, mapObject } = this;
      MapObjectApi.deleteMapObjectById(this.action.map_object_id, true)
        .then(() => {
          if (parentId) {
            this.loading = false;
            this.$emit('closeModal');
            EventBus.$emit('loadObject', 'map_object', parentId);
          } else {
            this.finishUp();
          }
          EventBus.$emit('updateActionOnMap', action, mapObject);
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.delete_failed', { model: this.$tc('models.mapobject', 1) }),
            data: error.response,
          });
          this.loading = false;
        });
    },
    handleActionImages() {
      if (this.action.dynamicActionType && this.action.dynamicActionType.data) {
        if (this.action.dynamicActionType.data.actionImageType
          && this.action.dynamicActionType.data.actionImageType.data) {
          this.action.dynamicActionType.data.actionImageType.data.forEach((imageType) => {
            this.$set(
              this.actionImages,
              imageType.id,
              this.images.filter(
                image => image.action_image_type_id === imageType.id,
              ),
            );
          });
        }
      }
    },
    getAction() {
      if (this.action_id === 0) {
        return;
      }
      this.actionLoading = true;
      ActionApi.getActionById(this.action_id, 'user,dynamicActionType,comment,comment.user,actionImage,dynamicActionType.actionImageType,dynamicActionType.dynamicActionTypeCategory,attribute,attribute.attributeLookupValue').then((response) => {
        this.action = response.data;
        if (this.action.map_object_id) {
          this.getMapObject(this.action.map_object_id);
        } else {
          this.mapObject = null;
        }

        this.showOnly = !!this.selectedStatus.is_final;

        if (this.action.dynamicActionType && this.action.dynamicActionType.data) {
          this.dynamicActionType = this.action.dynamicActionType.data;
          // eslint-disable-next-line max-len
          this.isChangeRequest = this.dynamicActionType.dynamicActionTypeCategory.data.is_change_request;
          this.isCreatesObject = this.dynamicActionType.creates_object;
          this.isDeletesObject = this.dynamicActionType.deletes_object;
          if (this.isChangeRequest || this.isCreatesObject) {
            this.getMapObjectTypeAttributes();
          }

          if (this.action.dynamicActionType.data.actionImageType
            && this.action.dynamicActionType.data.actionImageType.data) {
            this.actionImageTypes = this.action.dynamicActionType.data.actionImageType.data;

            this.actionImageTypes.forEach((imageType) => {
              this.actionImages[imageType.id] = this.images.filter(
                image => image.action_image_type_id === imageType.id,
              );
            });
          }
        }

        this.handleActionImages();

        KeyValueApi.getKeyValueByKey(`lock_action_${this.networkId}`)
          .then((r1) => {
            this.hasLockingKV = true;
            this.actionLocked = false;

            const resp = r1.data;
            if (resp) {
              if ((resp.value === '' || resp.value === 'user')) { // Locking based on user
                if (this.action.created_by !== null
                  && this.action.created_by !== undefined
                  && this.user.id !== this.action.created_by
                ) {
                  if (!this.$can('overrule', 'locking-action')) {
                    this.actionLocked = true;
                  }
                }
              } else if (resp.value === 'team') {
                if (this.action.created_by !== null
                  && this.action.created_by !== undefined
                  && this.user.id !== this.action.created_by
                ) {
                  TeamApi.checkSameTeam(this.tenantId, this.user.id, this.action.created_by)
                    .then((sameTeam) => {
                      if (!sameTeam) {
                        if (!this.$can('overrule', 'locking-action')) { this.actionLocked = true; }
                      }
                    });
                }
              }
            }
          });

        this.actionLoading = false;
      }).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.action', 1) }),
          data: error.response,
        });
        this.actionLoading = false;
      });
    },
    getMapObjectTypeAttributes() {
      this.mapObjectTypeAttributesLoading = true;
      MapObjectTypeApi.getMapObjectTypeAttributes(this.action.map_object_type_id, 'attributeLookupValue')
        .then((response) => {
          this.mapObjectTypeAttributes = response.data;
          this.mapObjectTypeAttributes.forEach((attribute) => {
            // eslint-disable-next-line no-param-reassign
            attribute.value = null;
          });
          if (this.mapObject) {
            this.mapObjectTypeAttributes.forEach((attribute, index) => {
              const objectAttribute = Object.assign({}, this.mapObject.attribute.data.filter(
                value => value.id === attribute.id,
              ));
              if (objectAttribute.length > 0) {
                objectAttribute[0].value = null;
                [this.mapObjectTypeAttributes[index]] = objectAttribute;
              }
            });
          }
          this.mapObjectTypeAttributes.forEach((attribute, index) => {
            const actionAttribute = Object.assign({}, this.action.attribute.data.filter(
              value => value.id === attribute.id,
            ));
            if (actionAttribute.length > 0) {
              actionAttribute[0].value = null;
              [this.mapObjectTypeAttributes[index]] = actionAttribute;
            }
          });
          this.mapObjectTypeAttributesLoading = false;
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjecttype', 1) }),
            data: error.response,
          });
          this.mapObjectTypeAttributesLoading = false;
        });
    },
    getMapObject(mapObjectId) {
      this.mapObjectLoading = true;
      MapObjectApi.getMapObjectbyId(mapObjectId, 'mapObjectLabel,mapObjectStatus,attribute,attribute.attributeLookupValue')
        .then((response) => {
          this.mapObject = response.data;
          if (this.isChangeRequest) {
            this.selectedMapObjectName = this.mapObject.name;
          }
          if (this.mapObject.mapObjectLabel && this.mapObject.mapObjectLabel.data) {
            this.label = this.mapObject.mapObjectLabel.data.label;
          } else {
            this.label = this.mapObject.name;
          }
          this.selectedMapObjectStatus = this.mapObject.mapObjectStatus.data;
          this.mapObjectLoading = false;
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.mapobject', 1) }),
            data: error.response,
          });
          this.mapObjectLoading = false;
        });
    },
    Compare(a, b) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      let comparison = 0;

      if (nameA > nameB) {
        comparison = 1;
      } else if (nameA < nameB) {
        comparison = -1;
      }

      return comparison;
    },
    getUsersTeams() {
      userApi.getUsersTeams().then((response) => {
        this.users = response.data;
        this.users.sort(this.Compare);
      });
    },
  },
  watch: {
    action_id() {
      this.action = {};
      this.copyImages = false;
      this.createRecurring = false;
      this.isChangeRequest = false;
      this.isCreatesObject = false;
      this.isDeletesObject = false;
      this.getAction();
    },
  },
};
</script>
<style scoped>
  hr {
    border: 0;
    border-bottom: 1px solid #d9dadb;
  }

  .footer-content {
    float: right;
  }

  .action-image-types {
    display: flex;
  }

  div.images-field {
    position: relative;
    display: inline-block;
    width: 50%;
  }

  .copy-images-text {
    font-style: italic;
  }

  .object-attribute-wrapper .title {
    display: inline-flex;
    float: left;
    width: 35%;
  }

  .object-attribute-wrapper .field.content {
    display: inline-flex;
    float: initial;
    overflow: initial;
    width: 65%;
  }

  .object-attribute-wrapper {
    margin-top: 20px;
  }
</style>
