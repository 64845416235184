const MapMode = {
  objectSelect: 'objectSelect',
  objectCreate: 'objectCreate',
  objectUpdate: 'objectUpdate',
  splitLine: 'splitLine',
  actionCreate: 'actionCreate',
  selectionSelect: 'selectionSelect',
  selectionCreate: 'selectionCreate',
  selectionUpdate: 'selectionUpdate',
  multiSelectionCreate: 'multiSelectionCreate',
};

export default MapMode;
