<template>
  <div v-if="visible == true" :class="'object-obstruction-item'">
    <h3>
      <a @click="loadMapObject(obstruction.map_object_id)">
        {{obstruction.map_object_name }}
      </a>
      <div class="buttons right">
        <a
          v-if="isObstructionActive(obstruction)"
          href="#" class="btn module-colorgreen-background"
        >
          {{ $t('obstruction.active') | capitalize }}
        </a>
        <a @click="editObstruction()" v-if="id == obstruction.map_object_id"
           href="#!" class="btn btn-icon module-color-background"
        >
          <font-awesome-icon icon="pen" />
        </a>
        <a @click="deleteObstructionById(obstruction.id)" v-if="id == obstruction.map_object_id"
           href="#!" class="btn btn-icon module-color-background"
        >
          <font-awesome-icon icon="trash" />
        </a>
        <a @click="zoomToMapObject()"
           href="#!" class="btn btn-icon module-color-background"
        >
          <font-awesome-icon icon="map-marker-alt" />
        </a>
      </div>
    </h3>
    <ul>
      <li>
        <span class="title">{{ $t('obstruction.period') | capitalize }}: </span>
        <span class="content" v-if="obstruction.period_id">
          {{ setObstructionPeriod(obstruction.period_id) }}
        </span>
        <span class="content" v-else>-</span>
      </li>
      <li>
        <span class="title">{{ $t('obstruction.from_date') | capitalize }}: </span>
        <span class="content" v-if="obstruction.from_date">
          <template v-if="obstruction.period_id == 3">
            {{ moment(obstruction.from_date, 'YYYY-MM-DD').format('DD/MM') }}
          </template>
          <template v-else>
            {{ moment(obstruction.from_date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
          </template>
        </span>
        <span class="content" v-else>-</span>
      </li>
      <li>
        <span class="title">{{ $t('obstruction.to_date') | capitalize }}: </span>
        <span class="content" v-if="obstruction.to_date">
          <template v-if="obstruction.period_id == 1">
            {{ moment(obstruction.to_date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}
          </template>
          <template v-if="obstruction.period_id == 2">
            -
          </template>
          <template v-if="obstruction.period_id == 3">
            {{ moment(obstruction.to_date, 'YYYY-MM-DD').format('DD/MM') }}
          </template>
        </span>
        <span class="content" v-else>-</span>
      </li>
      <li>
        <span class="title">{{ $t('obstruction.reason') | capitalize }}: </span>
        <span class="content" v-if="obstruction.reason">
          <pre class="object-obstruction-pre">{{ obstruction.reason }}</pre>
        </span>
        <span class="content" v-else>-</span>
      </li>
      <li>
        <span class="title">{{ $t('obstruction.detour') | capitalize }}: </span>
        <span class="content" v-if="obstruction.detour">
          <pre class="object-obstruction-pre">{{ obstruction.detour }}</pre>
        </span>
        <span class="content" v-else>-</span>
      </li>
    </ul>
  </div>
</template>
<script>
import moment from 'moment/moment';
import EventBus from '@/events/event-bus';
import MapObjectApi from '@/api/map_object';

export default {
  name: 'ObjectObstructionItem',
  components: {},
  props: {
    obstruction: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    moment,
    editObstruction() {
      this.$emit('showObjectObstructionsForm', 'edit', true, this.index);
    },
    deleteObstructionById(id) {
      this.$emit('deleteObstructionById', id);
    },
    setObstructionPeriod(id) {
      switch (id) {
        case 1: return 'Eenmalig';
        case 2: return 'Eenmalig, voor onbepaalde tijd';
        case 3: return 'Jaarlijks';
        default: return '-';
      }
    },
    zoomToMapObject() {
      MapObjectApi.getMapObjectbyId(this.obstruction.map_object_id).then((response) => {
        if (response && response.data && response.data.id === this.obstruction.map_object_id) {
          EventBus.$emit('Map.zoomToMapObject', response.data);
        } else {
          this.$notify({
            type: 'warning',
            title: this.$t('error.loading_failed', { model: this.$tc('models.mapobject', 1) }),
            text: this.$t('error.not_found', { model: this.$tc('models.mapobject', 1) }),
            duration: 1000,
          });
        }
      }).catch(() => {
        this.$notify({
          type: 'warning',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobject', 1) }),
          text: this.$t('error.not_found', { model: this.$tc('models.mapobject', 1) }),
          duration: 1000,
        });
      });
    },
    isObstructionActive(obstruction) {
      if (obstruction.period_id === 1 || obstruction.period_id === 3) {
        return (
          moment().isSameOrAfter(moment(obstruction.from_date))
          && moment().isSameOrBefore(moment(obstruction.to_date).add(1, 'days').startOf('day'))
        );
      }
      if (obstruction.period_id === 2) {
        return moment().isSameOrAfter(moment(obstruction.from_date));
      }
      return false;
    },
    loadMapObject(mapObjectId) {
      EventBus.$emit('loadObject', 'map_object', mapObjectId);
    },
  },
};
</script>

<style scoped>
  .module-colorgreen-background {
    background-color: #a8d93c !important;
  }

  a {
    cursor: pointer;
    font-size: 1.0em;
    margin: 0;
    padding: 0;
    color: #44a7dc;
    text-decoration: underline;
    outline: 0;
    transition: all 0.3s ease;
  }

  a.btn.btn-icon.zoomToAction {
    font-size: 0.8em;
  }

  .object-obstruction-item {
    border-bottom: 1px solid #d9dadb;
    border-left: 4px solid white;
    padding: 0 20px;
  }

  .object-obstruction-item h3 {
    margin: 0;
    padding: 12px 0;
  }

  .object-obstruction-pre {
    margin: 0;
    overflow: auto;
  }

  .right {
    float: right;
  }

  ul {
    overflow: auto;
    zoom: 1;
    font-size: .85em;
    margin: 0 0 15px 0;
    padding: 0;
  }

  ul li {
    line-height: 1.7em;
    position: relative;
    clear: both;
    list-style: none;
  }

  ul li span {
    display: block;
    float: left;
    width: 40%;
  }

  ul li span.title {
    width: 40%;
  }

  ul li span.content {
    display: block;
    float: left;
    width: 55%;
  }
  pre {
    font-family: inherit;
  }
</style>
