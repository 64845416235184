import axios from '@/plugins/axios';

export default {
  getActionStatus() {
    return axios.get('/v1/action_status?perPage=9999')
      .then(response => response.data);
  },
  getActionStatusById(id) {
    return axios.get(`/v1/action_status/${id}`)
      .then(response => response.data);
  },
  updateActionStatusById(id, data) {
    return axios.put(`/v1/action_status/${id}`, data)
      .then(response => response.data);
  },
  deleteActionStatusById(id) {
    return axios.delete(`/v1/action_status/${id}`)
      .then(response => response.data);
  },
  createActionStatus(data) {
    return axios.post('/v1/action_status', data)
      .then(response => response.data);
  },
};
