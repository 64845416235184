/* eslint-disable no-shadow,no-param-reassign */
// eslint-disable-next-line import/no-cycle
import switcher from '../../services/switcher';

const state = {
  tenant_id: localStorage.getItem('tenant_id') || null,
  network_id: localStorage.getItem('network_id') || null,
};

const getters = {
  getTenantId() {
    return state.tenant_id;
  },
  getNetworkId() {
    return state.network_id;
  },
};

const actions = {
  setTenantId(context, id) {
    localStorage.setItem('tenant_id', id);
    context.commit('setTenantId', id);
  },
  setNetworkId(context, id) {
    localStorage.setItem('network_id', id);
    context.commit('setNetworkId', id);
  },
  unsetTenantId(context) {
    switcher.clearSelectedTenantId();
    context.commit('unsetTenantId');
  },
  unsetNetworkId(context) {
    switcher.clearSelectedNetworkId();
    context.commit('unsetNetworkId');
  },
};

const mutations = {
  setTenantId(state, id) {
    state.tenant_id = id;
  },
  setNetworkId(state, id) {
    state.network_id = id;
  },
  unsetTenantId(state) {
    localStorage.removeItem('tenant_id');
    state.tenant_id = null;
  },
  unsetNetworkId(state) {
    localStorage.removeItem('network_id');
    state.network_id = null;
  },
};


export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
