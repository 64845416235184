<template>
  <a href="#!"
     class="btn module-color-background"
     model="action" :model-id="action.id"
     :loading="'.action'+action.id"
     @click="$emit('click')"
  >
    {{ actionButtonTitle }}
  </a>
</template>

<script>

export default {
  name: 'ActionButton',
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
  computed: {
    actionButtonTitle() {
      if (this.action.date_interval) {
        return this.$options.filters.capitalize(this.$t('actionlog.edit'));
      }
      if (this.action.actionStatus && this.action.actionStatus.data) {
        if (this.action.actionStatus.data.is_final) {
          return this.$options.filters.capitalize(this.$t('actionlog.show'));
        }
      }
      if (this.action.dynamicActionType && this.action.dynamicActionType.data) {
        if (this.action.dynamicActionType.data.creates_object) {
          return this.$options.filters.capitalize(this.$t('actionlog.place'));
        }
        if (this.action.dynamicActionType.data.deletes_object) {
          return this.$options.filters.capitalize(this.$t('actionlog.delete'));
        }
        if (this.action.dynamicActionType.data.dynamicActionTypeCategory
          && this.action.dynamicActionType.data.dynamicActionTypeCategory.data) {
          if (
            this.action.dynamicActionType.data.dynamicActionTypeCategory.data.is_change_request
          ) {
            return this.$options.filters.capitalize(this.$t('actionlog.change'));
          }
        }
      }
      return this.$options.filters.capitalize(this.$t('actionlog.process'));
    },
  },
};
</script>
