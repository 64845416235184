<template>
  <Modal
    :visible="visible"
    @closeModal="closeModal"
    :header="true"
    :footer="true"
    :no_padding="true"
    width="85%"
    :loading="loading"
  >
    <template v-slot:header>
      {{ title }}
    </template>

    <template v-slot:body>
      <div class="content-wrapper">
        <div class="left-content">
          <div class="action-type-header">{{ action_type_name }}</div>
          <slot name="left-content" />
        </div>


        <div class="right-content">
          <slot name="right-content" />
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <slot name="footer" />
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Layout/Modal.vue';

export default {
  name: 'ActionModal',
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    action_type_name: {
      type: String,
      default: '',
      required: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
<style scoped>
  div.action-type-header {
    font-family: "Abel", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.1em;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
  }

  .content-wrapper {
    position: relative;
    display: block;
    overflow: auto;
    background: #f2f7fe;
  }

  .left-content {
    float: left;
    display: inline-block;
    width: 70%;
    border-right: 1px solid #d9dadb;
    background: #f2f7fe;
    padding: 10px 24px 24px;
    height: inherit;
    min-height: 375px;
  }

  .right-content {
    float: left;
    display: inline-block;
    width: 30%;
    background: #f2f7fe;
    padding: 24px;
    height: auto;
  }
</style>
