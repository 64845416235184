<template>
  <MapIcon v-if="!loading" v-bind="icon.value" :count=0 :label="label" :size="size" />
</template>

<script>
import { mapGetters } from 'vuex';

import MapIcon from './Map/MapIcon.vue';

export default {
  name: 'IconByTypeId',
  components: { MapIcon },
  props: {
    map_object_type_id: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data() {
    return {
      icon: {},
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      keyValues: 'settings/getKeyValues',
    }),
  },
  methods: {
    getIcon() {
      let icon = this.keyValues.filter(
        u => u.key === `map_object_type_icon_${this.map_object_type_id}`,
      );
      if (icon.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        icon = icon[0];
      } else {
        icon = {};
      }
      this.icon = icon;
      this.loading = false;
    },
  },
  mounted() {
    this.getIcon();
  },
  watch: {
    map_object_type_id() {
      this.getIcon();
    },
  },
};
</script>
