<template>
  <ul class="images">
    <ImageLoader
      v-for="(image, index) in action_images[action_image_type_id]"
      :image="image"
      :key="index"
    >
      <li
        slot="image"
        class="image"
        :src="image.link"
        v-bind:style="{ 'background': 'url('+image.link+') 0% 0% / cover no-repeat' }"
        @click="showImage(index, image)"
      >
        <div v-if="!show_only && $can('delete', 'actionimage')"
             class="delete-image"
             @click="deleteImage(index)" v-on:click.stop
        >
          <font-awesome-icon icon="times" />
        </div>
        <div
          :class="['download-image', {'show-only': show_only}]"
          @click="downloadImage(image)" v-on:click.stop
        >
          <font-awesome-icon icon="save" />
        </div>
        <div
          v-if="selectable && !show_only"
          :class="['select-image', {'selected': image.selected}]"
          v-on:click.stop
          @click="selectImage(index)"
        >
        </div>
      </li>
      <li slot="preloader" class="empty-image preloader"><LoadingIcon /></li>
      <li slot="error" class="empty-image error">
        <div v-if="!show_only" class="delete-image" @click="deleteImage(index)" v-on:click.stop>
          <font-awesome-icon icon="times" />
        </div>
        Loading failed
      </li>
    </ImageLoader>
    <li
      v-if="!show_only && $can('create', 'actionimage')"
      @click="$refs.fileInput.click()"
      class="add-image"
      :action_image_type_id="action_image_type_id"
    >
      +
    </li>
    <Loading :visible="loading || external_loading" />
    <input
      class="file-input"
      ref="fileInput"
      type="file"
      @change="onFileChanged"
      multiple="multiple"
    />

    <ImagesModal
      :visible="showImageModal"
      @closeModal="closeModal"
      @updateIndex="updateIndex"
      :header="true"
      :footer="false"
      :loading="false"
      :images="action_images[action_image_type_id]"
      :index="currentIndex"
    />
  </ul>
</template>

<script>
import axios from 'axios';

import Loading from './Loading.vue';
import LoadingIcon from './LoadingIcon.vue';
import ImageLoader from './ImageLoader.vue';
import ImagesModal from './Layout/ImagesModal.vue';

export default {
  name: 'ImagePanel',
  components: {
    Loading,
    LoadingIcon,
    ImageLoader,
    ImagesModal,
  },
  props: {
    action_image_type_id: {
      type: Number,
      required: true,
    },
    action_images: {
      type: Array,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    show_only: {
      type: Boolean,
      default: false,
    },
    external_loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      images: [],
      url: null,
      imageToShow: null,
      showImageModal: false,
      loading: false,
      currentIndex: 0,
    };
  },
  methods: {
    closeModal() {
      this.showImageModal = false;
    },
    updateIndex(index) {
      this.currentIndex = index;
    },
    showImage(index) {
      if (!this.$can('show', 'actionimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.actionimage', 1) }),
          text: this.$t('error.no_access_show', { model: this.$tc('models.actionimage', 1) }),
        });
        return;
      }
      if (this.selectable) {
        this.selectImage(index);
      } else {
        this.currentIndex = index;
        this.showImageModal = true;
      }
    },
    selectImage(index) {
      const selectedValue = this.action_images[this.action_image_type_id][index].selected;
      this.$set(this.action_images[this.action_image_type_id][index], 'selected', !selectedValue);
    },
    deleteImage(index) {
      if (!this.$can('delete', 'actionimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.actionimage', 1) }),
          text: this.$t('error.no_access_delete', { model: this.$tc('models.actionimage', 1) }),
        });
        return;
      }
      // eslint-disable-next-line no-alert
      if (window.confirm(this.$t('dialog.delete_image_confirm'))) {
        this.$emit('deleteImage', this.action_image_type_id, index);
      }
    },
    onFileChanged(event) {
      if (!this.$can('create', 'actionimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.actionimage', 0) }),
          text: this.$t('error.no_access_create', { model: this.$tc('models.actionimage', 0) }),
        });
        return;
      }
      this.loading = true;
      for (let i = 0; i < event.target.files.length; i += 1) {
        const uploadedFile = event.target.files[i];
        const image = {
          link: URL.createObjectURL(uploadedFile),
          action_image_type_id: this.action_image_type_id,
          id: null,
          file: uploadedFile,
          resource: uploadedFile.name,
          selected: false,
        };
        this.$emit('addImage', this.action_image_type_id, image);
      }
      this.$refs.fileInput.value = '';
      this.loading = false;
    },
    downloadImage(image) {
      this.loading = true;
      let extension = '';
      if (image.id !== null) {
        extension = '.jpg';
      }

      axios({
        url: image.link,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', image.resource + extension);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
  ul.images {
    font-size: .95em;
    margin: 0;
  }

  ul.images .add-image {
    width: 100px;
    height: 80px;
    border: 1px solid #333;
    float: left;
    border-radius: 6px;
    margin: 10px 6px;
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    line-height: 80px;
    color: #333;
  }
  ul.images .add-image:hover {
    cursor: pointer;
    border: 1px solid silver;
    color: silver;
  }
  .file-input {
    display:none;
  }

  ul.images li.image,ul.images li.empty-image {
    float: left;
    margin: 10px 6px;
    width: 100px;
    height: 80px;
    overflow: visible;
    border-radius: 6px;
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    line-height: 80px;
    color: #333;
  }

  ul.images li.image:hover {
    cursor: pointer;
    color: silver;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  }

  ul.images li.empty-image.preloader {
    width: 100px;
    height: 80px;
    position: relative;
    display: inline-block;
  }

  ul.images li.empty-image.error {
    font-size: 12px;
  }

  .select-image {
    float: left;
    font-weight: bold;
    color: white;
    background: #fff;
    border: 3px solid green;
    border-radius: 50%;
    height: 23px;
    bottom: -16px;
    width: 23px;
    position: relative;
    margin-left: -6px;
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    line-height: 25px;
  }
  .select-image:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  }
  .select-image.selected {
    background: green;
  }

  .delete-image {
    float: left;
    font-weight: bold;
    color: white;
    background-color: #ef325e;
    border-radius: 50%;
    height: 23px;
    top: -7px;
    width: 23px;
    position: relative;
    margin-left: calc(100px - 17px);
    cursor: pointer;
    font-size: 13px;
    text-align: center;
    line-height: 23px;
  }
  .delete-image:hover {
    background-color: #a82540;
  }

  .download-image {
    float: left;
    font-weight: bold;
    color: black;
    background-color: #cfcfcf;
    border-radius: 50%;
    height: 23px;
    bottom: -39px;
    width: 23px;
    position: relative;
    margin-left: calc(100px - 17px);
    cursor: pointer;
    font-size: 15px;
    text-align: center;
    line-height: 25px;
  }
  .download-image:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  }
  .download-image.show-only {
    bottom: -64px;
  }
</style>
