<template>

  <font-awesome-icon
    v-if="device === 'mobile'"
    icon="mobile"
    title="mobile"
  />

  <font-awesome-icon
    v-else-if="device === 'api'"
    icon="link"
    title="api"
  />
</template>
<script>
export default {
  name: 'DeviceIcon',
  components: {},
  props: {
    value: {},
    device: {
      type: String,
      required: true,
    },
  },
};
</script>
