<template>
  <CollapsePanel
    v-if="mapObject"
    :title="$tc('models.image', 0)"
    :loading=loading
    :showCount=true
    :count="images.length"
    :showCollapseAction="false"
  >
    <div class="field">
      <GenericImagePanel
        :images="images"
        :show_only="false"
        model_type="mapobjectimage"
        @addImage="onImageAdd"
        @deleteImage="onImageDelete"
      />
    </div>
  </CollapsePanel>
</template>

<script>
import mapObjectApi from '@/api/map_object';

import EventBus from '@/events/event-bus';

import CollapsePanel from '@/components/CollapsePanel.vue';
import GenericImagePanel from '@/components/GenericImagePanel.vue';

export default {
  name: 'Images',
  components: {
    GenericImagePanel,
    CollapsePanel,
  },
  props: {
    mapObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      images: [],
    };
  },
  methods: {
    onImageAdd(file) {
      if (!this.$can('create', 'mapobjectimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectimage', 0) }),
          text: this.$t('error.no_access_create', { model: this.$tc('models.mapobjectimage', 0) }),
        });
        return;
      }
      this.loading = true;
      const formData = new FormData();
      formData.append('image', file.file);
      if (!this.mapObject) {
        return;
      }
      mapObjectApi.createImageForMapObject(this.mapObject.id, formData)
        .then((response) => {
          this.images.push(response.data);
          this.loading = false;
        });
    },
    onImageDelete(index) {
      if (!this.$can('delete', 'mapobjectimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectimage', 1) }),
          text: this.$t('error.no_access_delete', { model: this.$tc('models.mapobjectimage', 1) }),
        });
        return;
      }
      this.imageLoading = true;
      if (!this.mapObject) {
        return;
      }
      const image = this.images.splice(index, 1);
      mapObjectApi.deleteImageForMapObject(this.mapObject.id, image[0].id).then(() => {
        this.loading = false;
      });
    },
    getImages() {
      if (!this.$can('index', 'mapobjectimage')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectimage', 0) }),
          text: this.$t('error.no_access_index', { model: this.$tc('models.mapobjectimage', 0) }),
        });
        return;
      }
      if (!this.mapObject) {
        return;
      }
      this.loading = true;
      mapObjectApi.getImagesForMapObject(this.mapObject.id).then((response) => {
        this.images = response.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getImages();
    EventBus.$on('updateObjectImages', (mapObjectId) => {
      if (mapObjectId === this.mapObject.id) {
        this.getImages();
      }
    });
  },
  watch: {
    mapObject() {
      this.getImages();
    },
  },
};
</script>
