<template>
  <div></div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';
import GeoType from '@/enums/GeoType';

export default {
  name: 'DrawSelection',
  props: {
    map: {
      type: Object,
      required: true,
    },
    selection_type_id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      keyValues: 'settings/getKeyValues',
      mapObjectTypes: 'settings/getMapObjectTypes',
      network: 'settings/getNetworkModel',
      originalSelection: 'selected/getSelection',
      selectedObjects: 'map/getSelected',
      points: 'map/getPoints',
      selectionTypes: 'settings/getMapObjectSelectionTypes',
    }),
    chosenSelectionType() {
      if (this.selection_type_id !== null && this.selection_type_id !== 0) {
        return this.selectionTypes[this.selection_type_id];
      }
      return {};
    },
    isNetwork() {
      if (this.chosenSelectionType === {}) {
        return false;
      }
      return this.chosenSelectionType.is_network;
    },
    isOrdered() {
      if (this.chosenSelectionType === {}) {
        return false;
      }
      return this.chosenSelectionType.is_ordered;
    },
  },
  data() {
    return {
      interaction: true,
    };
  },
  methods: {
    ...mapActions({
      setSelectedOnMap: 'map/setSelected',
      reverseSelected: 'map/reverseSelected',
      addSelectedOnMap: 'map/addSelected',
      removeSelectedOnMap: 'map/removeSelected',
      resetSelectedOnMap: 'map/resetSelected',
    }),
    mapClick(e) {
      console.log('Map DrawSelection mapClick()', e);
    },
    toggleMapObject(mapObject, type) {
      console.log('DrawSelection.toggleMapObject()', mapObject, this.interaction);
      if (this.interaction === false) {
        return;
      }
      if (type === GeoType.point) {
        this.togglePoint(mapObject);
      } else if (type === GeoType.linestring) {
        this.toggleLine(mapObject);
      } else if (type === GeoType.polygon) {
        this.togglePolygon(mapObject);
      }
    },
    togglePoint(mapObject) {
      console.log('DrawSelection.togglePoint', mapObject);
      // if isNetwork is true, only lines can be selected and placed in a selection
      if (this.isNetwork) {
        this.$notify({
          type: 'warning',
          title: this.$t('selections.routenetwork_marker_click'),
        });
        return;
      }
      // if isOrdered is true, only lines in order can be selected and
      // placed with their start/endpoints in a selection
      if (this.isOrdered) {
        this.$notify({
          type: 'warning',
          title: this.$t('selections.route_line_click'),
        });
        return;
      }
      if (this.isMapObjectIdInSelection(mapObject.id)) {
        this.removeMapObjectFromSelection(mapObject);
        this.$notify({
          type: 'success',
          title: this.$t('selections.point_removed_from_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
        });
      } else {
        this.addMapObjectToSelection(mapObject);
        this.$notify({
          type: 'success',
          title: this.$t('selections.point_added_to_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
        });
      }
    },
    toggleLine(mapObject) {
      console.log('DrawSelection.toggleLine', mapObject, this.isOrdered, this.isNetwork, this.$options.filters.mapObjectLabel(mapObject));
      // if not ordered, just add or remove line in selection
      if (!this.isOrdered && !this.isNetwork) {
        if (this.isMapObjectIdInSelection(mapObject.id)) {
          this.removeMapObjectFromSelection(mapObject);
          this.$notify({
            type: 'success',
            title: this.$t('selections.line_removed_from_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
          });
        } else {
          this.addMapObjectToSelection(mapObject);
          this.$notify({
            type: 'success',
            title: this.$t('selections.line_added_to_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
          });
        }
      } else if (this.isNetwork) {
        if (this.isMapObjectIdInSelection(mapObject.id)) {
          if (!this.moreThanOneLineInSelectionWithStartOrEndPoint(mapObject.startPoint.data.id)) {
            this.removeMapObjectFromSelection(mapObject.startPoint.data);
          }
          if (!this.moreThanOneLineInSelectionWithStartOrEndPoint(mapObject.endPoint.data.id)) {
            this.removeMapObjectFromSelection(mapObject.endPoint.data);
          }
          this.removeMapObjectFromSelection(mapObject);
          this.$notify({
            type: 'success',
            title: this.$t('selections.line_removed_from_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
          });
        } else {
          if (!this.isMapObjectIdInSelection(mapObject.startPoint.data.id)) {
            this.addMapObjectToSelection(mapObject.startPoint.data);
          }
          this.addMapObjectToSelection(mapObject);
          if (!this.isMapObjectIdInSelection(mapObject.endPoint.data.id)) {
            this.addMapObjectToSelection(mapObject.endPoint.data);
          }
          this.$notify({
            type: 'success',
            title: this.$t('selections.line_added_to_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
          });
        }
      } else if (this.isOrdered) {
        // if selection type is ordered, we also need to add start- and endpoint to selection and
        // make sure that lines are connected
        const secondToLastObject = this.getSecondToLastObjectInSelection();
        if (this.isMapObjectIdInSelection(mapObject.id)
            && secondToLastObject && secondToLastObject.id === mapObject.id
        ) {
          this.removeLastLineFromOrderedSelection(mapObject);
        } else {
          this.addLineToOrderedSelection(mapObject);
        }
      }
    },
    addLineToOrderedSelection(mapObject) {
      console.log('addLineToOrderedSelection', mapObject);
      let startPoint;
      if (!mapObject.startPoint || !mapObject.startPoint.data) {
        startPoint = this.findPointById(mapObject.geoObject.data.properties.start_point);
      } else {
        startPoint = mapObject.startPoint.data;
      }
      let endPoint;
      if (!mapObject.endPoint || !mapObject.endPoint.data) {
        endPoint = this.findPointById(mapObject.geoObject.data.properties.end_point);
      } else {
        endPoint = mapObject.endPoint.data;
      }
      if (this.selectedObjects.length === 0) {
        this.addMapObjectToSelection(startPoint);
        this.addMapObjectToSelection(mapObject);
        this.addMapObjectToSelection(endPoint);
      } else {
        if (this.selectedObjects.length === 3) {
          if (this.selectedObjects[0].id === startPoint.id
              || this.selectedObjects[0].id === endPoint.id) {
            this.reverseSelected();
          }
        }
        if (startPoint.id !== this.getLastSelectedMapObjectObjectId()
            && endPoint.id !== this.getLastSelectedMapObjectObjectId()) {
          this.$notify({
            type: 'warning',
            title: `${this.$t('selections.line') + this.$options.filters.mapObjectLabel(mapObject) + this.$t('selections.is_not_connected_to_a_line') + this.$options.filters.mapObjectLabel(this.selectedObjects[this.selectedObjects.length - 2])})`,
          });
          return;
        }
        if (startPoint.id === this.getLastSelectedMapObjectObjectId()) {
          this.addMapObjectToSelection(mapObject);
          this.addMapObjectToSelection(endPoint);
        } else if (endPoint.id === this.getLastSelectedMapObjectObjectId()) {
          this.addMapObjectToSelection(mapObject);
          this.addMapObjectToSelection(startPoint);
        }
      }
      this.$notify({
        type: 'success',
        title: this.$t('selections.line_added_to_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
      });
    },
    getLastSelectedMapObjectObjectId() {
      if (this.selectedObjects === 0) {
        return null;
      }
      return this.selectedObjects[this.selectedObjects.length - 1].id;
    },
    findPointById(mapObjectId) {
      console.log('DrawSelection.findPointById', mapObjectId, this.points[Object.keys(this.points)[0]].data);
      return this.points[Object.keys(this.points)[0]].data
        .filter(item => item.map_object_ids.includes(mapObjectId));
    },
    removeLastLineFromOrderedSelection(mapObject) {
      console.log('DrawSelection.removeLastLineFromOrderedSelection', mapObject);
      if (this.selectedObjects.length === 3) {
        this.resetSelectedOnMap();
      } else {
        this.selectedObjects.splice(-2);
        this.setSelectedOnMap({ mapObjects: this.selectedObjects });
      }
      this.$notify({
        type: 'success',
        title: this.$t('selections.line_removed_from_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
      });
    },
    togglePolygon(mapObject) {
      if (this.isNetwork) {
        this.$notify({
          type: 'warning',
          title: this.$t('selections.routenetwork_polygon_click'),
        });
        return;
      }
      // if isOrdered is true, only lines in order can be selected and
      // placed with their start/endpoints in a selection
      if (this.isOrdered) {
        this.$notify({
          type: 'warning',
          title: this.$t('selections.route_polygon_click'),
        });
        return;
      }
      if (this.isMapObjectIdInSelection(mapObject.id)) {
        this.removeMapObjectFromSelection(mapObject);
        this.$notify({
          type: 'success',
          title: this.$t('selections.polygon_removed_from_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
        });
      } else {
        this.addMapObjectToSelection(mapObject);
        this.$notify({
          type: 'success',
          title: this.$t('selections.polygon_added_to_selection', { name: this.$options.filters.mapObjectLabel(mapObject) }),
        });
      }
    },
    getSecondToLastObjectInSelection() {
      if (this.selectedObjects.length < 2) {
        return null;
      }
      return this.selectedObjects[this.selectedObjects.length - 2];
    },
    moreThanOneLineInSelectionWithStartOrEndPoint(mapObjectId) {
      console.log('moreThanOneLineInSelectionWithStartOrEndPoint', mapObjectId, this.selectedObjects.filter((item) => {
        if (!item.startPoint || !item.startPoint.data) {
          return false;
        }
        if (!item.endPoint || !item.endPoint.data) {
          return false;
        }
        return item.startPoint.data.id === mapObjectId || item.endPoint.data.id === mapObjectId;
      }).length);
      return this.selectedObjects.filter((item) => {
        if (!item.startPoint || !item.startPoint.data) {
          return false;
        }
        if (!item.endPoint || !item.endPoint.data) {
          return false;
        }
        console.log('filter', mapObjectId, item.startPoint.data.id, item.endPoint.data.id, item.startPoint.data.id === mapObjectId || item.endPoint.data.id === mapObjectId);
        return item.startPoint.data.id === mapObjectId || item.endPoint.data.id === mapObjectId;
      }).length > 1;
    },
    isMapObjectIdInSelection(mapObjectId) {
      return this.selectedObjects.filter(item => item.id === mapObjectId).length > 0;
    },
    addMapObjectToSelection(mapObject) {
      this.addSelectedOnMap({ mapObject });
    },
    removeMapObjectFromSelection(mapObject) {
      this.removeSelectedOnMap({ mapObjectId: mapObject.id });
    },
  },
  mounted() {
    this.map.off('click');
    this.map.on('click', (e) => {
      this.mapClick(e);
    });
    EventBus.$on('Map.clickMapObject', (data, type) => {
      this.toggleMapObject(data, type);
    });
    EventBus.$on('Map.disableInteraction', () => {
      this.interaction = false;
    });
    EventBus.$on('Map.enableInteraction', () => {
      this.interaction = true;
    });
    EventBus.$on('DrawSelection.removeMapObject', (mapObject) => {
      this.toggleMapObject(mapObject, mapObject.geoObject.data.geometry.type.toLowerCase());
    });
    if (this.originalSelection
      && this.originalSelection.mapObject
      && this.originalSelection.mapObject.data) {
      this.setSelectedOnMap({ mapObjects: this.originalSelection.mapObject.data });
    }
  },
  beforeDestroy() {
    this.resetSelectedOnMap();
    EventBus.$off('Map.clickMapObject');
  },
};
</script>

<style scoped>

</style>
