<template>
  <img class="map-icon" :src="svg"/>
</template>

<script>
export default {
  name: 'MapIcon',
  computed: {
    svg() {
      const originalSize = 40;
      const circle = 8;
      const radius = 5;
      const padding = 5;
      const { color } = this;
      // eslint-disable-next-line camelcase
      const { text_color } = this;
      const { count } = this;
      const filteredLabel = String(this.label || '').replace(/\uFFFD/g, '')
        .replace(/[\u{0080}-\u{10FFFF}]/gu, '');
      let svg = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="${this.size + padding * 2}" height="${this.size + padding * 2}" viewBox="-${padding} -${padding} ${this.size + padding * 2} ${this.size + padding * 2}">`;
      if (this.type === 'circle') {
        const size = (this.size - 6);
        if (filteredLabel !== undefined && filteredLabel.length > 5) {
          svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="${color}"/>`
              + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 2}" fill="#fff"/>`
              + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 3}" fill="${color}"/>`
              // eslint-disable-next-line camelcase
              + `<text text-anchor="middle" x="${size / 2}" y="${size / 2 + 3}" font-family="Roboto, sans-serif" font-size="${size / 6 + 3}" fill="${text_color}">${filteredLabel}</text>`;
        } else {
          svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="${color}"/>`
              + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 2}" fill="#fff"/>`
              + `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2 - 3}" fill="${color}"/>`
              // eslint-disable-next-line camelcase
              + `<text text-anchor="middle" x="${size / 2}" y="${size / 2 + 3}" font-family="Roboto, sans-serif" font-size="${size / 4 + 1}" fill="${text_color}">${filteredLabel}</text>`;
        }
      } else if (this.type === 'small_circle') {
        svg += `<circle cx="${this.size / 2}" cy="${this.size / 2}" r="${this.size / 4}" fill="${color}"/>`;
        // eslint-disable-next-line camelcase
        svg += `<circle stroke-width="1" stroke="${text_color}" cx="${this.size / 2}" cy="${this.size / 2}" r="${this.size / 5.5}" fill="none"/>`;
      } else if (this.type === 'action') {
        svg += `<rect stroke="${color}" transform="rotate(45 22.00000000000001,29.437499999999996) " id="svg_12" height="13.300008" width="13.501524" y="22.787495" x="15.249238" stroke-opacity="null" stroke-width="3" fill="${color}"/>`
            + `<ellipse ry="15" rx="15" id="svg_1" cy="17.75" cx="22" stroke-width="3" stroke="${color}" fill="#ffffff"/>`;
      } else if (this.type === 'label') {
        svg += `<polygon fill="${color}" stroke="${color}" stroke-miterlimit="10" transform="scale(${this.size / originalSize}, ${this.size / originalSize})" points="20,35 17.3,29.8 14.7,24.7 20,24.7 25.3,24.7 22.6,29.8 "/>`
            + `<path fill="#ffffff" stroke="${color}" stroke-width="2" stroke-miterlimit="10" transform="scale(${this.size / originalSize}, ${this.size / originalSize})" d="M29.8,23.6H10.2c-4.3,0-7.7-3.5-7.7-7.7v-3.2 C2.5,8.5,6,5,10.2,5h19.5c4.3,0,7.7,3.5,7.7,7.7v3.2C37.5,20.1,34,23.6,29.8,23.6z"/>`
            // eslint-disable-next-line camelcase
            + `<text text-anchor="middle" x="${this.size / 2}" y="${this.size / 2 - 2}" font-family="Roboto, sans-serif" font-size="${this.size / 4}" fill="${text_color}">${filteredLabel}</text>`;
      } else if (this.type === 'icon') {
        const scale = parseFloat((this.size / originalSize).toFixed(2));
        const { size } = this;
        svg = `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="${size + (padding * 2)}" height="${size + (padding * 2)}" viewBox="-${padding} -${padding} ${size + (padding * 2)} ${size + (padding * 2)}">`;
        if (this.frame === 'square_rounded') {
          svg += `<rect fill="${color}" stroke="#ffffff" rx="${radius}" ry="${radius}" width="${size}" height="${size}"/>`;
        } else if (this.frame === 'square') {
          svg += `<rect fill="${color}" stroke="#ffffff" width="${size}" height="${size}"/>`;
        } else if (this.frame === 'circle') {
          svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" stroke="#ffffff" fill="${color}"/>`;
        } else if (this.frame === 'circle_outline') {
          svg += `<circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" stroke="${color}" fill="#ffffff" stroke-width="3" />`;
        } else if (this.frame === 'triangle') {
          svg += `<path fill="#FFFFFF" stroke="${color}" transform="scale(${scale}, ${scale})" stroke-width="3" stroke-miterlimit="10" d="M37.5,33.5L37.5,33.5V34c0,0.2,0,0.4,0,0.4`
            + 'c0,0.2,0,0.2-0.2,0.4c0,0.2-0.2,0.2-0.4,0.4h-0.2h-0.2c-0.2,0-0.4,0.2-0.6,0H3.9H3.5L3.1,35c-0.2,0-0.2-0.2-0.4-0.2'
            + 'c0-0.2-0.2-0.2-0.2-0.4c0,0,0-0.2,0-0.4c0-0.2,0.2-0.2,0.2-0.6L18.8,5.5V5.3L19,5.1l0.2-0.2l0.4-0.2c0.2,0,0.2,0,0.4,0'
            + 's0.2,0.2,0.4,0.2l0.2,0.2c0.2,0,0.2,0.2,0.4,0.4L37.5,33.5z"/>';
        }
        if (this.icon != null) {
          const matches = this.getIconValue(this.icon).match(/<svg.*?>(.*?)<\/svg>/s);
          matches[1].split('/>').forEach((elem) => {
            if (elem.indexOf('fill') !== -1) {
              svg += `${elem} transform='scale(${scale}, ${scale})'/>`;
            } else if (elem !== '') {
              svg += `${elem} fill="${this.text_color}" transform="scale(${scale}, ${scale})"/>`;
            }
          });
        }
      } else {
        svg = `<rect fill="#ffffff" stroke="${color}" rx="${radius}" ry="${radius}" width="${this.size}" height="${this.size}"/>`;
      }

      // Actie bol
      if (count !== 0) {
        svg += `<circle cx="${this.size - (circle / 2)}" cy="${circle / 2}" r="${circle}" fill="red"></circle>`;
        svg += `<text text-anchor="middle" x="${this.size - (circle / 2)}" y="${circle / 2 + 3}" font-family="Roboto, sans-serif" font-size="11px" fill="#fff">${count}</text>`;
      }

      svg += '</svg>';


      return `data:image/svg+xml;base64,${btoa(svg)}`;
    },
  },
  props: {
    type: {
      type: String,
      default: 'circle',
    },
    color: {
      type: String,
      default: 'blue',
    },
    color_selected: {
      type: String,
      default: 'red',
    },
    text_color: {
      type: String,
      default: 'white',
    },
    text_color_selected: {
      type: String,
      default: 'green',
    },
    icon: {
      type: String,
      default: '0_label',
    },
    frame: {
      type: String,
      default: 'square_rounded',
    },
    size: {
      type: Number,
      default: 40,
    },
    count: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '0',
    },
  },
  methods: {
    getIconValue(icon) {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`!raw-loader!@/assets/img/manage-plus-icons/${icon}.svg`).default;
    },
  },
};
</script>

<style scoped>

</style>
