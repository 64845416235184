import axios from '@/plugins/axios';

export default {
  getMapObjectTypes() {
    return axios.get('/v1/type?perPage=9999')
      .then(response => response.data);
  },
  getMapObjectTypeAttributes(id, include = '') {
    return axios.get(`/v1/type/${id}/attribute?perPage=9999&include=${include}`)
      .then(response => response.data);
  },
  addAttributeByMapObjectTypeId(id, data) {
    return axios.post(`/v1/type/${id}/attribute`, data)
      .then(response => response.data);
  },
  removeAttributeByMapObjectTypeId(id, data) {
    return axios.delete(`/v1/type/${id}/attribute`, data)
      .then(response => response.data);
  },
  addStatusByMapObjectTypeId(id, data) {
    return axios.post(`/v1/type/${id}/status`, data)
      .then(response => response.data);
  },
  removeStatusByMapObjectTypeId(id, data) {
    return axios.delete(`/v1/type/${id}/status`, data)
      .then(response => response.data);
  },
  getConstraintsByMapObjectTypeId(id) {
    return axios.get(`/v1/type/${id}/constraint`)
      .then(response => response.data);
  },
  addConstraintByMapObjectTypeId(id, data) {
    return axios.post(`/v1/type/${id}/constraint`, data)
      .then(response => response.data);
  },
  removeConstraintByMapObjectTypeId(id, data) {
    return axios.delete(`/v1/type/${id}/constraint`, data)
      .then(response => response.data);
  },
  removeActionStatusByMapObjectTypeId(id, data) {
    return axios.delete(`/v1/type/${id}/action_status`, data)
      .then(response => response.data);
  },
  addActionStatusByMapObjectTypeId(id, data) {
    return axios.post(`/v1/type/${id}/action_status`, data)
      .then(response => response.data);
  },
  getMapObjectTypeById(id) {
    return axios.get(`/v1/type/${id}`)
      .then(response => response.data);
  },
  getMapObjectTypeSettingsById(id) {
    return axios.get(`/v1/type/${id}/setting?perPage=9999`)
      .then(response => response.data);
  },
  addMapObjectTypeSettingsById(id, data) {
    return axios.post(`/v1/type/${id}/setting`, data)
      .then(response => response.data);
  },
  removeMapObjectTypeSettingsById(id, data) {
    return axios.delete(`/v1/type/${id}/setting`, data)
      .then(response => response.data);
  },
  createMapObjectType(data) {
    return axios.post('/v1/type', data)
      .then(response => response.data);
  },
  deleteMapObjectTypeById(id) {
    return axios.delete(`/v1/type/${id}`)
      .then(response => response.data);
  },
  updateMapObjectTypeById(id, data) {
    return axios.put(`/v1/type/${id}`, data)
      .then(response => response.data);
  },
  getMapObjectTypeActionStatusById(id) {
    return axios.get(`/v1/type/${id}/action_status?perPage=9999`)
      .then(response => response.data);
  },
  getMapObjectTypeStatusById(id) {
    return axios.get(`/v1/type/${id}/status?perPage=9999`)
      .then(response => response.data);
  },
  getMapObjectTypeConstraintsById(id) {
    return axios.get(`/v1/type/${id}/constraint?perPage=9999`)
      .then(response => response.data);
  },
  getMapObjectTypeSelectionsById(id) {
    return axios.get(`/v1/type/${id}/map_object_selection?perPage=9999`)
      .then(response => response.data);
  },
  removeSelectionByMapObjectTypeId(id, data) {
    return axios.delete(`/v1/type/${id}/map_object_selection`, data)
      .then(response => response.data);
  },
  addSelectionByMapObjectTypeId(id, data) {
    return axios.post(`/v1/type/${id}/map_object_selection`, data)
      .then(response => response.data);
  },
  getDynamicActionTypesById(id, include = '') {
    return axios.get(`/v1/type/${id}/dynamic_action_type?perPage=999999&include=${include}`)
      .then(response => response.data);
  },
};
