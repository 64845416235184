<template>
  <div>
    <div class="publication-overview">
      {{ $t('publication.publication_not_yet_possible') }}
    </div>
    <CollapsePanel
      :title="$t('publication.export_title')"
      :loading=false
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="export-list">
        <CollapsePanelSmallLink
          :title="$t('publication.export_objects')"
          :loading="loading"
          @clickItem="exportObjects" />
        <CollapsePanelSmallLink
          :title="$t('publication.export_actions')"
          :loading="loading"
          @clickItem="exportActions" />
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import CollapsePanel from '@/components/CollapsePanel.vue';
import CollapsePanelSmallLink from '@/components/CollapsePanelSmallLink.vue';

export default {
  name: 'ExportOverview',
  components: {
    CollapsePanelSmallLink,
    CollapsePanel,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    exportObjects() {
      this.loading = true;
      this.$emit('chooseExportType', 'objects');
    },
    exportActions() {
      this.loading = true;
      this.$emit('chooseExportType', 'actions');
    },
  },
};
</script>

<style scoped>
.export-list {
  margin: -10px 0;
}
.publication-overview {
  padding: 25px;
  border-bottom: 1px solid #d9dadb;
}
</style>
