<template>
  <div>
    <Breadcrumb
      :name=" $t('profile.edituser')"
      :id="user.id"
      :showBackButton="true"
      @backButtonPressed="clickBack"
    >
      <template v-slot:icon>
        <MapIcon color="red" :size="39"/>
      </template>
    </Breadcrumb>
    <CollapsePanel
      :title="$t('profile.user')"
      :loading=loading
      :showCount=false
      :collapsed="true"
      :disabled="false"
      :showCollapseAction="false"
      icon="pen"
    >
      <div class="content-padding" v-if="user">
        <label for="input-field-name"> {{ $t('profile.name') | capitalize }} </label>
        <div id="input-field-name" class="field">
          <input
            id="name"
            name="name"
            type="text"
            v-model="user.name"
          />
        </div>
        <label for="input-field-name"> {{ $t('profile.department') | capitalize }} </label>
        <div id="input-field-department" class="field">
          <input
            id="department"
            name="department"
            type="text"
            v-model="user.userAttribute.data.department"
          />
        </div>
        <label for="input-field-name"> {{ $t('profile.function') | capitalize }} </label>
        <div id="input-field-function" class="field" >
          <input
            id="function"
            name="function"
            type="text"
            v-model="user.userAttribute.data.function"
          />
        </div>
        <label for="input-field-name"> {{ $t('profile.phone') | capitalize }} </label>
        <div id="input-field-phone" class="field">
          <input
            id="phone"
            name="phone"
            type="text"
            v-model="user.userAttribute.data.phone"
          />
        </div>
        <label for="input-field-name"> {{ $t('profile.phone_mobile') | capitalize }} </label>
        <div id="input-field-phone_mobile" class="field">
          <input
            id="phone_mobile"
            name="phone_mobile"
            type="text"
            v-model="user.userAttribute.data.phone_mobile"
          />
        </div>
      </div>
      <div class="edit-object-buttons">
        <a class="btn grey" @click="clickBack">
          {{ $t('button.cancel') | capitalize }}
        </a>
        <a class="btn save-button" @click="saveUser">
          {{ $t('actionlog.save_changes') | capitalize }}
        </a>
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import UserApi from '@/api/user';
import MapIcon from '@/components/Map/MapIcon.vue';

export default {
  name: 'profile',
  components: {
    Breadcrumb,
    CollapsePanel,
    MapIcon,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
  },
  data() {
    return {
      user: [],
      loading: false,
    };
  },
  methods: {
    clickBack() {
      this.$router.push({
        name: 'profile',
      });
    },
    saveUser() {
      UserApi.updateUser(this.user).then(() => {
        this.$router.push({
          name: 'profile',
        });
      });
    },
    toggleShowCollapseMenu() {
      this.showCollapseMenu = !this.showCollapseMenu;
    },
  },
  beforeCreate() {
    UserApi.getMe().then((response) => {
      this.user = response.data;
    });
  },
};
</script>
<style>
.location-details li span{
  display: block;
  float: left;
  width: 40%;
}
.edit-object-buttons {
  padding: 10px 20px;
  position: relative;
  background: #f2f7fe;
  min-height: 50px;
  border-bottom: 1px solid #d9dadb;
}

.edit-object-buttons .save-button {
  float: right;
  margin-right: 7px;
}
.location-details {
  font-size:16px;
}
</style>
