<template>
  <div class="single-result" @click="loadMapObject(map_object)">
    <div class="result-icon">
      <IconByTypeId :map_object_type_id="map_object.map_object_type_id"
                    :label="map_object | mapObjectLabel"
      />
    </div>
    <div class="search-meta">
      <span class="name">
        {{ map_object_type_name }}
        {{ map_object | mapObjectLabel }}
      </span>
      <span v-if="$options.filters.mapObjectLabel(map_object) !== map_object.name"
            class="uuid"
      >
        {{ map_object.name }}
      </span>
      <br>
      <span class="road">
        {{ map_object.geoObject.data.properties.road }}
      </span>
      <span class="city">
        {{ map_object.geoObject.data.properties.city }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import IconByTypeId from '../IconByTypeId.vue';
import EventBus from '@/events/event-bus';

export default {
  name: 'SearchItem',
  components: { IconByTypeId },
  props: {
    map_object: {
      type: Object,
      required: true,
    },
    map_object_type_name: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchMapObject: 'selected/fetchMapObject',
      addSelected: 'map/addSelected',
    }),
    loadMapObject(mapObject) {
      EventBus.$emit('Map.zoomToMapObject', mapObject);
      if (this.$route.path.includes('route_management')) {
        this.$router.push({ name: 'route_management.show', params: { id: mapObject.id } });
      } else if (this.$route.path.includes('drawing')) {
        this.$router.push({ name: 'drawing.show', params: { id: mapObject.id } });
      } else {
        this.fetchMapObject({ id: mapObject.id });
      }
      this.$emit('closeSearch');
    },
  },
};
</script>

<style scoped>
  .single-result {
    min-height: 50px;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
    border-bottom: 1px solid #CCC;
  }

  .single-result:hover {
    background-color: rgba(230, 230, 230, 0.6);
  }

  .single-result .result-icon {
    float: left;
    padding: 0;
    height: 100%;
    vertical-align: middle;
  }

  .single-result .search-meta .name {
    float: left;
  }

  .single-result .search-meta .uuid {
    float: right;
    font-size: 80%;
  }

  .single-result .search-meta .road {
    float: left;
    font-style: italic;
    font-size: 80%;
  }

  .single-result .search-meta .city {
    float: right;
    font-size: 80%;
  }
</style>
