// eslint-disable-next-line import/no-cycle
import axios from '@/plugins/axios';

export default {
  getMapObjectAttributeById(id) {
    return axios.get(`/v1/object/${id}/attribute?perPage=9999`)
      .then(response => response.data);
  },
  getMapObjectbyId(id, include = '') {
    return axios.get(`/v2/object/${id}?include=${include}`)
      .then(response => response.data);
  },
  createMapObject(data) {
    return axios.post('/v2/object', data)
      .then(response => response.data);
  },
  updateMapObjectById(id, data) {
    return axios.put(`/v2/object/${id}`, data)
      .then(response => response.data);
  },
  splitMapObject(id, data) {
    return axios.post(`/v2/object/${id}/split`, data)
      .then(response => response.data);
  },
  findMapObject(data, include = '') {
    return axios.post(`/v2/object/find?include=${include}`, data)
      .then(response => response.data);
  },
  getAttachmentsForMapObject(id, include = '') {
    return axios.get(`/v1/object/${id}/attachment?include=${include}`)
      .then(response => response.data);
  },
  createAttachmentForMapObject(id, data) {
    return axios.post(`/v1/object/${id}/attachment`, data)
      .then(response => response.data);
  },
  uploadAttachmentForMapObject(url, data) {
    return axios.post(`/v1/${url}/javascript`, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data);
  },
  deleteAttachmentForMapObject(id, token) {
    return axios.delete(`/v1/object/${id}/attachment/${token}`)
      .then(response => response.data);
  },
  getCommentsForMapObject(id, include = '', perPage = 15, page = 1) {
    return axios.get(`/v1/object/${id}/comment?include=${include}&perPage=${perPage}&page=${page}`)
      .then(response => response.data);
  },
  getCommentForMapObject(id, commentId, include = '') {
    return axios.get(`/v1/object/${id}/comment/${commentId}?include=${include}`)
      .then(response => response.data);
  },
  createCommentForMapObject(id, data) {
    return axios.post(`/v1/object/${id}/comment`, data)
      .then(response => response.data);
  },
  deleteCommentForMapObject(id, commentId) {
    return axios.delete(`/v1/object/${id}/comment/${commentId}`)
      .then(response => response.data);
  },
  copyComments(id, newMapObjectId) {
    return axios.post(`/v1/object/${id}/comment/copy/${newMapObjectId}`)
      .then(response => response.data);
  },
  deleteMapObjectById(id, deleteWithActions = false) {
    const include = deleteWithActions ? 'remove_actions=true' : '';
    return axios.delete(`/v1/object/${id}?${include}`)
      .then(response => response.data);
  },
  getChildrenForMapObject(id, include = '', depth = false, perPage = 15, page = 1) {
    return axios.get(`/v1/object/${id}/children?include=${include}&depth=${depth}&perPage=${perPage}&page=${page}`)
      .then(response => response.data);
  },
  getImagesForMapObject(id) {
    return axios.get(`/v2/object/${id}/image`)
      .then(response => response.data);
  },
  createImageForMapObject(id, data) {
    return axios.post(`/v2/object/${id}/image`, data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data);
  },
  deleteImageForMapObject(mapObjectId, imageId) {
    return axios.delete(`/v2/object/${mapObjectId}/image/${imageId}`)
      .then(response => response.data);
  },
  copyImages(id, newMapObjectId) {
    return axios.post(`/v1/object/${id}/image/copy/${newMapObjectId}`)
      .then(response => response.data);
  },
  getActions(
    mapObjectId, includes, filter,
    pagination = 1, sort, sortBy,
    onlyNotFinal = false, nested = false,
  ) {
    const filters = `&page=${pagination}&sort=${sort}&sortBy=${sortBy}&onlyNotFinal=${onlyNotFinal}&nested=${nested}${filter}`;
    return axios.get(`/v2/object/${mapObjectId}/action?include=${includes}${filters}`)
      .then(response => response.data);
  },
  getRecurringActions(
    mapObjectId, includes, filter,
    pagination = 1, sort, sortBy,
    onlyNotFinal = false, nested = false,
  ) {
    const filters = `&page=${pagination}&sort=${sort}&sortBy=${sortBy}&onlyNotFinal=${onlyNotFinal}&nested=${nested}${filter}`;
    return axios.get(`/v2/object/${mapObjectId}/recurring_action?include=${includes}${filters}`)
      .then(response => response.data);
  },
  getParentForMapObject(id, include = '') {
    return axios.get(`/v1/object/${id}/parent?include=${include}`)
      .then(response => response.data);
  },
  getParentsForMapObject(id, include = '') {
    return axios.get(`/v1/object/${id}/parents?include=${include}`)
      .then(response => response.data);
  },
  createSelectionForMapObject(id, data) {
    return axios.post(`/v1/object/${id}/selection`, data);
  },
  createMapObjectsInBulk(data) {
    return axios.post('/v2/object/bulk', data)
      .then(response => response.data);
  },
  updateMapObjectsInBulk(data) {
    return axios.put('/v2/object/bulk', data)
      .then(response => response.data);
  },
  deleteMapObjectsInBulk(data) {
    return axios.post('/v2/object/bulk/delete', data)
      .then(response => response.data);
  },
  getNameForNewObject(mapObjectTypeId) {
    return axios.get(`/v2/object/new-name/${mapObjectTypeId}`)
      .then(response => response.data);
  },
};
