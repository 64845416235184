<template>
  <div>
    <span class="network-header">
      <MapIcon
        v-bind="networkIcon"
        :size="30"
      />
      <span>
        {{ $tc('models.network', 1) | capitalize }}
      </span>
      <span> </span>
      <span v-if="network">
        {{ network.name }}
      </span>
    </span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import MapIcon from '@/components/Map/MapIcon.vue';

import NetworkIcon from '@/enums/NetworkIcon';

export default {
  name: 'ActionModalNetworkInformation',
  components: { MapIcon },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      keyValues: 'settings/getKeyValues',
    }),
  },
  data() {
    return {
      networkIcon: NetworkIcon,
    };
  },
};
</script>

<style scoped>
  .network-header {
    position: relative;
    top: -5px;
  }
</style>
