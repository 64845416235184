var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"priority-options"},_vm._l((_vm.actionPriorities),function(actionPriority){return _c('div',{key:actionPriority.id,class:[
              'priority-option',
              `option-${actionPriority.id}`,
              {
                'selected': _vm.value === actionPriority.id,
                'disabled': _vm.disabled
              },
            ],on:{"click":function($event){return _vm.clickButton(actionPriority.id)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('priority.' + actionPriority.name.toLowerCase())))+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }