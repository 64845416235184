<template>
  <div>
    <Loading :visible="loading" />
    <div class="export-title-header">
      <h2 class="module-color module-title">{{ $t('publication.export_objects') }}</h2>
    </div>
    <StepContainer
      :step_number="1"
      :title="$t('publication.select_export_type')"
      :error_text="$t('publication.no_export_type_selected')"
      :button_text="$t('publication.continue')"
      :show_button="current_step === 1"
      @clickButton="chooseExportType"
    >
      <div v-if="current_step === 1">
        <label for="export-type">{{ $t('publication.choose_export_type') }}:</label>
        <br>
        <select id="export-type"
                v-model="chosenExportType"
                class="module-color-border"
        >
          <option v-for="(name, key) in exportTypes" :key="key" :value="key">
            {{ name }}
          </option>
        </select>
      </div>
      <div v-else-if="chosenExportType !== null">
        <i>{{ exportTypes[chosenExportType] }}</i>
      </div>
    </StepContainer>
    <StepContainer
      v-if="chosenExportType !== 'network' || current_step < 3"
      :step_number="2"
      :title="$t('publication.select_export_items')"
      :error_text="$t('publication.no_export_items_selected')"
      :button_text="$t('publication.continue')"
      :show_button="current_step === 2"
      :show_back_button="true"
      @clickButton="chooseExportItems"
      @clickBack="goBack"
    >
      <div v-if="current_step === 2">
        <label for="export-items">
          {{ $t('publication.choose_items') | capitalize }}:
        </label>
        <div id="export-items" class="input-select-field">
          <MultiSelect
            :options="exportItems"
            v-model="chosenExportItems"
            :multiple="true"
            :close-on-select="false"
            track-by="id"
            label="name"
            placeholder=""
            selectLabel=""
            selectedLabel=""
          />
        </div>
      </div>
      <div v-else-if="chosenExportItems.length > 0">
        <i>{{ chosenExportItems.map(item => item.name).join(', ') }}</i>
      </div>
    </StepContainer>
    <StepContainer
      :step_number="3"
      :title="$t('publication.select_export_format')"
      :error_text="$t('publication.no_export_format_selected')"
      :button_text="$t('publication.start_export')"
      :show_button="current_step === 3"
      :show_back_button="true"
      @clickButton="chooseExportFormat"
      @clickBack="goBack"
    >

      <div v-if="current_step === 3">
        <label for="export-format">{{ $t('publication.choose_export_format') }}:</label>
        <br>
        <select id="export-format"
                v-model="chosenExportFormat"
                class="module-color-border"
        >
          <option v-for="(name, key) in filteredExportFormats" :key="key" :value="key">
            {{ name }}
          </option>
        </select>
      </div>
      <div v-else-if="chosenExportFormat !== null && current_step > 3">
        <i>{{ exportFormats[chosenExportFormat] }}</i>
      </div>
    </StepContainer>
    <div v-if="current_step === STEP_SUCCESS" class="export-success">
      <p>{{ $t('publication.export_started') }}</p>
      <a @click="reset" class="btn">{{ $t('publication.export_again') }}</a>
    </div>
    <div v-else-if="current_step === STEP_ERROR" class="export-error">
      <p>{{ $t('error.400') }}:</p>
      <div v-if="error.data.data">
        <p v-if="error.data.data.status">
          {{ error.data.data.status }}: {{ $t('error.'+error.data.data.status) }}
        </p>
        <p v-if="error.data.data">
          Details: {{error.data.data}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from 'vue-multiselect';
import switcher from '@/services/switcher';
import ExportApi from '@/api/export';
import StepContainer from '@/components/Layout/StepContainer.vue';
import Loading from '@/components/Loading.vue';


export default {
  name: 'ExportObjects',
  components: {
    Loading,
    MultiSelect,
    StepContainer,
  },
  data() {
    return {
      loading: false,
      error: null,
      current_step: 1,
      STEP_SUCCESS: 5,
      STEP_ERROR: 6,
      exportTypes: {
        map_object_selection: this.$t('publication.selections'),
        map_object_type: this.$t('publication.object_types'),
        network: this.$options.filters.capitalize(this.$tc('models.network', 1)),
      },
      exportItems: [],
      exportFormats: {
        csv: 'CSV',
        compact_excel: this.$t('publication.compact_excel'),
        detailed_excel: this.$t('publication.detailed_excel'),
        gpx: 'GPX',
        kml: 'KML',
        shape: 'Shapefile',
      },
      chosenExportType: 'map_object_selection',
      chosenExportItems: [],
      chosenExportFormat: 'csv',
    };
  },
  computed: {
    ...mapGetters({
      mapObjectSelections: 'settings/getMapObjectSelections',
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
    filteredExportFormats() {
      const formats = JSON.parse(JSON.stringify(this.exportFormats));
      if (this.chosenExportType !== 'map_object_selection') {
        delete formats.gpx;
      }
      return formats;
    },
  },
  methods: {
    goBack() {
      this.current_step = this.current_step - 1;
      if (this.chosenExportType === 'network') {
        this.current_step = this.current_step - 1;
      }
      if (this.current_step === 1) {
        this.chosenExportItems = [];
      }
    },
    reset() {
      this.loading = false;
      this.error = null;
      this.current_step = 1;
      this.exportItems = [];
      // eslint-disable-next-line prefer-destructuring
      this.chosenExportType = Object.keys(this.exportTypes)[0];
      this.chosenExportItems = [];
      // eslint-disable-next-line prefer-destructuring
      this.chosenExportFormat = Object.keys(this.exportFormats)[0];
    },
    chooseExportType() {
      if (this.chosenExportType === 'network') {
        this.current_step = 3;
      } else {
        this.current_step = 2;
      }
      if (this.chosenExportType === 'map_object_type') {
        this.exportItems = Object.values(this.mapObjectTypes);
      } else if (this.chosenExportType === 'map_object_selection') {
        this.exportItems = Object.values(this.mapObjectSelections);
      }
    },
    chooseExportItems() {
      if (this.chosenExportItems.length === 0) {
        this.$notify({
          type: 'error',
          title: this.$t('publication.error_no_item_selected_title'),
          text: this.$t('publication.error_no_item_selected_content'),
        });
        return;
      }
      this.current_step = 3;
    },
    chooseExportFormat() {
      this.current_step = 4;
      this.startExport();
    },
    startExport() {
      this.loading = true;
      const data = {
        data: {
          network_id: parseInt(switcher.getSelectedNetworkId(), 10),
          export_type: this.chosenExportType,
          export_format: this.chosenExportFormat,
          ids: this.chosenExportItems.map(item => item.id),
        },
      };
      ExportApi.postObjectExport(data).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.create_failed', { model: 'Export' }),
          data: error.response,
        });
        this.error = error.response;
        this.current_step = this.STEP_ERROR;
        this.loading = false;
      }).then((result) => {
        if (result && result.data && result.data.success) {
          this.loading = false;
          this.current_step = this.STEP_SUCCESS;
        }
      });
    },
  },
};
</script>

<style scoped>
.export-title-header {
  padding: 20px 25px 0 25px;
}
.export-success, .export-error {
  padding: 15px;
}
.export-success p, .export-error p {
  font-weight: bold;
  font-style: italic;
}
</style>
