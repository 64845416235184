<template>
  <div id="main" :style="{'--module-color': `var(--${moduleColor})`}">
    <SideNav>
      <Module
        v-if="$can('module', 'routebeheer')
        || $can('module', 'beheerplus')"
        :nav="$t('module.route_management')"
        :title="$t('module.route_management') | capitalize"
        router-link="route_management"
      />
      <Module
        v-if="$can('module', 'tekenen')"
        :nav="$t('module.drawing')"
        :title="$t('module.drawing') | capitalize"
        router-link="drawing"
      />
      <Module
        v-if="$can('module', 'selecties')"
        :nav="$t('module.selection')"
        :title="$t('module.selection') | capitalize"
        router-link="selection"
      />
      <Module
        v-if="$can('module', 'publicatie')"
        :nav="$t('module.publication')"
        :title="$t('module.publication') | capitalize"
        router-link="publication"
      />
      <Settings />
    </SideNav>
    <Map v-if="!loadingNetwork && !loadingSettings && network !== null" :network="network" />
    <Drawer :nav="nav" :title="title" :fullscreen="fullscreen">
      <Loading :visible="loadingNetwork || loadingSettings" />
      <slot v-if="!loadingNetwork && !loadingSettings"></slot>
    </Drawer>

    <div id="map-actions-float">
      <Search />
      <MapFilter />
    </div>
    <InfoModal />
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import Drawer from '@/components/Layout/Drawer.vue';
import Module from '@/components/Layout/Module.vue';
import Search from '@/components/Layout/Search.vue';
import MapFilter from '@/components/Layout/MapFilter.vue';
import Settings from '@/components/Layout/Settings.vue';
import SideNav from '@/components/Layout/SideNav.vue';
import Map from '@/components/Map/LeafletMap.vue';
import Loading from '@/components/Loading.vue';
// import Statistics from '@/modules/route_manager/components/Statistics.vue';
import InfoModal from '@/components/InfoModal.vue';

export default {
  name: 'ModuleLayoutWithRouting',
  components: {
    InfoModal,
    // Statistics,
    Loading,
    Drawer,
    Module,
    Search,
    MapFilter,
    Settings,
    SideNav,
    Map,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
    }),
    nav() {
      switch (this.$route.name) {
        case 'route_management':
          return 'routebeheer';
        case 'drawing':
          return 'tekenen';
        case 'selection':
          return 'selecties';
        case 'publication':
          return 'publicaties';
        default:
          return 'routebeheer';
      }
    },
    title() {
      let routeName = this.$route.name;
      if (routeName === 'home') {
        routeName = 'route_management';
      }
      // eslint-disable-next-line prefer-destructuring
      routeName = routeName.split('.')[0];
      return this.$options.filters.capitalize(this.$t(`module.${routeName}`));
    },
    moduleColor() {
      let routeName = this.$route.name;
      // eslint-disable-next-line prefer-destructuring
      routeName = routeName.split('.')[0];
      return `module-${routeName}-color`;
    },
  },
  props: {
    fullscreen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loadingNetwork: false,
      loadingSettings: false,
    };
  },
  async beforeMount() {
    this.loadingNetwork = true;
    this.loadingSettings = true;
    await this.$store.dispatch('settings/fetchNetwork').then(async () => {
      this.loadingNetwork = false;

      await this.$store.dispatch('settings/fetchSettings').then(() => {
        this.loadingSettings = false;
      });
    });
  },
};
</script>

<style scoped>
  #main {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #dddddd;
  }

  #map-actions-float {
    position: absolute;
    z-index: -1;
    right: 20px;
    top: 15px;
  }
  .mgl-map-wrapper {
    left: 80px;
    z-index: -1;
  }
</style>
