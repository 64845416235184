<template>
    <div class="container-fluid bg-login">
      <div class="bg-banner">
        <a-row>
            <a-col :span="24">
                <div class="form-login">
                    <a-form class="login-form">
                        <a-card :bordered="false" title="Wachtwoord vergeten">
                          <a-icon @click="$router.back();" type="arrow-left" slot="extra" />
                            <div v-if="sentMail">
                              <span class="icon icon-email"></span>
                              <p class="title">We hebben u een e-mail verstuurd.</p>
                              <p>Na het klikken op de link in de e-mail,
                                kunt u een nieuw wachtwoord instellen.</p>
                              <p><i>Let op: deze link is slechts  1  uur geldig.</i></p>
                            </div>
                            <div v-else>
                              <a-form-item label="E-Mail" class="mb-0">
                                <a-input size="large"
                                         placeholder="Uw e-mailadres"
                                         v-model="email" id="email"
                                />
                              </a-form-item>
                              <a-button type="primary" class="pull-right mb-20"
                                        :loading="loading" @click="postPasswordForgot"
                              >
                                Wachtwoord resetten
                              </a-button>
                            </div>
                        </a-card>
                    </a-form>
                </div>
            </a-col>
        </a-row>
      </div>
    </div>
</template>

<script>

import '../../plugins/ant-design-vue';
import Auth from '@/api/auth';
import axios from '@/plugins/axios';

export default {
  name: 'login',
  data() {
    return {
      email: '',
      loading: false,
      sentMail: false,
    };
  },
  methods: {
    resetStoredData() {
      localStorage.removeItem('tenant_id');
      localStorage.removeItem('network_id');
      axios.defaults.headers['x-tenant'] = null;
      axios.defaults.headers['x-network'] = null;
    },
    postPasswordForgot() {
      this.resetStoredData();
      this.loading = true;
      Auth.sendPasswordResetMail(this.email)
        .then(() => {
          this.sentMail = true;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  beforeMount() {
    this.$emit('showFullscreen', true);
  },
};
</script>

<style>

    .form-login {
        max-width: 400px;
        height: 100vh;
        margin: 25vh auto auto;
    }

    .bg-login {
        background-image: url('/img/bg.png');
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
    }

    .bg-banner {
      background: url('/img/banner.svg') no-repeat;
    }

    .login-form .ant-card-head-title {
        color: #3bade8;
    }

    .login-form .ant-card-body {
        background: #f9f9f9 !important;
    }

    .login-form input:-webkit-autofill,
    .login-form input:-webkit-autofill:hover,
    .login-form input:-webkit-autofill:focus,
    .login-form input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .login-form input {
        font-size: 13px;
        border: 0;
    }

    .login-form .ant-select {
        font-size: 13px;
        border: 0;
    }

    .login-form .ant-select-lg .ant-select-selection--single {
        border: none;
    }

    .login-form button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .login-form .ant-card-body {
        padding: 15px 30px;
    }

</style>
