<template>
  <a :class="{'big-link-item': true, 'warning': warning}" @click="clickItem">
    <Loading :visible=loading />
    <span class="item-name">{{ title }}</span>
    <br />
    <small class="item-type">{{ type }}</small>
    <span v-if="warning" class="warning-icon">
      <font-awesome-icon icon="exclamation-circle" />
    </span>
    <span class="arrow-right">
      <font-awesome-icon icon="chevron-right" />
    </span>
  </a>
</template>

<script>
import Loading from './Loading.vue';

export default {
  name: 'CollapsePanelBigLink',
  components: {
    Loading,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    warning: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    clickItem() {
      this.$emit('clickItem');
    },
  },
};
</script>

<style scoped>
  .big-link-item {
    position: relative;
    display: block;
    text-decoration: none;
    color: #42464c;
    overflow: hidden;
    border-bottom: 1px solid #cfd4da;
    padding: 10px 20px;
    touch-action: manipulation;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    font-size: 1.0em;
    margin: 0;
  }
  .big-link-item.warning {
    background: rgba(238, 51, 95, 0.1);
  }
  .big-link-item:hover {
    background: #ebf3fd;
  }

  .big-link-item span.item-name {
    position: relative;
    color: #42464c;
    overflow: hidden;
  }

  .big-link-item span.warning-icon {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(238, 51, 95, 1);
  }

  .big-link-item span.arrow-right {
    transition: all 0.3s ease;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: .5em;
    opacity: .5;
  }
</style>
