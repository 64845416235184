<template>
  <div @click="closeModal" :class="{'modal-container': true, 'show': visible}" style="">
    <div @click="closeModal" class="modal-mask"></div>
    <div @click="closeModal" class="modal-wrap">
      <div class="modal" :style="'width: '+width" v-on:click.stop>
        <Loading :visible="loading" />
        <div class="modal-content" v-on:click.stop>
          <div v-if="header" class="modal-header">
            <span class="title">
              <slot name="header" />
            </span>
            <div class="close-button"  @click="closeModal">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <div :class="{'modal-body': true, 'no-padding': no_padding}">
            <slot name="body" />
          </div>
          <div v-if="footer" class="modal-footer">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '../Loading.vue';

export default {
  name: 'Modal',
  components: { Loading },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    header: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '80%',
    },
    no_padding: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: true,
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
  watch: {
  },
};
</script>

<style scoped>
  .modal-container {
    opacity: 0;
    transition: opacity 0.3s ease-out;
    z-index: 1000;
  }
  .modal-container.show {
    transition: opacity 0.3s ease-out;
    opacity: 1;
  }
  .modal-mask {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    z-index: 1000;
  }

  .modal-wrap {
    display: none;
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    outline: 0;
  }

  .modal-container.show .modal-mask,
  .modal-container.show .modal-wrap {
    display: block;
  }

  .modal {
    border-radius: 10px;
    width: 80%;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 0;
    list-style: none;
    position: relative;
    margin: 0 auto 20px;
    top: 50px;
  }

  .modal-content {
    position: relative;
    background-color: #fff;
    border: 0;
    margin-bottom: 100px;
    border-radius: 10px;
    background-clip: padding-box;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .modal-header {
    padding: 16px 24px;
    border-radius: 10px 10px 0 0;
    background: #fff;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid #e8e8e8;
    font-size: 1.3em;
  }

  .modal-body {
    padding: 24px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .modal-body.no-padding {
    padding: 0;
  }

  .modal-footer {
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    border-radius: 0 0 10px 10px;
    overflow: auto;
  }
  .close-button {
    float: right;
    font-size: 1.5em;
    line-height: 28px;
    display: block;
  }
  .close-button:hover {
    color: #ee335f;
    cursor: pointer;
  }

</style>
