<template>
  <div></div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';
import GeoType from '@/enums/GeoType';

export default {
  name: 'ShowObject',
  props: {
    map: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      keyValues: 'settings/getKeyValues',
      mapObject: 'selected/getMapObject',
      mapObjectId: 'selected/getMapObjectId',
      mapObjectTypes: 'settings/getMapObjectTypes',
      network: 'settings/getNetworkModel',
    }),
  },
  methods: {
    ...mapActions({
      fetchMapObject: 'selected/fetchMapObject',
      fetchMapObjectByLatLng: 'selected/fetchMapObjectByLatLng',
      addSelectedOnMap: 'map/addSelected',
      removeSelectedOnMap: 'map/removeSelected',
      resetSelectedOnMap: 'map/resetSelected',
    }),
    mapClick(e) {
      console.log('Map ShowObject mapClick()', e);
    },
    changeRoute(id) {
      console.log('Map ShowObject changeRoute()', id);
      if (parseInt(this.$route.params.id, 10) === parseInt(id, 10)) {
        return;
      }
      console.log('path', this.$route.path);
      if (this.$route.path.includes('route_management')) {
        this.$router.push({ name: 'route_management.show', params: { id } });
      } else if (this.$route.path.includes('drawing')) {
        this.$router.push({ name: 'drawing.show', params: { id } });
      } else {
        console.log('Nothing!');
      }
    },
    clickPoint(data) {
      console.log('Map ShowObject clickPoint()', data);
      this.resetSelectedOnMap();
      this.addSelectedOnMap({ mapObject: data });
      this.changeRoute(data.id);
    },
    clickLine(mapObject) {
      console.log('Map ShowObject clickLine()', mapObject, mapObject.id);
      this.resetSelectedOnMap();
      this.addSelectedOnMap({ mapObject });
      if (mapObject.startPoint && mapObject.startPoint.data) {
        this.addSelectedOnMap({ mapObject: mapObject.startPoint.data });
      }
      if (mapObject.endPoint && mapObject.endPoint.data) {
        this.addSelectedOnMap({ mapObject: mapObject.endPoint.data });
      }
      this.changeRoute(mapObject.id);
    },
    clickPolygon(data) {
      console.log('Map ShowObject clickPolygon()', data);
      this.resetSelectedOnMap();
      this.addSelectedOnMap({ mapObject: data });
      this.changeRoute(data.id);
    },
    loadMapObject(mapObject) {
      console.log('Map ShowObject loadMapObject()', mapObject);
    },
  },
  mounted() {
    console.log('Map ShowObject mounted()');
    this.map.off('click');
    this.map.on('click', (e) => {
      this.mapClick(e);
    });
    EventBus.$on('Map.clickMapObject', (data, type) => {
      console.log('Map ShowObject clickMapObject()');
      if (type === GeoType.point) {
        this.clickPoint(data);
      } else if (type === GeoType.linestring) {
        this.clickLine(data);
      } else if (type === GeoType.polygon) {
        this.clickPolygon(data);
      }
    });
  },
  beforeDestroy() {
    console.log('Map ShowObject beforeDestroy()');
    this.resetSelectedOnMap();
    EventBus.$off('Map.clickMapObject');
  },
  watch: {
    mapObject(value) {
      if (value) {
        this.loadMapObject(this.mapObject);
      }
    },
  },
};
</script>

<style scoped>

</style>
