<template>
  <div id="search-component">
    <div id="search-pop-up-field"
         :class="['module-color-border', {'active': active}]"
    >
      <div id="search-wrapper">
        <select id="search-type-select"
                v-model="searchType"
                @change="selectSearchType"
                class="module-color-border"
        >
          <option value="all">
            {{ $t('search.all') | capitalize }}
          </option>
          <option  disabled>
            {{ $t('search.specific_objects') | capitalize }}
          </option>
          <option value="label">
            {{ $t('model.name') | capitalize }}
          </option>
          <option value="address">
            {{ $t('model.address') | capitalize }}
          </option>
          <option v-for="(type, key) in mapObjectTypes"
                  :key="key"
                  :value="'type-'+type.id"
          >
            {{ type.name }}
          </option>
        </select>
        <input
          id="search-input"
          ref="search"
          :class="['module-color-border']"
          type="text"
          v-model="searchInput"
          @input="typing"
        />
      </div>
      <button type="submit" id="submit-search" class="module-color"
              ref="searchButton"
              @click="active = !active">
        <font-awesome-icon icon="search" />
      </button>
    </div>
    <div v-if="active && showSearchResults"
         id="results"
         style="background: white"
    >
      <div v-if="searchType === 'address' || searchType === 'all'" id="show-address-results">
        <div class="result-header module-color">{{ $t('model.address') | capitalize }}</div>
        <div id="addresses-results">
          <div v-if="addressResults.length === 0" class="no-results">
            <span>{{ $t('model.no_results') | capitalize }}.</span>
          </div>
          <div v-for="(address, key) in addressResults" :key="key"
               class="address-result"
               @click="clickAddress(address.geometry.location.lat, address.geometry.location.lng)"
          >
            {{ address.formatted_address }}
          </div>
        </div>
      </div>
      <div class="result-header module-color">
        {{ $tc('models.object', 0) | capitalize }}
        <span id="search-exact-results">
          <input @change="searchObjects"
                 type="checkbox"
                 name="exact-results"
                 v-model="exactResults" />
          {{ $t('search.only_exact_results') | capitalize }}
        </span>
      </div>

      <div v-if="objectResults.length === 0" class="no-results">
        <span>{{ $t('model.no_results') | capitalize }}.</span>
      </div>
      <Loading :visible="objectsLoading || addressLoading" />
      <SearchItem v-for="(object, key) in objectResults"
                  :key="key"
                  :map_object="object"
                  :map_object_type_name="mapObjectTypes[object.map_object_type_id].name"
                  @closeSearch="active = false"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MapObjectApi from '@/api/map_object';
import GoogleApi from '@/api/google';

import EventBus from '@/events/event-bus';

import Loading from '../Loading.vue';
import SearchItem from './SearchItem.vue';

export default {
  name: 'Search',
  components: { SearchItem, Loading },
  data() {
    return {
      active: false,
      showSearchResults: false,
      searchInput: '',
      searchType: 'all',
      objectResults: [],
      addressResults: [],
      timer: null,
      interval: 300,
      objectsLoading: false,
      addressLoading: false,
      lastPromise: null,
      exactResults: false,
      bbox: '',
      operator: 'ILIKE',
    };
  },
  computed: {
    ...mapGetters({
      networkId: 'switcher/getNetworkId',
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
  },
  methods: {
    selectSearchType() {
      this.$nextTick(() => this.$refs.search.focus());
      this.typing();
    },
    typing() {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.searchObjects, this.interval);
    },
    searchObjects() {
      this.showSearchResults = this.searchInput.length > 0;
      if (!this.showSearchResults) {
        return;
      }
      let input = this.searchInput;
      if (!this.exactResults) {
        input = input.replace(/[^A-Za-z0-9]/gi, '').split('').join('%');
        input = `%${input}%`;
      }

      let queries = {};
      if (this.searchType.substring(0, 5) === 'type-') {
        queries = [
          {
            query: {
              map_object_type_id: parseInt(this.searchType.substring(5), 10),
            },
            operator: '=',
          }, {
            query: {
              label: input,
            },
            operator: this.operator,
          },
        ];
      } else {
        const query = {};
        query[this.searchType] = input;
        queries = [{ query, operator: this.operator }];
      }
      this.findObjects(queries);
      if (this.searchType === 'address' || this.searchType === 'all') {
        this.searchAddress();
      }
    },
    findObjects(queries) {
      this.objectsLoading = true;
      const promise = MapObjectApi.findMapObject(
        { data: { queries, limit: 10000 } }, 'mapObjectLabel',
      );
      this.lastPromise = promise;
      return new Promise(() => {
        promise.then((response) => {
          if (promise === this.lastPromise) {
            this.lastPromise = null;
            this.objectResults = response.data.sort(
              (a, b) => (a.map_object_type_id > b.map_object_type_id ? 1 : -1),
            );
            this.objectsLoading = false;
          }
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: this.$t('error.loading_failed', { model: this.$tc('models.mapobject', 0) }),
            data: error.response,
          });
          this.objectsLoading = false;
        });
      });
    },
    searchAddress() {
      this.addressLoading = true;
      GoogleApi.searchGoogleGeocodingApi(this.searchInput, this.bbox).then((response) => {
        if (response.status === 'OK') {
          this.addressResults = response.results;
        } else {
          this.addressResults = [];
        }
        this.addressLoading = false;
      }).catch((error) => {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$t('model.address') }),
          data: error.response,
        });
        this.addressLoading = false;
      });
    },
    clickAddress(lat, lng) {
      EventBus.$emit('Map.zoomTo', lat, lng);
      this.active = false;
    },
    calculateBBoxFromNetwork(network) {
      if (!network || !network.geoObject || !network.geoObject.data) {
        return;
      }
      const coords = network.geoObject.data.geometry.coordinates;
      const lats = []; const lngs = [];
      coords[0].forEach((coord) => {
        lats.push(coord[1]);
        lngs.push(coord[0]);
      });
      const minlat = Math.min.apply(null, lats);
      const maxlat = Math.max.apply(null, lats);
      const minlng = Math.min.apply(null, lngs);
      const maxlng = Math.max.apply(null, lngs);

      this.bbox = `${minlat},${minlng}|${maxlat},${maxlng}`;
    },
  },
  watch: {
    active() {
      if (this.active) {
        this.$nextTick(() => this.$refs.search.focus());
      }
    },
  },
  mounted() {
    this.$store.dispatch('settings/fetchNetwork').then((network) => {
      this.calculateBBoxFromNetwork(network);
    });
  },
};
</script>

<style scoped>
  #search-component {
    float: left;
  }

  #search-component #search-pop-up-field {
    position: relative;
    width: 0;
    margin-bottom: 15px;
    overflow: hidden;
    height: 50px;
    padding: 0 46px 0 2px;
    border: 1px solid var(--module-color);
    border-radius: 555px;
    font-weight: 300;
    line-height: 36px;
    background: #fff;
    transition: width 0.2s ease;
  }

  #search-component #search-pop-up-field.active {
    width: 420px;
  }

  #search-component #search-pop-up-field #search-wrapper {
    display: none;
  }

  #search-component #search-pop-up-field.active #search-wrapper {
    display: inline-block;
  }

  #search-component #search-pop-up-field #search-type-select {
    width: 150px;
    height: 50px;
    border: 0;
    border-right: 1px solid var(--module-color);
    border-radius: 555px 0 0 555px;
    padding: 0 10px 0 15px;
    cursor: pointer;
    background: #fefefe;
  }

  #search-component #search-pop-up-field #search-input {
    width: 220px;
    height: 50px;
    padding: 0 5px 0 10px;
    border: 0;
    border-right: 1px solid var(--module-color);
    font-weight: 300;
    line-height: 36px;
    background: #fff;
    font-size: 1em;
    letter-spacing: .05em;
    -webkit-appearance: none;
    font-family: inherit;
    margin: 0;
  }

  #search-component #search-pop-up-field #submit-search {
    position: absolute;
    right: 0;
    top: 25px;
    transform: translateY(-50%);
    font-size: 1em;
    color: var(--module-color);
    width: 50px;
    height: 50px;
    line-height: 50px;
    border: none;
    background: none;
    padding: 0;
    border-radius: 555px;
    display: inline-block;
    font-weight: 300;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: .1em;
    transition: all 0.3s ease;
    -webkit-appearance: button;
    cursor: pointer;
  }

  #search-component #search-pop-up-field #submit-search:hover {
    color: #42464c;
  }

  #search-component #results {
    max-height: 450px;
    overflow-y: auto;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  #search-component #results .result-header {
    padding: 8px;
    color: var(--module-color);
    border-bottom: 1px solid #CCC;
    border-top: 1px solid #CCC;
  }

  #search-component #results .result-header #search-exact-results {
    float: right;
    color: #777;
  }

  #search-component #results #show-address-results #addresses-results .address-result {
    min-height: 45px;
    line-height: 35px;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
    border-bottom: 1px solid #CCC;
  }

  #search-component #results #show-address-results #addresses-results .address-result:hover {
    background-color: rgba(230, 230, 230, 0.6);
  }

  #search-component #results div.no-results {
    height: 35px;
    line-height: 35px;
    vertical-align: middle;
  }

  #search-component #results div.no-results span {
    margin: 0 15px;
    color: #777;
    font-style: italic;
    cursor: initial;
  }

</style>
