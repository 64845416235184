<template>
  <div>
    <Breadcrumb
      :id="network.id"
      :name="$t('drawing.multiselect')"
      :showBackButton="true"
      @backButtonPressed="goBack"
    >
      <template v-slot:icon>
        <MapIcon v-bind="networkIcon" icon="13_roadsign" :size="39"/>
      </template>
    </Breadcrumb>
    <CollapsePanel
      :title="$t('drawing.choose_type_for_multiselect')"
      :loading=false
      :showCount=false
      :showCollapseAction="false"
      :collapsed="true"
    >
      <div class="map-object-selection-types-list">
        <CollapsePanelSmallLink
          v-for="(mapObjectType, index) in mapObjectTypes"
          :key="index" :title="mapObjectType.name"
          :loading="chosenMapObjectType === mapObjectType.id"
          @clickItem="chooseMapObjectType(mapObjectType.id)" />
      </div>
    </CollapsePanel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CollapsePanel from '@/components/CollapsePanel.vue';
import EventBus from '@/events/event-bus';
import MapIcon from '@/components/Map/MapIcon.vue';
import Breadcrumb from '@/components/Layout/Breadcrumb.vue';
import NetworkIcon from '@/enums/NetworkIcon';
import CollapsePanelSmallLink from '@/components/CollapsePanelSmallLink.vue';

export default {
  name: 'drawing.multiselect',
  components: {
    CollapsePanelSmallLink,
    Breadcrumb,
    MapIcon,
    CollapsePanel,
  },
  computed: {
    ...mapGetters({
      network: 'settings/getNetworkModel',
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
  },
  data() {
    return {
      networkIcon: NetworkIcon,
      chosenMapObjectType: null,
    };
  },
  methods: {
    ...mapActions({
      fetchSelection: 'selected/fetchSelection',
    }),
    goBack() {
      this.$router.push({ name: 'drawing' });
    },
    chooseMapObjectType(mapObjectTypeId) {
      if (!this.$can('index', 'mapobjectselection')) {
        this.$notify({
          type: 'error',
          title: this.$t('error.loading_failed', { model: this.$tc('models.mapobjectselection', 0) }),
          text: this.$t('error.no_access_index', { model: this.$tc('models.mapobjectselection', 0) }),
        });
        this.chosenMapObjectType = null;
        return;
      }
      this.chosenMapObjectType = mapObjectTypeId;
      this.$router.push({ name: 'drawing.multiselect.choose', params: { type: mapObjectTypeId } });
    },
  },
  mounted() {
    EventBus.$emit('Map.changeMapMode', null);
    EventBus.$emit('Map.enableSelectArea');
    EventBus.$on('Map.selectArea', (e) => {
      console.log(e);
    });
  },
};
</script>

<style scoped>
.map-object-selection-list {
  margin: -10px 0 0 0;
}
.search-field {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #cfd4da;
}
.search-field label {
  width: 25%;
  padding-left: 20px;
}
.search-field .search-icon {
  width: 40px;
  height: 34px;
  transition: all 0.3s ease;
  position: absolute;
  padding: 7px 10px;
  top: 42%;
  transform: translateY(-50%);
  opacity: .8;
}
.search-field .search-icon:hover {
  box-shadow: 0 0 100px 100px rgba(255, 255, 255, 0.1);
  opacity: 1;
  cursor: pointer;
}
</style>
