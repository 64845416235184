export default (store) => {
  const { ability } = store.getters;

  ability.update(store.state.rules);

  return store.subscribe((mutation) => {
    // eslint-disable-next-line default-case
    switch (mutation.type) {
      case 'setUserAndPermissions':
        ability.update(store.state.rules);
        break;
      case 'logout':
        ability.update([]);
        break;
    }
  });
};
