<template>
  <div :class="['loading-modal', 'loading', {'open': visible}]">
    <LoadingIcon />
  </div>
</template>

<script>
import LoadingIcon from './LoadingIcon.vue';

export default {
  name: 'Loading',
  components: { LoadingIcon },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
  .loading-modal {
    border-radius: inherit;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none; }
  .loading-modal.big {
    width: 100%;
    background: none; }
  .loading-modal.open {
    display: block; }

</style>
