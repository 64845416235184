// eslint-disable-next-line import/no-cycle
import store from '../store';

export default {
  getSelectedTenantId() {
    return localStorage.getItem('tenant_id');
  },
  clearSelectedTenantId() {
    store.dispatch('switcher/unsetTenantId');
    localStorage.removeItem('tenant_id');
  },
  getSelectedNetworkId() {
    return localStorage.getItem('network_id');
  },
  clearSelectedNetworkId() {
    store.dispatch('switcher/unsetNetworkId');
    localStorage.removeItem('network_id');
  },
};
