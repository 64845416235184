<template>
  <div></div>
</template>

<script>
import L from 'leaflet';
import { mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';

export default {
  name: 'SplitLine',
  props: {
    map: {
      type: Object,
      required: true,
    },
    mapObject: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      mapObjectTypes: 'settings/getMapObjectTypes',
    }),
  },
  data() {
    return {
      coords: [],
      tempPoints: [],
      tempCluster: new L.LayerGroup({}),
      chosenIndex: null,
      chosenMarker: null,
    };
  },
  methods: {
    addCoordinateWithoutRedraw(key, lat, lng) {
      this.coords.push({ key, lat, lng });
      const coord = this.addTempCoordinate(key, lat, lng);
      this.tempPoints.push(coord);
    },
    addTempCoordinate(key, lat, lng) {
      const icon = this.createTempCoordinateIcon();
      const coordinate = new L.Marker([lat, lng], {
        icon,
        key,
      });
      coordinate.on('click', () => {
        this.handleTempCoordinateClick(key, coordinate);
      });
      this.tempCluster.addLayer(coordinate);
      return coordinate;
    },
    handleTempCoordinateClick(key, coordinate) {
      if (this.chosenIndex) {
        this.chosenMarker.setIcon(this.createTempCoordinateIcon());
      }
      this.chosenIndex = key;
      this.chosenMarker = coordinate;
      this.chosenMarker.setIcon(this.createTempCoordinateIcon('red'));
      EventBus.$emit('Map.shareSplitLineIndex', key);
    },
    createTempCoordinateIcon(color = '#666') {
      const svg = `<svg version="1.0" width="18" height="18" xmlns="http://www.w3.org/2000/svg">
               <g>
                <rect stroke-width="2" id="temp_coord" height="14" width="14"
                 y="2" x="2" stroke="${color}" fill="#ffffff" rx="2" ry="2"
                />
               </g>
              </svg>`;
      const iconUrl = `data:image/svg+xml;base64,${btoa(svg)}`;
      return L.icon({ iconUrl, iconAnchor: [8, 8] });
    },
    setStartPoint(mapObject) {
      const coord = mapObject.geoObject.data.geometry.coordinates;
      const lat = coord[1];
      const lng = coord[0];
      this.coords.push({ key: 'start_point', lat, lng });
      this.tempPoints.push({ key: 'start_point', lat, lng });

      const marker = this.createPoint(mapObject);

      this.startPoint = marker;
      this.tempCluster.addLayer(marker);
    },
    setEndPoint(mapObject) {
      const coord = mapObject.geoObject.data.geometry.coordinates;
      const lat = coord[1];
      const lng = coord[0];
      this.coords.push({ key: 'end_point', lat, lng });

      const marker = this.createPoint(mapObject);

      this.endPoint = marker;
      this.tempCluster.addLayer(marker);
    },
    createPoint(mapObject) {
      const { coordinates } = mapObject.geoObject.data.geometry;
      const marker = new L.Marker([coordinates[1], coordinates[0]]);
      marker.id = mapObject.id;
      marker.label = this.$options.filters.mapObjectLabel(mapObject);
      const iconData = this.mapObjectTypes[mapObject.map_object_type_id].icon.data;
      const icon = this.getIconByMapObjectType(iconData, 0, marker.label, true);
      marker.setIcon(icon);
      return marker;
    },
    setCoordinatesByObject(mapObject) {
      if (mapObject && mapObject.geoObject && mapObject.geoObject.data.geometry.coordinates) {
        this.nextKey = mapObject.geoObject.data.geometry.coordinates.length;
        this.setStartPoint(mapObject.startPoint.data);
        mapObject.geoObject.data.geometry.coordinates.forEach((coords, idx) => {
          if (idx === 0 || idx === (this.nextKey - 1)) {
            return;
          }
          const key = idx;
          const lat = coords[1];
          const lng = coords[0];
          this.addCoordinateWithoutRedraw(key, lat, lng);
        });
        this.setEndPoint(mapObject.endPoint.data);
      }
    },
    resetDraw() {
      this.coords = [];
      this.tempPoints = [];
      this.startPoint = false;
      this.endPoint = false;
      this.nextKey = 0;
      this.tempCluster = new L.LayerGroup({});
      this.chosenIndex = null;
      this.chosenMarker = null;
    },
  },
  mounted() {
    this.map.off('click');
    this.map.addLayer(this.tempCluster);
    if (this.mapObject) {
      this.setCoordinatesByObject(this.mapObject);
    }
  },
  beforeDestroy() {
    this.map.removeLayer(this.tempCluster);
    this.resetDraw();
  },
  watch: {
    mapObject() {
      if (this.coords.length === 0) {
        this.setCoordinatesByObject(this.mapObject);
      }
    },
  },
};
</script>

<style scoped>

</style>
