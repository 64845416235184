<template>
  <div class="dropdown">
    <a class="btn main module-color-background save-button" @click="clickButton(mainButton)">
      {{ $t(`button.${mainButton}`) }}
    </a>
    <a class="btn dropdown-icon module-color-background" @click="dropdown">
      <font-awesome-icon v-if="showDropdown" icon="chevron-up" />
      <font-awesome-icon v-else icon="chevron-down" />
    </a>
    <div v-if="showDropdown" class="dropdown-content">
      <a v-for="(button) in otherButtons" :key="button" @click="clickButton(button)">
        {{ $t(`button.${button}`) }}
      </a>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

const saveButtons = [
  'save_and_show',
  'save_and_edit',
  'save_and_index',
  'save_and_create',
];
export default {
  name: 'MultiButton',
  computed: {
    ...mapGetters({
      preferredSaveButton: 'settings/getPreferredSaveButton',
    }),
    mainButton() {
      if (this.preferredSaveButton !== null
        && this.saveButtons.includes(this.preferredSaveButton)
      ) {
        return this.preferredSaveButton;
      }
      return this.saveButtons[0];
    },
    otherButtons() {
      const buttons = JSON.parse(JSON.stringify(this.saveButtons));
      if (this.preferredSaveButton !== null
        && buttons.includes(this.preferredSaveButton)
      ) {
        return buttons.filter(item => item !== this.preferredSaveButton);
      }
      return buttons.splice(1);
    },
  },
  data() {
    return {
      showDropdown: false,
      saveButtons,
    };
  },
  methods: {
    dropdown() {
      this.showDropdown = !this.showDropdown;
    },
    clickButton(button) {
      this.$store.dispatch('settings/setCurrentPreferredSaveButton', { button });
      this.$emit(button);
      return `hello${button}`;
    },
  },
};
</script>

<style scoped>
  .dropdown {
    margin-right: 7px;
    float: right;
  }
  .dropdown a.btn.main {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 8px;
  }
  .dropdown a.btn.dropdown-icon {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 0;
    padding-left: 8px;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    /*display: none;*/
    position: absolute;
    background: #efefef;
    min-width: 160px;
    z-index: 1;
    display: block;
    transform: translate3d(0px, -112px, 0px);
    top: -6px;
    left: auto;
    right: 25px;
    bottom: auto;
    letter-spacing: .1em;
    font-size: .7em;
    text-transform: uppercase;
    border: 1px solid rgba(0,40,100,.12);
    border-radius: 10px;
  }

  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
  }

  .dropdown-icon {
    border-left: 1px solid white;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    padding-left: 7px;
  }
</style>
