<template>
<div class="step-container">
  <div class="step-box select-export-type">
    <div class="step-circle circle-export">
      <div class="step">{{ step_number }}</div>
    </div>
    <div class="text">
      <div class="step-text">{{ title }}</div>
      <div class="select-export-type-text result-text panel">
        <p>
          <slot></slot>
        </p>
        <div>
          <a
            v-if="show_button"
            @click="clickButton"
            class="btn module-color-background"
          >
            {{ button_text }}
          </a>
          <a
            v-if="show_button && show_back_button"
            @click="clickBackButton"
            class="btn module-color-background"
          >
            {{ $t('button.back') }}
          </a>
      </div>
        <p></p>
      </div>
      <div class="select-export-type-error error-text">
        {{ error_text }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'StepContainer',
  props: {
    step_number: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    error_text: {
      type: String,
      required: true,
    },
    button_text: {
      type: String,
      required: true,
    },
    show_button: {
      type: Boolean,
      required: true,
    },
    show_back_button: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    clickButton() {
      this.$emit('clickButton');
    },
    clickBackButton() {
      this.$emit('clickBack');
    },
  },
};
</script>

<style scoped>
.step-container {
  padding: 10px;
  height: 100%; }
.step-container div a.btn {
  border-radius: 555px;
  padding: 1px 20px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  text-decoration: none;
  margin-left: 7px;
  font-weight: 300;
  letter-spacing: .1em;
  font-size: .7em; }
.step-container .step-box {
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  min-height: 100px;
  height: inherit; }
.step-container .step-circle {
  position: absolute;
  border-radius: 50%;
  line-height: 60px;
  width: 60px;
  height: 60px;
  background-color: var(--module-color, #c7d853); }
.step-container .step {
  position: absolute;
  border-radius: 50%;
  color: white;
  font-size: 30px;
  top: 0;
  left: 23px;
  height: 100%;
  width: 100%;
  float: left; }
.step-container .text {
  margin-left: 80px; }
.step-container .step-text {
  height: 50%;
  font-weight: bold;
}
.step-container .result-text {
  min-height: 30px; }
.step-container .result-text div a.btn {
  float: none;
}
.step-container .error-text {
  color: #ff0000;
  display: none; }
</style>
